import React, { Component } from 'react';

import ActionItem from '../action_item';
import I18n from 'common/i18n';

interface Props {
  isCatalogFederatedView: boolean;
  isDataFederatedView: boolean;
  sourceLink?: string;
  link: string;
}
class GoToSourceActionItem extends Component<Props> {
  shouldRender = () => {
    const { isCatalogFederatedView, isDataFederatedView } = this.props;
    return isCatalogFederatedView || isDataFederatedView;
  };

  render() {
    if (!this.shouldRender()) {
      return null;
    }

    const { link, sourceLink, isDataFederatedView } = this.props;
    const assetLink = isDataFederatedView ?
      sourceLink! :
      link;

    const label = I18n.t('shared.asset_browser.result_list_table.action_dropdown.go_to_source');
    return (
      <ActionItem
        label={label}
        href={assetLink}
        target={'_blank'}
      />
    );
  }
}

export default GoToSourceActionItem;
