import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { View } from 'common/types/view';
import type { GuidanceSummaryV2 } from 'common/types/approvals';

import I18n from 'common/i18n';
import AccessManager, { AccessManagerProps } from 'common/components/AccessManager';
import { MODES as ACCESS_MANAGER_MODES } from 'common/components/AccessManager/Constants';
import { defaultAccessManagerOnConfirm, PermissionsSavedPayload } from 'common/components/AccessManager/Util';
import currentUserCanTransferOwnership from 'common/views/current_user_can_transfer_ownership';

import ActionItem from '../action_item';

export interface TransferOwnershipActionItemProps {
  view: View;
  setCurrentModal: (
    modal: typeof AccessManager,

    // 'onDismiss' and 'view' are both passed in by `setCurrentModal`
    props: Omit<AccessManagerProps, 'onDismiss' | 'view'>
  ) => void;
  approvalsGuidance: GuidanceSummaryV2;
  closeCurrentModal: () => void;
  transferOwnership: (permissionsInfo: PermissionsSavedPayload) => void;
}

class TransferOwnershipActionItem extends Component<TransferOwnershipActionItemProps> {
  shouldRender() {
    const { view, approvalsGuidance } = this.props;

    if (isEmpty(view) || view.locked) {
      return false;
    }

    return currentUserCanTransferOwnership(view, approvalsGuidance);
  }

  render() {
    const { setCurrentModal, closeCurrentModal, transferOwnership } = this.props;
    const closeCurrentModalAndUpdate = (updatePayload: PermissionsSavedPayload) => {
      closeCurrentModal();
      transferOwnership(updatePayload);
    };

    if (!this.shouldRender()) {
      return null;
    }

    return (
      <ActionItem
        label={I18n.t('shared.asset_browser.result_list_table.action_dropdown.transfer_ownership')}
        onClick={() =>
          setCurrentModal(AccessManager, {
            mode: ACCESS_MANAGER_MODES.CHANGE_OWNER,
            onConfirm: defaultAccessManagerOnConfirm(closeCurrentModalAndUpdate)
          })
        }
      />
    );
  }
}

export default TransferOwnershipActionItem;
