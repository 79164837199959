/*
  NOTE: This file contains helper functions for the ManageMetadataComponent that are intended for reuse outside of the
  component (e.g., in frontend, etc.). If you need a helper function that isn't in this file, consider moving it here
  instead of importing it from within the *private* helper files inside the component. The ManageMetadataComponent
  maintains a "public API" via the index file. Any functions not exported there are subject to change without regard for
  external dependencies. If a function will be used externally, it should be included in this file to ensure that anyone
  making changes is explicitly aware of its external use.
*/
import { omit as _omit } from 'lodash';
import { isErrorCell } from 'common/components/DatasetTable/cell/TableCell';
import { PhxChannel } from 'common/types/dsmapi';
import { FieldInput, TemplateResult } from 'common/types/metadataTemplate';
import { RevisionMetadata } from 'common/types/revision';
import { cellErrorMessageToString } from 'common/dsmapi/metadataTemplate';

export interface EvaluationPayload {
  inputs: FieldInput[];
  metadata: RevisionMetadata;
  templates: string[];
}

export interface EvaluationResult {
  results: TemplateResult[];
  metadata: RevisionMetadata;
  errors: string[];
}

type RawEvaluationResult = Omit<EvaluationResult, 'errors'>;

export const evaluateMetadata = (
  channel: PhxChannel,
  payload: EvaluationPayload
): Promise<EvaluationResult> => {
  return new Promise((resolve, reject) => {
    channel
      .push('run', {
        inputs: payload.inputs,
        // Make sure to omit this unrelated stuff because
        // we don't care or use it and it can be very big
        metadata: _omit(payload.metadata, ['columns', 'inputs', 'queryString']),
        templates: payload.templates
      })
      .receive('ok', (response: RawEvaluationResult) => {
        const errors = response.results.flatMap((templateResult) =>
          templateResult.result.flatMap((cellResult) =>
            isErrorCell(cellResult.results[0]) ? [cellErrorMessageToString(cellResult.results[0])] : []
          )
        );
        resolve({ ...response, errors });
      })
      .receive('error', (error) => {
        reject(error);
      });
  });
};
