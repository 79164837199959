import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

import I18n from 'common/i18n';

import ActionItem from '../action_item';
import {
  showToastNow,
  showToastOnPageReload,
  ToastType
} from 'common/components/ToastNotification/Toastmaster';
import { withGuidanceV2 } from 'common/core/approvals/index_new';
import { checkStatus } from 'common/http';
import { explicitWithdrawApprovalModal } from 'common/components/AssetActionBar/components/confirmation_dialog/utils';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { View } from 'common/types/view';
import currentUserCanWithdrawApprovalRequest from 'common/views/current_user_can_withdraw_approval_request';

export interface WithdrawFromApprovalActionItemProps {
  view: View;
  approvalsGuidance: GuidanceSummaryV2;
}

class WithdrawFromApprovalActionItem extends Component<WithdrawFromApprovalActionItemProps> {
  shouldRender() {
    const { approvalsGuidance, view } = this.props;

    if (isEmpty(view) || view.locked) {
      return false;
    }

    return currentUserCanWithdrawApprovalRequest(view, approvalsGuidance);
  }

  render() {
    const { approvalsGuidance, view } = this.props;
    const scope = 'shared.asset_browser.result_list_table';

    if (!this.shouldRender()) {
      return null;
    }

    const onClick = () => {
      explicitWithdrawApprovalModal().then((confirmResolution) => {
        if (confirmResolution.confirmed) {
          withGuidanceV2(approvalsGuidance)
            .withdraw()
            .then(checkStatus)
            .then(() => {
              showToastOnPageReload({
                type: ToastType.SUCCESS,
                content: I18n.t('toasts.withdraw_approval_success', { name: view.name, scope })
              });

              window.location.reload();
            })
            .catch((err) => {
              showToastNow({
                type: ToastType.ERROR,
                content: I18n.t('toasts.unknown_error', { scope })
              });

              console.error('Error withdrawing approval request', err);
            });
        }
      });
    };

    return (
      <ActionItem
        label={I18n.t('action_dropdown.withdraw_from_approval', { scope })}
        onClick={onClick} />
    );
  }
}

export default WithdrawFromApprovalActionItem;
