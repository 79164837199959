export default [
  {
    name: 'SELECT',
    translationKey: 'shared.query_editor.keyword_list.select'
  },
  {
    name: 'JOIN',
    translationKey: 'shared.query_editor.keyword_list.join'
  },
  {
    name: 'WHERE',
    translationKey: 'shared.query_editor.keyword_list.where'
  },
  {
    name: 'GROUP BY',
    translationKey: 'shared.query_editor.keyword_list.group_by'
  },
  {
    name: 'HAVING',
    translationKey: 'shared.query_editor.keyword_list.having'
  },
  {
    name: 'ORDER BY',
    translationKey: 'shared.query_editor.keyword_list.order_by'
  }
];
