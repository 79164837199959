import {
  defaultHeaders,
  fetchJson,
  fetchWithDefaultHeaders
} from 'common/http/index';

const BASE_URL = '/api/config_gates/for/provisions';

export const getAllGates = () => {
  const options = {
    credentials: 'same-origin',
    headers: defaultHeaders
  };

  return fetchJson(BASE_URL, options);
};

export const createGate = ({ provisionLookup, reason, notificationTargets }) => {
  const body = { provisionLookup, reason, notificationTargets };
  const options = {
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'POST'
  };

  return fetchJson(BASE_URL, options);
};

export const updateGate = ({ provisionLookup, reason, notificationTargets }) => {
  const apiPath = `${BASE_URL}/${provisionLookup}`;
  const body = { provisionLookup, reason, notificationTargets };
  const options = {
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT'
  };

  return fetchWithDefaultHeaders(apiPath, options);
};

export const deleteGate = provisionLookup => {
  const apiPath = `${BASE_URL}/${provisionLookup}?name=${provisionLookup}`;
  const options = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'DELETE'
  };

  return fetchWithDefaultHeaders(apiPath, options);
};

export const addAllowedUsers = (provisionLookup, userIds) => {
  const apiPath = `${BASE_URL}?method=addAllowedUsers&name=${provisionLookup}`;
  const body = { userIds };
  const options = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    body: JSON.stringify(body),
    method: 'PUT'
  };

  return fetchWithDefaultHeaders(apiPath, options); // no response body, so fetchJson fails
};

export const replaceAllowedUsers = (gateName, userIds) => {
  const apiPath = `${BASE_URL}?method=replaceAllowedUsers&name=${gateName}`;
  const body = { userIds };
  const options = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    body: JSON.stringify(body),
    method: 'PUT'
  };

  return fetchWithDefaultHeaders(apiPath, options); // no response body, so fetchJson fails
};
