import React from 'react';
import {
  uniqWith as _uniqWith,
  isUndefined as _isUndefined,
  includes as _includes,
  cloneDeep as _cloneDeep
} from 'lodash';
import I18n from 'common/i18n';
import { uniqColumnRefs } from 'common/soql/soql-helpers';
import { getBuiltins, getCustomFieldsets, hasRequiredExprs, intoMetadataComponents } from 'common/dsmapi/metadataTemplate';
import { isColumnEqualIgnoringPosition, ColumnRef } from 'common/types/soql';
import {
  getInputValueFromAssetMetadata,
  templateResultToErrors,
  templateResultToValue
} from '../utils/helpers';
import WithFlashMessage from '../../containers/WithFlashMessageContainer';
import BuiltinFieldset from '../fieldSets/BuiltinFieldset';
import CustomFieldset from '../fieldSets/CustomFieldset';
import { Json } from 'common/components/ObjectEditor';
import { getCurrentUser } from 'common/current_user';
import {
  FieldIdentifier,
  FieldsetIdentifier,
  FieldInput,
  MetadataType,
  FieldT,
  FieldValue,
  MetadataTemplate,
  TemplateResult
} from 'common/types/metadataTemplate';
import { PhxChannel } from 'common/types/dsmapi';
import { AssetMetadata, NestedFieldInputs, OnUploadAttachmentSignature } from '../../types';
import UserSegment from 'common/types/users/userSegment';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.dataset_tab') =>
  I18n.t(key, { scope });

const BUILT_IN_SUBSCRIPT = '__builtin__';

export interface TemplatedMetadataFormProps {
  channel: PhxChannel | null;
  metadataTemplates: MetadataTemplate[];
  templateResults: TemplateResult[];
  requestInProgress: boolean;
  setTemplateErrors: (errors: string[]) => void;
  evaluateMetadata: (inputs: FieldInput[]) => Promise<AssetMetadata[]>;
  assetMetadata: AssetMetadata;
  formSubmitted: boolean;
  handleDatasetFormSubmit: () => void;
  handleMetadataChange: (newAssetMetadata: AssetMetadata) => void;
  onUploadAttachment: OnUploadAttachmentSignature;
  isTabular: boolean;
}

interface TemplatedMetadataFormState {
  fieldInputs: NestedFieldInputs;
}

class TemplatedMetadataForm extends React.Component<TemplatedMetadataFormProps, TemplatedMetadataFormState> {
  constructor(props: TemplatedMetadataFormProps) {
    super(props);

    this.state = {
      fieldInputs: {}
    };
  }

  componentDidMount = () => {
    const { evaluateMetadata, channel } = this.props;
    const { fieldInputs } = this.state;

    if (channel) {
      const initializedInputs = this.initializeInputs(fieldInputs);

      this.setState({ ...this.state, fieldInputs: initializedInputs });
      evaluateMetadata(this.getFieldInputs(initializedInputs));
    }
  };

  componentDidUpdate = (prevProps: Readonly<TemplatedMetadataFormProps>) => {
    const { channel, evaluateMetadata } = this.props;
    const { channel: oldChannel } = prevProps;
    const { fieldInputs } = this.state;
    if (!oldChannel && channel) {
      const initializedInputs = this.initializeInputs(fieldInputs);

      this.setState({ ...this.state, fieldInputs: initializedInputs });
      evaluateMetadata(this.getFieldInputs(initializedInputs));
    }
  };

  getBuiltins = (): FieldIdentifier[] => {
    const { metadataTemplates } = this.props;
    return getBuiltins(metadataTemplates);
  };

  getCustomFieldsets = (): FieldsetIdentifier[] => {
    const { metadataTemplates } = this.props;
    return getCustomFieldsets(metadataTemplates);
  };

  initializeInputs = (fieldInputs: NestedFieldInputs) => {
    const { metadataTemplates, assetMetadata } = this.props;
    const updatedFieldInputs = _cloneDeep(fieldInputs);

    const allColumnRefs = this.getAllColumnRefs();

    allColumnRefs.forEach((columnRef) => {
      const qualifier = columnRef.qualifier || BUILT_IN_SUBSCRIPT;
      const qualifiedValues = updatedFieldInputs[qualifier];
      const modifiedValue = qualifiedValues && qualifiedValues[columnRef.value];

      // '' is falsy, but we want to allow people to ''ify values
      if (_isUndefined(modifiedValue)) {
        const inputValue = getInputValueFromAssetMetadata(assetMetadata, columnRef, metadataTemplates);
        if (qualifiedValues) {
          updatedFieldInputs[qualifier][columnRef.value] = inputValue;
        } else {
          updatedFieldInputs[qualifier] = {
            [columnRef.value]: inputValue
          };
        }
      }
    });

    return updatedFieldInputs;
  };

  onUpdateInput = async (columnRef: ColumnRef, value: Json) => {
    const { handleMetadataChange, evaluateMetadata } = this.props;
    const { fieldInputs } = this.state;

    const qualifierKey = columnRef.qualifier || BUILT_IN_SUBSCRIPT;
    const updatedFieldInputs: NestedFieldInputs = {
      ...fieldInputs,
      [qualifierKey]: {
        ...fieldInputs[qualifierKey],
        ...this.clearedDependentFields(columnRef),
        [columnRef.value]: value
      }
    };

    const inputs = this.getFieldInputs(updatedFieldInputs);
    this.setState({ ...this.state, fieldInputs: updatedFieldInputs });

    const newMetadata = await evaluateMetadata(inputs);
    newMetadata.forEach((newAssetMetadata: AssetMetadata) => {
      handleMetadataChange(newAssetMetadata);
    });
  };

  getDependentFields = (columnRef: ColumnRef): FieldT[] => {
    const { metadataTemplates } = this.props;
    return metadataTemplates.flatMap((template) => {
      return template.custom_fields.flatMap((fieldSet) => {
        // Dependent fields are always in the same fieldset
        if (fieldSet.fieldset_qualifier !== columnRef.qualifier) {
          return [];
        }
        return fieldSet.fields.filter((field) => {
          const metadataComponents = intoMetadataComponents(
            fieldSet.fieldset_qualifier,
            field.field_name,
            field.parsed_expr,
            field.labels_options
          );

          return metadataComponents.match({
            none: () => false,
            some: (components) => {
              switch (components.type) {
                case MetadataType.dependentSelectWithSelectParent:
                case MetadataType.dependentSelectWithMultiSelectParent:
                case MetadataType.dependentMultiSelect:
                  return (
                    components.parentField && isColumnEqualIgnoringPosition(columnRef, components.parentField)
                  );
                default:
                  return false;
              }
            }
          });
        });
      });
    });
  };

  clearedDependentFields = (columnRef: ColumnRef): Record<string, Json> => {
    const dependentFields = this.getDependentFields(columnRef);
    return dependentFields.reduce(
      (accumulator, field) => ({
        ...accumulator,
        [field.field_name]: null
      }),
      {}
    );
  };

  getAllColumnRefs = (): ColumnRef[] => {
    const { metadataTemplates } = this.props;

    return _uniqWith(
      metadataTemplates.flatMap((template) =>
        template.builtin_fields
          .concat(template.custom_fields.flatMap((fieldSet) => fieldSet.fields))
          .flatMap((field) => uniqColumnRefs(field.parsed_expr))
      ),
      isColumnEqualIgnoringPosition
    );
  };

  getFieldInputs = (fieldInputs: NestedFieldInputs): FieldInput[] => {
    const allColumnRefs = this.getAllColumnRefs();

    return this.getInputsForColumnRefs(allColumnRefs, fieldInputs);
  };

  getInputsForColumnRefs = (columnRefs: ColumnRef[], fieldInputs: NestedFieldInputs) => {
    return columnRefs.map((columnRef) => {
      const qualifiedValues = fieldInputs[columnRef.qualifier || BUILT_IN_SUBSCRIPT];

      const inputValue =
        qualifiedValues && !_isUndefined(qualifiedValues[columnRef.value])
          ? qualifiedValues[columnRef.value]
          : null; // We're trying to get the input before we've initialized it. Just return null for now.

      const newFieldInput: FieldInput = {
        qualifier: columnRef.qualifier,
        field_name: columnRef.value,
        value: inputValue
      };

      return newFieldInput;
    });
  };

  getValue = (identifier: FieldIdentifier): FieldValue => {
    const { templateResults } = this.props;
    const { fieldInputs } = this.state;

    const columnRefs = identifier.instances.flatMap((fieldInstance) =>
      uniqColumnRefs(fieldInstance.parsed_expr)
    );

    return {
      inputs: this.getInputsForColumnRefs(columnRefs, fieldInputs),
      errors: templateResultToErrors(templateResults, identifier),
      output: templateResultToValue(templateResults, identifier)
    };
  };

  render() {
    // The user has either hit save already.
    // The only thing formSubmitted/hasSubmitted does is supress all the red validation errors when someone
    // initially loads a revision and nothing is filled out, otherwise the validation errors should show.
    const {
      handleDatasetFormSubmit,
      formSubmitted: hasSubmitted,
      assetMetadata,
      requestInProgress,
      onUploadAttachment,
      isTabular
    } = this.props;

    const builtins = this.getBuiltins();

    const nameAndDescription = builtins.filter((field) =>
      _includes(['name', 'description'], field.fieldName)
    );
    const rowLabel = builtins.filter((field) => _includes(['row_label'], field.fieldName));
    const contactEmail = builtins.filter((field) => _includes(['contact_email'], field.fieldName));
    const categoriesAndTags = builtins.filter((field) => _includes(['category', 'tags'], field.fieldName));
    const licenseAndAttribution = builtins.filter((field) =>
      _includes(['license_id', 'attribution', 'attribution_link'], field.fieldName)
    );
    const resourceName = builtins.filter((field) => _includes(['resource_name'], field.fieldName));
    const attachments = builtins.filter((field) => _includes(['attachments'], field.fieldName));

    const isCommunityUser = getCurrentUser()?.userSegment === UserSegment.CommunityUser;

    const showRowLabel = isTabular ||
      rowLabel.some(field => hasRequiredExprs(field.instances, field.qualifier) || !!assetMetadata.builtIn.rowLabel);

    return (
      <WithFlashMessage className="dataset-form-flash">
        <div id="metadata-component-dataset-form-container">
          <span className="metadata-component-dataset-form-required-note">
            {I18n.t('shared.dataset_management_ui.metadata_manage.required_note')}
          </span>
          <form
            onSubmit={handleDatasetFormSubmit}
            className="metadata-component-dataset-form"
            id="datasetForm"
          >
            {/*
              I'm commenting this out for now because:
              Until we build the admin page, the only template ever allowed will be the 'default' template,
              which is generated from the domain's config.
              So for a time, we will just have feature parity, though the underlying mechanism for validation will
              be different

              <div className='applicable-templates'>
                  {
                    this.state.templates.map(templates => (
                      <SimpleMultiSelect
                        options={templates.map(t => t.name)}
                        selectedOptions={this.state.applicableTemplates.map(t => t.name)}
                        onSelectedOptionsChanged={this.onSelectedTemplateChange}
                      />
                    )).getOrElseValue(<span></span>)
                  }
                </div> */}

            <BuiltinFieldset
              inProgress={requestInProgress}
              assetMetadata={assetMetadata}
              key="nameDescription"
              title={t('titles.dataset_title')}
              subtitle={t('subtitles.dataset_subtitle')}
              fields={nameAndDescription}
              hasSubmitted={hasSubmitted}
              getValue={this.getValue}
              onUpdateField={this.onUpdateInput}
              onUploadAttachment={onUploadAttachment}
            />

            {/*
              Not sure if we want to, but if special behavior ever becomes
              associated with these fields, we may want to break them into their own components
              like RowLabelField etc.
            */}
            {showRowLabel &&
              <BuiltinFieldset
                inProgress={requestInProgress}
                assetMetadata={assetMetadata}
                key="rowLabel"
                title={t('titles.row_label_title')}
                subtitle={I18n.t('shared.dataset_management_ui.metadata_manage.edit_metadata.row_label_help')}
                fields={rowLabel}
                hasSubmitted={hasSubmitted}
                getValue={this.getValue}
                onUpdateField={this.onUpdateInput}
                onUploadAttachment={onUploadAttachment}
              />}

            <BuiltinFieldset
              inProgress={requestInProgress}
              assetMetadata={assetMetadata}
              key="categoriesTags"
              title={t('titles.tags_title')}
              subtitle={t('subtitles.tags_subtitle')}
              fields={categoriesAndTags}
              hasSubmitted={hasSubmitted}
              getValue={this.getValue}
              onUpdateField={this.onUpdateInput}
              onUploadAttachment={onUploadAttachment}
            />

            <BuiltinFieldset
              inProgress={requestInProgress}
              assetMetadata={assetMetadata}
              key="licenseAttribution"
              title={t('titles.licenses_title')}
              subtitle={''}
              fields={licenseAndAttribution}
              hasSubmitted={hasSubmitted}
              getValue={this.getValue}
              onUpdateField={this.onUpdateInput}
              onUploadAttachment={onUploadAttachment}
            />

            <BuiltinFieldset
              inProgress={requestInProgress}
              assetMetadata={assetMetadata}
              key="contactEmail"
              title={t('titles.contact_title')}
              subtitle={''}
              fields={contactEmail}
              hasSubmitted={hasSubmitted}
              getValue={this.getValue}
              onUpdateField={this.onUpdateInput}
              onUploadAttachment={onUploadAttachment}
            />

            <BuiltinFieldset
              inProgress={requestInProgress}
              assetMetadata={assetMetadata}
              key="resourceName"
              title={t('titles.resource_name_title')}
              subtitle={''}
              fields={resourceName}
              hasSubmitted={hasSubmitted}
              getValue={this.getValue}
              onUpdateField={this.onUpdateInput}
              onUploadAttachment={onUploadAttachment}
            />

            {!isCommunityUser && (
              <BuiltinFieldset
                inProgress={requestInProgress}
                assetMetadata={assetMetadata}
                key="attachments"
                title={t('titles.attachments_title')}
                subtitle={t('subtitles.attachments_subtitle')}
                fields={attachments}
                hasSubmitted={hasSubmitted}
                getValue={this.getValue}
                onUpdateField={this.onUpdateInput}
                onUploadAttachment={onUploadAttachment}
              />
            )}

            {this.getCustomFieldsets().map((fieldSet) => (
              <CustomFieldset
                inProgress={requestInProgress}
                key={fieldSet.qualifier}
                title={fieldSet.fieldsetName}
                subtitle=""
                hasSubmitted={hasSubmitted}
                fields={fieldSet.fields}
                getValue={this.getValue}
                onUpdateField={this.onUpdateInput}
              />
            ))}
          </form>
        </div>
      </WithFlashMessage>
    );
  }
}

export default TemplatedMetadataForm;
