import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';


const Spacer = ({ width }) => {
  if (width <= 0) return null;
  return (<td className="table-cell-spacer" style={{ width }} />);
};

Spacer.propTypes = {
  width: PropTypes.number.isRequired
};

const LeftSpacer = ({ columnsInView, columns, getWidth }) => {
  const fromIdx = 0;
  const toIdx = columnsInView.minColIdx;
  const width = _.sum(columns.slice(fromIdx, toIdx).map(c => getWidth(c.id)));
  return <Spacer width={width} />;
};

const RightSpacer = ({ columnsInView, columns, getWidth }) => {
  const fromIdx = columnsInView.maxColIdx;
  const toIdx = columns.length - 1;
  const width = _.sum(columns.slice(fromIdx, toIdx).map(c => getWidth(c.id)));
  return <Spacer width={width} />;
};

const SpacerProps = {
  getWidth: PropTypes.func.isRequired,
  columnsInView: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired
};

LeftSpacer.propTypes = SpacerProps;
RightSpacer.propTypes = SpacerProps;

export { LeftSpacer, RightSpacer };
