export enum TEAM_ROLES {
  OWNER = 'owner',
  MEMBER = 'member'
}

export enum RIGHTS {
  CAN_COLLABORATE = 'can_collaborate',
  MANAGE_SUPERADMIN_ACCESS = 'manage_superadmin_access',
  MANAGE_TEAMS ='manage_teams',
  MANAGE_USERS ='manage_users'
}
