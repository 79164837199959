import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchboxFilter from './searchbox_filter';
import I18n from 'common/i18n';

import * as filters from '../../actions/filters';
import { TAB_MY_TEAM_ASSETS } from 'common/components/AssetBrowser/lib/constants';

export class OwnedByFilter extends Component {
  render() {
    const { ownedBy, changeOwner, usersList, showTeamsOnly } = this.props;
    const owners = usersList.filter((owner) => showTeamsOnly ? owner?.type === 'team' : true);
    const options = owners.map((user) => ({ title: user.displayName, value: user.id }));

    return (
      <div className="filter-section owned-by">
        <label className="filter-label">
          {I18n.t('shared.asset_browser.filters.owned_by.label')}
        </label>
        <SearchboxFilter
          inputId="owned-by-filter"
          options={options}
          onSelection={changeOwner}
          placeholder={I18n.t('shared.asset_browser.filters.owned_by.placeholder')}
          value={ownedBy?.displayName} />
      </div>
    );
  }
}

OwnedByFilter.propTypes = {
  ownedBy: PropTypes.shape({
    displayName: PropTypes.string,
    id: PropTypes.string
  }),
  changeOwner: PropTypes.func.isRequired,
  usersList: PropTypes.array.isRequired,
  showTeamsOnly: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  ownedBy: state.filters.ownedBy,
  usersList: state.filters.usersList,
  showTeamsOnly: state.header.activeTab === TAB_MY_TEAM_ASSETS
});

const mapDispatchToProps = (dispatch) => ({
  changeOwner: (value) => dispatch(filters.changeOwner(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnedByFilter);
