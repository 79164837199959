import type { FunctionComponent } from 'react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-expect-error
import TypedCell from './TypedCell';
import { ColumnFormat } from 'common/types/viewColumn';

interface Props {
  uid: string;
  isDropping: boolean;
  isError: boolean;
  value: string | null
  format: ColumnFormat;
}

const BlobCell: FunctionComponent<Props> = ({ uid, value, isDropping, format }) => {
  let link = null;
  if (value) {
    link = (<a target="_blank" href={`/views/${uid}/files/${value}`}>{value}</a>);
  }
  return <TypedCell isDropping={isDropping} value={link} format={format} />;
};

export default BlobCell;
