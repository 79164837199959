import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import I18n from 'common/i18n';
import { withGuidanceV2, mapPermissionScopeToTargetAudience } from 'common/core/approvals/index_new';
import currentUserCanManageViewers from 'common/views/current_user_can_manage_viewers';
import GuidanceSummaryV2PropType from 'common/propTypes/GuidanceSummaryV2PropType';

import ActionItem from '../action_item';
import AccessManager from 'common/components/AccessManager';
import { MODES as ACCESS_MANAGER_MODES } from 'common/components/AccessManager/Constants';
import { defaultAccessManagerOnConfirm } from 'common/components/AccessManager/Util';
import { requireApprovalRequestWithdrawalBeforeAction } from 'common/components/AssetActionBar/components/publication_action';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';

class ManageViewersActionItem extends Component {
  static propTypes = {
    approvalsGuidance: GuidanceSummaryV2PropType,
    assetIsStoryDraft: PropTypes.bool,
    closeCurrentModal: PropTypes.func.isRequired,
    revisionInfo: PropTypes.object,
    setCurrentModal: PropTypes.func.isRequired,
    updateAudience: PropTypes.func,
    view: PropTypes.object
  };

  shouldRender() {
    const { approvalsGuidance, assetIsStoryDraft, revisionInfo, view } = this.props;
    if (isEmpty(approvalsGuidance) || isEmpty(view) || view.locked) {
      return false;
    }

    // NOTE: assetIsStoryDraft is a necessary guard here, because window.IS_STORY_PUBLISHED will be missing
    //       which will cause currentUserCanManageViewers to Error if the asset is a story.
    if (revisionInfo || assetIsStoryDraft || view.locked) {
      return false;
    } else {
      return currentUserCanManageViewers(view, approvalsGuidance);
    }
  }

  render() {
    const {
      approvalsGuidance,
      closeCurrentModal,
      setCurrentModal,
      updateAudience,
      updateView,
      view
    } = this.props;
    const closeCurrentModalAndUpdate = (updatePayload) => {
      const { permissions, mode } = updatePayload;
      const targetScope = permissions.scope;
      const targetAudience = mapPermissionScopeToTargetAudience(targetScope);
      const scopeChanging = (targetScope !== view.permissions.scope);
      const willEnterApprovalQueue = (
        scopeChanging &&
        targetAudience &&
        withGuidanceV2(approvalsGuidance).willEnterApprovalQueue(targetAudience)
      );

      // If the scope is changing and will be changed to public or internal audience,
      // we will need to check if the asset will require approval, and show a different message if it will.
      let toastKey = willEnterApprovalQueue
        ? 'shared.components.asset_action_bar.publication_action.submitted_asset_for_approval'
        : `shared.site_chrome.access_manager.${mode}.success_toast`;
      updatePayload.willEnterApprovalQueue = true;

      closeCurrentModal();
      updateAudience(updatePayload);
      // There are some elements of the view (notably the federatedTo list) which
      // changes when the audience changes.This ensures that those elements of the
      // view object stay up to date if someone repeatedly changes the audience.
      updateView();

      showToastNow({
        type: ToastType.SUCCESS,
        content: I18n.t(toastKey)
      });
    };

    if (!this.shouldRender()) { return null; }

    const label = I18n.t('shared.asset_browser.result_list_table.action_dropdown.change_audience');

    const accessManagerProps = {
      mode: ACCESS_MANAGER_MODES.CHANGE_AUDIENCE,
      onConfirm: defaultAccessManagerOnConfirm(closeCurrentModalAndUpdate, false),
      approvalsGuidance
    };

    const onClick = () => {
      return requireApprovalRequestWithdrawalBeforeAction(
        approvalsGuidance,
        () => setCurrentModal(AccessManager, { ...accessManagerProps })
      );
    };

    return (
      <ActionItem
        label={label}
        onClick={onClick} />
    );
  }
}

export default ManageViewersActionItem;
