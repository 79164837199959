import React from 'react';
import { InputProps } from 'common/components/Forms/Input';

/**
 * Respond to an input event and update an input's values.
 * This will set the input to being `valid` and to having no `errorMessage`.
 * Input validity should be re-checked using the input's `onBlur` event,
 * and when the form that the input is in is submitted.
 *
 * Use with React's `useState` hook to track an inputs value as it changes.
 *
 * @param e Input event
 * @param currentState The current props of the input
 * @param setState Function to call to set input state
 *
 * @example
 * ```ts
 * import React, { FunctionComponent, useState } from 'react';
 *
 * import Input, { InputProps } from 'common/components/Forms/Input;
 *
 * const MyComponent: FunctionComponent = () => {
 *   const [myInputState, setMyInputState] = useState<InputProps>({
 *      label: 'My Input',
 *      name: 'myInput',
 *      value: ''
 *   });
 *
 *  return (
 *   <Input
 *      {...myInputState}
 *      onChange={(e) => updateInputState(e, myInputState, setMyInputState)}
 *   />
 *  );
 * }
 * ```
 */
export const updateInputState = (
  e: React.ChangeEvent<HTMLInputElement>,
  currentState: InputProps,
  setState: React.Dispatch<React.SetStateAction<InputProps>>
) =>
  setState({
    ...currentState,
    value: e.target.value,
    valid: true,
    errorMessage: undefined
  });

/**
 * Given input props, will return a "blank" version of the props.
 *
 * This basically means setting an empty value and setting the input as "valid".
 *
 * @param inputProps Input props to add blank values to
 */
export const createBlankInputProps = (inputProps: InputProps): InputProps => ({
  valid: true,
  value: '',
  ...inputProps // intentionally spread last to allow overriding defaults
});
