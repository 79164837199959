import React from 'react';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import PendoIdWrapper, { DisplayStyle } from 'common/components/PendoIdWrapper';
import Button, { VARIANTS } from 'common/components/Button';
import withTooltip from '../hocs/withTooltip';
import { downloadAgent, DownloadResult } from 'common/downloadAgent';
import { fetchTranslation } from 'common/locale';
import { StepProps } from 'common/components/hocs/withStepper/types';

const t = (k: string) => fetchTranslation(k, 'shared.components.setup_agent.name_download_agent');

interface NameDownloadAgentState {
  error: string;
  downloadResult: DownloadResult | null;
}

export default class NameDownloadAgent extends React.Component<StepProps, NameDownloadAgentState> {
  constructor(props: StepProps) {
    super(props);
    this.state = {
      error: '',
      downloadResult: null

    };
    this.downloadAgent = this.downloadAgent.bind(this);
  }

  componentDidMount() {
    const { handleStepStateChange, stepState, stepIdx } = this.props;

    handleStepStateChange({
      disableNext: !stepState[`step-${stepIdx}-state`].downloaded
    });
  }

  downloadAgent(e: React.MouseEvent<HTMLButtonElement>) {
    const { stepState, stepIdx } = this.props;
    const newName = stepState[`step-${stepIdx}-state`].name;
    e.preventDefault();
    downloadAgent({ name: newName })
      .then((result) => {
        const { handleStepStateChange } = this.props;
        this.setState({ downloadResult: result });
        handleStepStateChange({
          downloaded: true,
          disableNext: false
        });
      })
      .catch((error: any) => {
        if (error.message) {
          this.setState({ error: error.message });
        } else {
          this.setState({ error: t('unknown_error') });
        }
      });
  }

  render() {
    const { stepState, handleStepStateChange, stepIdx } = this.props;
    const name = stepState[`step-${stepIdx}-state`].name || '';
    const downloaded = stepState[`step-${stepIdx}-state`].downloaded;
    const Tip = withTooltip(
      <button className="provision-agent-tooltip-launcher">
        {t('tooltip_launcher_text')}
      </button>,
      <span className="provision-agent-tooltip-text">
        <p>{t('tooltip_text_p1')}</p>
        <p>{t('tooltip_text_p2')}</p>
      </span>
    );
    const downloadDisabled = !name || downloaded;

    return (
      <div className="provision-agent-step">
        <h5>
          {t('name_and_download')}
          <Tip />
        </h5>
        <div className="stepper-content">
          <label
            htmlFor="agent-name"
            className="provision-agent">
            {t('name_required')}
          </label>
          <input
            id="agent-name"
            type="text"
            name="Agent Name"
            placeholder={t('agent_name_ex')}
            value={name}
            onChange={e => handleStepStateChange({
              name: e.target.value
            })}
          />
          <p className="tips">
            {t('naming_tip')}
            <span>{t('naming_tip_example')}</span>
          </p>
          <PendoIdWrapper id="download-agent-button" displayStyle={DisplayStyle.BLOCK}>
            <Button
              disabled={downloadDisabled}
              variant={downloadDisabled ? VARIANTS.DEFAULT : VARIANTS.PRIMARY}
              onClick={this.downloadAgent}>
              <SocrataIcon name={downloaded ? IconName.Check2 : IconName.Download} />
              {downloaded ? t('agent_downloaded') : t('download_agent')}
            </Button>
          </PendoIdWrapper>
          <span className="provision-error-message">{this.state.error}</span>
          <p>{t('agent_download_will')}</p>

          {
            this.state.downloadResult ?
            <div className="verify-download inmodal-code-window">
              <p>{t('verify_download')}</p>
              <code>sha256sum {this.state.downloadResult.filename}</code>
              <p>{t('expected_value')}</p>
              <code>{this.state.downloadResult.sha256}</code>
            </div> :
            null
          }
        </div>
      </div>
    );
  }
}
