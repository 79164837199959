import { createReducer } from '@reduxjs/toolkit';
import {
  MetadataChannelActionType,
  MetadataChannelJoinedAction,
  MetadataChannelNewEvaluationRequestAction,
  MetadataChannelReceivedEvaluationResultsAction
} from '../actions/metadataChannel';
import { MetadataTemplate, TemplateResult } from 'common/types/metadataTemplate';
import { PhxChannel } from 'common/types/dsmapi';

export interface MetadataChannelState {
  channel: PhxChannel | null;
  socketInfo: {
    connectionEstablishedOnce: boolean;
    connectionErrorCount: number;
  };
  metadataTemplates: MetadataTemplate[];
  latestRequestId: string;
  templateResults: TemplateResult[];
}

export const initialState: MetadataChannelState = {
  channel: null,
  socketInfo: {
    connectionEstablishedOnce: false,
    connectionErrorCount: 0
  },
  metadataTemplates: [],
  latestRequestId: '',
  templateResults: []
};

const metadataChannelReducer = createReducer<MetadataChannelState>(initialState, (builder) => {
  builder
    .addCase(
      MetadataChannelActionType.METADATA_CHANNEL_JOINED,
      (state: MetadataChannelState, action: MetadataChannelJoinedAction) => ({
        ...state,
        channel: action.channel,
        metadataTemplates: action.templates
      })
    )
    .addCase(
      MetadataChannelActionType.METADATA_CHANNEL_SOCKET_CONNECTED,
      (state: MetadataChannelState): MetadataChannelState => ({
        ...state,
        socketInfo: {
          ...state.socketInfo,
          connectionEstablishedOnce: true
        }
      })
    )
    .addCase(
      MetadataChannelActionType.METADATA_CHANNEL_SOCKET_ERROR,
      (state: MetadataChannelState): MetadataChannelState => ({
        ...state,
        socketInfo: {
          ...state.socketInfo,
          connectionErrorCount: state.socketInfo.connectionErrorCount + 1
        }
      })
    )
    .addCase(
      MetadataChannelActionType.METADATA_CHANNEL_NEW_EVALUATION_REQUEST,
      (
        state: MetadataChannelState,
        action: MetadataChannelNewEvaluationRequestAction
      ): MetadataChannelState => ({
        ...state,
        latestRequestId: action.requestId
      })
    )
    .addCase(
      MetadataChannelActionType.METADATA_CHANNEL_CLEAR_EVALUATION_REQUEST,
      (state: MetadataChannelState): MetadataChannelState => ({
        ...state,
        latestRequestId: ''
      })
    )
    .addCase(
      MetadataChannelActionType.METADATA_CHANNEL_RECEIVED_EVALUATION_RESULTS,
      (
        state: MetadataChannelState,
        action: MetadataChannelReceivedEvaluationResultsAction
      ): MetadataChannelState => ({
        ...state,
        templateResults: action.templateResults
      })
    );
});

export default metadataChannelReducer;
