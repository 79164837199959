import PropTypes from 'prop-types';
import React from 'react';
import TypedCell from './TypedCell';
import EditCell from './EditCell';

const DEFAULT_LOCATION = {
  latitude: '',
  longitude: '',
  human_address: {
    street: '',
    city: '',
    state: '',
    zip: ''
  }
};

function LocationCell(props) {
  if (!props.value) {
    return (<TypedCell value={''} format={props.format} />);
  }

  const {
    latitude,
    longitude,
  } = props.isError ? DEFAULT_LOCATION : props.value;

  let ha = (props.isError ? DEFAULT_LOCATION : props.value).human_address;

  if (typeof(ha) === 'string') {
    try {
      ha = JSON.parse(ha);
    } catch {
      console.error(`Unable to JSON parse ${ha}`);
      ha = {};
    }
  }

  const street = ha?.address || '';
  const city = ha?.city || '';
  const state = ha?.state || '';
  const zip = ha?.zip || '';

  if (props.isEditing) {
    // OK - what's going on here is that we need to flatten out the location structure
    // for the EditCell, since it's nested several layers deep, we just want to present
    // to the user a flat set of fields. Then, when they hit save, we need to un-flatten
    // them and nest them back into the expected structure, and then we'll call the actual
    // props.updateCell func with the nested value
    const editable = {
      latitude,
      longitude,
      street,
      city,
      state,
      zip
    };

    const updateCell = (newEditable) => {
      const newLocation = newEditable ? {
        human_address: {
          address: newEditable.street,
          city: newEditable.city,
          state: newEditable.state,
          zip: newEditable.zip
        },
        latitude: newEditable.latitude,
        longitude: newEditable.longitude
      } : newEditable;
      return props.updateCell(newLocation);
    };

    return <EditCell value={editable} updateCell={updateCell} />;
  }


  const renderType = props.format ? props.format.view : 'address_coords';

  const address = `${street} ${city} ${state} ${zip}`;
  const coords = `(${latitude}, ${longitude})`;

  if (renderType === 'address') {
    return (<TypedCell value={address} format={props.format} />);
  } else if (renderType === 'coords') {
    return (<TypedCell value={coords} format={props.format} />);
  } else {
    // default is to render address and coords
    return (<TypedCell value={`${address} ${coords}`} format={props.format} />);
  }
}

LocationCell.propTypes = {
  value: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    human_address: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  format: PropTypes.object,
  isEditing: PropTypes.bool,
  updateCell: PropTypes.func,
  isError: PropTypes.bool
};

export default LocationCell;
