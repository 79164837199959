import _ from 'lodash';

const getInitialState = () => (_.get(window, 'socrata.assetBrowser.staticData.assetInventoryViewModel') || {
  asset_inventory: {
    button_disabled: true,
    show_create_button: false,
    show_schedule_job_button: false
  }
});

export default (state) => {
  if (_.isUndefined(state)) {
    return getInitialState();
  }

  return state;
};
