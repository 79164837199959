import React from 'react';
import I18n from 'common/i18n';
import SocrataIcon, { IconName } from './SocrataIcon';
const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'shared.components.indeterminate_pager', ...options });

interface Props {
  hasNext: boolean;
  hasPrev: boolean;
  onNext: () => void;
  onPrev: () => void;
}
export default function IndeterminatePager({ onNext, onPrev, hasNext, hasPrev }: Props) {
  return (
    <div className="socrata-pager">
      <span className="pager-buttons">
        <button disabled={!hasPrev} className="pager-button-previous" onClick={onPrev}>
          <SocrataIcon name={IconName.ArrowLeft} />
          {t('previous')}
        </button>
        <button disabled={!hasNext} className="pager-button-next" onClick={onNext}>
          {t('next')}
          <SocrataIcon name={IconName.ArrowRight} />
        </button>
      </span>
    </div>
  );
}
