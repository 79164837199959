import React from 'react';
import _ from 'lodash';
import { getName } from './lib';
import { ProvisionPluginProps } from './types';
import { fetchTranslation } from 'common/locale';
const t = (k: string) => fetchTranslation(k, 'shared.components.setup_plugin.naming');

class NamePlugin extends React.Component<ProvisionPluginProps> {
  componentDidMount() {
    const { handleStepStateChange } = this.props;
    handleStepStateChange({
      disableNext: true,
      name: ''
    });
  }

  handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { handleStepStateChange } = this.props;
    const disableNext = e.target.value === '';
    handleStepStateChange({
      disableNext: disableNext,
      name: e.target.value
    });
  };

  render() {
    const name = getName(this.props.stepState);
    return (
      <div className="provision-plugin-step">
        <h5>{t('title')}</h5>
        <div className="stepper-content">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}>
            <label className="provision-plugin">{t('prompt')}</label>
            <div className="provision-controls">
              <input
                name={t('plugin_name')}
                placeholder={t('input_placeholder')}
                value={name}
                onChange={this.handleNameChange} />
            </div>
            <p className="tips">{t('tip')}</p>
            <p className="tips">{t('alpha_numeric_info')}</p>
          </form>
        </div>
      </div>
    );
  }
}

export default NamePlugin;
