import React, { useContext } from 'react';
import I18n from 'common/i18n';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import VideoCard from './VideoCard';
import { VideoSourcesContext, ITopic, IVideoSource } from './VideoSourcesProvider';

type Props = {
  showDrawer?: () => void;
  showFilterButton: boolean;
};

function Videos(props: Props) {
  const { showDrawer, showFilterButton } = props;
  const {
    availableTopics = [],
    selectedTopics = [],
    videoSourcesByKey = {}
  } = useContext(VideoSourcesContext);
  const scope = 'shared.components.videos';

  const renderVideoCards = (videos: IVideoSource[]) => {
    return (
      videos
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((video, index: number) => (
          <VideoCard key={index} {...video} />
        ))
    );
  };

  const renderTopic = (topic: ITopic, index: number) => {
    return (
      <div key={index}>
        <div className="video-guides-category-header forge-typography--subtitle1">
          {topic.label}
        </div>
        <div className="video-guides-grid">
          {renderVideoCards(videoSourcesByKey[topic.key])}
        </div>
      </div>
    );
  };

  const getTopicsToDisplay = () => {
    if (selectedTopics.length === 0) {
      return availableTopics;
    }

    return availableTopics.filter(topic => selectedTopics.indexOf(topic.key) > -1);
  };

  return (
    <div>
      {showFilterButton && (
        <div className="video-guides-videos-filter-button">
          <ForgeButton>
            <button type="button" onClick={showDrawer}>
              <ForgeIcon name="filter_list" />
              {I18n.t('filters', { scope })}
            </button>
          </ForgeButton>
        </div>
      )}
      {getTopicsToDisplay().map(renderTopic)}
    </div>
  );
}

export default Videos;
