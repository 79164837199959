import { cloneDeep as _cloneDeep } from 'lodash';
import { Revision, RevisionMetadata } from 'common/types/revision';
import { AssetMetadata, InnerMetadataObject } from '../../../types';

export const revisionToAssetMetadata = (revision: Revision): AssetMetadata => {
  const { metadata } = revision;
  const innerMetadataObject = metadata.metadata;
  return {
    builtIn: {
      name: metadata.name,
      rowLabel: innerMetadataObject.rowLabel,
      rdfSubject: innerMetadataObject.rdfSubject,
      rdfClass: innerMetadataObject.rdfClass,
      tags: metadata.tags,
      resourceName: metadata.resourceName,
      attachments: revision.attachments,
      license: {
        ...metadata.license,
        licenseId: metadata.licenseId
      },
      attribution: {
        name: metadata.attribution,
        link: metadata.attributionLink
      },
      description: metadata.description,
      category: metadata.category
    },
    privateMetadata: {
      contactEmail: metadata.privateMetadata.contactEmail,
      customFields: _cloneDeep(metadata.privateMetadata.custom_fields)
    },
    customFields: _cloneDeep(metadata.metadata.custom_fields),
    results: {
      blob: innerMetadataObject,
      privateBlob: metadata.privateMetadata
    }
  };
};

export const assetMetadataToRevisionMetadata = (
  assetMetadata: AssetMetadata,
  revision: Revision
): RevisionMetadata => {
  const {
    builtIn: {
      name,
      rowLabel,
      rdfSubject,
      rdfClass,
      resourceName,
      description,
      attachments,
      tags,
      license,
      attribution,
      category
    },
    privateMetadata,
    customFields,
    results
  } = assetMetadata;
  const metadata: InnerMetadataObject = {
    ...results?.blob,
    rowLabel,
    rdfSubject,
    rdfClass,
    custom_fields: customFields,
    availableDisplayTypes: (revision.metadata.metadata as InnerMetadataObject).availableDisplayTypes
  };

  return {
    name,
    resourceName,
    description,
    attachments,
    tags,
    licenseId: license.licenseId,
    license: {
      termsLink: license.termsLink,
      name: license.name
    },
    attribution: attribution.name,
    attributionLink: attribution.link,
    category,
    metadata,
    privateMetadata: {
      ...results?.privateBlob,
      contactEmail: privateMetadata.contactEmail,
      custom_fields: privateMetadata.customFields
    },
    queryString: revision.metadata.queryString,
    columns: revision.metadata.columns,
    clientContext: revision.metadata.clientContext,
    inputs: results?.inputs
  };
};
