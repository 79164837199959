import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { handleEnter } from 'common/dom_helpers/keyPressHelpers';
import I18n from 'common/i18n';

import * as constants from 'common/components/AssetBrowser/lib/constants';
import * as headerActions from '../actions/header';
import AssetCounts from './asset_counts';

export class Header extends Component {
  scope = 'shared.asset_browser.header.asset_tabs';

  getTabDisplayText = (tab) => {
    const { viewingOwnProfile } = this.props;

    // if we're viewing somebody else's profile, show their name
    // (=== false because this can be null if you're looking at your own profile)
    if (viewingOwnProfile === false) {
      const { targetUserDisplayName } = _.get(window, 'socrata.assetBrowser.staticData', {});

      if (tab === constants.TAB_MY_ASSETS) {
        return I18n.t('users_assets', { scope: this.scope, userName: targetUserDisplayName });
      }

      if (tab === constants.TAB_MY_TEAM_ASSETS) {
        return I18n.t('teams_assets', { scope: this.scope, userName: targetUserDisplayName });
      }

      if (tab === constants.TAB_SHARED_TO_ME) {
        return I18n.t('shared_to_user', { scope: this.scope, userName: targetUserDisplayName });
      }
    }

    return I18n.t(_.snakeCase(tab), { scope: this.scope });
  };

  renderTabByKey = (tab) => {
    const { activeTab, changeTab } = this.props;

    const displayText = this.getTabDisplayText(tab);
    const tabClasses = classNames('asset-tab', _.kebabCase(tab), {
      'active': tab === activeTab
    });

    const handleTabClick = (e) => {
      e.preventDefault();
      changeTab(tab);
    };

    return (
      <a
        key={tab}
        href="#"
        className={tabClasses}
        onClick={handleTabClick}
        onKeyDown={handleEnter(() => changeTab(tab), true)}>
        {displayText}
      </a>
    );
  };

  render() {
    const { approvalsSettingsLink, isMobile, showAssetCounts, tabs } = this.props;
    const headerClasses = classNames('header', { 'mobile': isMobile });

    const assetTabs = (
      <div className="asset-tabs">
        {_.keys(tabs).map((tab) => this.renderTabByKey(tab))}
      </div>
    );

    return (
      <div className={headerClasses}>
        {!isMobile && assetTabs}
        {showAssetCounts && <AssetCounts />}
        {approvalsSettingsLink}
        {isMobile && assetTabs}
      </div>
    );
  }
}

Header.propTypes = {
  activeTab: PropTypes.string.isRequired,
  approvalsSettingsLink: PropTypes.node,
  changeTab: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  tabs: PropTypes.object
};

const mapStateToProps = (state) => ({
  activeTab: state.header.activeTab,
  isMobile: state.windowDimensions.isMobile,
  viewingOwnProfile: state.assetBrowserProps.viewingOwnProfile
});

const mapDispatchToProps = (dispatch) => ({
  changeTab: (newTab) => dispatch(headerActions.changeTab(newTab))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
