import * as _ from 'lodash';
import { Option, option, none, some } from 'ts-option';
import cronstrue from 'cronstrue';
import { getLocale } from 'common/locale';

interface Range {
  type: 'range';
  min: number;
  max: number;
  step: number;
}

interface CompoundSpec {
  type: 'compound';
  value: AtomicSpec[];
}

interface Absolute {
  type: 'absolute';
  value: number;
}

interface Unrestricted {
  type: 'unrestricted';
}

type AtomicSpec = Range | Absolute | Unrestricted;
interface SpecError {
  type: 'error';
  reason: string;
}
export type Spec = CompoundSpec | AtomicSpec;

class SpecResult {
  private spec: Spec | null;
  private error: string | null;
  constructor(spec: Spec | null, error: string | null) {
    this.spec = spec;
    this.error = error;
  }

  mapError(cb: (error: string) => void) {
    if (this.error) {
      cb(this.error);
    }
    return this;
  }

  map(cb: (spec: Spec) => void) {
    if (!this.error && this.spec) {
      cb(this.spec);
    }
    return this;
  }

  isError() {
    return !!this.error;
  }

  getError() {
    return this.error;
  }
}

function validNumber(s: string): boolean {
  return !_.isNaN(parseInt(s));
}

function parseAtomic(s: string): AtomicSpec | SpecError {
  if (s === '*') {
    return { type: 'unrestricted' };
  } else if (_.includes(s, '-')) {
    const [range, step] = s.split('/');
    const [min, max] = range.split('-');
    const stepWithDefault = step || '1';
    if (validNumber(min) && validNumber(max) && validNumber(stepWithDefault)) {
      return { type: 'range', min: parseInt(min), max: parseInt(max), step: parseInt(step) || 1 };
    }
  } else if (validNumber(s)) {
    return { type: 'absolute', value: parseInt(s) };
  }
  return { type: 'error', reason: `Could not parse ${s} as a specifier`};
}

function parseSpec(s: string): Spec | SpecError {
  if (_.includes(s, ',')) {
    const value = s.split(',').map(parseAtomic);
    const error = _.find(value, v => v.type === 'error');
    if (error) return error;
    // cheating with the cast here...
    return { type: 'compound', value: value as AtomicSpec[] };
  } else {
    return parseAtomic(s);
  }
}

function specToString(spec: Spec): string {
  if (spec.type === 'unrestricted') return '*';
  if (spec.type === 'absolute') return _.toString(spec.value);
  if (spec.type === 'range') {
    if (spec.step === 1) {
      return `${spec.min}-${spec.max}`;
    } else {
      return `${spec.min}-${spec.max}/${spec.step}`;
    }
  }
  if (spec.type === 'compound') {
    return spec.value.map(specToString).join(',');
  }
  throw new Error(`Unknown spec: ${JSON.stringify(spec)}`);
}

function validateSpec(label: string, spec: Spec | SpecError, min: number, max: number): SpecResult {
  if (spec.type === 'error') return new SpecResult(null, spec.reason);

  const rangeError = `${label} is out of range ${min}-${max}`;
  if (spec.type === 'unrestricted') {
    return new SpecResult(spec, null);
  } else if (spec.type === 'absolute') {
    if (spec.value >= min && spec.value <= max) {
      return new SpecResult(spec, null);
    }
    return new SpecResult(spec, rangeError);
  } else if (spec.type === 'range') {
    if (spec.min >= min && spec.max <= max) {
      return new SpecResult(spec, null);
    }
    return new SpecResult(spec, rangeError);
  } else if (spec.type === 'compound') {
    return spec.value
      .map(v => validateSpec(label, v, min, max))
      .reduce((value: SpecResult, acc: SpecResult) => {
        if (acc.isError()) return acc;
        return value;
      }, new SpecResult(spec, null));
  }
  throw new Error('Spec was unknown?');
}

export default class Cron {
  private spec: string;
  private error: string | undefined;

  private minute: Spec;
  private hour: Spec;
  private day: Spec;
  private month: Spec;
  private dow: Spec;

  constructor(spec: string) {
    this.spec = spec;

    const [minute, hour, day, month, dow] = spec.split(' ');
    this.init('minute', minute, 0, 59);
    this.init('hour', hour, 0, 23);
    this.init('day', day, 1, 31);
    this.init('month', month, 1, 12);
    this.init('dow', dow, 0, 7);
  }

  private init(name: string, value: string, min: number, max: number) {
    const spec = parseSpec(value);
    // could probably group up syntactic errors and semantic errors,
    // and make things more elegant, but this-is-fine.gif
    if (spec.type == 'error') {
      this.error = spec.reason;
    } else {
      validateSpec(name, spec, min, max)
        .map(s => this[name] = s)
        .mapError(e => this.error = e);
    }
  }

  public getError(): Option<string> {
    return option(this.error);
  }

  public isValid(): boolean {
    return !this.error;
  }

  public getMinute(): Spec {
    return this.minute;
  }
  public setMinute(s: Spec): Cron {
    this.minute = s;
    return this;
  }
  public getHour(): Spec {
    return this.hour;
  }
  public setHour(s: Spec): Cron {
    this.hour = s;
    return this;
  }
  public getDay(): Spec {
    return this.day;
  }
  public setDay(s: Spec): Cron {
    this.day = s;
    return this;
  }
  public getMonth(): Spec {
    return this.month;
  }
  public setMonth(s: Spec): Cron {
    this.month = s;
    return this;
  }
  public getDayOfWeek(): Spec {
    return this.dow;
  }
  public setDayOfWeek(s: Spec): Cron {
    this.dow = s;
    return this;
  }

  public humanize(): string {
    try {
      return cronstrue.toString(this.spec, { locale: getLocale() });
    } catch (e) {
      console.error(e);
      // i have no idea
      return '';
    }
  }


  public stringify(): string {
    return [
      specToString(this.minute),
      specToString(this.hour),
      specToString(this.day),
      specToString(this.month),
      specToString(this.dow)
    ].join(' ');
  }
}
