// Note: If you add a new tab, take a look through _result_list_table.scss
// and note the tab-specific rules therein. You may want to define your own
// tab-specific styles or apply the existing styles to your new tab. If you
// don't, you'll get generic styling.
export const TAB_ALL_ASSETS = 'allAssets';
export const TAB_MY_ASSETS = 'myAssets';
export const TAB_MY_TEAM_ASSETS = 'myTeamAssets';
export const TAB_SHARED_TO_ME = 'sharedToMe';
export const TAB_MY_QUEUE = 'myQueue'; // Special tab that renders a link to "Approval History"
export const TAB_FEDERATED = 'federated';
export const TAB_SETTINGS = 'settings';

export type Tab =
  | typeof TAB_ALL_ASSETS
  | typeof TAB_MY_ASSETS
  | typeof TAB_MY_TEAM_ASSETS
  | typeof TAB_SHARED_TO_ME
  | typeof TAB_MY_QUEUE
  | typeof TAB_FEDERATED
  | typeof TAB_SETTINGS;

export const ASSET_SELECTOR = 'assetSelector';

export const AUDIENCE_HIDDEN = 'hidden';
export const AUDIENCE_INTERNAL = 'internal';
export const AUDIENCE_PRIVATE = 'private';
export const AUDIENCE_PUBLIC = 'public';

export const AUTHORITY_COMMUNITY = 'community';
export const AUTHORITY_OFFICIAL = 'official';

export const ASSET_UPDATES = 'asset_updates';
export const APPROVERS = 'approvers';
export const WARNING = 'warning';

export const COLUMN_ACTIONS = 'actions';
export const COLUMN_APPROVAL_STATUS = 'status';
export const COLUMN_APPROVAL_SUBMITTED_AT = 'submitted_at';
export const COLUMN_AUDIENCE = 'audience';
export const COLUMN_CATEGORY = 'category';
export const COLUMN_INTENDED_AUDIENCE = 'intended_audience';
export const COLUMN_LAST_UPDATED_DATE = 'lastUpdatedDate';
export const COLUMN_NAME = 'name';
export const COLUMN_OWNER = 'owner';
export const COLUMN_REQUESTER = 'requester';
export const COLUMN_SOURCE = 'source';
export const COLUMN_TYPE = 'type';

export const DESCRIPTION_AWAITING_APPROVAL = 'awaiting_approval';
export const DESCRIPTION_HIDDEN_FROM_CATALOG = 'hidden_from_catalog';
export const DESCRIPTION_SHARED_TO_ME = 'shared_to_me';

export const ICON_EYE_BLOCKED = 'eye-blocked';
export const ICON_MY_ORG = 'my-org';
export const ICON_PRIVATE = 'private';
export const ICON_PUBLIC_OPEN = 'public-open';

// Acceptable render style props to the AssetBrowser and AssetSelector
export const RENDER_STYLE_CARD = 'card';
export const RENDER_STYLE_LIST = 'list';

export const VERSION_DRAFT = 'draft';
export const VERSION_PUBLISHED = 'published';

export const SUPPORT_URL = 'https://support.socrata.com/hc/en-us/articles/360009189933-Approvals';
