import { EmailVerificationApi, Configuration, ErrorResponse } from '@socrata/core-email-verification-api';
import { defaultHeaders } from 'common/http';

/**
 * Core Email Verification API Client
 *
 * Generated from OpenAPI Specification that lives at:
 * https://github.com/socrata/apis/blob/main/specs/email_verification/email_verification.yaml
 */
export default new EmailVerificationApi(
  new Configuration({
    credentials: 'same-origin',
    basePath: window.location.origin,
    headers: defaultHeaders
  })
);

/**
 * Returns a promise that can be used to parse the JSON out of an OpenAPI error response
 * @param error error to parse
 */
export const parseError = (error: Response): Promise<ErrorResponse> =>
  new Promise((resolve, reject) => {
    error
      .json()
      .then((parsed: ErrorResponse) => resolve(parsed))
      .catch((e: any) => reject(`Error parsing error JSON: ${e}`));
  });
