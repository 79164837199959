import React from 'react';
import Stepper from 'common/components/hocs/withStepper/Stepper';
import { FinishOptions, StepOptions } from 'common/components/hocs/withStepper/types';
import PendoIdWrapper, { DisplayStyle } from 'common/components/PendoIdWrapper';
import PluginOverview from './PluginOverview';
import NamePlugin from './NamePlugin';
import RunPlugin from './RunPlugin';
import { Plugin } from 'common/types/gateway';
import { fetchTranslation } from 'common/locale';
const t = (k: string) => fetchTranslation(k, 'shared.components.setup_plugin');

import './index.scss';

const steps: StepOptions[] = [
  { title: t('step_1'), component: PluginOverview },
  { title: t('step_2'), component: NamePlugin },
  { title: t('step_3'), component: RunPlugin }
];

const title = t('title');

interface ProvisionPluginModalProps {
  hideModal: () => void;
  plugin: Plugin;
}

function ProvisionPluginModal({ hideModal, plugin }: ProvisionPluginModalProps) {
  const pageOptions = { plugin: plugin };
  const finishAction: FinishOptions = {
    action: hideModal,
    actionLabel: t('done')
  };
  return (
    <PendoIdWrapper id="provision-plugin-modal" displayStyle={DisplayStyle.BLOCK}>
      <Stepper
        showSteps={true}
        steps={steps}
        onDismiss={hideModal}
        modalTitle={title}
        selectedFinish={finishAction}
        finishOptions={[finishAction]}
        pageOptions={pageOptions} />
    </PendoIdWrapper>
  );
}

export default ProvisionPluginModal;
