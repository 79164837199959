import camelCase from 'lodash/fp/camelCase';
import flow from 'lodash/fp/flow';
import fromPairs from 'lodash/fp/fromPairs';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import toPairs from 'lodash/fp/toPairs';
import toString from 'lodash/fp/toString';
import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import eq from 'lodash/fp/eq';

import { fetchJson, defaultHeaders } from './http';
import { SORT_DIRECTION as COMMON_SORT_DIRECTION } from './Constants';
export const SORT_DIRECTION = COMMON_SORT_DIRECTION;

import { makeGetRequest } from './catalog-service-api';

const formatUserPropertyValue = (key, value) => (key === 'role_id' ? toString(value) : value);
const formatUserPropertyTuple = ([key, value]) => [camelCase(key), formatUserPropertyValue(key, value)];
const formatUserProperties = flow(
  toPairs,
  map(formatUserPropertyTuple),
  fromPairs
);
const mapUserListFromApiResponse = map(formatUserProperties);

export const SORT_KEYS = {
  EMAIL: 'email',
  LAST_AUTHENTICATED_AT: 'last_authenticated_at',
  ROLE_NAME: 'role_name',
  SCREEN_NAME: 'screen_name',
  RELEVANCE: null
};

export const STATUS_FILTERS = {
  ALL: 'ALL',
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED'
};

export const mapStatusFilter = cond([
  [eq(STATUS_FILTERS.ALL), constant(null)],
  [eq(STATUS_FILTERS.DISABLED), constant(true)],
  [eq(STATUS_FILTERS.ENABLED), constant(false)]
]);

export const ONLY_FILTERS = {
  COMMUNITY_MEMBERS: 'community_members',
  SITE_MEMBERS: 'site_members'
};

const order = (orderBy, sortDirection) => (isEmpty(orderBy) ? null : `${orderBy} ${sortDirection}`);

const getUsers = (
  domain,
  {
    query,
    filters,
    orderBy = SORT_KEYS.SCREEN_NAME,
    sortDirection = SORT_DIRECTION.ASC,
    limit,
    offset = 0
  } = {}
) =>
  makeGetRequest('/api/catalog/v1/users', {
    domain,
    limit,
    offset,
    order: order(orderBy, sortDirection),
    q: query,
    ...(filters || {})
  }).then((json) => ({
    ...json,
    resultCount: json.resultSetSize,
    users: mapUserListFromApiResponse(json.results)
  }));

const transformAutocompleteResult = (result) => ({
  ...result,
  title: get('user.screen_name', result)
});

const autocomplete = (domain, query, { only, rights, status = STATUS_FILTERS.ENABLED } = {}) =>
  makeGetRequest('/api/catalog/v1/users/autocomplete', {
    disabled: mapStatusFilter(status),
    domain,
    only,
    q: query,
    rights
  }).then(({ results, ...searchResults }) => ({
    ...searchResults,
    results: results.map(transformAutocompleteResult)
  }));

const buildFetchOptionsFor = (method) => ({
  credentials: 'same-origin',
  headers: defaultHeaders,
  method
});

const resetPassword = (userId) => {
  const apiPath = `/admin/users/${userId}/reset_password`;
  return fetchJson(apiPath, buildFetchOptionsFor('POST'));
};

const changeUserStatus = (userId, disable) => {
  const apiPath = `/api/users/${userId}?method=${disable ? 'disable' : 'enable'}`;
  return fetchJson(apiPath, buildFetchOptionsFor('PUT'));
};

const getSuperAdmins = () =>
  fetchJson('/api/users?admin=true&limit=1000&page=1', defaultHeaders);

const disableUser = (userId) => changeUserStatus(userId, true);

const enableUser = (userId) => changeUserStatus(userId, false);

export default {
  autocomplete,
  disableUser,
  enableUser,
  getSuperAdmins,
  getUsers,
  resetPassword
};
