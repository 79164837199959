import PropTypes from 'prop-types';
import React from 'react';
import Alignment from './Alignment';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';
import { ColumnFormat } from 'common/types/viewColumn';
import { PicklistOption } from 'common/components/Picklist';
import DropdownWithIcon from './DropdownWithIcon';

const t = (k: string): string => I18n.t(k, { scope: 'shared.dataset_management_ui.format_column' });

interface RenderTypeChooserProps {
  onChange: (format: ColumnFormat) => void;
  format: ColumnFormat;
  defaultRender: string;
}
function RenderTypeChooser({ onChange, format, defaultRender }: RenderTypeChooserProps) {
  const dropdownProps = {
    onSelection: (e: PicklistOption) => onChange({ displayStyle: e.value }),
    value: format.displayStyle || defaultRender,
    placeholder: t('render_type'),
    options: [
      {
        title: t('normal'),
        value: 'normal',
        icon: 'socrata-icon-text',
        render: DropdownWithIcon
      },
      {
        title: t('url'),
        value: 'url',
        icon: 'socrata-icon-link',
        render: DropdownWithIcon
      },
      {
        title: t('email'),
        value: 'email',
        icon: 'socrata-icon-email',
        render: DropdownWithIcon
      }
    ]
  };
  return (
    <div>
      <label>{t('render_type')}</label>
      <Dropdown {...dropdownProps} />
    </div>
  );
}

interface Props {
  onUpdateFormat: (format: ColumnFormat) => void;
  format: ColumnFormat;
  showAlignment : boolean;// not shown when hierarchy is choosed
  showRenderAs: boolean; // for many column types that default to text (geometry types, URL), these render options don't make sense and also don't work.
  includeFormTag: boolean;
  defaultAlignment: string;
  defaultRender: string;
}

function TextColumnFormat({
  onUpdateFormat,
  format,
  showRenderAs,
  showAlignment,
  includeFormTag = true,
  defaultAlignment,
  defaultRender,
}: Props) {
  const renderContent = () => {
    return (
      <>
        {showAlignment && <Alignment onChange={onUpdateFormat} format={format} defaultAlignment={defaultAlignment}/>}
        {showRenderAs && <RenderTypeChooser onChange={onUpdateFormat} format={format} defaultRender={defaultRender}/>}
      </>
    );
  };

  return (
    <div id="format-column" data-testid='text-column-format'>
      {includeFormTag ? <form>{renderContent()}</form> : renderContent()}
    </div>
  );
}

export default TextColumnFormat;
