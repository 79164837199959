import './SoQLEditor.scss';
/* eslint react/jsx-boolean-value: 0 */
import React from 'react';
import AceEditor from 'react-ace';
import * as ace from 'ace-builds';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/ext-language_tools';
import './SoQLEditor.scss';
import SoQLMode from './SoQLAceMode.js';


interface Props {
  soql: string;
  height?: string;
}
export default function ReadOnlySoQLEditor(props: Props) {
  return (
    <AceEditor
      theme="monokai"
      mode="sql"
      width="100%"
      height={props.height || '400px'}
      fontSize={20}
      tabSize={2}
      showGutter={true}
      onChange={() => {}}
      name={'column-editor'}
      editorProps={{ $blockScrolling: true }}
      enableBasicAutocompletion={false}
      enableLiveAutocompletion={false}
      defaultValue={props.soql}
      value={props.soql}
      onLoad={() => {
        return (editor: any) => {
          editor.getSession().setMode(new SoQLMode());
        };
      }}
    />
  );
}
