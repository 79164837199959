import * as React from 'react';
import PropTypes from 'prop-types';
import ColumnTypeIcon from 'common/components/ColumnTypeIcon';
import formatString from 'common/js_utils/formatString';
import { fetchTranslation } from 'common/locale';
import { SoQLType } from 'common/types/soql';
import { ColumnLike } from '.';
const t = (k: string) => fetchTranslation(k, 'shared.soql_docs');


const ColumnDoc: React.SFC<Props> = ({ column, abbreviated }) => {
  if (column) {
    const className = abbreviated ? 'dsmui-soql-doc doc-abbreviated' : 'dsmui-soql-doc doc-full';
    return (
      <div className={className}>
        <p className="h4 column-name">
          <ColumnTypeIcon type={column.soqlType} />
          {formatString(t('column'), { fieldName: column.fieldName })}
        </p>
        <p className="h6 column-type">{formatString(t('type'), { type: column.soqlType })}</p>
      </div>
    );
  }

  return null;
};

interface Props {
  column: {
    fieldName: string;
    soqlType: SoQLType;
  };
  abbreviated: boolean;
}

export default ColumnDoc;
