import {
  cloneDeep,
  find,
  forEach,
  get,
  has,
  includes,
  isEmpty,
  isNil,
  keys,
  merge,
  reduce,
  set,
  slice
} from 'lodash';
import I18n from 'common/i18n';
import assert from 'common/assertions/assert';
import { formatValuePlainText, getColumnFormats } from '../helpers/ColumnFormattingHelpers.js';
import RenderByHelper from '../helpers/RenderByHelper';
import { applyIndexsFromPalette } from '../helpers/BucketHelper.js';
import { getDecoratedVif, DecoratedVif } from '../views/map/vifDecorators/vifDecorator';
import * as selectors from '../../authoring_workflow/selectors/vifAuthoring.js';
import CategoricalDataManager from './CategoricalDataManager.js';
import MetadataProvider, { getDisplayableColumns } from './MetadataProvider';
import TimeDataManager from './TimeDataManager.js';
import {
  hasLabelChange,
  mergeCustomPaletteAndBucket
} from 'common/visualizations/helpers/BucketHelper';

// Constants
import {
  COLOR_PALETTE_VALUES,
  DEFAULT_BOOLEAN_VALUE,
  DEFAULT_COLOR_PALETTE,
  EMPTY_TRANSPARENT_COLOR,
  QUANTIFICATION_METHODS
} from '../../authoring_workflow/constants';
import { ColorBucket, ColorPalette, DataValue, Vif } from '../vif';
import { VifAuthoring } from 'common/authoring_workflow/reducers/types';
import { ViewColumn } from 'common/types/viewColumn';
import { DataToRender } from '../views/BaseVisualization/types';
import { getCustomColorPaletteByQuantification } from 'common/visualizations/helpers/palettes/customPaletteHelpers';

const scope = 'shared.visualizations.charts.common';

/**
 * Returns either a default or modified custom color palette.
 *
 * This is handled separately for pieCharts vs barChart/columnChart/timelineChart
 * because pieCharts assign palette colors to the columnNames found in
 * series[0].dataSource.dimension.columnName and the values to group are returned
 * from the CategoricalDataManager in result.rows, whereas barChart, columnChart,
 * and timelineChart use series[0].dataSource.dimension.grouping.columnName
 * and their data managers return the values to group as result.columns.
 */
export const generateCustomColorPalette = async (vifAuthoring: VifAuthoring): Promise<{ customColorPalette: ColorPalette, dimensionColumnName: string }> => {
  const newVif = cloneDeep(selectors.getCurrentVif(vifAuthoring));
  const dimensionColumnName = selectors.getColorPaletteGroupingColumnName(vifAuthoring);
  const visualizationType = selectors.getSelectedVisualizationType(vifAuthoring);
  const isSupportedChartType = includes(
    ['pieChart', 'barChart', 'columnChart', 'timelineChart', 'map', 'scatterChart'],
    visualizationType
  );
  const customPalette = selectors.getCustomColorPalettes(vifAuthoring);
  const currentPalette = has(customPalette, dimensionColumnName) ?
    customPalette[dimensionColumnName] :
    {};

  assert(
    isSupportedChartType && !isNil(dimensionColumnName),
    'To create a custom color palette you need a valid chart type and custom palette configuration'
  );

  if (includes(['barChart', 'columnChart', 'timelineChart', 'comboChart'], visualizationType)) {
    const currentVif = selectors.getCurrentVif(vifAuthoring);

    set(
      newVif,
      'series[0].dataSource.dimension.grouping.columnName',
      dimensionColumnName
    );

    const metadata = await getDatasetMetadata(currentVif);
    const dimensionColumn = selectors.getDimension(vifAuthoring);
    const column = find(metadata.columns, (c: ViewColumn) => c.name === dimensionColumn.columnName);
    const isCalendarDate = !isNil(column) && (column.dataTypeName === 'calendar_date');
    const precision = selectors.getPrecision(vifAuthoring);
    const shouldGetTimeData = (visualizationType === 'timelineChart') && isCalendarDate && (precision !== 'none');

    const result: DataToRender = shouldGetTimeData ?
      await TimeDataManager.getData(newVif) :
      await CategoricalDataManager.getData(newVif);

    // Slicing the result because the first index is 'dimension' which is not a group name
    const groups = slice(result.columns, 1);

    // Reset the indices of the currentPalette groups to -1 before reassigning them
    // An index of -1 makes it so the group is not displayed in the custom color palette list
    const customColorPalette = reduce(currentPalette, (accumulator, value, key) => {
      accumulator[key] = cloneDeep(value);
      accumulator[key].index = -1;
      return accumulator;
    }, {});

    const displayableColumns = getDisplayableColumns(metadata);
    result.columnFormats = getColumnFormats(displayableColumns);

    forEach(groups, (item, index) => {
      const { group, label } = getGroupLabel(item, dimensionColumnName, result);
      const color = has(currentPalette, group) ?
        currentPalette[group].color :
        COLOR_PALETTE_VALUES[DEFAULT_COLOR_PALETTE][index];

      customColorPalette[group] = { color, index, label };
    });
    const newColorPalette = applyIndexsFromPalette(currentPalette, customColorPalette);

    return { customColorPalette: newColorPalette, dimensionColumnName };

  } else if (visualizationType === 'scatterChart' || visualizationType === 'map') {
    const colorByColumn = selectors.getColorPaletteGroupingColumnName(vifAuthoring);
    const quantificationMethod = selectors.getColorByQuantificationMethod(vifAuthoring);
    const currentCustomPalette = getCustomColorPaletteByQuantification(currentPalette, quantificationMethod);
    const vif = get(vifAuthoring, `vifs.${visualizationType}`);
    const decoratedVif = getDecoratedVif(vif).cloneWithSingleSeries(selectors.getCurrentSeriesIndex(vifAuthoring));
    let newColorPalette = await getColorPalette(
      decoratedVif,
      colorByColumn,
      currentCustomPalette,
    );
    newColorPalette = applyIndexsFromPalette(currentCustomPalette, newColorPalette);

    return { customColorPalette: newColorPalette, dimensionColumnName };
  } else if (visualizationType === 'pieChart') {
    set(
      newVif,
      'series[0].dataSource.dimension.columnName',
      dimensionColumnName
    );

    const metadata = await getDatasetMetadata(newVif);
    const displayableColumns = getDisplayableColumns(metadata);

    return Promise.resolve(CategoricalDataManager.getData(newVif).then((result: DataToRender) => {
      let offset = 0;

      result.columnFormats = getColumnFormats(displayableColumns);

      const groups = result.rows;
      const lastIndexInGroups = keys(groups).length - 1;
      // Reset the indices of the currentPalette groups to -1 before reassigning them
      // An index of -1 makes it so the group is not displayed in the custom color palette list
      const customColorPalette = reduce(currentPalette, (accumulator, value, key) => {
        accumulator[key] = cloneDeep(value);
        accumulator[key].index = -1;
        return accumulator;
      }, {});

      forEach(groups, (item, groupIndex: number) => {
        const { group, label } = getGroupLabel(item[0], dimensionColumnName, result);
        let index;

        // This inserts the `(Other)` group at the end of the customColorPalette
        if (group === I18n.t('other_category', { scope })) {
          index = lastIndexInGroups;
          offset = 1;
        } else {
          index = groupIndex - offset;
        }

        const color = has(currentPalette, group) ?
          currentPalette[group].color :
          COLOR_PALETTE_VALUES[DEFAULT_COLOR_PALETTE][index];
        customColorPalette[group] = { color, index, label };
      });
      const newColorPalette = applyIndexsFromPalette(currentPalette, customColorPalette);

      return { customColorPalette: newColorPalette, dimensionColumnName };
    }));
  } else {
    return Promise.reject(new Error(`Custom color palette not supported for chart type : ${visualizationType}`));
  }
};

function getGroupLabel(
  group: DataValue,
  dimensionColumnName: string,
  dataToRender: DataToRender
): { group: string | number, label: string | number } {
  const namedGroups = [I18n.t('no_value', { scope }), I18n.t('other_category', { scope })];
  let label;
  let newGroup;

  if (group === null) {
    label = newGroup = I18n.t('no_value', { scope });
  } else if (namedGroups.includes(group)) {
    label = newGroup = group;
  } else {
    newGroup = group;
    label = formatValuePlainText(group, dimensionColumnName, dataToRender);
  }

  return { group: newGroup, label };
}

function cloneAndResetIndex(palette: ColorPalette): ColorPalette {
  return reduce(palette, (accumulator, value, key) => {
    accumulator[key] = cloneDeep(value);
    accumulator[key].index = -1;
    return accumulator;
  }, {});
}

async function getDatasetMetadata(vif: Vif) {
  const domain = get(vif, 'series[0].dataSource.domain');
  const datasetUid = get(vif, 'series[0].dataSource.datasetUid');
  const metadataProvider = new MetadataProvider({ domain, datasetUid }, true);

  return metadataProvider.getDatasetMetadata();
}

/**
 * Color palettes are dependent on the data that the visualization is based off of. Notably,
 * the buckets or categories that are associated with each color can change when the data changes.
 * This method gets the buckets that should exist based on the current data and merges those
 * buckets into the color palette saved on the VIF (currentPalette). Note that indexes from
 * currentPalette are not preserved, and should be restored by the caller of this function.
 */
async function getColorPalette(
  vif: DecoratedVif,
  /** Column name. The values of this column are used to generate the color palette buckets. */
  colorByColumn: string,
  /** Palette saved on the VIF */
  currentPalette: ColorPalette,
) {
  const metaDataPromise = vif.getDatasetMetadata();
  const noValueLabel = I18n.t('no_value', { scope });
  const datasetMetadata = await metaDataPromise;
  const colorByColumnDetails = find(datasetMetadata.columns, ['fieldName', colorByColumn]);
  const isColorByBooleanColumn = get(colorByColumnDetails, 'renderTypeName') === 'checkbox';

  return RenderByHelper.getColorByBuckets(vif, colorByColumn, null, metaDataPromise).
    then((colorByBuckets: ColorBucket[]) => {
      const newColorPalette = cloneAndResetIndex(currentPalette);
      const isLinearQuantificationMethod = vif.getColorByQuantificationMethod() === QUANTIFICATION_METHODS.linear.value;
      const hasLabelChanged = hasLabelChange(currentPalette, colorByBuckets);

      forEach(colorByBuckets, (colorByBucket, index) => {
        const { color, id, label } = colorByBucket;
        // Color not configured in Vif.
        const newColor = color === EMPTY_TRANSPARENT_COLOR ? color : COLOR_PALETTE_VALUES[DEFAULT_COLOR_PALETTE][index];

        if (vif.isRegionMap() || isLinearQuantificationMethod) {
          newColorPalette[label] = mergeCustomPaletteAndBucket({
            customColorPalette: currentPalette,
            bucket: colorByBucket,
            hasLabelChanged,
            bucketIndex: index
          });
        } else if (isColorByBooleanColumn && isEmpty(id)) {
          const newLabel = currentPalette[noValueLabel] ? noValueLabel : DEFAULT_BOOLEAN_VALUE;

          newColorPalette[noValueLabel] = currentPalette[newLabel] ?
            merge({}, currentPalette[newLabel], { index, label }) :
            merge(colorByBucket, { index, color: newColor });
        } else if (has(currentPalette, label)) {
          // Color already configured in Vif.
          newColorPalette[label] = merge({}, currentPalette[label], { index, id });
        } else if (has(currentPalette.category, label)) {
          // Color already configured in Vif but is using category quantification
          newColorPalette.category[label] = merge({}, currentPalette.category[label], { index, id });
        } else {
          newColorPalette[label] = merge(colorByBucket, { index, color: newColor });
        }
      });

      return newColorPalette;
    });
}

export default {
  generateCustomColorPalette
};
