import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'common/components/Dropdown';
import { fetchTranslation } from 'common/locale';
import { TIME_FORMAT_TITLES } from 'common/DataTypeFormatter';
import { ColumnFormat } from 'common/types/viewColumn';
import { PicklistOption } from 'common/components/Picklist';

// const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.format_column', '');

interface Props {
  onChange: (f: ColumnFormat) => void;
  format: ColumnFormat;
}
const utc_subtitles = {
  'iso_8601_date': "ISO8601 date in the user's local timezone",
  'iso_8601_date_time': "ISO8601 date and time in the user's local timezone",
  'iso_8601_date_utc': 'ISO8601 date in UTC',
  'iso_8601_date_time_utc': 'ISO8601 date and time in UTC',
};

const getSubTitles = (key:string)=> {
  return utc_subtitles[key];
};
function DatetimeFormat({ onChange, format }: Props) {
  const dropdownProps = {
    onSelection: (e: PicklistOption) => onChange({ view: e.value as string }),
    value: format.view || null,
    options: TIME_FORMAT_TITLES.map((option: { title: string; value: string }) => ({
      title: option.title,
      value: option.value,
      render: () => (
        <div>
          <span>{option.title}</span>
          {/* <p className="subtitle">{t(`utc_subtitles.${option.value}`)}</p> */}
          <p className="subtitle">{getSubTitles(option.value)}</p>
        </div>
      )
    }))
  };

  return (
    <div>
      <label>{'Display Format'}</label>
      <Dropdown {...dropdownProps} />
    </div>
  );
}

export default DatetimeFormat;
