// This list of functions was generated from the source code for dev.socrata.com; see https://github.com/socrata/dev.socrata.com
export default {
  'between': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'between ... and ...',
    'type': 'function',
    'function': '$1 between $low and $high',
    'description': 'Returns TRUE for values in a given range',
    'versions': [
      2.1
    ],
    'datatypes': [
      'number',
      'double',
      'money',
      'floating_timestamp',
      'text'
    ],
    'params': {
      '$1': [
        'number',
        'double',
        'money',
        'floating_timestamp',
        'text'
      ],
      '$low': [
        'typeof($1)'
      ],
      '$high': [
        'typeof($1)'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'between',
    'fullpath': '/docs/functions/between'
  },
  'within_box': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'within_box(...)',
    'type': 'function',
    'function': 'within_box($1, $nw_lat, $nw_long, $se_lat, $sw_long)',
    'description': 'Returns the rows that have geodata within the specified box, defined by latitude, longitude corners',
    'versions': [
      2.0,
      2.1
    ],
    'datatypes': [
      'location',
      'point',
      'multipoint',
      'line',
      'multiline',
      'polygon',
      'multipolygon'
    ],
    'params': {
      '$1': [
        'location',
        'point',
        'multipoint',
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ],
      '$nw_lat': [
        'number'
      ],
      '$nw_long': [
        'number'
      ],
      '$se_lat': [
        'number'
      ],
      '$se_long': [
        'number'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'within_box',
    'fullpath': '/docs/functions/within_box'
  },
  'case': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'case(...)',
    'type': 'function',
    'function': 'case($eval, $return, ...)',
    'description': 'Returns different values based on the evaluation of boolean comparisons',
    'versions': [
      2.1
    ],
    'datatypes': [
      'checkbox',
      'double',
      'floating_timestamp',
      'line',
      'multiline',
      'location',
      'money',
      'number',
      'point',
      'multipoint',
      'polygon',
      'multipolygon',
      'text'
    ],
    'params': {
      '$eval': [
        'boolean'
      ],
      '$return': [
        'any'
      ]
    },
    'returns': 'typeof($return)',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'case',
    'fullpath': '/docs/functions/case'
  },
  'lower': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'lower(...)',
    'type': 'function',
    'function': 'lower($1)',
    'description': 'Returns the lowercase equivalent of a string of text',
    'versions': [
      2.1
    ],
    'datatypes': [
      'text'
    ],
    'params': {
      '$1': [
        'text'
      ]
    },
    'returns': 'text',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'lower',
    'fullpath': '/docs/functions/lower'
  },
  'date_extract_dow': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_extract_dow(...)',
    'type': 'function',
    'function': 'date_extract_dow($1)',
    'description': 'Extracts the day of the week as an integer between 0 and 6 (inclusive).',
    'versions': [
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_extract_dow',
    'fullpath': '/docs/functions/date_extract_dow'
  },
  'min': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'min(...)',
    'type': 'function',
    'function': 'min($1)',
    'description': 'Returns the minimum of a given set of numbers',
    'versions': [
      2.1
    ],
    'datatypes': [
      'double',
      'floating_timestamp',
      'money',
      'number',
      'text'
    ],
    'params': {
      '$1': [
        'double',
        'floating_timestamp',
        'money',
        'number',
        'text'
      ]
    },
    'returns': 'typeof($1)',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'min',
    'fullpath': '/docs/functions/min'
  },
  'date_extract_woy': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_extract_woy(...)',
    'type': 'function',
    'function': 'date_extract_woy($1)',
    'description': 'Extracts the week of the year as an integer between 0 and 51 (inclusive).',
    'versions': [
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_extract_woy',
    'fullpath': '/docs/functions/date_extract_woy'
  },
  'intersects': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'intersects(...)',
    'type': 'function',
    'function': 'intersects($1, $2)',
    'description': 'Allows you to compare two geospatial types to see if they intersect or overlap each other',
    'versions': [
      2.1
    ],
    'datatypes': [
      'point',
      'multipoint',
      'line',
      'multiline',
      'polygon',
      'multipolygon'
    ],
    'params': {
      '$1': [
        'point',
        'multipoint',
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ],
      '$2': [
        'point',
        'multipoint',
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'intersects',
    'fullpath': '/docs/functions/intersects'
  },
  'stddev_samp': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'stddev_samp(...)',
    'type': 'function',
    'function': 'stddev_samp($1)',
    'description': 'Returns a sampled standard deviation of a given set of numbers',
    'versions': [
      2.1
    ],
    'datatypes': [
      'number',
      'double',
      'money'
    ],
    'params': {
      '$1': [
        'number',
        'double',
        'money'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'stddev_samp',
    'fullpath': '/docs/functions/stddev_samp'
  },
  'date_trunc_ym': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_trunc_ym(...)',
    'type': 'function',
    'function': 'date_trunc_ym($1)',
    'description': 'Truncates a calendar date at the year/month threshold',
    'versions': [
      2.0,
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'text',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_trunc_ym',
    'fullpath': '/docs/functions/date_trunc_ym'
  },
  'date_trunc_ymd': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_trunc_ymd(...)',
    'type': 'function',
    'function': 'date_trunc_ymd($1)',
    'description': 'Truncates a calendar date at the year/month/date threshold',
    'versions': [
      2.0,
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'text',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_trunc_ymd',
    'fullpath': '/docs/functions/date_trunc_ymd'
  },
  'avg': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'avg(...)',
    'type': 'function',
    'function': 'avg($1)',
    'description': 'Returns the average of a given set of numbers',
    'versions': [
      2.0,
      2.1
    ],
    'datatypes': [
      'number',
      'double',
      'money'
    ],
    'params': {
      '$1': [
        'number',
        'double',
        'money'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'avg',
    'fullpath': '/docs/functions/avg'
  },
  'date_extract_y': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_extract_y(...)',
    'type': 'function',
    'function': 'date_extract_y($1)',
    'description': 'Extracts the year as an integer.',
    'versions': [
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_extract_y',
    'fullpath': '/docs/functions/date_extract_y'
  },
  'not_in': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'not in(...)',
    'type': 'function',
    'function': '$1 not in($2, ...)',
    'description': 'Matches values not in a given set of options',
    'versions': [
      2.1
    ],
    'datatypes': [
      'text',
      'number',
      'double',
      'money',
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'any'
      ],
      '$2': [
        'typeof($1)'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'not_in',
    'fullpath': '/docs/functions/not_in'
  },
  'count': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'count(...)',
    'type': 'function',
    'function': 'count($1)',
    'description': 'Returns a count of a given set of records',
    'versions': [
      2.0,
      2.1
    ],
    'datatypes': [
      'checkbox',
      'double',
      'floating_timestamp',
      'line',
      'location',
      'money',
      'multiline',
      'multipoint',
      'multipolygon',
      'number',
      'point',
      'polygon',
      'text'
    ],
    'params': {
      '$1': [
        'any'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'count',
    'fullpath': '/docs/functions/count'
  },
  'date_trunc_y': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_trunc_y(...)',
    'type': 'function',
    'function': 'date_trunc_y($1)',
    'description': 'Truncates a calendar date at the year threshold',
    'versions': [
      2.0,
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'text',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_trunc_y',
    'fullpath': '/docs/functions/date_trunc_y'
  },
  'sum': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'sum(...)',
    'type': 'function',
    'function': 'sum($1)',
    'description': 'Returns the sum of a given set of numbers',
    'versions': [
      2.1
    ],
    'datatypes': [
      'double',
      'money',
      'number'
    ],
    'params': {
      '$1': [
        'double',
        'money',
        'number'
      ]
    },
    'returns': 'typeof($1)',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'sum',
    'fullpath': '/docs/functions/sum'
  },
  'not_between': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'not between ... and ...',
    'type': 'function',
    'function': '$1 not between $low and $high',
    'description': 'Returns TRUE for values not in a given range',
    'versions': [
      2.1
    ],
    'datatypes': [
      'number',
      'double',
      'money',
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'number',
        'double',
        'money',
        'floating_timestamp'
      ],
      '$low': [
        'typeof($1)'
      ],
      '$high': [
        'typeof($1)'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'not_between',
    'fullpath': '/docs/functions/not_between'
  },
  'date_extract_d': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_extract_d(...)',
    'type': 'function',
    'function': 'date_extract_d($1)',
    'description': 'Extracts the day from the date as an integer.',
    'versions': [
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_extract_d',
    'fullpath': '/docs/functions/date_extract_d'
  },
  'extent': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'extent(...)',
    'type': 'function',
    'function': 'extent($1)',
    'description': 'Returns a bounding box that encloses a set of geometries',
    'versions': [
      2.1
    ],
    'datatypes': [
      'point',
      'multipoint',
      'line',
      'multiline',
      'polygon',
      'multipolygon'
    ],
    'params': {
      '$1': [
        'point',
        'multipoint',
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ]
    },
    'returns': 'polygon',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'extent',
    'fullpath': '/docs/functions/extent'
  },
  'convex_hull': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'convex_hull(...)',
    'type': 'function',
    'function': 'convex_hull($1)',
    'description': 'Returns the minimum convex geometry that encloses all of the geometries within a set',
    'versions': [
      2.1
    ],
    'datatypes': [
      'point',
      'multipoint',
      'line',
      'multiline',
      'polygon',
      'multipolygon'
    ],
    'params': {
      '$1': [
        'point',
        'multipoint',
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ]
    },
    'returns': 'polygon',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'convex_hull',
    'fullpath': '/docs/functions/convex_hull'
  },
  'starts_with': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'starts_with(...)',
    'type': 'function',
    'function': 'starts_with($1, $2)',
    'description': 'Matches on text strings that start with a given substring',
    'versions': [
      2.1
    ],
    'datatypes': [
      'text'
    ],
    'params': {
      '$1': [
        'text'
      ],
      '$2': [
        'text'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'starts_with',
    'fullpath': '/docs/functions/starts_with'
  },
  'date_extract_ss': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_extract_ss(...)',
    'type': 'function',
    'function': 'date_extract_ss($1)',
    'description': 'Extracts the second from the time as an integer.',
    'versions': [
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_extract_ss',
    'fullpath': '/docs/functions/date_extract_ss'
  },
  'simplify': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'simplify(...)',
    'type': 'function',
    'function': 'simplify($1)',
    'description': 'Reduces the number of vertices in a line or polygon',
    'versions': [
      2.1
    ],
    'datatypes': [
      'line',
      'multiline',
      'polygon',
      'multipolygon'
    ],
    'params': {
      '$1': [
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ],
      '$2': [
        'number'
      ]
    },
    'returns': 'typeof($1)',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'simplify',
    'fullpath': '/docs/functions/simplify'
  },
  'date_extract_mm': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_extract_mm(...)',
    'type': 'function',
    'function': 'date_extract_mm($1)',
    'description': 'Extracts the minute from the time as an integer.',
    'versions': [
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_extract_mm',
    'fullpath': '/docs/functions/date_extract_mm'
  },
  'date_extract_hh': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_extract_hh(...)',
    'type': 'function',
    'function': 'date_extract_hh($1)',
    'description': 'Extracts the hour of the day as an integer between 0 and 23 (inclusive).',
    'versions': [
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_extract_hh',
    'fullpath': '/docs/functions/date_extract_hh'
  },
  'max': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'max(...)',
    'type': 'function',
    'function': 'max($1)',
    'description': 'Returns the maximum of a given set of numbers',
    'versions': [
      2.1
    ],
    'datatypes': [
      'double',
      'floating_timestamp',
      'money',
      'number',
      'text'
    ],
    'params': {
      '$1': [
        'double',
        'floating_timestamp',
        'money',
        'number',
        'text'
      ]
    },
    'returns': 'typeof($1)',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'max',
    'fullpath': '/docs/functions/max'
  },
  'in': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'in(...)',
    'type': 'function',
    'function': '$1 in($2, ...)',
    'description': 'Matches values in a given set of options',
    'versions': [
      2.1
    ],
    'datatypes': [
      'text',
      'number',
      'double',
      'money',
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'any'
      ],
      '$2': [
        'typeof($1)'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'in',
    'fullpath': '/docs/functions/in'
  },
  'within_polygon': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'within_polygon(...)',
    'type': 'function',
    'function': 'within_polygon($1, $2)',
    'description': 'Returns the rows that have locations within the specified box, defined by latitude, longitude corners',
    'versions': [
      2.1
    ],
    'datatypes': [
      'point',
      'multipoint',
      'line',
      'multiline',
      'polygon',
      'multipolygon'
    ],
    'params': {
      '$1': [
        'point',
        'multipoint',
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ],
      '$2': [
        'polygon',
        'multipolygon'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'within_polygon',
    'fullpath': '/docs/functions/within_polygon'
  },
  'like': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': "like '...'",
    'type': 'function',
    'function': '$1 like $match',
    'description': 'Allows for substring searches in text strings',
    'versions': [
      2.1
    ],
    'datatypes': [
      'text'
    ],
    'params': {
      '$1': [
        'text'
      ],
      '$match': [
        'text'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'like',
    'fullpath': '/docs/functions/like'
  },
  'num_points': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'num_points(...)',
    'type': 'function',
    'function': 'num_points($1)',
    'description': 'Returns the number of vertices in a geospatial data record',
    'versions': [
      2.1
    ],
    'datatypes': [
      'point',
      'multipoint',
      'line',
      'multiline',
      'polygon',
      'multipolygon'
    ],
    'params': {
      '$1': [
        'point',
        'multipoint',
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'num_points',
    'fullpath': '/docs/functions/num_points'
  },
  'date_extract_m': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'date_extract_m(...)',
    'type': 'function',
    'function': 'date_extract_m($1)',
    'description': 'Extracts the month as an integer.',
    'versions': [
      2.1
    ],
    'datatypes': [
      'floating_timestamp'
    ],
    'params': {
      '$1': [
        'floating_timestamp'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'date_extract_m',
    'fullpath': '/docs/functions/date_extract_m'
  },
  'within_circle': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'within_circle(...)',
    'type': 'function',
    'function': 'within_circle($1, $lat, $long, $radius_m)',
    'description': 'Returns the rows that have locations within a specified circle, measured in meters',
    'versions': [
      2.0,
      2.1
    ],
    'datatypes': [
      'location',
      'point',
      'multipoint',
      'line',
      'multiline',
      'polygon',
      'multipolygon'
    ],
    'params': {
      '$1': [
        'location',
        'point',
        'multipoint',
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ],
      '$lat': [
        'number'
      ],
      '$long': [
        'number'
      ],
      '$radius_m': [
        'number'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'within_circle',
    'fullpath': '/docs/functions/within_circle'
  },
  'not_like': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': "not like '...'",
    'type': 'function',
    'function': '$1 not like $match',
    'description': 'Allows for matching text fields that do not contain a substring',
    'versions': [
      2.1
    ],
    'datatypes': [
      'text'
    ],
    'params': {
      '$1': [
        'text'
      ],
      '$match': [
        'text'
      ]
    },
    'returns': 'boolean',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'not_like',
    'fullpath': '/docs/functions/not_like'
  },
  'upper': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'upper(...)',
    'type': 'function',
    'function': 'upper($1)',
    'description': 'Returns the uppercase equivalent of a string of text',
    'versions': [
      2.1
    ],
    'datatypes': [
      'text'
    ],
    'params': {
      '$1': [
        'text'
      ]
    },
    'returns': 'text',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'upper',
    'fullpath': '/docs/functions/upper'
  },
  'distance_in_meters': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'distance_in_meters(...)',
    'type': 'function',
    'function': 'distance_in_meters($1, $2)',
    'description': 'Returns the distance between two Points in meters',
    'versions': [
      2.1
    ],
    'datatypes': [
      'point'
    ],
    'params': {
      '$1': [
        'point'
      ],
      '$2': [
        'point'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'distance_in_meters',
    'fullpath': '/docs/functions/distance_in_meters'
  },
  'stddev_pop': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'stddev_pop(...)',
    'type': 'function',
    'function': 'stddev_pop($1)',
    'description': 'Returns the population standard deviation of a given set of numbers',
    'versions': [
      2.1
    ],
    'datatypes': [
      'number',
      'double',
      'money'
    ],
    'params': {
      '$1': [
        'number',
        'double',
        'money'
      ]
    },
    'returns': 'number',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'stddev_pop',
    'fullpath': '/docs/functions/stddev_pop'
  },
  'simplify_preserve_topology': {
    'layout': 'with-sidebar',
    'sidebar': 'documentation',
    'title': 'simplify_preserve_topology(...)',
    'type': 'function',
    'function': 'simplify_preserve_topology($1)',
    'description': 'Reduces the number of vertices in a line or polygon, preserving topology',
    'versions': [
      2.1
    ],
    'datatypes': [
      'line',
      'multiline',
      'polygon',
      'multipolygon'
    ],
    'params': {
      '$1': [
        'line',
        'multiline',
        'polygon',
        'multipolygon'
      ],
      '$2': [
        'number'
      ]
    },
    'returns': 'typeof($1)',
    'parent_paths': [
      '/docs/functions/'
    ],
    'parents': [
      'SoQL Function Listing'
    ],
    'name': 'simplify_preserve_topology',
    'fullpath': '/docs/functions/simplify_preserve_topology'
  }
};
