import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import GuidanceSummaryV2PropType from 'common/propTypes/GuidanceSummaryV2PropType';

import ManageViewersActionItem from './action_items/manage_viewers_action_item';
import EditMetadataActionItem from './action_items/edit_metadata_action_item';
import ManageCollaboratorsActionItem from './action_items/manage_collaborators_action_item';
import TransferOwnershipActionItem from './action_items/transfer_ownership_action_item';
import DeleteAssetActionItem from './action_items/delete_asset_action_item';
import LockAssetActionItem from './action_items/lock_asset_action_item';
import WithdrawFromApprovalActionItem from './action_items/withdraw_from_approval_action_item';
import ToggleCatalogVisibility from './action_items/toggle_catalog_visibility';
import GoToSourceActionItem from './action_items/go_to_source_action_item';
import ManageFederationsActionItem from './action_items/manage_federations_action_item';

class ActionItemList extends Component {
  static propTypes = {
    approvalsGuidance: GuidanceSummaryV2PropType,
    assetIsStoryDraft: PropTypes.bool,
    assetType: PropTypes.string.isRequired,
    closeCurrentModal: PropTypes.func.isRequired,
    // null means asset is not a revision
    revisionInfo: PropTypes.object,
    setCurrentModal: PropTypes.func.isRequired,
    updateAudience: PropTypes.func,
    transferOwnership: PropTypes.func,
    view: PropTypes.object
  };

  render() {
    // list of all action items
    const actionItems = [
      GoToSourceActionItem,
      EditMetadataActionItem,
      ManageViewersActionItem,
      ManageCollaboratorsActionItem,
      ManageFederationsActionItem,
      ToggleCatalogVisibility,
      TransferOwnershipActionItem,
      LockAssetActionItem,
      DeleteAssetActionItem,
      WithdrawFromApprovalActionItem
    ];

    // TODO: The above list is never actually filtered, so this is never true
    if (actionItems.length === 0) {
      return (
        <div className="no-actions-possible">
          {I18n.t('shared.asset_browser.result_list_table.action_dropdown.no_actions_possible')}
        </div>
      );
    }

    return (
      <div className="action-dropdown-menu">
        {actionItems.map((ActionItem) => (
          <ActionItem {...this.props} key={ActionItem} />
        ))}
      </div>
    );
  }
}

export default ActionItemList;
