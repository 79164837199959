import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import getLocale from 'common/js_utils/getLocale';

/**
 * Renders a link with a locale prefix, i.e.
 * <LocalizedLink path="/browse" target="_blank">Go to Catalog</LocalizedLink>
 * would render:
 * On an Italian site: <a href="/it/browse" target="_blank">Go to Catalog</a>
 * On an English site: <a href="/en/browse" target="_blank">Go to Catalog</a>
 *
 * It's up to the caller to provide all translations for children. All this component
 * does is add the locale prefix to the URL.
 */
export default class LocalizedLink extends Component {
  render() {
    const url = `/${getLocale()}${this.props.path}`;

    const linkProps = omit(this.props, ['path', 'children']);
    return (
      <a href={url} {...linkProps}>
        {this.props.children}
      </a>
    );
  }
}

LocalizedLink.propTypes = {
  path: PropTypes.any.isRequired
};
