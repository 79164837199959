import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchboxFilter from './searchbox_filter';
import I18n from 'common/i18n';

import * as filters from 'common/components/AssetBrowser/actions/filters';

export class CategoryFilter extends Component {
  flattenCategoriesWithParent(categories) {
    let allCategories = [];

    /// Put data in a shape that will work in the picklist component and the search box component
    categories.forEach(function (x) {
      let parentCategory = {
        'title': x.category,
        'value': x.category,
        'children': []
      };

      if (x.children) {
        x.children.forEach(function (child) {
          let newCategory = {
            'title': child.category,
            'value': child.category,
            'isSubCategory': true
          };
          parentCategory.children.push(newCategory);

        });
      }

      allCategories.push(parentCategory);
      allCategories = allCategories.concat(parentCategory.children);
    });

    return allCategories;
  }

  render() {
    const { category, changeCategory, domainCategories } = this.props;

    return (
      <div className="filter-section category">
        <label className="filter-label">
          {I18n.t('shared.asset_browser.filters.category.label')}
        </label>

        <SearchboxFilter
          inputId="category-filter"
          options={this.flattenCategoriesWithParent(domainCategories)}
          onSelection={changeCategory}
          placeholder={I18n.t('shared.asset_browser.filters.category.placeholder')}
          value={category}
          hasClickableParentCategories={true} />
      </div>
    );
  }
}

CategoryFilter.propTypes = {
  category: PropTypes.string,
  changeCategory: PropTypes.func.isRequired,
  domainCategories: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  category: state.filters.category,
  domainCategories: state.filters.domainCategories
});

const mapDispatchToProps = (dispatch) => ({
  changeCategory: (value) => dispatch(filters.changeCategory(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFilter);
