import { DsmapiUser } from './dsmapi';
import { FieldInput } from './metadataTemplate';
import { ViewColumn } from './viewColumn';
import { SchemaChangeOperation, TaskSet } from './taskSet';
import { ClientContextVariableCreate } from './clientContextVariable';

export const isSoQLBasedRevision = (rev: Revision) => {
  return !!rev.metadata?.queryString;
};

export interface Attachment {
  filename: string;
  asset_id: string;
  name: string;
  blob_id?: string;
  created_at?: string;
}

export interface RevisionMetadata {
  name: string;
  resourceName?: string;
  description: string | null;
  attachments: Attachment[];
  tags: string[] | null;
  licenseId: string | null;
  license: {
    termsLink: string;
    name: string;
  };
  attributionLink: string | null;
  attribution: string | null;
  category: string | null;
  metadata: Record<string, any>;
  privateMetadata: Record<string, any>;
  queryString?: string;
  columns?: Partial<ViewColumn>[];
  inputs?: FieldInput[];
  clientContext?: ClientContext;
}

export interface ClientContext {
  clientContextVariables: ClientContextVariableCreate[];
}

export interface Revision {
  id: number;
  fourfour: string;
  revision_seq: number;
  attachments: Attachment[];
  created_at: Date;
  updated_at: Date;
  blob_id: number | null;
  metadata: RevisionMetadata;
  closed_at: string | null;
  domain_id: number;
  output_schema_id: number | null;
  // this parent concept is something
  // only used by USAID. I'm not really
  // sure what it does. But is_parent is
  // only true when the dataset, er,
  // excuse me..."data asset" is a USAID
  // "data asset".
  is_parent: boolean;
  href?: ExternalData[];
  notes?: string | null;
  created_by: DsmapiUser;
  task_sets: TaskSet[];
  creation_source: 'browser' | 'api' | 'schedule';
  archive?: {
    data_version?: number;
    visible: boolean;
    // This is really unfortunate, but the alternative i think is more
    // complexity explosion. These two properties are purely for HHS,
    // and set by the hhsLegacyShim module
    export_link?: string;
    is_hhs_entry?: boolean;
  };
  action: {
    type: 'update' | 'replace';
  };
}

interface ExternalData {
  urls: Record<string, string>
  data_dictionary?: string,
  data_dictionary_type?: string,
  title?: string
  description?: string,
}

export interface SortBy {
  field_name: string;
  ascending: boolean;
}

export interface License {
  name: string;
}

export interface Tag {}

export interface MetadataChanges {
  name?: string;
  license?: License;
  tags: Tag[];
  description?: string;
  privateMetadata?: any;
  metadata?: any;
  sorts: SortBy[];
  attachments: Attachment[];
  clientContext?: ClientContext;
}

export interface MetadataDiff {
  diff?: {
    old: MetadataChanges;
    new: MetadataChanges;
  };
}

export interface ApplyMetadataPlanStep {
  type: 'apply_metadata';
  will_have_pk?: boolean;
  has_pk_changes?: boolean;
  diff: {
    old: MetadataChanges;
    new: MetadataChanges;
  };
}

export function isApplyMetadataPlanStep(s: PlanStep): s is ApplyMetadataPlanStep {
  return s.type === 'apply_metadata';
}

export interface SetSchemaPlanStep {
  type: 'set_schema';
  operations: SchemaChangeOperation[];
}

export function isSetSchemaPlanStep(s: PlanStep): s is SetSchemaPlanStep {
  return s.type === 'set_schema';
}

export interface UpsertPlanStep {
  type: 'upsert_task';
  total_rows: number;
  error_count: number;
}

export type PlanStep = ApplyMetadataPlanStep | SetSchemaPlanStep | UpsertPlanStep;
export type Plan = PlanStep[];
