import formatString from 'common/js_utils/formatString';
import { fetchTranslation } from 'common/locale';
import _ from 'lodash';
import React from 'react';
import { IconName } from '../SocrataIcon';
import './AssetChanges.scss';
import ChangesWithDetails from './ChangesWithDetails';
import { Tense } from './util';

const t = (k: string) => fetchTranslation(k, 'shared.components.asset_changes.upsert');

interface Props {
  step: {
    total_rows: number;
    error_count: number;
  };
}
function UpsertStep({ step }: Props) {
  const rows = step.total_rows;
  const omitted = step.error_count;

  const details = [];
  if (rows) {
    details.push(
      <span key="inserted-rows">
        {formatString(t(`inserted.${rows > 1 ? 'plural' : 'singular'}`),{
          count: rows
        })}
      </span>
    );
  }
  if (omitted) {
    details.push(
      <span key="deleted-rows" className="deleted-rows">
        {formatString(t(`omitted.${omitted > 1 ? 'plural' : 'singular'}`),{
          count: omitted
        })}
      </span>
    );
  }

  return (
    <div className="asset-change-step">
      <ChangesWithDetails
        icon={IconName.Data}
        details={_.flatMap(details, (el) => [' ', el])}
        contents={null}
        name={t('insert_data')} />
    </div>
  );
}

export default UpsertStep;
