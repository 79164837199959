import React from 'react';
import { Modal, ModalContent, ModalHeader } from 'common/components/Modal';
import { fetchTranslation } from 'common/locale';
const t = (k: string) => fetchTranslation(k, 'shared.components.setup_plugin.navigation_informational');

interface NavHelpModalProps {
  close: () => void;
}

function NavHelpModal({ close }: NavHelpModalProps) {
  return (
    <div id="navigation-informational-modal">
      <Modal onDismiss={close} className="navigation-informational-modal">
        <ModalHeader onDismiss={close} />
        <ModalContent>
          <p>{t('cd_info')}</p>
          <ol>
            <li>{t('open_prompt')}</li>
            <li>
              <p>{t('spec')}</p>
              <p>{t('example')}</p>
              <div className="inmodal-code-window">
                <pre className="hljs shell">
                  {t('example_cd')}
                </pre>
              </div>
            </li>
          </ol>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default NavHelpModal;
