import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import { scgcEnabled } from 'common/domain/helpers';

import * as constants from 'common/components/AssetBrowser/lib/constants';
import * as ceteraHelpers from 'common/components/AssetBrowser/lib/helpers/cetera';
import { APPROVALS } from 'common/core/approvals_enums';

const scope = 'shared.asset_browser.result_list_table.audience_values';

export class AudienceCell extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this,
      'isPublic',
      'isPrivate',
      'isSharedToCurrentUser',
      'isRejected',
      'isPending',
      'isSharedToCurrentUsersTeam',
      'isInternal');
  }

  isPublic() {
    return this.props.visibleToAnonymous;
  }

  isInternal() {
    return scgcEnabled() && this.props.visibleToSite;
  }

  isPrivate() {
    return !this.props.isPublic;
  }

  isSharedToCurrentUser() {
    const currentUserId = _.get(window.socrata, 'currentUser.id');

    return !!_.find(this.props.grants, (grant) => grant.user_id === currentUserId);
  }

  isSharedToCurrentUsersTeam() {
    const currentUsersTeams = ceteraHelpers.getCurrentUsersTeams();

    return !!_.find(this.props.grants, (grant) => _.includes(currentUsersTeams, grant.user_id));
  }

  // "Rejected" via approvals
  isRejected() {
    return this.props.approvals.some((item) => item.state === APPROVALS.STATUS.REJECTED);
  }

  // "Awaiting approval"
  isPending() {
    const { PENDING, IN_PROGRESS } = APPROVALS.STATUS;

    return this.props.approvals.some((item) => [PENDING, IN_PROGRESS].includes(item.state));
  }

  audienceCellText() {
    // Order matters! Hidden, Internal, Public, or Private
    if (this.props.isExplicitlyHidden || this.isRejected()) {
      return I18n.t(constants.AUDIENCE_HIDDEN, { scope });
    } else if (this.isInternal()) {
      return I18n.t(constants.AUDIENCE_INTERNAL, { scope });
    } else if (this.isPublic()) {
      return I18n.t(constants.AUDIENCE_PUBLIC, { scope });
    } else if (this.isPrivate() || this.isPending()) {
      // This is also the default, but retained for clarity
      return I18n.t(constants.AUDIENCE_PRIVATE, { scope });
    } else {
      return I18n.t(constants.AUDIENCE_PRIVATE, { scope });
    }
  }

  audienceIcon() {
    const hiddenOrRejected = this.props.isExplicitlyHidden || this.isRejected();

    // Order matters! Blocked, Internal, Public, or Private
    if (hiddenOrRejected) {
      return constants.ICON_EYE_BLOCKED;
    } else if (this.isInternal()) {
      return constants.ICON_MY_ORG;
    } else if (this.isPublic()) {
      return constants.ICON_PUBLIC_OPEN;
    } else if (this.isPrivate()) {
      // This is also the default, but retained for clarity
      return constants.ICON_PRIVATE;
    } else {
      return constants.ICON_PRIVATE;
    }
  }

  descriptionElement() {
    // Additional text to specify Pending, Rejected, Shared To User, or Explicitly Hidden.
    // Returns undefined if no descriptionText is necessary/present
    let descriptionText;

    if (this.isPending()) {
      descriptionText = I18n.t(constants.DESCRIPTION_AWAITING_APPROVAL, { scope });
    } else if (this.isRejected()) {
      descriptionText = I18n.t(APPROVALS.STATUS.REJECTED, { scope });
    } else if (this.isSharedToCurrentUser()) {
      descriptionText = scgcEnabled() ? '' : I18n.t(constants.DESCRIPTION_SHARED_TO_ME, { scope });
    } else if (this.props.isExplicitlyHidden) {
      descriptionText = I18n.t(constants.DESCRIPTION_HIDDEN_FROM_CATALOG, { scope });
    }

    if (!_.isEmpty(descriptionText)) {
      return (
        <div className="audience-description">
          {descriptionText}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="audience-cell">
        <span className="title">
          <SocrataIcon name={this.audienceIcon()} />
          <strong className="title-text">{this.audienceCellText()}</strong>
        </span>
        {this.descriptionElement()}
      </div>
    );
  }
}

AudienceCell.propTypes = {
  approvals: PropTypes.array,
  grants: PropTypes.array,
  isExplicitlyHidden: PropTypes.bool,
  isPublic: PropTypes.bool.isRequired,
  isPublished: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool,
  visibleToAnonymous: PropTypes.bool.isRequired,
  visibleToSite: PropTypes.bool
};

AudienceCell.defaultProps = {
  approvals: []
};

export default AudienceCell;
