import React, { useState } from 'react';
import I18n from 'common/i18n';
import { ForgeDrawer, ForgeModalDrawer, ForgeScaffold } from '@tylertech/forge-react';
import OtherResources from './OtherResources';
import Filters from './Filters';
import Videos from './Videos';
import VideoSourcesProvider, { userHasRole } from './VideoSourcesProvider';
import useViewportSize from './useViewportSize';
import './index.scss';

function VideoGuides() {
  // 686 is a weird breakpoint, but this is where the video cards shifts from 2 to 1 column
  const { isTabletView, isMobileView } = useViewportSize(1024, 688);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const scope = 'shared.components.videos';

  // Show Other Resources only if the user has a role other than Viewer.
  // This is identified by user having "create_datasets" role.
  const showOtherResources = userHasRole('create_datasets');

  const hideDrawer = () => {
    setIsDrawerOpen(false);
  };

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const introContents = (
    <>
      <p className="intro-paragraph">{I18n.t('intro', { scope })}</p>
      {
        (showOtherResources)
          ? (
            <>
              <div>{I18n.t('other_resources', { scope })}</div>
              <OtherResources />
            </>
          )
          : null
      }
    </>
  );

  const header = (isMobileView)
    ? (
      <div slot="header" className="video-guides-header">
        <div className="header-grid">
          <div className="header-logo">
            <img
              src="https://cdn.forge.tylertech.com/v1/images/spot-hero/chickadee-guide-spot-hero-03.svg"
              alt=""
              className="spot-hero"
            />
          </div>
          <div className="header-intro">
            <h1 className="forge-typography--headline4">{I18n.t('title', { scope })}</h1>
          </div>
        </div>
        <div>
          <div className="forge-typography--body1">
            {introContents}
          </div>
        </div>
      </div>
    )
    : (
      <div slot="header" className="video-guides-header">
        <h1 className="forge-typography--headline4">{I18n.t('title', { scope })}</h1>
        <div className="header-grid">
          <div className="header-logo">
            <img
              src="https://cdn.forge.tylertech.com/v1/images/spot-hero/chickadee-guide-spot-hero-03.svg"
              alt=""
              className="spot-hero"
            />
          </div>
          <div className="header-intro forge-typography--body1">
            {introContents}
          </div>
        </div>
      </div>
    );

  return (
    <VideoSourcesProvider>
      <ForgeScaffold className="video-guides-forged">
        {header}
        <div slot="body-left" className="video-guides-filters">
          {isTabletView ? (
            <ForgeModalDrawer open={isDrawerOpen} on-forge-modal-drawer-close={hideDrawer}>
              <Filters hideDrawer={hideDrawer} />
            </ForgeModalDrawer>
          ) : (
            <ForgeDrawer>
              <Filters />
            </ForgeDrawer>
          )}
        </div>
        <div slot="body" className="video-guides-body">
          <Videos showFilterButton={isTabletView} showDrawer={showDrawer} />
        </div>
      </ForgeScaffold>
    </VideoSourcesProvider>
  );
}

export default VideoGuides;
