import { Agent } from './types/gateway';
import { View } from './types/view';

const base = '/api/publishing/v1';

export const listRevisions = (view: View) => `${base}/revision/${view.id}`;


export const agentIndex = `${base}/connection_agent`;

export const provisionAgent = `${agentIndex}/launcher`;

export const pluginTypeIndex = `${base}/connection_agent/plugin`;

// why is this file copied from dsmui but with only a few things

export function showAgent(agentUid: string): string {
  return `${base}/connection_agent/${agentUid}`;
}

export function chownAgent(agent: Agent, toUser: string): string {
  return `${base}/connection_agent/${agent.agent_uid}/chown/${toUser}`;
}

export function agentLogRange(agent: Agent, start: string, end: string): string {
  const q = new URLSearchParams({ start, end }).toString();
  return `${base}/connection_agent/${agent.agent_uid}/logs?${q}`;
}
