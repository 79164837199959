import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button, { VARIANTS, SIZES } from 'common/components/Button';
import SocrataIcon from 'common/components/SocrataIcon';
import { showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';
import I18n from 'common/i18n';

export default class ClickToCopyCodeBlock extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired
  };

  state = {
    copied: false
  };

  onCopy = () => {
    this.setState({ copied: true });
    showSuccessToastNow(I18n.t('shared.components.click_to_copy_code_block.copied_to_clipboard'));
  };

  render() {
    const { className, value } = this.props;
    const { copied } = this.state;

    return (
      <div className={className}>
        <code>{value}</code>
        <CopyToClipboard text={value} onCopy={this.onCopy}>
          <Button aria-label='copy' size={SIZES.SMALL} variant={VARIANTS.TRANSPARENT}>
            {copied ? <SocrataIcon name="checkmark-alt" /> : <SocrataIcon name="copy-document" />}
          </Button>
        </CopyToClipboard>
      </div>
    );
  }
}
