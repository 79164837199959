import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchboxFilter from './searchbox_filter';
import I18n from 'common/i18n';

import * as filters from '../../actions/filters';

export class RequesterFilter extends Component {
  render() {
    const { requester, changeRequester, requesters} = this.props;

    return (
      <div className="filter-section requester" data-testid="ab-filter-requester">
        <label className="filter-label">
          {I18n.t('shared.asset_browser.filters.requester.label')}
        </label>
        <SearchboxFilter
          inputId="requester-filter"
          options={requesters}
          onSelection={changeRequester}
          placeholder={I18n.t('shared.asset_browser.filters.requester.placeholder')}
          value={requester?.title} />
      </div>
    );
  }
}

RequesterFilter.propTypes = {
  requester: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string
  }),
  changeRequester: PropTypes.func.isRequired,
  requesters: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  requester: state.filters.requester,
  requesters: state.catalog.approvalRequesters
});

const mapDispatchToProps = (dispatch) => ({
  changeRequester: (value) => dispatch(filters.changeRequester(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(RequesterFilter);
