import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import formatNumber from 'common/js_utils/formatNumber';

interface Props {
  pageNumber: number;
  resultsPerPage: number;
  total: number;
}

const ResultCount: FunctionComponent<Props> = ({pageNumber, resultsPerPage, total}) => {
  const first = (pageNumber - 1) * resultsPerPage + 1;
  const last = Math.min(pageNumber * resultsPerPage, total);
  // ES cuts off the result total at 10k
  const totalText = total == 10000 ? '10k+' : formatNumber(total);
  const resultLabel = (total === 0) ?
    I18n.t('shared.result_count_no_results') :
    I18n.t('shared.result_count', { total: totalText, count: total, first, last });

  return (
    <div className="result-count" role="alert">
      {resultLabel}
    </div>
  );
};

export default ResultCount;
