import { createReducer } from '@reduxjs/toolkit';
import {
  UploadFileActionType,
  AddNewFileAction,
  UpdateFileAction,
  RemoveFileAction,
  ReorderFilesAction,
  InitializeFilesListAction
} from '../actions/uploadFile';
import { FileUploadStatus, UploadStatuses } from '../../types';

export interface UploadFileState {
  files: FileUploadStatus[];
}

export const initialState: UploadFileState = {
  files: []
};

const uploadFileReducer = createReducer<UploadFileState>(initialState, (builder) => {
  builder
    .addCase(
      UploadFileActionType.ADD_NEW_FILE,
      (state: UploadFileState, action: AddNewFileAction): UploadFileState => {
        const newFileStatus: FileUploadStatus = {
          uploadId: action.uploadId,
          status: UploadStatuses.IN_PROGRESS,
          percent: 0,
          uploadedFile: action.file
        };

        return {
          ...state,
          files: [...state.files, newFileStatus]
        };
      }
    )
    .addCase(
      UploadFileActionType.UPDATE_FILE,
      (state: UploadFileState, action: UpdateFileAction): UploadFileState => {
        const updatedFiles = state.files.map((file) => {
          if (file.uploadId === action.changes.uploadId) {
            return {
              ...file,
              ...action.changes
            };
          }
          return file;
        });

        return {
          ...state,
          files: updatedFiles
        };
      }
    )
    .addCase(
      UploadFileActionType.REMOVE_FILE,
      (state: UploadFileState, action: RemoveFileAction): UploadFileState => {
        const updatedFiles = state.files.filter((file) => {
          return file.uploadId !== action.uploadId;
        });

        return {
          ...state,
          files: updatedFiles
        };
      }
    )
    .addCase(
      UploadFileActionType.REORDER_FILES,
      (state: UploadFileState, action: ReorderFilesAction): UploadFileState => ({
        ...state,
        files: action.files
      })
    )
    .addCase(
      UploadFileActionType.INITIALIZE_FILES_LIST,
      (state: UploadFileState, action: InitializeFilesListAction): UploadFileState => ({
        ...state,
        files: action.files
      })
    )
    .addDefaultCase(
      (state: UploadFileState): UploadFileState => ({
        ...state
      })
    );
});

export default uploadFileReducer;
