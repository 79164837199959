import { fetchTranslation } from 'common/locale';
import { ProvisionPluginProps } from './types';
import React, { FunctionComponent } from 'react';
const t = (k: string) => fetchTranslation(k, 'shared.components.setup_plugin.overview');

export const PluginOverview: FunctionComponent<ProvisionPluginProps> = ({ pageOptions }) => {
  const plugin = pageOptions.plugin;
  const description = plugin.manifest.description;
  return (
    <div className="provision-plugin-step overview-container">
      <div className="left-column">
        <img src={`data:image/jpeg;base64,${plugin.icon}`} alt="" />
      </div>
      <div className="right-column">
        <div>
          <h4>{t('title')}</h4>
        </div>
        <div>
          <h5>{t('description')}</h5>
          <p>{description || t('no_description')}</p>
          <h5>{t('required_fields')}</h5>
          {plugin.manifest.required_fields.map(function(field) {
            return <p key={field.field_key}>{field.field_name}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

export default PluginOverview;
