import React, { useState } from 'react';

import Checkbox, { CheckboxProps } from './Checkbox';

interface UncontrolledCheckboxCustomProps {
  /** Whether or not to start the input checked */
  defaultChecked?: boolean;
}

type UncontrolledCheckboxProps = CheckboxProps & UncontrolledCheckboxCustomProps;

/**
 * Generic uncontrolled Checkbox component.
 *
 * Wraps the regular Checkbox component but handles its own state.
 */
const UncontrolledCheckbox = React.forwardRef<HTMLInputElement, UncontrolledCheckboxProps>((props, ref) => {
  const [checked, setChecked] = useState(props.defaultChecked || false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(e.target.checked);
  };

  return <Checkbox {...props} ref={ref} onChange={handleChange} checked={checked} />;
});

export default UncontrolledCheckbox;
