import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cx from 'classnames';

import Button from 'common/components/Button';
import { SocrataIcon } from 'common/components/SocrataIcon';

import './dropdown-button.scss';

/** A button in a DropdownButton's list. Use DropdownButton as the parent. */
export class DropdownItem extends PureComponent {
  static displayName = 'DropdownItem';

  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    // If false, renders nothing. If true, renders content.
    isVisible: PropTypes.bool.isRequired,
    // Button click handler.
    onClick: PropTypes.func.isRequired,
    // Props applied to the component's root element, an <li>.
    childProps: PropTypes.object,
    // I18n title attribute applied to the HTML interactive element of this component (a <button>).
    title: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    isVisible: true
  };

  render() {
    const { children, disabled, isVisible, onClick, childProps, title } = this.props;

    return (
      isVisible && (
        <li className="dropdown-button-item" {...childProps}>
          <Button className="dropdown-button-item-button" transparent disabled={disabled} onClick={onClick} title={title}>
            {children}
          </Button>
        </li>
      )
    );
  }
}

/** An internal helper for DropdownButton. Handles positioning and closing the dropdown on click. */
class DropdownContainer extends PureComponent {
  state = {
    marginOffset: 0
  };

  static propTypes = {
    children: PropTypes.node,
    // Handler for any click on this container or its children.
    onClick: PropTypes.func.isRequired
  };


  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    if (this.container) {
      const windowWidth = window.innerWidth;
      const rightBounds = this.container.getBoundingClientRect().right;
      if (rightBounds > windowWidth) {
        this.setState({ marginOffset: windowWidth - rightBounds });
      }
    }
  };

  render() {
    const { children, onClick } = this.props;
    const { marginOffset } = this.state;
    return (
      <ul
        onClick={onClick}
        style={{ marginLeft: marginOffset }}
        ref={(ref) => (this.container = ref)}
        className="dropdown-button-actions">
        {children}
      </ul>
    );
  }
}

class DropdownButton extends PureComponent {
  static Item = DropdownItem;

  static propTypes = {
    // Suggest you use DropdownItems, as they provide correct styling and DOM structure (<li>s).
    children: PropTypes.node,
    isDisabled: PropTypes.bool
  };

  state = {
    showDropdown: false
  };

  componentWillUnmount() {
    this.toggleDocumentMouseDown(false);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.state.showDropdown !== nextState.showDropdown) {
      this.toggleDocumentMouseDown(nextState.showDropdown);
    }
  }

  onMouseDown = (ev) => {
    if (!(this.dropdownRef === ev.target || this.dropdownRef.contains(ev.target))) {
      this.setState({ showDropdown: false });
    }
  };

  toggleDocumentMouseDown = (isMounted) => {
    window.document[isMounted ? 'addEventListener' : 'removeEventListener']('mousedown', this.onMouseDown);
  };

  hideDropdown = () => this.setState({ showDropdown: false });

  toggleDropdown = () => this.setState(({ showDropdown }) => ({ showDropdown: !showDropdown }));

  render() {
    const { children, isDisabled } = this.props;
    const { showDropdown } = this.state;

    const buttonClass = cx(
      {
        selected: showDropdown
      },
      'kebab-button'
    );

    return (
      <div className="dropdown-button" ref={(ref) => (this.dropdownRef = ref)}>
        <Button aria-label='dropdown' transparent className={buttonClass} onClick={this.toggleDropdown} disabled={isDisabled}>
          <SocrataIcon name="kebab" />
        </Button>
        {showDropdown && <DropdownContainer onClick={this.hideDropdown}>{children}</DropdownContainer>}
      </div>
    );
  }
}

export default DropdownButton;
