/**
 * Intended to be used as a client-side autocomplete.
 * Callers of CachedAutocomplete should pass array of objects as `dataCached`.
 * Most other props are optional, see 'defaultProps'.
 */

import React from 'react';
import classNames from 'classnames';

import Autocomplete from 'common/components/Autocomplete/components/Autocomplete';
import './cached-autocomplete.scss';

const CachedAutocomplete = (props) => {
  // Recommended props to address, but there are more in underlying Autocomplete component
  const defaultProps = {
    dataCached: [], // array of objects that search bar matches against
    searchKeys: [], // used by the query to match on values of `dataCached` objects
    onQuerySubmit: (matchedObjects) => matchedObjects, // passed callback; invoked by selection
    onClearSearch: () => {},
    onSelectSetSelectionAsQuery: true, // mirrors underlying Autocomplete component default
    focusFirstResult: false, // mirrors underlying Autocomplete component default
    placeholder: 'Search',
    className: 'cached-autocomplete',
    caseSensitive: false
  };

  const {
    dataCached,
    searchKeys,
    caseSensitive,
    onQuerySubmit,
    className
  } = props;

  const getMatches = (query) => dataCached
    .map(data => {
      const searchString = searchKeys.map(key => data[key]).join(' - ');
      const matchedString = caseSensitive ?
        searchString.includes(query) && searchString :
        searchString.toLowerCase().includes(query.toLowerCase()) && searchString;

      if (matchedString) {
        return { matchedString, data };
      }
    })
    .filter(Boolean)
    .map(({ matchedString, data }) => {
      const start = caseSensitive ?
        matchedString.indexOf(query) :
        matchedString.toLowerCase().indexOf(query.toLowerCase());

      return {
        title: matchedString,
        matches: [{ start, length: query.length }],
        data
      };
    });

  const cls = classNames('cached-autocomplete', className);

  const autocompleteOptions = {
    ...defaultProps,
    ...props,
    className: cls,
    millisecondsBeforeSearch: 100,
    getSearchResults: (query) => Promise.resolve({ results: getMatches(query) }),
    onChooseResult: (query) =>
      query && onQuerySubmit(getMatches(query).map((match) => match.data))
  };

  return <Autocomplete {...autocompleteOptions} />;
};

export default CachedAutocomplete;
