import { cloneDeep as _cloneDeep, omitBy as _omitBy, isUndefined as _isUndefined } from 'lodash';
import { View } from 'common/types/view';
import { Attachment } from 'common/types/revision';
import { AssetMetadata, InnerMetadataObject, CoreAttachment } from '../../../types';

const coreAttachmentToAttachment = (coreAttachment: CoreAttachment): Attachment => ({
  name: coreAttachment.name,
  filename: coreAttachment.filename,
  asset_id: coreAttachment.assetId
});

const attachmentToCoreAttachment = (attachment: Attachment): CoreAttachment => ({
  name: attachment.name,
  filename: attachment.filename,
  assetId: attachment.asset_id
});

export const viewToAssetMetadata = (view: View): AssetMetadata => {
  const innerMetadataObject: InnerMetadataObject = view.metadata;

  let attachments: Attachment[];

  if (innerMetadataObject.attachments) {
    attachments = innerMetadataObject.attachments.map((coreAttachment) =>
      coreAttachmentToAttachment(coreAttachment)
    );
  } else {
    attachments = [];
  }

  return {
    builtIn: {
      name: view.name,
      rowLabel: innerMetadataObject.rowLabel,
      rdfSubject: innerMetadataObject.rdfSubject,
      rdfClass: innerMetadataObject.rdfClass,
      tags: view.tags || null,
      resourceName: view.resourceName,
      attachments,
      license: {
        name: view.license?.name || '',
        termsLink: view.license?.termsLink || '',
        licenseId: view.licenseId || null
      },
      attribution: {
        name: view.attribution || null,
        link: view.attributionLink || null
      },
      description: view.description || null,
      category: view.category || null
    },
    customFields: _cloneDeep(innerMetadataObject?.custom_fields),
    privateMetadata: {
      contactEmail: view.privateMetadata?.contactEmail || null,
      customFields: _cloneDeep(view.privateMetadata?.custom_fields) || {}
    },
    results: {
      blob: _cloneDeep(innerMetadataObject),
      privateBlob: _cloneDeep(view.privateMetadata)
    }
  };
};

export const assetMetadataToView = (view: View, assetMetadata: AssetMetadata): View => {
  const updatedTags = assetMetadata.builtIn.tags !== null ? assetMetadata.builtIn.tags : undefined;

  let updatedAttachments: CoreAttachment[] | undefined;

  if (assetMetadata.builtIn.attachments.length > 0) {
    updatedAttachments = assetMetadata.builtIn.attachments.map((attachment) =>
      attachmentToCoreAttachment(attachment)
    );
  } else {
    updatedAttachments = undefined;
  }

  const updatedLicenseId =
    assetMetadata.builtIn.license.licenseId !== null ? assetMetadata.builtIn.license.licenseId : undefined;
  const updatedLicenseName =
    assetMetadata.builtIn.license.name !== '' ? assetMetadata.builtIn.license.name : undefined;
  const updatedLicenseTermsLink =
    assetMetadata.builtIn.license.termsLink !== '' ? assetMetadata.builtIn.license.termsLink : undefined;

  const updatedLicense = updatedLicenseName
    ? {
        name: updatedLicenseName,
        termsLink: updatedLicenseTermsLink
      }
    : undefined;

  const updatedAttribution =
    assetMetadata.builtIn.attribution.name !== null ? assetMetadata.builtIn.attribution.name : undefined;
  const updatedAttributionLink =
    assetMetadata.builtIn.attribution.link !== null ? assetMetadata.builtIn.attribution.link : undefined;

  const updatedDescription =
    assetMetadata.builtIn.description !== null ? assetMetadata.builtIn.description : undefined;
  const updatedCategory =
    assetMetadata.builtIn.category !== null ? assetMetadata.builtIn.category : undefined;

  const updatedMetadataBlob = _omitBy(
    {
      ...assetMetadata.results?.blob,
      rowLabel: assetMetadata.builtIn.rowLabel,
      rdfSubject: assetMetadata.builtIn.rdfSubject,
      rdfClass: assetMetadata.builtIn.rdfClass,
      attachments: updatedAttachments
    },
    _isUndefined
  );

  if (assetMetadata.customFields && Object.keys(assetMetadata.customFields).length > 0) {
    updatedMetadataBlob.custom_fields = assetMetadata.customFields;
  }

  let updatedPrivateMetadata = {
    ...assetMetadata.results?.privateBlob
  };

  updatedPrivateMetadata.contactEmail =
    assetMetadata.privateMetadata.contactEmail !== null
      ? assetMetadata.privateMetadata.contactEmail
      : undefined;

  updatedPrivateMetadata.custom_fields =
    Object.keys(assetMetadata.privateMetadata.customFields).length > 0
      ? assetMetadata.privateMetadata.customFields
      : undefined;

  updatedPrivateMetadata = _omitBy(updatedPrivateMetadata, _isUndefined);

  if (Object.keys(updatedPrivateMetadata).length === 0) {
    updatedPrivateMetadata = undefined;
  }

  const viewChanges: Partial<View> = _omitBy(
    {
      name: assetMetadata.builtIn.name,
      metadata: updatedMetadataBlob,
      tags: updatedTags,
      resourceName: assetMetadata.builtIn.resourceName,
      licenseId: updatedLicenseId,
      license: updatedLicense,
      attribution: updatedAttribution,
      attributionLink: updatedAttributionLink,
      description: updatedDescription,
      category: updatedCategory,
      privateMetadata: updatedPrivateMetadata
    } as Partial<View>,
    _isUndefined
  );

  return {
    ...view,
    ...viewChanges
  };
};
