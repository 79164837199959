import { DsmapiUser } from './dsmapi';
import { InputSchema, NestedInputSchema } from './dsmapiSchemas';
import { Namespace } from './gateway';

export interface ParseOptions {
  column_header: number;
  header_count: number;
  parse_source: boolean;
}

interface SourceFailure {
  message: string;
  key: 'unparsable_file' | 'request_invalid' | 'too_many_columns' | 'cancelled' | 'internal_error';
}

interface URLSource {
  type: 'url';
  url: string;
}
interface UploadSource {
  type: 'upload';
  filename: string;
}
interface AgentSource {
  type: 'connection_agent';
  agent_uid: string;
  namespace: Namespace;
  path: string[];
  human_path: string[];
  parameters: any; // refine
}
interface ViewSource {
  type: 'view';
  loaded: boolean;
  fourfour: string;
}
export interface ArchivalSource {
  type: 'archival';
  status: 'queued' | 'materialized' | 'complete';
}

export function isArchivalSourceType(s: SourceType): s is ArchivalSource {
  return s.type === 'archival';
}

export function isViewSourceType(s: SourceType): s is ViewSource {
  return s.type === 'view';
}

export function isUnloadedViewSource(s: SourceType): boolean {
  return isViewSourceType(s) && !s.loaded;
}

type SourceType = URLSource | UploadSource | AgentSource | ViewSource | ArchivalSource;
export interface Source {
  id: number;
  parse_options: ParseOptions;
  finished_at: string | null;
  updated_at: string | null;
  created_at: string | null;
  failed_at: string | null;
  failure_details: SourceFailure | null;
  source_type: SourceType;
  schemas: InputSchema[];
  blob: unknown | null;
  created_by: DsmapiUser;
}
export type NestedSource = Omit<Source, 'schemas'> & {
  schemas: NestedInputSchema[];
};
