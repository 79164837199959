import _ from 'lodash';
import { fetchWithDefaultHeaders, fetchJsonWithDefaultHeaders } from 'common/http';
import { ClientContextVariable } from 'common/types/clientContextVariable';

export enum CollocationStatus {
  Completed = 'completed',
  InProgress = 'in-progress',
  NotNeeded = 'not-needed',
  Missing = 'missing'
}

export interface CollocateCheckResponse {
  status: CollocationStatus;
  message: string;
}

interface CollocationCompleted extends CollocateCheckResponse {
  status: CollocationStatus.Completed;
}
interface CollocationInProgress extends CollocateCheckResponse {
  status: CollocationStatus.InProgress;
}
interface CollocationNotNeeded extends CollocateCheckResponse {
  status: CollocationStatus.NotNeeded;
}
interface CollocationMissing extends CollocateCheckResponse {
  status: CollocationStatus.Missing;
}

export const isCollocationCompleted = (status: string): boolean => status === CollocationStatus.Completed;
export const isCollocationInProgress = (status: string): boolean => status === CollocationStatus.InProgress;
export const isCollocationNotNeeded = (status: string): boolean => status === CollocationStatus.NotNeeded;
export const isCollocationMissing = (status: string): boolean => status === CollocationStatus.Missing;

export const isCollocationNeeded = async (
  basedOnUid: string,
  soqlQuery: string,
  clientContextVariables: ClientContextVariable[]
): Promise<boolean> => {
  const response = await checkIfCollocationNeeded(basedOnUid, soqlQuery, clientContextVariables);
  return isCollocationMissing(response.status);
};

export const checkIfCollocationNeeded = (
  basedOnUid: string,
  soqlQuery: string,
  clientContextVariables: ClientContextVariable[]
): Promise<CollocateCheckResponse> => {
  const typeMap = {};
  const valMap = {};
  clientContextVariables.forEach((variable) => {
    typeMap[variable.name + '.' + variable.viewId] = variable.dataType;
    valMap[variable.name + '.' + variable.viewId] = variable.defaultValue;
  });
  const body = {
    basedOnUid,
    query: soqlQuery,
    paramTypes: typeMap,
    paramVals: valMap
  };
  return fetchJsonWithDefaultHeaders('/api/collocate?method=checkExistsOrNeeded', {
    method: 'PUT',
    body: JSON.stringify(body)
  });
};

type Cost = {
  moves: number;
  totalSizeBytes: number;
  moveSizeMaxBytes?: number;
};

type Move = {
  datasetInternalName: string;
  storeIdFrom: string;
  storeIdTo: string;
  cost: Cost;
  complete?: boolean;
};

interface CollocateResponse {
  status: string;
  message: string;
  cost: Cost;
  moves: Move[];
  requestId: string;
}

export enum CollocateResponseStatus {
  Approved = 'approved', // DC approved collocation plan
  Rejected = 'rejected' // DC rejected the job as too expensive
}

export interface CollocateJobApproved extends CollocateResponse {
  jobId: string;
  status: CollocateResponseStatus.Approved;
}
interface CollocateJobRejected extends CollocateResponse {
  status: CollocateResponseStatus.Rejected;
}
interface CollocateJobNotNeeded extends CollocateResponse {
  status: CollocationStatus.NotNeeded;
}
interface CollocateJobCompleted extends CollocateResponse {
  jobId: string;
  status: CollocationStatus.Completed;
}
interface CollocateJobInProgress extends CollocateResponse {
  jobId: string;
  status: CollocationStatus.InProgress;
}

export const isCollocationJobApproved = (
  response: CollocationJobResponse
): response is CollocateJobApproved =>
  isCollocateResponse(response) && response.status === CollocateResponseStatus.Approved;
export const isCollocationJobRejected = (
  response: CollocationJobResponse
): response is CollocateJobRejected =>
  isCollocateResponse(response) && response.status === CollocateResponseStatus.Rejected;
export const isCollocationJobNotNeeded = (
  response: CollocationJobResponse
): response is CollocateJobNotNeeded =>
  isCollocateResponse(response) && response.status === CollocationStatus.NotNeeded;
export const isCollocationJobCompleted = (
  response: CollocationJobResponse
): response is CollocateJobCompleted =>
  isCollocateResponse(response) && response.status === CollocationStatus.Completed;
export const isCollocationJobInProgress = (
  response: CollocationJobResponse
): response is CollocateJobInProgress =>
  isCollocateResponse(response) && response.status === CollocationStatus.InProgress;

export type CollocationJobStarted = CollocateJobApproved | CollocateJobInProgress;
export const isCollocationJobStarted = (
  response: CollocationJobResponse
): response is CollocationJobStarted =>
  isCollocationJobApproved(response) || isCollocationJobInProgress(response);
export const isCollocationJobAlreadyDone = (response: CollocationJobResponse): boolean =>
  isCollocationJobNotNeeded(response) || isCollocationJobCompleted(response);

interface CoreClientHttpException {
  code: string;
  error: boolean;
  message: string;
  data?: Record<string, any>;
  requestId: string;
}
// Failed to access a relevant view during collocation job creation
interface PermissionDenied extends CoreClientHttpException {
  code: 'permission-denied';
  error: true;
}
interface InvalidRequest extends CoreClientHttpException {
  error: true;
}
interface NotFound extends CoreClientHttpException {
  code: 'not-found';
  error: true;
}

export type CollocationJobResponse = CollocateResponse | CoreClientHttpException;
export type CollocateJobFailed = CollocateJobRejected | CoreClientHttpException;

const isCollocateResponse = (response: CollocationJobResponse): response is CollocateResponse =>
  _.has(response, 'status');
export const isCoreException = (response: CollocationJobResponse): response is CoreClientHttpException =>
  !isCollocateResponse(response);

// These are mostly modeled after common/http#fetchJsonWithParsedError.
// The difference is that the OK response also grabs the request id.
const handleCollocationResponseWhenOkay = (response: Response) =>
  response.json().then((json) => ({
    ...json,
    requestId: response.headers.get('X-Socrata-RequestId')
  }));
const handleCollocationResponseWhenError = (error: { response: Response }) => {
  if (error.response) {
    return error.response.json().then(
      (json) => ({ ...json, requestId: error.response.headers.get('X-Socrata-RequestId') }),
      () => {
        throw error;
      }
    );
  }
};

export const collocate = (
  basedOnUid: string,
  soqlQuery: string,
  clientContextVariables: ClientContextVariable[]
): Promise<CollocationJobResponse> => {
  const typeMap = {};
  const valMap = {};
  clientContextVariables.forEach((variable) => {
    typeMap[variable.name + '.' + variable.viewId] = variable.dataType;
    valMap[variable.name + '.' + variable.viewId] = variable.defaultValue;
  });
  const body = {
    basedOnUid,
    query: soqlQuery,
    paramTypes: typeMap,
    paramVals: valMap
  };
  return fetchWithDefaultHeaders('/api/collocate?method=createFromQuery', {
    method: 'POST',
    body: JSON.stringify(body)
  }).then(handleCollocationResponseWhenOkay, handleCollocationResponseWhenError);
};

export const checkCollocationStatus = (jobId: string): Promise<CollocationJobResponse> => {
  return fetchWithDefaultHeaders(`/api/collocate/${jobId}`).then(
    handleCollocationResponseWhenOkay,
    handleCollocationResponseWhenError
  );
};

const progressiveBackoff = (tries: number) => [1000, 2000, 5000, 10000][tries - 1] || 10000;
interface StatusPollerOptions {
  onCompleted: () => void;
  onCoreException?: (error: CoreClientHttpException) => void;
}
export const pollForCollocationStatus = async (
  jobId: string,
  options: StatusPollerOptions,
  tries = 0
): Promise<void> => {
  const response = await checkCollocationStatus(jobId);

  if (isCoreException(response)) {
    if (options.onCoreException) options.onCoreException(response);
  } else if (isCollocationJobCompleted(response)) {
    options.onCompleted();
  } else {
    setTimeout(() => pollForCollocationStatus(jobId, options, tries + 1), progressiveBackoff(tries));
  }
};
