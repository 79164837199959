import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { View } from 'common/types/view';
import type { GuidanceSummaryV2 } from 'common/types/approvals';

import I18n from 'common/i18n';
import currentUserCanManageCollaborators from 'common/views/current_user_can_manage_collaborators';
import AccessManager, { AccessManagerProps } from 'common/components/AccessManager';
import { MODES as ACCESS_MANAGER_MODES } from 'common/components/AccessManager/Constants';
import { defaultAccessManagerOnConfirm } from 'common/components/AccessManager/Util';

import ActionItem from '../action_item';

export interface ManageCollaboratorsActionItemProps {
  view: View;
  assetIsStoryDraft: boolean;
  revisionInfo: any;
  setCurrentModal: (
    modal: typeof AccessManager,

    // 'onDismiss' and 'view' are both passed in by `setCurrentModal`
    props: Omit<AccessManagerProps, 'onDismiss' | 'view'>
  ) => void;
  approvalsGuidance: GuidanceSummaryV2;
  closeCurrentModal: () => void;
}

class ManageCollaboratorsActionItem extends Component<ManageCollaboratorsActionItemProps> {
  shouldRender() {
    const { view } = this.props;

    if (isEmpty(view) || view.locked) {
      return false;
    }

    return currentUserCanManageCollaborators(view);
  }

  render() {
    const { setCurrentModal, closeCurrentModal, approvalsGuidance } = this.props;

    if (!this.shouldRender()) {
      return null;
    }

    return (
      <ActionItem
        label={I18n.t('shared.asset_browser.result_list_table.action_dropdown.manage_collaborators')}
        onClick={() =>
          setCurrentModal(AccessManager, {
            mode: ACCESS_MANAGER_MODES.MANAGE_COLLABORATORS,
            onConfirm: defaultAccessManagerOnConfirm(closeCurrentModal),
            approvalsGuidance
          })
        }
      />
    );
  }
}

export default ManageCollaboratorsActionItem;
