import _ from 'lodash';

import airbrake from 'common/airbrake';
import ceteraUtils from 'common/cetera/utils';
import { scgcEnabled } from 'common/domain/helpers';
import FeatureFlags from 'common/feature_flags';
import { getCurrentDomain } from 'common/currentDomain';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import * as ceteraActions from 'common/components/AssetBrowser/actions/cetera';
import * as filterActions from 'common/components/AssetBrowser/actions/filters';
import { tabShouldExcludeFederatedAssets } from 'common/components/AssetBrowser/lib/helpers/federation';
import { getAnalysisId } from 'common/program_analytics/helpers';

export const INITIAL_RESULTS_FETCHED = 'INITIAL_RESULTS_FETCHED';
export const FETCH_INITIAL_RESULTS = 'FETCH_INITIAL_RESULTS';

export const getCurrentUserFilter = () => {
  return {
    ownedBy: {
      id: _.get(window.socrata, 'currentUser.id'),
      displayName: _.get(window.socrata, 'currentUser.displayName')
    }
  };
};

export const getCurrentUserId = () => _.get(getCurrentUserFilter(), 'ownedBy.id');

// Gets the teams the current user is a member of. This is similar to `getProfileUserTeams`, but always will
// return the teams of the current user, while `getProfileUserTeams` will return the teams for the user who's
// profile page is being viewed.
export const getCurrentUsersTeams = () =>
  _.get(window, 'socrata.currentUser.teams', []).map((team) => team.id);

// When we are on a /profile page, we are usually looking at the current user, but if we have permission to
// view other user's profiles, then we may be looking at a different user, in which case we need to show info
// relevant to that user, and not the current user. The `render_user_profile_state_for_javascript` helper
// renders the information we need in `socrata.profile.user` for this use case.
export const getProfileUserTeams = () =>
  _.get(window, 'socrata.profile.user.teams', []).map((team) => team.id);

const userFlags = () => _.get(window.socrata, 'currentUser.flags', []);

export const isSiteMember = () => {
  return userFlags().includes('siteMember');
};

export const isSuperAdmin = () => {
  return userFlags().includes('admin');
};

export const isSiteMemberOrSuperAdmin = () => {
  return isSiteMember() || isSuperAdmin();
};


// This function is exported so that other services may use it to translate a given set of filters
// into the corresponding set of query parameters to pass to Cetera for search queries.
export const translateFiltersToQueryParameters = (filters) => {
  const {
    approvalStatus,
    assetSelector,
    assetTypes,
    authority,
    category,
    collectionParent,
    forUser,
    ids,
    onlyRecentlyViewed,
    enrolledInArchival,
    order,
    outcomeApplicationStatus,
    ownedBy,
    pageNumber,
    pageSize,
    parentIds,
    q,
    sharedTo,
    source,
    tag,
    showInternalTargetAudience,
    showPublicTargetAudience,
    requester,
    version,
    visibility
  } = filters;

  const ceteraOrder = () => {
    if (_.isUndefined(order)) {
      return;
    }

    const direction = order.ascending ? 'ASC' : 'DESC';
    switch (order.value) {
      case 'category':
        return `domain_category ${direction}`;
      case 'lastUpdatedDate':
        return `updatedAt ${direction}`;
      case 'type':
        return `datatype ${direction}`;
      case 'requester':
        return `submitter ${direction}`;
      default:
        return `${order.value} ${direction}`;
    }
  };

  const currentDomain = getCurrentDomain();

  let published = null;
  if (version === 'published') {
    published = true;
  } else if (version === 'draft') {
    published = false;
  }

  let lastAccessedUids = null;

  if (window.lastAccessed) {
    lastAccessedUids = onlyRecentlyViewed ? Object.keys(window.lastAccessed.get()) : null;
  }

  const customMetadataFilters = (filters.action === filterActions.CLEAR_ALL_FILTERS) ?
    {} : filters.customFacets;

  // Unfortunately, when SIAM says "public" and "private", what it really means currently are visibility=open
  // vs. visibility=internal. What makes something internal is a combination of things, including
  // literal privacy, but also hidden-ness and the various approval processes. Unless strict_permissions
  // (which really means SCGC) is on, let's leave this be.
  //
  // For SCGC, permissions.scope should encompass these things, and should be trusted for open/not-open
  // decisions. In the catalog API, permission scope is called `audience`.
  let updatedVisibility = visibility;
  let audience = null;

  if (visibility != null && scgcEnabled()) {
    updatedVisibility = null;

    if (visibility === 'site') {
      audience = 'site';
    } else if (visibility === 'internal') {
      audience = 'private';
    } else {
      audience = 'public';
    }
  }

  /* Allows some asset selectors (storyteller, measures) to request
   * federated assets. */
  let domains = null;
  if (source !== null) {
    domains = source;
  }

  const targetAudience = [];
  if (showPublicTargetAudience) targetAudience.push('public');
  if (showInternalTargetAudience) targetAudience.push('internal');

  const submitterId = requester?.value;

  // Note! All of these parameter names use camelCase, though the catalog search API is inconsistent and
  // sometimes uses snake_case for certain parameters. See common/cetera/utils.js to understand the
  // translations. Also, not all parameters are necessarily declared here; Some will be found in
  // common/cetera/utils.js.
  return {
    assetSelector,
    approvalStatus,
    audience,
    category,
    collectionParent,
    customMetadataFilters,
    enrolledInArchival,
    domains,
    forUser: forUser || _.get(ownedBy, 'id'),
    idFilters: ids || lastAccessedUids,
    limit: pageSize,
    only: assetTypes === 'blob' ? 'file' : assetTypes,
    order: ceteraOrder(),
    outcomeApplicationStatus,
    pageNumber,
    parentIds,
    published,
    provenance: authority,
    q,
    searchContext: currentDomain,
    sharedTo,
    showVisibility: 'true',
    submitterId: submitterId,
    tags: tag,
    targetAudience: targetAudience,
    visibility: updatedVisibility
  };
};

// Caution: If a key exists in getState().filters but is missing from parameters, then the value in the
// current state will be used instead. If you wish values in the parameters object to override the current
// state, you _must_ provide the override value in the parameters object.
export const mergedCeteraQueryParameters = (getState, parameters = {}) => {
  const activeTab = _.get(getState(), 'header.activeTab');
  const baseFilters = _.get(getState(), `assetBrowserProps.tabs.${activeTab}.props.baseFilters`);

  /**
   * @desc EN-38144: The AssetBrowser should never show internal or private assets from federated domains.
   *  This flag is a temporary measure that catalog service will use to dictate this behavior.
   */
  const assetSelector = _.get(getState(), 'assetBrowserProps.assetSelector');

  const mergedFilters = (_.get(getState(), 'initialProps.mergeFilters') || _.merge)(
    {},
    getState().catalog,
    getState().filters,
    getState().header,
    parameters,
    {assetSelector},
    baseFilters
  );

  // Some tabs should never show assets from federation domains, regardless of the page context.
  if (tabShouldExcludeFederatedAssets(activeTab)) {
    _.set(mergedFilters, 'source', getCurrentDomain());
  }

  // Specific default filter for federation tab. We don't use baseFilters because those
  // are designed to work as hidden, non-interactive filters. We let users modify the
  // federation-related filter, so that won't work here. See comment in
  // internal_asset_manager.js.
  // Specifically check for blank - if we use a straight merge(), source: null will take priority!
  if (activeTab === constants.TAB_FEDERATED && _.isEmpty(mergedFilters.source)) {
    const { sources } = getState().filters;
    if (!_.isEmpty(sources)) {
      mergedFilters.source = sources.join(',');
    }
  }

  // Specific filter behavior for TAB_MY_TEAM_ASSETS tab. This is needed because the default filter state of
  // "All" for the "Owned By" filter, requires passing all team 4x4s of the teams that the user is a member
  // of, as the forUser filter passed to Catalog API.
  if (activeTab === constants.TAB_MY_TEAM_ASSETS && mergedFilters.ownedBy?.id == null) {
    // This is awful and needs to be refactored. If we're on /admin/assets, then viewingOwnProfile is undefined
    // and we should show the current user's team assets on the My Team Assets tab.
    const viewingOwnProfile = getState().assetBrowserProps.viewingOwnProfile;
    if (viewingOwnProfile === undefined) {
      mergedFilters.forUser = getCurrentUsersTeams();
    } else {
      // Otherwise, we're on the profile page and need to know if we're on the profile page and need to know
      // if we're looking at the current user's profile, or another user's profile.
      if (viewingOwnProfile) {
        mergedFilters.forUser = getCurrentUsersTeams();
      } else {
        mergedFilters.forUser = getProfileUserTeams();
      }
    }
  }

  // expansion of categories: if a category has children, include them
  // Note: we only acknowledge 2 layers of categories. The base layer and their
  // children. (great) grandchildren may exist and they are completely ignored.
  if (mergedFilters.category != null) {
    const category = mergedFilters.category;
    const domainCategory = getState().filters.domainCategories.find(cat => {
      return cat.category === category;
    });
    if (domainCategory != null) {
      const fam = _.map(domainCategory.children, function (ch) { return ch.category; });
      fam.push(category);
      mergedFilters.category = fam;
    }
  }

  return translateFiltersToQueryParameters(mergedFilters);
};

export const fetchAssetCounts = (dispatch, getState, parameters = {}) => {
  dispatch(ceteraActions.fetchingAssetCounts());

  return ceteraUtils.facetCountsQuery(mergedCeteraQueryParameters(getState, parameters)).then((response) => {
    if (response && _.isArray(response) && response.length > 0) {
      const datatypesFacet = _.filter(response, ((facetType) => facetType.facet === 'datatypes'))[0];

      if (datatypesFacet && _.has(datatypesFacet, 'values')) {
        dispatch(ceteraActions.fetchingAssetCountsSuccess());
        dispatch(ceteraActions.updateAssetCounts(datatypesFacet.values));
      }
    } else {
      dispatch(ceteraActions.fetchingAssetCountsError());
    }
  });
};

// If result is of type system_dataset, try to fetch the analysis ID.
// If the analysis ID exists, add it to the result. If not, that's ok! Move on.
const annotateResponse = (response) => {
  let results = response.results;
  var annotatedResults = results.map((result) => {
    let asset = result.resource;
    if (asset.type == 'system_dataset') {
      return getAnalysisId(asset.id).then((analysisId) => {
        asset.analysisId = analysisId;
        return result;
      }).catch(() => {
        console.error(`Failed to fetch analysis for view with uid ${asset.id}`);
        return result;
      });
    } else {
      return Promise.resolve(result);
    }
  });
  return Promise.all(annotatedResults).then((results) => {
    response.results = results;
    return response;
  });
};

//
export const fetchResults = (dispatch, getState, parameters = {}, onSuccess = _.noop) => {
  const { onlyRecentlyViewed, enrolledInArchival } = _.merge({}, getState().filters, parameters);
  let { sortByRecentlyViewed } = _.merge({}, getState().filters, parameters);

  dispatch(ceteraActions.fetchingResults());

  return ceteraUtils.query(mergedCeteraQueryParameters(getState, parameters))
    .then((response) => {
      return annotateResponse(response); // check for system datasets, make requests to analysis endpoint, add that bit to object.;
    })
    .then((annotatedResponse) => {
      if (_.isObject(annotatedResponse)) {
        /**
         * @desc EN-17000: If user checks "Only recently viewed", any active sort is cleared and the results
         *  are automatically sorted by most recently viewed (in the catalog.js reducer). If the user then
         *  clicks a column to sort again, we remove the sortByRecentlyViewed override and let them sort the
         *  onlyRecentlyViewed results however they want.
         */
        const explicitOrder = _.get(parameters, 'order') || _.get(getState(), 'catalog.order');
        sortByRecentlyViewed = sortByRecentlyViewed || (onlyRecentlyViewed && _.isEmpty(explicitOrder));
        dispatch(ceteraActions.updateCatalogResults(
          annotatedResponse,
          onlyRecentlyViewed,
          enrolledInArchival,
          sortByRecentlyViewed
        ));
        dispatch(ceteraActions.fetchingResultsSuccess());
        onSuccess(annotatedResponse.results.length > 0);

        const showAssetCounts = _.get(getState(), 'assetBrowserProps.showAssetCounts');
        if (showAssetCounts) {
          fetchAssetCounts(dispatch, getState, parameters);
        }
      } else {
        dispatch(ceteraActions.fetchingResultsError());
      }
    })
    .catch((error) => {
      airbrake.notify({
        error,
        context: { component: 'AssetSelector federated domains' }
      });
      dispatch(ceteraActions.fetchingResultsError(error.message));
    });
};

export const fetchInitialResults = (parameters = {}) => (dispatch, getState) => {
  const onSuccess = () => dispatch({ type: INITIAL_RESULTS_FETCHED });
  return fetchResults(dispatch, getState, { action: FETCH_INITIAL_RESULTS, ...parameters }, onSuccess);
};
