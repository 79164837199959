import { useState } from 'react';
import { FieldValue } from 'common/types/metadataTemplate';

export const useErrors = (
  _hasSubmitted: boolean,
  inProgress: boolean,
  value: FieldValue
): [errors: string[], setInput: () => void] => {
  const [hasReceivedInput, setHasReceivedInput] = useState(false);
  const [prevInput, setHasReceivedPrevInput] = useState(false);
  const setInput = () => {
    setHasReceivedPrevInput(hasReceivedInput);
    setHasReceivedInput(true);
  };

  // Breaking this out into a procedural thing because it's getting confusing
  let errors = value.errors;

  // Leaving this here because it may come up again
  // Before you have entered anything in a form, it's kind of jarring to have
  // every field marked as an error. So the original behavior was to not show errors
  // until the user hit submit. But that's also kind of confusing, since it says
  // "errors" on the LHS sidebar but doesn't show what the errors are.
  // So we'll just show errors all the time (except for the case in the below comment)

  // if (!hasReceivedInput && !hasSubmitted) {
  //   // the user is initially entering something, suppress all errors
  //   errors = [];
  // }

  if (hasReceivedInput && inProgress && !prevInput) {
    // This is the weird case. when you first load the form, and type a character, hasReceivedInput
    // becomes true, inProgress becomes true, so we should show errors if they exist. Except the errors
    // that exist at that point are for the previous evaluation.
    // So lets say you have a required field, Dataset Description, and the initial value is '' and we load
    // the page and evaluate the template. There are errors, namely, "Dataset Description is required".
    // But we don't show them at that point, because the user hasn't done anything. We only want to show
    // them when we evaluate the template for that input, not when we have errors but we're inProgress for
    // the very first evaluation.
    // So we store one extra bit of information that tracks if there have been multiple inputs. Once that
    // happens, we just show the errors and they update whenever evaluation completes.
    // Without this case, an error message will appear briefly and be cleared when the evaluation completes
    // which is kind of jarring.
    errors = [];
  }

  return [errors, setInput];
};
