import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

// see similar implementation common/components/VideoGuides/useViewportSize.ts

//using breakpoints from https://socrata.atlassian.net/wiki/spaces/PD/pages/355041411/Responsive+Breakpoints
const SMALL_BREAKPOINT = 768;

function useScreenSize(mobileBreakpoint: number = SMALL_BREAKPOINT) {
  // by setting the default in useState to one we've calculated, we avoid cases where we may return the wrong value on initial load
  const [isMobileView, setIsMobile] = useState(window.innerWidth < mobileBreakpoint);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < mobileBreakpoint);
    }

    const throttledHandleResize = throttle(handleResize, 300);
    window.addEventListener('resize', throttledHandleResize);
    handleResize();

    return () => window.removeEventListener('resize', throttledHandleResize);
  }, [mobileBreakpoint]);

  return isMobileView;
}

export default useScreenSize;
