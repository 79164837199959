import { cloneDeep, get, isNil, set, has, unset } from 'lodash';

const modulePath = 'socrata.modules';

export const AccountModules = {
  // Returns true if account modules are available, false otherwise. The modules may be unavailable
  // if we're not on a Socrata domain (i.e., in a Javascript-powered visualization embed).
  available: () => has(window, modulePath),
  value: (key) => {
    if (isNil(`window.${modulePath}.${key}`)) {
      throw new Error(`Invalid module: '${key}'. Check that window.${modulePath} is present or that withTestModules() has set this module.`);
    }

    return get(window, `${modulePath}.${key}`);
  }
};

// Sets account modules for the duration of the current describe block.
// Restores old values afterwards.
//
// Usage:
//
// import { withTestModules } from 'common/account_modules';
//
// describe('some account module test', () => {
//   withTestModules({'enable_form_creation': false});
//
//   it('applies to a domain with enable_form_creation enabled', () => { ... });
// });
//

export const withTestModules = (modules) => {
  let originalValues;

  // mocha -> window.before / window.after
  // jest -> beforeAll / afterAll
  // eslint-disable-next-line no-undef
  const beforeFunction = window.before ? window.before : beforeAll;

  // eslint-disable-next-line no-undef
  const afterFunction = window.after ? window.after : afterAll;

  beforeFunction(() => {
    originalValues = has(window, modulePath) ? cloneDeep(get(window, modulePath)) : null;
    if (modules) {
      set(window, modulePath, {
        ...originalValues,
        ...modules
      });
    } else {
      unset(window, modulePath);
    }
  });

  afterFunction(() => {
    if (originalValues) {
      set(window, modulePath, originalValues);
    } else {
      unset(window, modulePath);
    }
  });
};


export default AccountModules;
