import _, { initial } from 'lodash';

import assert from 'common/assertions/assert';

import { getQueryParameter } from 'common/components/AssetBrowser/lib/query_string';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import * as filterActions from 'common/components/AssetBrowser/actions/filters';
import * as headerActions from 'common/components/AssetBrowser/actions/header';
import { APPROVALS } from 'common/core/approvals_enums';

/*
 * Since we're now storing some of the redux state in the URL, that means that we've distributed "truth" into
 * two different locations. At initial page load, Rails sets the initialState from a combination of default
 * state and URL parameters. Subsequent user interactions update both Redux state _and_ URL query parameters.
 */

const getStaticData = (key) => _.get(window, `socrata.assetBrowser.staticData.${key}`);

export const getInitialState = (initialProps) => {
  // Map all the domain's custom facets to an object of the facet param name to the value for
  // custom facets that have filters present in the URL query params.
  const customFacetFilters = (getStaticData('domainCustomFacets') || []).
    reduce((acc, customFacet) => {
      const customFacetValue = getQueryParameter(customFacet.param);
      if (customFacetValue) {
        acc[customFacet.param] = customFacetValue;
      }
      return acc;
    }, {});

  return {
    approvalStatus: getQueryParameter('approvalStatus'),
    assetTypes: getQueryParameter('assetTypes'),
    authority: getQueryParameter('authority'),
    category: getQueryParameter('category'),
    collectionParent: getQueryParameter('collectionParent'),
    customFacets: customFacetFilters,
    derivedFromFilterEnabled: true,
    domainCustomFacets: getStaticData('domainCustomFacets') || [],
    domainCategories: getStaticData('domainCategories') || [],
    domainTags: getStaticData('domainTags') || [],
    ids: getQueryParameter('ids'),
    idsFilterEnabled: true,
    onlyRecentlyViewed: false,
    enrolledInArchival: false,
    ownedBy: {
      displayName: getQueryParameter('ownerName'),
      id: getQueryParameter('ownerId')
    },
    parentIds: getQueryParameter('parentIds'),
    q: getQueryParameter('q'),
    source: getQueryParameter('source'),
    sources: getStaticData('sources'),
    tag: getQueryParameter('tag'),
    usersList: getStaticData('usersList') || [],
    version: getQueryParameter('version') || _.first(initialProps.allowedVersions),
    allowedVersions: initialProps.allowedVersions,
    showSystemDatasets: initialProps.showSystemDatasets,
    visibility: getQueryParameter('visibility'),
    requesters: [],
    showInternalTargetAudience: false,
    showPublicTargetAudience: false,
  };
};

// This function should _only_ be used for keeping track of user changes to the query. See also changeQ() in
// platform-ui/frontend/public/javascripts/internal_asset_manager/actions/filters.js
export const getCurrentQuery = () => getQueryParameter('q', '');

export const getUnfilteredState = (state) => ({
  approvalStatus: null,
  assetTypes: null,
  authority: null,
  category: null,
  collectionParent: null,
  customFacets: {},
  domainCategories: getStaticData('domainCategories') || [],
  domainCustomFacets: getStaticData('domainCustomFacets') || [],
  domainTags: getStaticData('domainTags') || [],
  ids: state.idsFilterEnabled ? state.ids : null,
  onlyRecentlyViewed: false,
  enrolledInArchival: false,
  ownedBy: {
    displayName: null,
    id: null
  },
  parentIds: state.derivedFromFilterEnabled ? state.parentIds : [],
  q: getCurrentQuery(),
  showAuthorityFilter: state.showAuthorityFilter,
  showInternalTargetAudience: false,
  showOwnedByFilter: state.showOwnedByFilter,
  showPublicTargetAudience: false,
  source: null,
  sources: getStaticData('sources'),
  tag: null,
  usersList: getStaticData('usersList') || [],
  version: _.first(state.allowedVersions),
  allowedVersions: state.allowedVersions,
  showSystemDatasets: state.showSystemDatasets,
  visibility: null
});

export default (initialProps) => (state, action) => {
  if (_.isUndefined(state)) {
    return getInitialState(initialProps);
  }

  if (action.type === filterActions.TOGGLE_PUBLIC_TARGET_AUDIENCE) {
    return {
      ...state,
      showPublicTargetAudience: !state.showPublicTargetAudience
    };
  }

  if (action.type === filterActions.TOGGLE_INTERNAL_TARGET_AUDIENCE) {
    return {
      ...state,
      showInternalTargetAudience: !state.showInternalTargetAudience
    };
  }

  if (action.type === filterActions.TOGGLE_AWAITING_APPROVAL) {
    return {
      ...state,
      approvalStatus: state.approvalStatus === APPROVALS.STATUS.PENDING ?
        null : APPROVALS.STATUS.PENDING
    };
  }

  if (action.type === filterActions.TOGGLE_DERIVED_FROM_FILTER) {
    const nextDerivedFromFilterEnabled = !state.derivedFromFilterEnabled;
    return {
      ...state,
      derivedFromFilterEnabled: nextDerivedFromFilterEnabled,
      parentIds: nextDerivedFromFilterEnabled ? action.parentIds : null
    };
  }

  if (action.type === filterActions.TOGGLE_IDS_FILTER) {
    const nextIdsFilterEnabled = !state.idsFilterEnabled;
    return {
      ...state,
      ids: nextIdsFilterEnabled ? action.ids : null,
      idsFilterEnabled: nextIdsFilterEnabled
    };
  }

  if (action.type === filterActions.TOGGLE_RECENTLY_VIEWED) {
    return {
      ...state,
      onlyRecentlyViewed: !state.onlyRecentlyViewed
    };
  }

  if (action.type === filterActions.TOGGLE_ENROLLED_IN_ARCHIVAL) {
    return {
      ...state,
      enrolledInArchival: !state.enrolledInArchival
    };
  }

  if (action.type === filterActions.CHANGE_ASSET_TYPE) {
    return {
      ...state,
      assetTypes: action.value,
      ...action.catalogParams
    };
  }

  if (action.type === filterActions.CHANGE_AUTHORITY) {
    return {
      ...state,
      authority: action.value
    };
  }

  if (action.type === filterActions.CHANGE_CATEGORY) {
    return {
      ...state,
      category: action.value
    };
  }

  if (action.type === filterActions.CHANGE_REQUESTER) {
    return {
      ...state,
      requester: action.value
    };
  }

  if (action.type === filterActions.CHANGE_SOURCE) {
    return {
      ...state,
      source: action.value
    };
  }

  if (action.type === filterActions.CHANGE_OWNER) {
    return {
      ...state,
      ownedBy: action.value
    };
  }

  if (action.type === filterActions.CHANGE_TAG) {
    return {
      ...state,
      tag: action.value
    };
  }

  if (action.type === filterActions.CHANGE_VERSION) {
    return {
      ...state,
      version: action.value
    };
  }

  if (action.type === filterActions.CHANGE_VISIBILITY) {
    return {
      ...state,
      visibility: action.value
    };
  }

  if (action.type === filterActions.CHANGE_Q) {
    return {
      ...state,
      q: action.value
    };
  }

  if (action.type === filterActions.CHANGE_CUSTOM_FACET) {
    const newState = _.cloneDeep(state);
    if (action.value) {
      newState.customFacets[action.facetParam] = action.value;
    } else {
      delete newState.customFacets[action.facetParam];
    }
    return newState;
  }

  if (action.type === filterActions.CLEAR_SEARCH) {
    return {
      ...state,
      q: ''
    };
  }

  if (action.type === filterActions.CLEAR_ALL_FILTERS) {
    return getUnfilteredState(state);
  }

  // headerActions reducers
  if (action.type === headerActions.CHANGE_TAB) {
    const { newTab } = action;
    assert(newTab, `${headerActions.CHANGE_TAB} action requires property: newTab`);

    // Only the "Federated" tab supports the "source" filter, so remove the
    // filter if the user is switching somewhere else.
    if (newTab !== constants.TAB_FEDERATED) {
      delete state.source;
    }

    // Upon switching between My Team Assets and Shared To Me, the contents of the Owned By filter will
    // change between showing Teams or Users respectively, so we clear the Owned By filter on tab changes.
    if (newTab === constants.TAB_MY_TEAM_ASSETS || newTab === constants.TAB_SHARED_TO_ME) {
      return {
        ...state,
        ownedBy: {
          displayName: null,
          id: null
        }
      };
    }

    if (newTab === constants.TAB_MY_ASSETS || newTab === constants.TAB_SHARED_TO_ME) {
      return {
        ...state,
        showAuthorityFilter: false,
        showOwnedByFilter: false
      };
    }
    if (newTab === constants.TAB_ALL_ASSETS) {
      return {
        ...state,
        showAuthorityFilter: true,
        showOwnedByFilter: true
      };
    }
  }

  return state;
};
