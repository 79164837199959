import $ from 'jquery';
import _ from 'lodash';
import 'velocity-animate';

import I18n from 'common/i18n';

 /**
  * Truncates text and adds a More/Less toggle. If the content does not exceed the allowed lines or
  * height, then it is not truncated and no labels are added.
  *
  * @param  {Node} el The .collapsible-content element. Must have a .collapsible parent.
  * @param  {Object} options
  *   - {boolean} collapsed - Whether or not the text should be collapsed. Allows caller to pass in its own state.
  *   - {function} setCollapsed - Function to call to set collapsed state. Allows caller to manage its own state.
  *   - {function} expandedCallback - Function to call when .collapsible-content is expanded
  *   - {Number} lines - Maximum lines of text for .collapsible-contet, overrides `height`, defaults to 2
  *   - {Number} height - Maximum allowed height for .collapsible-content
  *   - {Object} toggleText - text overrides for the toggle labels, has a default
  *     - {String} more - label to use instead of "Show more"
  *     - {String} less - label to use instead of "Show less"
  */
export default function(el, options) {
  var $el = $(el);
  var parent = el.parentElement;
  const $parent = $(parent);
  const lineHeight = _.get(options, 'lineHeight', parseInt($el.css('line-height'), 10));
  var collapsedHeight = options.height || (options.lines || 2) * lineHeight;
  var originalHeight = el.getBoundingClientRect().height;
  var expandedCallback = options.expandedCallback;

  /**
   * Functions to manage collapsed state.
   */
  const setCollapsed = (collapsed) => {
    parent.dataset.collapsed = collapsed;

    const _setCollapsed = _.get(options, 'setCollapsed', _.noop);
    _setCollapsed(collapsed);
  };

  const getCurrentlyCollapsed = () => _.get(options, 'collapsed', parent.dataset.collapsed === 'true');

  /**
   * Functions to update appearance.
   */
  const velocityOptions = {
    duration: 320,
    easing: [0.645, 0.045, 0.355, 1]
  };

  const collapse = () => {
    setCollapsed(true);
    $el.velocity({
      height: collapsedHeight
    }, velocityOptions);
  };

  const expand = () => {
    setCollapsed(false);
    $el.velocity({
        height: originalHeight
      }, {
        ...velocityOptions,
        //height is unset so element can resize with window
        complete: (element) => $(element).css('height', '')
      });

    if (_.isFunction(expandedCallback)) {
      expandedCallback();
    }
  };

  const toggleState = (shouldBeCollapsed) => {
    if (shouldBeCollapsed) {
      collapse();
    } else {
      expand();
    }
  };

  /**
   * Functions to initialize UI.
   */
  const createAndPositionCover = () => {
    if ($parent.find('.collapsible-cover').length > 0) {
      return;
    }

    const cover = document.createElement('div');
    cover.setAttribute('class', 'collapsible-cover');
    parent.appendChild(cover);

    // the cover only needs to obscure the last line
    cover.style.height = `${lineHeight}px`;
    // position the cover on top of the last line
    cover.style.top = `${collapsedHeight - lineHeight}px`;
  };

  const createToggle = (type) => {
    const toggle = document.createElement('button');
    toggle.setAttribute('class', `collapse-toggle ${type} btn btn-link`);
    toggle.setAttribute('aria-expanded', type == 'more' ? 'false' : 'true');
    toggle.textContent = _.get(options, `toggleText.${type}`, I18n.t(type, { scope: 'common' }));
    parent.appendChild(toggle);
  };

  const createToggles = () => {
    if ($parent.find('.collapse-toggle').length == 0) {
      createToggle('more');
      createToggle('less');
    }

    $parent.find('.collapse-toggle').
      off('click').
      on('click', (event) => {
        event.preventDefault();

        toggleState(!getCurrentlyCollapsed());
      });
  };

  function init() {
    if (collapsedHeight >= originalHeight) {
      return;
    }

    createAndPositionCover();
    createToggles();

    // Set UI state to whatever was passed in, or automatically collapse it
    toggleState(_.get(options, 'collapsed', true));
  }

  init();
}
