import contentDisposition from 'content-disposition';
import { provisionAgent } from 'common/dsmapiLinks';
import _ from 'lodash';

export interface DownloadResult {
  sha256: string | null;
  filename: string | null;
}
export const downloadAgent = ({ name }: { name: string }): Promise<DownloadResult> => {
  return new Promise((resolve, reject) => {

    const xhr = new XMLHttpRequest();
    xhr.open('POST', provisionAgent);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Content-Type', 'application/json');

    // why is this so gross? this is a hack to trigger the browser to download something
    // and then since we've told the xhr that we expect the response to be a blob, we
    // have to jump through some hoops to read it as json in the error case (when the
    // error is a regular json payload, not a zipfile)
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], { type: 'application/zip' });
        const filename = contentDisposition.parse(
          xhr.getResponseHeader('Content-Disposition')!
        ).parameters.filename;

        const saveOrOpenBlob = (window.navigator as any)?.msSaveOrOpenBlob;
        if (_.isFunction(saveOrOpenBlob)) {
          saveOrOpenBlob(blob, filename);
        } else {
          const a: any = document.createElement('a');
          a.style = 'display: none';
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        return resolve({
          sha256: xhr.getResponseHeader('x-socrata-agent-sha256'),
          filename
        });
      } else if (xhr.status === 400) {
        const errBlob = new Blob([xhr.response], { type: 'text/plain' });

        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
          const result = e.target?.result;
          if (result && _.isString(result)) {
            const { message } = JSON.parse(result);
            const error = message.replace('{name}', name);

            reject({ message: error });
          } else {
            reject({ message: 'unknown_error' });
          }
        });

        reader.readAsText(errBlob);
      } else {

        reject({ message: 'unknown_error' });
      }
    };

    const body = JSON.stringify({ name });
    xhr.send(body);
  });
};
