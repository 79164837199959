import React from 'react';
import ReactDOM from 'react-dom';

import _ from 'lodash';

// hack to get around Window being modified globally
// this is basically Window | null.
type TrueWindow = ReturnType<typeof window.open>;
export interface Props {
  windowName?: string;
  windowTitle?: string;
  trackWindow?: (targetWindow: TrueWindow) => void;
  setCustomTargetWindow?: (targetWindow: TrueWindow | undefined) => void;
  onClose?: () => void;

  position?: {
    top: number;
    left: number;
  };
  size?: {
    width: number;
    height: number;
  };
}

// Basically all the useful things about this file were stolen liberally from
// https://medium.com/hackernoon/using-a-react-16-portal-to-do-something-cool-2a2d627b0202
class ExternalWindowPortal extends React.Component<Props> {
  containerEl: HTMLElement;
  externalWindow: ReturnType<typeof window.open> | null;
  onCloseParent: () => void;

  constructor(props: Props) {
    super(props);

    this.containerEl = document.createElement('main');
    this.containerEl.setAttribute('role', 'main');
    this.containerEl.id = 'content';
    this.externalWindow = null;
    this.onCloseParent = this.closeChildWindow.bind(this);
  }

  constructWindowFeatures() {
    const { top, left } = this.props.position || {};
    const { width, height } = this.props.size || {};

    return _.chain({ top, left, width, height })
      .omitBy(v => !v)
      .map((v, k) => `${k}=${v}`)
      //.tap(str => console.log('windowFeatures=', str))
      .join(',')
      .value();
  }

  copyStyles(sourceDoc: Document, targetDoc: Document) {
    const viewport = Array.from(sourceDoc.querySelectorAll('meta')).find(meta => meta.name === 'viewport');
    if (viewport) {
      const newViewport = sourceDoc.createElement('meta');
      newViewport.name = viewport.name;
      newViewport.content = viewport.content;
      targetDoc.head.appendChild(newViewport);
    }

    Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
      if (!styleSheet.href && styleSheet.cssRules.length > 0) { // for <style> elements
        const newStyleEl = sourceDoc.createElement('style');

        if (styleSheet.ownerNode && (styleSheet.ownerNode as Element).id) {
          newStyleEl.id = (styleSheet.ownerNode as Element).id;
        }

        Array.from(styleSheet.cssRules).forEach(cssRule => {
          // write the text of each rule into the body of the style element
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
        });

        targetDoc.head.appendChild(newStyleEl);
      } else if (styleSheet.href) { // for <link> elements loading CSS from a URL
        const newLinkEl = sourceDoc.createElement('link');

        newLinkEl.rel = 'stylesheet';
        newLinkEl.media = styleSheet.media.mediaText;
        newLinkEl.href = styleSheet.href;
        targetDoc.head.appendChild(newLinkEl);
      }
    });
  }

  componentDidMount() {
    // This captures when the parent window is closed or refreshed, making it useful during development.
    // More importantly, we don't want the undocked child to hang around after the parent it shares state with is closed.
    window.addEventListener('beforeunload', this.onCloseParent);

    this.externalWindow = window.open('', this.props.windowName || '', this.constructWindowFeatures());
    if (!this.externalWindow) { throw new Error('Could not undock component.'); }

    this.externalWindow.document.title = this.props.windowTitle || window.document.title;
    this.copyStyles(document, this.externalWindow.document);
    this.externalWindow.document.body.appendChild(this.containerEl);

    if (this.props.trackWindow) {
      this.props.trackWindow(this.externalWindow);
    }
    if (this.props.onClose) {
      this.externalWindow.addEventListener('unload', this.props.onClose);
    }

    // Okay, I am kinda confused. This seems to be necessary SOMEtimes, but not ALWAYS? I don't understand, but it's here.
    if (this.props.setCustomTargetWindow) {
      this.props.setCustomTargetWindow(this.externalWindow);
    }
  }

  componentWillUnmount() {
    this.externalWindow?.close();
    window.removeEventListener('beforeunload', this.onCloseParent);
    if (this.props.setCustomTargetWindow) {
      this.props.setCustomTargetWindow(undefined);
    }
  }

  closeChildWindow() {
    this.externalWindow?.close();
    if (this.props.setCustomTargetWindow) {
      this.props.setCustomTargetWindow(undefined);
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }
}

export default ExternalWindowPortal;
