import _ from 'lodash';
import React from 'react';

import TextInput from './TextInput/TextInput';

import I18n from 'common/i18n';
import { ColumnFormat } from 'common/types/viewColumn';

const t = (k: string) => I18n.t(k, { scope: 'shared.dataset_management_ui.format_column' });
export interface Props {
  onChange: (f: ColumnFormat) => void;
  onRemove: (k: string) => void;
  format: ColumnFormat;
  formatIndex: number;
}

function NumberSeparators({ onChange, format, onRemove, formatIndex }: Props) {
  const noCommasChecked = format.noCommas;
  const isOverridingThousands = !_.isUndefined(format.groupSeparator);
  const toggleNoCommas = () => {
    const updatedFormat: ColumnFormat = { noCommas: !format.noCommas };

    if (isOverridingThousands) {
      updatedFormat.groupSeparator = undefined;
    }

    onChange(updatedFormat);
  };

  const thousandsDisabled = !!format.noCommas;
  const thousandsSeparatorChecked = isOverridingThousands;
  const toggleThousandsSeparator = () => {
    if (!thousandsDisabled) {
      if (isOverridingThousands) {
        onRemove('groupSeparator');
      } else {
        onChange({ groupSeparator: ',' });
      }
    }
  };
  const overrideThousands = isOverridingThousands ? (
    <TextInput
      field={{
        name: 'override-thousands',
        value: format.groupSeparator ?? undefined,
        disabled: thousandsDisabled
      }}
      dataTestId='override-thousands-input'
      inErrorState={format.groupSeparator == ''}
      handleChange={(e) => onChange({ groupSeparator: e.target.value || '' })}
    />
  ) : null;

  const isOverridingDecimal = !_.isUndefined(format.decimalSeparator);
  const decimalSeparatorChecked = isOverridingDecimal;
  const toggleDecimalSeparator = () => {
    if (isOverridingDecimal) {
      onRemove('decimalSeparator');
    } else {
      onChange({ decimalSeparator: '.' });
    }
  };
  const overrideDecimals = isOverridingDecimal ? (
    <TextInput
      field={{
        name: 'override-decimal',
        value: format.decimalSeparator
      }}
      dataTestId='override-decimal-input'
      inErrorState={false}
      handleChange={(e) => onChange({ decimalSeparator: e.target.value || '' })}
    />
  ) : null;

  const classNames = ['checkbox'];

  if (thousandsDisabled) {
    classNames.push('disabled');
  }

  return (
    <div>
      <div className="checkbox no-commas">
        <input
          id={`no-commas-${formatIndex}`}
          data-testid="no-commas-checkbox"
          type="checkbox"
          defaultChecked={noCommasChecked}
          onChange={toggleNoCommas}
        />
        <label htmlFor={`no-commas-${formatIndex}`}>
          <span className="fake-checkbox">
            <span className="socrata-icon-checkmark3" />
          </span>
          {t('hide_thousands')}
        </label>
      </div>
      <div className="thousands-separator">
        <div className={classNames.join(' ')}>
          <input
            id={`thousands-separator-${formatIndex}`}
            data-testid="thousands-separator-checkbox"
            type="checkbox"
            checked={thousandsSeparatorChecked}
            onChange={toggleThousandsSeparator}
          />
          <label htmlFor={`thousands-separator-${formatIndex}`}>
            <span className="fake-checkbox">
              <span className="socrata-icon-checkmark3" />
            </span>
            {t('override_thousands')}
          </label>
        </div>
        {overrideThousands}
      </div>
      <div className="decimal-separator">
        <div className="checkbox">
          <input
            id={`decimal-separator-${formatIndex}`}
            data-testid="decimal-separator-checkbox"
            type="checkbox"
            checked={decimalSeparatorChecked}
            onChange={toggleDecimalSeparator}
          />
          <label htmlFor={`decimal-separator-${formatIndex}`}>
            <span className="fake-checkbox">
              <span className="socrata-icon-checkmark3" />
            </span>
            {t('override_decimal')}
          </label>
        </div>
        {overrideDecimals}
      </div>
    </div>
  );
}

export default NumberSeparators;
