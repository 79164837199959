import React from 'react';

import _ from 'lodash';
import I18n from 'common/i18n';
import Button, { VARIANTS, SIZES } from 'common/components/Button';
import { isDerivedView, isDataset, isPublished } from 'common/views/view_types';
import { isUnacceptableQueryJson } from 'common/views/helpers';
import { View, ViewRight } from 'common/types/view';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_banner'});

export const canRenderExploreEntry = async (view: View): Promise<boolean> => {
  // don't show entry if you don't have permission to see the view (how would that even...)
  // or if the view has disqualifying queryJson
  if (!view.rights.includes(ViewRight.Read) || isUnacceptableQueryJson(view)) return false;
  // other than that, show it for every published, tabular thing
  return isPublished(view) && (isDataset(view) || isDerivedView(view));
};

export interface ExploreEntryProps {
  url: string;
  intro: string;
}

export const ExploreEntry = ({url, intro}: ExploreEntryProps): JSX.Element => {
  return (
    <span className="banner-main">
      <span>{intro}</span>
      <Button
        href={url}
        size={SIZES.X_SMALL}
        variant={VARIANTS.DEFAULT}>
          {t('try_it_now')}
      </Button>
    </span>
  );
};
