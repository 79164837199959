import React from 'react';
import moment from 'moment';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { Agent } from 'common/types/gateway';
import { attachTo } from 'common/components/legacy';

import './index.scss';

const t = (k: string) => I18n.t(`shared.data_gateway.${k}`);

interface Props {
  agent: Agent;
}

const flyoutId = (agent: Agent) => {
  return `online-offline-${agent.agent_uid}`;
};

const Flyout: React.SFC<Props> = (props) => (
  <div className="flyout flyout-hidden" id={flyoutId(props.agent)}>
    <section className="flyout-content">
      <span className="flyout-body">
        {props.children}
      </span>
    </section>
  </div>
);

const OnlineFlyout = ({ agent }: Props) => (
  <Flyout agent={agent}>
    <span className="online-offline-time">
      {t('online_for')}{' '}{moment.utc(agent.went_online_at).locale(I18n.locale).fromNow(true)}
    </span>
  </Flyout>
);

const OfflineFlyout = ({ agent }: Props) => (
  <Flyout agent={agent}>
    <span className="online-offline-time">
      {t('offline_for')}{' '}{moment.utc(agent.went_offline_at).locale(I18n.locale).fromNow(true)}
    </span>
  </Flyout>
);

export default class GatewayOnlineOffline extends React.Component<Props> {
  flyoutParentEl: Element | null = null;
  // there is a bug where a re-render causes the flyout to stop working.
  // i've given up.
  // fuck the common/Flyout it needs to be re-thought.
  componentDidMount() {
    this.attachFlyouts();
  }

  componentDidUpdate() {
    this.attachFlyouts();
  }

  attachFlyouts() {
    attachTo(this.flyoutParentEl);
  }

  render() {
    const { agent } = this.props;
    let inner = null;
    if (agent.went_offline_at) {
      inner = (
        <div className="agent-status agent-offline" data-flyout={flyoutId(agent)}>
          <span className="text-muted">{t('went_offline')}</span>
          <SocrataIcon name={IconName.CloseCircle} />
          <OfflineFlyout agent={agent} />
        </div>
      );
    } else if (agent.went_online_at) {
      inner = (
        <div className="agent-status agent-online" data-flyout={flyoutId(agent)}>
          <span className="text-muted">{t('went_online')}</span>
          <SocrataIcon name={IconName.CheckmarkAlt} />
          <OnlineFlyout agent={agent} />
        </div>
      );
    }

    return (
      <div ref={el => { this.flyoutParentEl = el; }}>
        {inner}
      </div>
    );


  }
}
