import React from 'react';

import './index.scss';

export interface Option {
  title: string;
  value: string | number | undefined;
}

interface Field {
  name: string;
  label?: string;
  isRequired: boolean;
  value: string | number | undefined;
  options: Option[];
  disabled?: boolean;
  selected?: boolean;
}

interface SelectProps {
  field: Field;
  inErrorState?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function Select({ field, inErrorState, handleChange }: SelectProps) {
  const classNames = ['select-component-main'];

  if (inErrorState) {
    classNames.push('select-component-error');
  }

  return (
    <select
      name={field.name}
      id={field.name}
      aria-label={field.label}
      aria-required={field.isRequired}
      value={field.value}
      onChange={handleChange}
      className={classNames.join(' ')}
      disabled={field.disabled}>
      {field.options.map((option, idx) => (
        <option value={option.value} key={idx} selected={field.selected}>
          {option.title}
        </option>
      ))}
    </select>
  );
}

export default Select;
