import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import { propTypeEitherRequired } from 'common/propTypes';

import RoutingTab from './RoutingTab';

const eitherPathOrPathSelector = propTypeEitherRequired({
  path: PropTypes.string,
  pathSelector: PropTypes.func
});

const eitherIsVisibileOrIsVisibleSelector = propTypeEitherRequired({
  isVisible: PropTypes.bool,
  isVisibleSelector: PropTypes.func
});

/**
 * This is passed in as children of a TabbedPanelInterface
 *
 * More of a "bucket of config" than a component, this is only a component so that
 * it can be used via JSX. It is easier to think about it more as a JSON object.
 */
class TabbedPanel extends Component {
  static propTypes = {
    // path to render tab under
    path: eitherPathOrPathSelector,

    // function to call to get path for tab; used if path is not passed in
    // will be called with the current redux state
    pathSelector: eitherPathOrPathSelector,

    // component to render as the content for this path
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element]).isRequired,

    // component to render for the tab link itself; either this or tabTitle can be used
    tabComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element]),

    // title to use for tab if no tabComponent is supplied
    tabTitle: PropTypes.string,

    // whether or not this tab is visible
    isVisible: eitherIsVisibileOrIsVisibleSelector,

    // function to call to determine if this tab is visible; used if isVisible is not passed in
    // will be called with the current redux state
    isVisibleSelector: eitherIsVisibileOrIsVisibleSelector
  };

  /*
   * Given a state (from redux) and props from a TabbedPanel, this will return the "connected" props
   * TabbedPanel can either be passed some props (i.e. isVisible, path) directly
   * or given selector functions that grab things from state
   *
   * We cannot directly connect these to state since Router requires a list of Route components
   * and doesn't know how to traverse children to find Routes
   */
  static connectTabPanelToState = (
    state,
    { path, pathSelector, component, tabComponent, tabTitle, isVisible, isVisibleSelector }
  ) => ({
    path: path || pathSelector(state),
    component,
    tabComponent: tabComponent || (tabTitle ? (() => <RoutingTab to={path}>{tabTitle}</RoutingTab>) : null),
    isVisible: isVisibleSelector ? isVisibleSelector(state) : isVisible
  });

  /** Generate a Route for the given path and component */
  static generateRoute = ({ path, component, isVisible = true }) =>
    isVisible && <Route key={path} path={path} component={component} />;

  render() {
    return null;
  }
}

export default TabbedPanel;
