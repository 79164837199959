import React, { useState, useRef, useEffect } from 'react';
import {
  ForgeStack,
  ForgeSelect,
  ForgeTextField,
  ForgeIconButton,
  ForgeIcon,
  ForgeTooltip
} from '@tylertech/forge-react';
import { ISelectOption } from '@tylertech/forge';
import CopyToClipboard from 'react-copy-to-clipboard';
import I18n from 'common/i18n';
import { generateBaseUrl } from '../helpers';
import { DatasetLandingPageEnhancedView } from 'common/types/view';

const t = (key: string, options: { [key: string]: any } = {}) =>
  I18n.t(key, { scope: 'shared.components.share_and_embed.embed_tab', ...options });

export interface EmbedTabProps {
  view: DatasetLandingPageEnhancedView;
  setShowCopiedLinkToast: (shouldShow: boolean) => void;
  activeTab: number;
}

export enum EmbedSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  CUSTOM = 'custom'
}

const embedSizeDimensions: Record<
  Exclude<EmbedSizes, EmbedSizes.CUSTOM>,
  { width: number; height: number }
> = {
  small: { width: 500, height: 425 },
  medium: { width: 760, height: 646 },
  large: { width: 950, height: 808 }
};

const isValidCustomDimension = (dimension: string): boolean => {
  if (!dimension) {
    return true; // Let them empty out the textbox
  }

  const convertedDimension = Number(dimension);
  if (
    !isNaN(convertedDimension) &&
    convertedDimension <= Number.MAX_SAFE_INTEGER &&
    convertedDimension >= 1
  ) {
    return true;
  }
  return false;
};

const matchEmbedSizeDimensions = (width: number, height: number): EmbedSizes => {
  const matchingSize = Object.entries(embedSizeDimensions).find(
    ([, { width: sizeWidth, height: sizeHeight }]) => {
      return width === sizeWidth && height === sizeHeight;
    }
  );

  if (matchingSize) {
    return EmbedSizes[matchingSize[0].toUpperCase() as keyof typeof EmbedSizes];
  } else {
    return EmbedSizes.CUSTOM;
  }
};

const dropdownOptions: ISelectOption[] = [
  {
    value: EmbedSizes.SMALL,
    label: `${embedSizeDimensions.small.width} x ${embedSizeDimensions.small.height}`
  },
  {
    value: EmbedSizes.MEDIUM,
    label: `${embedSizeDimensions.medium.width} x ${embedSizeDimensions.medium.height}`
  },
  {
    value: EmbedSizes.LARGE,
    label: `${embedSizeDimensions.large.width} x ${embedSizeDimensions.large.height}`
  },
  { value: EmbedSizes.CUSTOM, label: t('size_options.custom') }
];

const dropdownOptionBuilder = ({ label, value }: { label: string; value: EmbedSizes }) => {
  const subTitle =
    value === EmbedSizes.CUSTOM ? undefined : `<span slot="subtitle">${t(`size_options.${value}`)}</span>`;

  return `<forge-list-item>
      <span slot="title">${label}</span>
      ${subTitle || ''}
    </forge-list-item>`;
};

const generateNewEmbedCode = (
  view: DatasetLandingPageEnhancedView,
  width: number,
  height: number
): string => {
  const embedUrl = `${generateBaseUrl()}/w/${view.id}`;

  const attributionLink = `<p><a href="https://tylertech.com" target="_blank">${t('embed_attribution_link_label')}</a></p>`;

  return `<div><iframe title="${view.name}" width="${width}" height="${height}" src="${embedUrl}" frameborder="0" tabindex="0" scrolling="no"></iframe>${attributionLink}</div>`;
};

const EmbedTab: React.FunctionComponent<EmbedTabProps> = ({ view, setShowCopiedLinkToast, activeTab }) => {
  const [embedUrl, setEmbedUrl] = useState<string>(
    generateNewEmbedCode(view, embedSizeDimensions.small.width, embedSizeDimensions.small.height)
  );
  const [selectedSize, setSelectedSize] = useState<EmbedSizes>(EmbedSizes.SMALL);
  const [width, setWidth] = useState<number>(embedSizeDimensions.small.width);
  const [height, setHeight] = useState<number>(embedSizeDimensions.small.height);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const resizeEmbedTextbox = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (activeTab == 1) {
      window.requestAnimationFrame(resizeEmbedTextbox);
    }
  }, [activeTab]);

  useEffect(() => {
    resizeEmbedTextbox();
  }, [embedUrl]);

  const onChangeDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newWidth = width;
    let newHeight = height;

    switch (event.target.value as EmbedSizes) {
      case EmbedSizes.SMALL: {
        newWidth = embedSizeDimensions.small.width;
        newHeight = embedSizeDimensions.small.height;
        break;
      }
      case EmbedSizes.MEDIUM: {
        newWidth = embedSizeDimensions.medium.width;
        newHeight = embedSizeDimensions.medium.height;
        break;
      }
      case EmbedSizes.LARGE: {
        newWidth = embedSizeDimensions.large.width;
        newHeight = embedSizeDimensions.large.height;
        break;
      }
      // For custom, just leave the width/height as is
    }

    updateState(newWidth, newHeight, event.target.value as EmbedSizes);
  };

  const onChangeTextbox = (newValue: number, dimension: 'width' | 'height') => {
    const newWidth = dimension === 'width' ? newValue : width;
    const newHeight = dimension === 'height' ? newValue : height;

    updateState(newWidth, newHeight, matchEmbedSizeDimensions(newWidth, newHeight));
  };

  const updateState = (newWidth: number, newHeight: number, newSelectedSize: EmbedSizes) => {
    setSelectedSize(newSelectedSize);
    setWidth(newWidth);
    setHeight(newHeight);
    setEmbedUrl(generateNewEmbedCode(view, newWidth, newHeight));
  };

  return (
    <div className="embed-tab">
      <ForgeStack>
        <div>
          <span>{t('embed_link_top_label')}</span>
          <ForgeTextField className="embed-url" floatLabelType="always">
            <textarea ref={textAreaRef} id="embed-url-text-area" rows={2} value={embedUrl} disabled />
            <label slot="label" htmlFor="embed-url-text-area">
              {t('embed_link_label')}
            </label>
            <CopyToClipboard text={embedUrl}>
              <ForgeIconButton className="link-copy-button" slot="trailing">
                <button
                  onClick={() => setShowCopiedLinkToast(true)}
                  aria-label={t('copy_button_label', { scope: 'shared.components.share_and_embed' })}
                  data-testid="embed-tab-copy-button"
                >
                  <ForgeIcon name="content_copy" />
                </button>
                <ForgeTooltip position="bottom">
                  {t('copy_button_label', { scope: 'shared.components.share_and_embed' })}
                </ForgeTooltip>
              </ForgeIconButton>
            </CopyToClipboard>
          </ForgeTextField>
        </div>
        <ForgeSelect
          className="size-select"
          label={t('size_select_label')}
          options={dropdownOptions}
          value={selectedSize}
          optionBuilder={dropdownOptionBuilder}
          onChange={onChangeDropdown}
          data-testid="embed-tab-size-select"
        >
          <span slot="helper-text">{t('size_select_helper_text')}</span>
        </ForgeSelect>
        <ForgeStack inline gap={8}>
          <ForgeTextField className="dimension-textbox">
            <label htmlFor="width" slot="label">
              {t('width_label')}
            </label>
            <input
              id="width"
              type="text"
              value={width}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (isValidCustomDimension(event.target.value)) {
                  onChangeTextbox(Number(event.target.value), 'width');
                }
              }}
              data-testid="embed-tab-width-input"
            />
            <span slot="helper-text">px</span>
          </ForgeTextField>
          <span className="dimension-separator">X</span>
          <ForgeTextField className="dimension-textbox">
            <label htmlFor="height" slot="label">
              {t('height_label')}
            </label>
            <input
              id="height"
              type="text"
              value={height}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (isValidCustomDimension(event.target.value)) {
                  onChangeTextbox(Number(event.target.value), 'height');
                }
              }}
              data-testid="embed-tab-height-input"
            />
            <span slot="helper-text">px</span>
          </ForgeTextField>
        </ForgeStack>
      </ForgeStack>
    </div>
  );
};

export default EmbedTab;
