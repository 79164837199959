import { fetchJsonWithDefaultHeaders } from 'common/http';
import { MetadataChanges, Revision, RevisionMetadata } from 'common/types/revision';
import {
  ApplyMetadataTaskResult,
  ColumnChangeAttributes,
  CreateColumnOperation,
  DeleteColumnOperation,
  SchemaChangeOperation,
  SchemaChangeTaskResult,
  TaskSetStatus,
  UpdateColumnOperation,
  UpsertTaskResult
} from 'common/types/taskSet';
import { View } from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import _ from 'lodash';
import { none, Option, option, some } from 'ts-option';
import { Change, getRevisions } from './util';

type ArchivedColumnMetadata = Pick<ViewColumn, 'fieldName' | 'name' | 'description' | 'dataTypeName'>;
interface MetadataUpdates {
  updates: Record<string, any>;
  additions: Record<string, any>;
  deletions: Record<string, any>;
}
interface ColumnUpdates {
  updates: ArchivedColumnMetadata[];
  additions: ArchivedColumnMetadata[];
  deletions: ArchivedColumnMetadata[];
}
interface ArchiveRow {
  update_date: string;
  user: string;
  rows: number;
  row_change: number;
  metadata_published: RevisionMetadata;
  metadata_updates: MetadataUpdates;
  column_level_metadata: ArchivedColumnMetadata[];
  column_level_metadata_updates: ColumnUpdates;
  archive_link: string;
}

function getArchiveFourFour(view: View) {
  const primer = (view.metadata?.custom_fields?.Archive || {})['Archive Repository'];
  if (primer && _.isString(primer)) {
    const archiveFourFour = _.last(primer.split('/'));
    return option(archiveFourFour);
  }
  return none;
}

async function getLegacyCount(fourfour: string): Promise<number> {
  return (
    await fetchJsonWithDefaultHeaders(
      `/id/${fourfour}?${new URLSearchParams({
        $query: 'SELECT count(*)'
      }).toString()}`
    )
  )[0].count;
}

async function getRows(archiveFourFour: string, offset: number, limit: number): Promise<ArchiveRow[]> {
  const url = `/id/${archiveFourFour}?${new URLSearchParams({
    $query: `
      SELECT
        update_date,
        user,
        rows,
        row_change,
        metadata_published,
        metadata_updates,
        column_level_metadata,
        column_level_metadata_updates,
        archive_link
      ORDER BY update_date DESC
      OFFSET ${offset}
      LIMIT ${limit}
    `
  }).toString()}`;

  const rows: Record<string, any>[] = await fetchJsonWithDefaultHeaders(url);
  const archiveRows: ArchiveRow[] = rows.map((row) => {
    return {
      update_date: row.update_date,
      user: row.user,
      rows: _.toNumber(row.rows),
      row_change: _.toNumber(row.row_change),
      metadata_published: JSON.parse(row.metadata_published) as RevisionMetadata,
      metadata_updates: JSON.parse(row.metadata_updates) as MetadataUpdates,
      column_level_metadata_updates: JSON.parse(row.column_level_metadata_updates) as ColumnUpdates,
      column_level_metadata: JSON.parse(row.column_level_metadata) as ArchivedColumnMetadata[],
      archive_link: row.archive_link.url
    };
  });

  return archiveRows;
}

const intoRevision = (fourfour: string, row: ArchiveRow, prevRow: ArchiveRow): Revision => {
  const user = {
    display_name: row.user,
    user_id: null
  };

  const log = [];
  if (row.row_change !== 0 && !_.isNaN(row.row_change)) {
    const upsertTask: UpsertTaskResult = {
      stage: 'upsert_task',
      details: {
        total_rows: row.rows,
        'Rows Created': 0,
        'Rows Deleted': 0,
        'Rows Updated': 0,
        error_count: 0
      }
    };
    log.push(upsertTask);
  }

  const ddl = row.column_level_metadata_updates;
  if (ddl.additions.length || ddl.deletions.length || ddl.updates.length) {
    const intoColumnChangeAttributes = (change: ArchivedColumnMetadata): ColumnChangeAttributes => ({
      flags: [],
      field_name: change.fieldName,
      display_name: change.name || change.fieldName,
      position: 0,
      description: change.description,
      format: {},
      width: 0,
      soql_type: change.dataTypeName
    });
    const creates = ddl.additions.map((add): CreateColumnOperation => {
      return {
        type: 'create_column',
        attributes: intoColumnChangeAttributes(add)
      };
    });

    const updates = ddl.updates.flatMap((update): UpdateColumnOperation[] => {
      const prevDefinition = prevRow.column_level_metadata.find((col) => col.fieldName === update.fieldName);

      if (prevDefinition) {
        return [
          {
            type: 'update_column',
            attributes: {
              ...intoColumnChangeAttributes(update),
              original_field_name: prevDefinition.fieldName
            },
            prev_attributes: intoColumnChangeAttributes(prevDefinition)
          }
        ];
      }
      return [];
    });

    const deletes = ddl.deletions.flatMap((del): DeleteColumnOperation[] => {
      const prevDefinition = prevRow.column_level_metadata.find((col) => col.fieldName === del.fieldName);

      if (prevDefinition) {
        return [
          {
            type: 'drop_column',
            attributes: {
              field_name: del.fieldName
            },
            prev_attributes: intoColumnChangeAttributes(del)
          }
        ];
      }
      return [];
    });

    if (creates.length || updates.length || deletes.length) {
      const schemaChangeTask: SchemaChangeTaskResult = {
        stage: 'set_schema',
        details: {
          // bleh, cast is an up-cast here.
          operations: _.concat(creates as SchemaChangeOperation[], updates, deletes)
        }
      };
      log.push(schemaChangeTask);
    }
  }

  if (
    !_.isEmpty(row.metadata_updates.additions) ||
    !_.isEmpty(row.metadata_updates.deletions) ||
    !_.isEmpty(row.metadata_updates.updates)
  ) {
    const intoMetadataChanges = (m: RevisionMetadata): MetadataChanges => {
      return {
        name: m.name,
        attachments: [],
        sorts: [],
        metadata: m,
        tags: [],
        description: m.description || undefined
      };
    };
    const metadataTask: ApplyMetadataTaskResult = {
      stage: 'apply_metadata',
      details: {
        diff: {
          old: intoMetadataChanges(prevRow.metadata_published),
          new: intoMetadataChanges(row.metadata_published)
        }
      }
    };
    log.push(metadataTask);
  }
  return {
    fourfour,
    id: -1,
    revision_seq: -1,
    attachments: [],
    created_at: new Date(row.update_date),
    updated_at: new Date(row.update_date),
    closed_at: row.update_date,
    blob_id: null,
    action: {
      type: 'update'
    },
    output_schema_id: null,
    task_sets: [
      {
        created_at: row.update_date,
        finished_at: row.update_date,
        created_by: user,
        id: 0,
        log,
        status: TaskSetStatus.Successful,
        request_id: ''
      }
    ],
    domain_id: 0,
    is_parent: false,
    created_by: user,
    metadata: row.metadata_published,
    archive: {
      visible: true,
      // ;_;
      is_hhs_entry: true,
      export_link: row.archive_link
    },
    creation_source: 'browser'
  };
};

const getHHSRevisionsIncludingLegacyArchives =
  (view: View) =>
  async (cursor: Option<string>, limit: number): Promise<{changes: Change[], next: Option<string>}> => {
    const offset = cursor.map((c) => _.toInteger(c) || 0).getOrElseValue(0);
    const realRevisions = await getRevisions(view)(offset, limit);
    let revisions: Revision[] = realRevisions.revisions;
    const legacyCount = await getArchiveFourFour(view)
      .map(getLegacyCount)
      .getOrElse(() => Promise.resolve(0));

    const legacyOffset = Math.max(0, offset - realRevisions.totalCount.getOrElseValue(offset));
    const legacyLimit = limit - realRevisions.revisions.length;

    if (legacyLimit > 0) {
      revisions = await getArchiveFourFour(view)
        .map(async (archiveFourFour) => {
          // this is to go one passed the end so we have the previous row available, so we can generate a diff
          const legacyRows = await getRows(archiveFourFour, legacyOffset, legacyLimit + 1);

          const { results: fakeRevisions } = legacyRows.reduceRight<{
            prevRow: ArchiveRow | null;
            results: Revision[];
          }>(
            (acc, row: ArchiveRow) => {
              const { prevRow, results } = acc;
              if (prevRow) {
                return {
                  prevRow: row,
                  results: [...results, intoRevision(view.id, row, prevRow)]
                };
              } else {
                return {
                  prevRow: row,
                  results: []
                };
              }
            },
            {
              prevRow: null,
              results: [] as Revision[]
            }
          );
          return _.concat(realRevisions.revisions, fakeRevisions);
        })
        .getOrElseValue(Promise.resolve(realRevisions.revisions));
    }

    const totalSize = realRevisions.totalCount.map((c) => c + legacyCount).getOrElseValue(0);

    return {
      changes: revisions.map(r => ({ type: 'revision', value: r})),
      next: offset < totalSize ? some(offset + revisions.length + '') : none
    };
  };

export default getHHSRevisionsIncludingLegacyArchives;
