import PropTypes from 'prop-types';

export const MessageShapePropType = PropTypes.shape({
  buttonLabel: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
});

export const AcceptedFileTypesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    extension: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
);
