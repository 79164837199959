import React from 'react';
import { ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

export interface FieldsetProps {
  title: string;
  subtitle: string;
  containerClass?: string;
  legendClass?: string;
  className?: string;
  closable?: boolean;
  closeCallback?: () => void;
}

const Fieldset: React.FunctionComponent<FieldsetProps> = ({
  title,
  subtitle,
  children,
  containerClass,
  legendClass,
  className,
  closable,
  closeCallback
}) => {
  const fieldSetClasses = ['metadata-component-fieldset'];
  const legendClasses = ['metadata-component-tab-title'];

  if (containerClass) {
    fieldSetClasses.push(containerClass);
  }

  if (legendClass) {
    legendClasses.push(legendClass);
  }

  return (
    <div id="fieldset" className={className}>
      <fieldset className={fieldSetClasses.join(' ')}>
        <legend className={legendClasses.join(' ')}>
          {title}
          {closable && (
            <ForgeIconButton>
              <button
                className="metadata-component-close-button"
                type="button"
                onClick={closeCallback}
                data-testid="metadata-component-fieldset-close-button"
              >
                <ForgeIcon name="close" />
              </button>
            </ForgeIconButton>
          )}
        </legend>
        <span className="metadata-component-tab-subtitle">{subtitle}</span>
        {children}
      </fieldset>
    </div>
  );
};

export default Fieldset;
