import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

import Form from 'common/components/Forms/Form';
import I18n from 'common/i18n';

export class AssetInventoryActions extends Component {
  render() {
    const { buttonDisabled, showScheduleJobButton } = this.props;
    const linkScope = 'shared.asset_browser.asset_inventory_dataset_link';
    const getLinkTranslation = (key) => I18n.t(key, { scope: linkScope });
    const actionsScope = 'shared.asset_browser.asset_inventory_actions';
    const getActionsTranslation = (key) => I18n.t(key, { scope: actionsScope });

    const buttons = [];

    if (showScheduleJobButton) {
      buttons.push((
        <Form key="schedule" action="/admin/asset_inventory/schedule">
          <button className="btn btn-primary btn-inverse schedule-job" type="submit">
            {getActionsTranslation('update_asset_inventory')}
          </button>
        </Form>
      ));
    }

    if (!buttonDisabled) {
      const buttonClassnames = classNames('btn btn-primary btn-inverse view-asset-inventory', { 'btn-disabled': buttonDisabled });
      const buttonTitle = getLinkTranslation('disabled_tooltip');
      buttons.push((
        <a key="show" href="/admin/asset_inventory">
          <button className={buttonClassnames} disabled={buttonDisabled} title={buttonTitle}>
            {getLinkTranslation('asset_inventory_dataset')}
          </button>
        </a>
      ));
    }

    return (<div className="asset-inventory-actions-wrapper">
      {buttons}
    </div>);
  }
}

AssetInventoryActions.propTypes = {
  buttonDisabled: PropTypes.bool.isRequired,
  showScheduleJobButton: PropTypes.bool.isRequired
};

const mapStateToProps = ({ assetInventoryViewModel }) => ({
  buttonDisabled: !!_.get(assetInventoryViewModel, 'asset_inventory.button_disabled'),
  showScheduleJobButton: !!_.get(assetInventoryViewModel, 'asset_inventory.show_schedule_job_button')
});

export default connect(mapStateToProps)(AssetInventoryActions);
