import React from 'react';
import { ForgeIcon } from '@tylertech/forge-react';
import classNames from 'classnames';

import { SoQLType } from 'common/types/soql';
import { getIconNameForDataType, getForgeIconNameForDataType } from 'common/views/dataTypeMetadata';

interface Props {
  type: SoQLType | null;
  disabled?: boolean;
  forge?: boolean;
  slot?: string;
}

export default function ColumnTypeIcon({ type, disabled, forge, slot }: Props): JSX.Element | null {
  if (!type) return null;
  if (forge) {
    const iconName = getForgeIconNameForDataType(type.toString());
    return <ForgeIcon slot={slot} name={iconName} />;
  } else {
    const iconName = getIconNameForDataType(type.toString());
    const klass = classNames('column-type-icon', `socrata-icon-${iconName}`, {
      'type-icon-disabled': !!disabled
    });
    return <span className={klass}></span>;
  }
}
