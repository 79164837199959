import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { MetadataChannelActions, metadataChannelReceivedEvaluationResults } from './metadataChannel';
import { FormErrors, AssetMetadata } from '../../types';
import { RootState } from '../store';

export enum FormActionType {
  markFormDirty = 'MARK_FORM_DIRTY',
  setFormErrors = 'SET_FORM_ERRORS',
  setTemplateErrors = 'SET_TEMPLATE_ERRORS',
  setFormState = 'SET_FORM_STATE',
  markFormClean = 'MARK_FORM_CLEAN',
  markFormSubmitted = 'MARK_FORM_SUBMITTED',
  markFormUnsubmitted = 'MARK_FORM_UNSUBMITTED'
}

export interface MarkFormDirtyAction {
  type: FormActionType.markFormDirty;
}

export interface SetTemplateErrorsAction {
  type: FormActionType.setTemplateErrors;
  templateErrors: string[];
}

export interface MarkFormCleanAction {
  type: FormActionType.markFormClean;
}

export interface SetFormStateAction {
  type: FormActionType.setFormState;
  state: AssetMetadata | undefined;
}

export interface MarkFormSubmittedAction {
  type: FormActionType.markFormSubmitted;
}

export interface SetFormErrorsAction {
  type: FormActionType.setFormErrors;
  errors: FormErrors;
}

export interface MarkFormUnsubmittedAction {
  type: FormActionType.markFormUnsubmitted;
}

export type FormActions =
  | SetTemplateErrorsAction
  | MarkFormCleanAction
  | MarkFormSubmittedAction
  | SetFormErrorsAction
  | SetFormStateAction
  | MarkFormUnsubmittedAction
  | MarkFormDirtyAction;

type DispatchableActions = FormActions | MetadataChannelActions;

type Dispatch = ThunkDispatch<RootState, void, DispatchableActions>;

export const markFormDirty = (): MarkFormDirtyAction => ({
  type: FormActionType.markFormDirty
});

export const setTemplateErrors = (templateErrors: string[]): SetTemplateErrorsAction => ({
  type: FormActionType.setTemplateErrors,
  templateErrors
});

export const markFormClean = (): MarkFormCleanAction => ({
  type: FormActionType.markFormClean
});

export const setFormState = (state: AssetMetadata | undefined): SetFormStateAction => ({
  type: FormActionType.setFormState,
  state
});

export const markFormSubmitted = (): MarkFormSubmittedAction => ({
  type: FormActionType.markFormSubmitted
});

export const setFormErrors = (errors: FormErrors): SetFormErrorsAction => ({
  type: FormActionType.setFormErrors,
  errors
});

export const markFormUnsubmitted = (): MarkFormUnsubmittedAction => ({
  type: FormActionType.markFormUnsubmitted
});

export const dismissMetadataComponent =
  (onClose: () => void): ThunkAction<void, RootState, void, FormActions> =>
  (dispatch: Dispatch) => {
    // Ensure form is marked clean so that if user reopens pane they don't see the
    // save button enabled before they make any changes.
    dispatch(markFormClean());

    // Alright, this is funky and a relic from the past.
    // way back in the day, this whole "ManageMetadata" thing was implemented as one component
    // which had state in two places in redux, and two places local to the component. It put things
    // in redux to share form state with other components (like the publish button, and metadata sidebar)
    // and had some local state which consisted of the form data itself, mostly.
    // In refactoring it to all just be in redux, things became *much* simpler, but the old behavior of
    // you being shown a modal of "You have unsaved changes, do you want to abandon them?"
    // and clicking yes actually abandoning them was kind of broken, since if you navigated back to the
    // form, the state was still there in redux, so you'd be shown the errors and the old state.
    // Arguably, this is a *better* user experience, but it complicates things as you navigate around the app.
    // What happens if you change a column name, don't hit save, and then navigate to the table page? Should
    // the table have your updated column name? It's in the form state, but not really part of the rest
    // of the state yet, as you you haven't comitted to the change. What happens if you enter an invalid
    // column name, navigate away and say "yes I want to abandon my changes" and then you see a
    // disabled publish button because there is lurking invalid data...that's weird!
    //
    // So, let's use the redux state, since it makes things *way* simpler, and we only have state in one place,
    // rather than 4, but we'll maintain the old behavior of blowing the state away when the user dismisses
    // the modal, even though we *could* keep it.
    dispatch(markFormUnsubmitted());
    dispatch(setFormState(undefined));
    dispatch(setFormErrors({}));
    dispatch(setTemplateErrors([]));
    dispatch(metadataChannelReceivedEvaluationResults([]));

    onClose();
  };
