import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import React from 'react';

interface Props {
  icon: IconName;
  title: string;
}

function DropdownWithIcon({ icon, title }: Props) {
  return (
    <div>
      <SocrataIcon name={icon} />
      {title}
    </div>
  );
}


export default DropdownWithIcon;
