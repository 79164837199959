import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';

import * as constants from 'common/components/AssetBrowser/lib/constants';
import * as filters from 'common/components/AssetBrowser/actions/filters';

export class VersionFilter extends Component {
  render() {
    const { version, changeVersion, allowedVersions } = this.props;

    const scope = 'shared.asset_browser.filters.version';

    const versionOptions = [
      { title: I18n.t('options.all', { scope }), value: null, defaultOption: true },
      { title: I18n.t('options.published', { scope }), value: constants.VERSION_PUBLISHED },
      {
        title: I18n.t('options.draft', { scope }),
        icon: <SocrataIcon name="draft-badge" />,
        value: constants.VERSION_DRAFT
      }
    ];

    return (
      <div className="filter-section version">
        <label className="filter-label">{I18n.t('label', { scope })}</label>
        <Dropdown
          disabled={allowedVersions && allowedVersions.length === 1}
          name="version"
          onSelection={(option) => changeVersion(option.value)}
          options={versionOptions}
          size="medium"
          value={version || null} />
      </div>
    );
  }
}

VersionFilter.propTypes = {
  version: PropTypes.string,
  changeVersion: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  version: state.filters.version,
  allowedVersions: state.filters.allowedVersions
});

const mapDispatchToProps = (dispatch) => ({
  changeVersion: (value) => dispatch(filters.changeVersion(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionFilter);
