import React from 'react';
import { ForgeIconButton, ForgeIcon, ForgeTextField, ForgeCircularProgress } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { FileUploadStatus, UploadStatuses } from 'common/dsmapi/ManageMetadataComponent/types';

const t = (
  key: string,
  options = {},
  scope = 'shared.dataset_management_ui.metadata_manage.attachments_input'
) => I18n.t(key, { ...options, scope });

export interface AttachmentProps {
  file: FileUploadStatus;
  idx: number;
  onRemove: () => void;
  onEdit: (name: string) => void;
}

const Attachment: React.FunctionComponent<AttachmentProps> = ({ file, onRemove, onEdit }) => {
  const statusIndicator = (() => {
    switch (file.status) {
      case UploadStatuses.SUCCESS: {
        return (
          <ForgeIcon
            className="attachment-status-icons success-icon"
            name="check_circle"
            role="img"
            aria-label={t('upload_success_aria_label', { fileName: file.uploadedFile.name })}
          />
        );
      }
      case UploadStatuses.IN_PROGRESS: {
        return (
          <ForgeCircularProgress
            className="attachment-progress-indicator"
            progressbar-aria-label={t('upload_indicator_aria_label', { fileName: file.uploadedFile.name })}
            open
            determinate
            progress={file.percent}
          />
        );
      }
      case UploadStatuses.ERROR: {
        return (
          <ForgeIcon
            id="attachment-status-error-icon"
            className="attachment-status-icons error-icon"
            name="error"
            role="img"
            aria-label={t('upload_error_aria_label', { fileName: file.uploadedFile.name })}
          />
        );
      }
    }
  })();

  return (
    <div className="metadata-component-attachment">
      <ForgeTextField>
        <input
          value={file.uploadedFile.name}
          type="text"
          className="metadata-component-filename"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onEdit(event.target.value)}
          data-testid="metadata-component-attachments-name-field"
        />
        {file.error && (
          <span
            className="metadata-component-error-message"
            slot="helper-text"
            aria-labelledby="attachment-status-error-icon"
          >
            {file.error.message}
          </span>
        )}
      </ForgeTextField>
      <div className="metadata-component-attachment-status">
        {statusIndicator}
        <ForgeIconButton>
          <button
            type="button"
            aria-label={t('remove_attachment')}
            onClick={onRemove}
            data-testid="metadata-component-attachments-remove-button"
          >
            <ForgeIcon name="close" />
          </button>
        </ForgeIconButton>
      </div>
    </div>
  );
};

export default Attachment;
