import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/fp/map';

/**
 * Used by TabbedPanelInterface to render a list of tabs at the top.
 * This also expects the current "active" component to be passed in as a child.
 */
class TabbedView extends Component {
  static propTypes = {
    // panelConfigs are generated from the list of TabbedPanel components
    // passed to the TabbedPanelInterface
    panelConfigs: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,

        // note that this is an optional component; it is possible to have a component
        // at a path but not have a tab in the header for it
        tabComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
        isVisible: PropTypes.bool
      })
    ).isRequired
  };

  /**
   * Returns a function that will render a TabbedView with tabs from the given panelConfigs
   *
   * Intended to be called by react-router to render the current path
   * The child of the TabbedView is rendered in the main part of the interface
   */
  static withPanelConfigs = (panelConfigs) => ({ children }) => (
    <TabbedView panelConfigs={panelConfigs}>{children}</TabbedView>
  );

  /**
   * Takes a list of panelConfigs and generates the list of tabComponent for them
   */
  static generateTabComponents = map(
    ({ tabComponent, path: key, isVisible = true }) =>
      isVisible && tabComponent && React.createElement(tabComponent, { key })
  );

  render() {
    const { panelConfigs, children } = this.props;
    return (
      <div className="tabbed-view-container">
        <div className="nav-bar">
          <div className="nav-tabs">
            {/* List of ALL visible tabs */}
            <ul>{TabbedView.generateTabComponents(panelConfigs)}</ul>
          </div>
        </div>
        <div className="tabbed-view-active-component">
          {/* This will be the "active" component for the current route */}
          {children}
        </div>
      </div>
    );
  }
}

export default TabbedView;
