import _ from 'lodash';
import {fetchResults} from 'common/components/AssetBrowser/lib/helpers/cetera';
import {updateQueryString} from 'common/components/AssetBrowser/lib/query_string';
import {getCurrentQuery, getUnfilteredState} from 'common/components/AssetBrowser/reducers/filters';
import * as autocompleteActions from 'common/autocomplete/actions';
import {regexMatchPatterns} from 'common/utilities/Constants';

import {clearPage} from './pager';
import {clearSortOrder} from './sort_order';
import {APPROVALS} from 'common/core/approvals_enums';

export const CHANGE_ASSET_TYPE = 'CHANGE_ASSET_TYPE';
export const CHANGE_AUTHORITY = 'CHANGE_AUTHORITY';
export const CHANGE_CATEGORY = 'CHANGE_CATEGORY';
export const CHANGE_CUSTOM_FACET = 'CHANGE_CUSTOM_FACET';
export const CHANGE_DERIVED_FROM = 'CHANGE_DERIVED_FROM';
export const CHANGE_IDS = 'CHANGE_IDS';
export const CHANGE_OWNER = 'CHANGE_OWNER';
export const CHANGE_Q = 'CHANGE_Q';
export const CHANGE_REQUESTER = 'CHANGE_REQUESTER';
export const CHANGE_SOURCE = 'CHANGE_SOURCE';
export const CHANGE_TAG = 'CHANGE_TAG';
export const CHANGE_VERSION = 'CHANGE_VERSION';
export const CHANGE_VISIBILITY = 'CHANGE_VISIBILITY';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const TOGGLE_RECENTLY_VIEWED = 'TOGGLE_RECENTLY_VIEWED';
export const TOGGLE_ENROLLED_IN_ARCHIVAL = 'TOGGLE_ENROLLED_IN_ARCHIVAL';
export const TOGGLE_AWAITING_APPROVAL = 'TOGGLE_AWAITING_APPROVAL';
export const TOGGLE_DERIVED_FROM_FILTER = 'TOGGLE_DERIVED_FROM_FILTER';
export const TOGGLE_IDS_FILTER = 'TOGGLE_IDS_FILTER';
export const TOGGLE_PUBLIC_TARGET_AUDIENCE = 'TOGGLE_PUBLIC_TARGET_AUDIENCE';
export const TOGGLE_INTERNAL_TARGET_AUDIENCE = 'TOGGLE_INTERNAL_TARGET_AUDIENCE';

export const toggleInternalTargetAudience = () => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: TOGGLE_INTERNAL_TARGET_AUDIENCE});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  const showInternalTargetAudience = !getState().filters.showInternalTargetAudience;

  return fetchResults(
    dispatch,
    getState,
    {
      action: TOGGLE_INTERNAL_TARGET_AUDIENCE,
      pageNumber: 1,
      showInternalTargetAudience,
      q: getCurrentQuery()
    },
    onSuccess
  );
};

export const togglePublicTargetAudience = () => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: TOGGLE_PUBLIC_TARGET_AUDIENCE});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  const showPublicTargetAudience = !getState().filters.showPublicTargetAudience;

  return fetchResults(
    dispatch,
    getState,
    {
      action: TOGGLE_PUBLIC_TARGET_AUDIENCE,
      pageNumber: 1,
      showPublicTargetAudience,
      q: getCurrentQuery()
    },
    onSuccess
  );
};


export const toggleAwaitingApproval = () => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: TOGGLE_AWAITING_APPROVAL});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  const approvalStatus = getState().filters.approvalStatus === APPROVALS.STATUS.PENDING ?
    null : APPROVALS.STATUS.PENDING;

  return fetchResults(
    dispatch,
    getState,
    {
      action: TOGGLE_AWAITING_APPROVAL,
      approvalStatus,
      pageNumber: 1,
      q: getCurrentQuery()
    },
    onSuccess
  );
};

export const toggleDerivedFromFilter = (parentIds) => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: TOGGLE_DERIVED_FROM_FILTER, parentIds});
    clearPage(dispatch);
    updateQueryString({getState});
  };
  const derivedFromFilterEnabled = !getState().filters.derivedFromFilterEnabled;

  return fetchResults(
    dispatch,
    getState,
    {
      action: TOGGLE_DERIVED_FROM_FILTER,
      derivedFromFilterEnabled: derivedFromFilterEnabled,
      pageNumber: 1,
      parentIds: derivedFromFilterEnabled ? parentIds : null,
      // This filter is only available when the AssetBrowser is being used as a modal
      // in storyteller, so the query is not available through the URL.
      q: getState().filters.q
    },
    onSuccess
  );
};

export const toggleIdsFilter = (ids) => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: TOGGLE_IDS_FILTER, ids});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  const idsFilterEnabled = !getState().filters.idsFilterEnabled;

  return fetchResults(
    dispatch,
    getState,
    {
      action: TOGGLE_IDS_FILTER,
      ids: idsFilterEnabled ? ids : null,
      idsFilterEnabled: idsFilterEnabled,
      pageNumber: 1,
      // This filter is only available when the AssetBrowser is being used as a modal
      // in storyteller, so the query is not available through the URL.
      q: getState().filters.q
    },
    onSuccess
  );
};

export const toggleRecentlyViewed = () => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: TOGGLE_RECENTLY_VIEWED});

    if (getState().filters.onlyRecentlyViewed) {
      dispatch(clearSortOrder());
    }
    clearPage(dispatch);
    updateQueryString({getState});
  };

  return fetchResults(
    dispatch,
    getState,
    {
      action: TOGGLE_RECENTLY_VIEWED,
      onlyRecentlyViewed: !getState().filters.onlyRecentlyViewed,
      sortByRecentlyViewed: !getState().filters.onlyRecentlyViewed,
      pageNumber: 1,
      q: getCurrentQuery()
    },
    onSuccess
  );
};

export const toggleEnrolledInArchival = () => (dispatch, getState) => {

  const onSuccess = () => {
    dispatch({type: TOGGLE_ENROLLED_IN_ARCHIVAL});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  return fetchResults(
    dispatch,
    getState,
    {
      action: TOGGLE_ENROLLED_IN_ARCHIVAL,
      enrolledInArchival: !getState().filters.enrolledInArchival,
      pageNumber: 1,
      q: getCurrentQuery()
    },
    onSuccess
  );
};

export const changeAssetType = (value, catalogParams = {}) => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: CHANGE_ASSET_TYPE, value, catalogParams});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  // USAID, EN-25021: strip out invalid catalog search assetType value (read: hack) that allows collectionParent
  // HREFs to be filtered in /admin/assets. Invalid 'parent_hrefs' value (hack) is set in
  // 'common/components/AssetBrowser/components/filters/asset_types_filter.jsx', and is used to keep the UI
  // in a logical state. This .replace() should not affect non-USAID assetTypes.
  const normalizedValue = value ? value.replace('parent_hrefs', '') : null;

  return fetchResults(
    dispatch,
    getState,
    {
      action: CHANGE_ASSET_TYPE,
      assetTypes: normalizedValue,
      pageNumber: 1,
      q: getCurrentQuery(),
      ...catalogParams
    },
    onSuccess
  );
};

export const changeAuthority = (value) => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: CHANGE_AUTHORITY, value});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  if (value !== getState().filters.authority) {
    return fetchResults(
      dispatch,
      getState,
      {
        action: CHANGE_AUTHORITY,
        authority: value,
        pageNumber: 1,
        q: getCurrentQuery()
      },
      onSuccess
    );
  }
};

export const changeCategory = (option) => (dispatch, getState) => {
  const category = option.value;

  const onSuccess = () => {
    dispatch({type: CHANGE_CATEGORY, value: category});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  if (category !== getState().filters.category) {
    return fetchResults(
      dispatch,
      getState,
      {
        action: CHANGE_CATEGORY,
        category,
        pageNumber: 1,
        q: getCurrentQuery()
      },
      onSuccess
    );
  }
};

export const changeIds = (uid) => (dispatch, getState) => {

  const onSuccess = () => {
    dispatch({type: CHANGE_IDS, value: uid});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  if (uid !== getState().filters.ids) {
    return fetchResults(
      dispatch,
      getState,
      {
        action: CHANGE_IDS,
        ids: [uid],
        pageNumber: 1,
        q: getCurrentQuery()
      },
      onSuccess
    );
  }
};

export const changeOwner = (option) => (dispatch, getState) => {
  const owner = {
    displayName: option.title,
    id: option.value
  };

  const onSuccess = () => {
    dispatch({type: CHANGE_OWNER, value: owner});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  if (owner.id !== getState().filters.ownedBy.id) {
    return fetchResults(
      dispatch,
      getState,
      {
        action: CHANGE_OWNER,
        ownedBy: owner,
        pageNumber: 1,
        q: getCurrentQuery()
      },
      onSuccess
    );
  }
};

export const changeRequester = (option) => (dispatch, getState) => {
  // the search box filter uses value == null to indicate the 'ALL' pseudo-filter
  // we transform that to undefined here so that defaults are used
  const requester = option.value === null ? undefined : option;

  const onSuccess = () => {
    dispatch({type: CHANGE_REQUESTER, value: requester});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  if (requester?.value !== getState().filters.requester?.value) {
    return fetchResults(
      dispatch,
      getState,
      {
        action: CHANGE_REQUESTER,
        requester,
        pageNumber: 1,
        q: getCurrentQuery()
      },
      onSuccess
    );
  }
};

export const changeSource = (option) => (dispatch, getState) => {
  const source = option.value;

  const onSuccess = () => {
    dispatch({type: CHANGE_SOURCE, value: source});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  return fetchResults(
    dispatch,
    getState,
    {
      action: CHANGE_SOURCE,
      source,
      pageNumber: 1,
      q: getCurrentQuery()
    },
    onSuccess
  );
};

export const changeTag = (option) => (dispatch, getState) => {
  const tag = option.value;

  const onSuccess = () => {
    dispatch({type: CHANGE_TAG, value: tag});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  if (tag !== getState().filters.tag) {
    return fetchResults(
      dispatch,
      getState,
      {
        action: CHANGE_TAG,
        tag,
        pageNumber: 1,
        q: getCurrentQuery()
      },
      onSuccess
    );
  }
};

export const changeVersion = (value) => (dispatch, getState) => {
  if (value === getState().filters.version) {
    return;
  }
  const allowedVersions = getState().filters.allowedVersions;
  if (allowedVersions && !_.includes(allowedVersions, value)) {
    return;
  }

  return fetchResults(
    dispatch,
    getState,
    {
      action: CHANGE_VERSION,
      version: value,
      pageNumber: 1,
      q: getCurrentQuery()
    },
    () => {
      dispatch({type: CHANGE_VERSION, value});
      clearPage(dispatch);
      updateQueryString({getState});
    }
  );
};

export const changeVisibility = (value) => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: CHANGE_VISIBILITY, value});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  if (value !== getState().filters.visibility) {
    return fetchResults(
      dispatch,
      getState,
      {
        action: CHANGE_VISIBILITY,
        visibility: value,
        pageNumber: 1,
        q: getCurrentQuery()
      },
      onSuccess
    );
  }
};

export const changeQ = (value) => {
  // EN-18325: Clear existing sort order, and sort by "relevance"
  const order = {value: 'relevance', ascending: false};

  // This is the deafult search by query.
  const searchByQ = (dispatch, getState) => {
    const onSuccess = () => {
      dispatch({type: CHANGE_Q, value});
      dispatch(clearSortOrder());
      clearPage(dispatch);
      updateQueryString({getState});
    };

    return fetchResults(
      dispatch,
      getState,
      {action: CHANGE_Q, q: value, pageNumber: 1, order}, onSuccess
    );
  };

  // EN-18709: If the search string is a 4x4, then we should search on ids=4x4 instead.
  // If this fails, then we will fall back to the original search.
  if (regexMatchPatterns.FOUR_BY_FOUR_PATTERN.test(value.trim())) {
    const ids = [value.trim()];
    return (dispatch, getState) => {
      const onSuccess = (notEmpty) => {
        if (notEmpty) {
          dispatch(clearSortOrder());
          clearPage(dispatch);
          updateQueryString({getState});
        } else {
          searchByQ(dispatch, getState);
        }
      };

      return fetchResults(
        dispatch,
        getState,
        {action: CHANGE_Q, ids, q: null, pageNumber: 1, order}, onSuccess
      );
    };
  } else {
    return searchByQ;
  }
};

export const changeCustomFacet = (facetParam, value) => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: CHANGE_CUSTOM_FACET, facetParam, value});
    clearPage(dispatch);
    updateQueryString({getState});
  };

  const existingCustomFacets = getState().filters.customFacets;

  if (value !== existingCustomFacets[facetParam]) {
    return fetchResults(
      dispatch,
      getState,
      {
        action: CHANGE_CUSTOM_FACET,
        customFacets: {...existingCustomFacets, [facetParam]: value},
        pageNumber: 1,
        q: getCurrentQuery()
      },
      onSuccess
    );
  }
};

export const clearSearch = () => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({type: CLEAR_SEARCH});
    dispatch(autocompleteActions.clearSearch()); // For autocomplete to update its text input
    clearPage(dispatch);
    updateQueryString({getState, shouldClearSearch: true});
  };

  return fetchResults(dispatch, getState, {action: CLEAR_SEARCH, q: '', pageNumber: 1}, onSuccess);
};

export const clearAllFilters = (options = {}) => (dispatch, getState) => {
  const shouldClearSearch = _.get(options, 'shouldClearSearch', false);
  const onSuccess = () => {
    dispatch({type: CLEAR_ALL_FILTERS});
    if (shouldClearSearch) {
      dispatch({type: CLEAR_SEARCH});
      dispatch(autocompleteActions.clearSearch()); // For autocomplete to update its text input
    }
    clearPage(dispatch);
    updateQueryString({getState, shouldClearSearch});
  };

  const initialState = {
    ...getUnfilteredState(getState()),
    action: CLEAR_ALL_FILTERS,
    pageNumber: 1,
    q: getCurrentQuery()
  };

  if (shouldClearSearch) {
    initialState.q = '';
  }

  return fetchResults(dispatch, getState, initialState, onSuccess);
};
