import React from 'react';
import { FlashMessageWrapperDirectProps } from 'common/dsmapi/ManageMetadataComponent/containers/FlashMessageWrapperContainer';
import FlashMessage from './FlashMessage';

const FlashMessageWrapper: React.FunctionComponent<FlashMessageWrapperDirectProps> = ({
  flashMessages,
  onCloseClick
}) => {
  return (
    <div>
      {flashMessages.map((flashMessage) => (
        <FlashMessage
          {...flashMessage}
          key={flashMessage.id}
          onCloseClick={() => onCloseClick(flashMessage.id)}
        />
      ))}
    </div>
  );
};

export default FlashMessageWrapper;
