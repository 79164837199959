import { ApiKeysApi, Configuration } from '@socrata/core-api-keys-api';
import { defaultHeaders } from 'common/http';

/**
 * Core API Keys API Client
 *
 * Generated from OpenAPI Specification that lives at:
 * https://github.com/socrata/apis/blob/main/specs/api_keys/api_keys.yaml
 */
export default new ApiKeysApi(
  new Configuration({
    credentials: 'same-origin',
    basePath: window.location.origin,
    headers: defaultHeaders
  })
);
