import {
  Binding,
  ColumnRef,
  Expr,
  isBinding,
  isColumnEqualIgnoringPosition,
  isColumnRef,
  traverseExpr
} from 'common/types/soql';
import { uniqWith } from 'lodash';

/*
  We want to give the user the ability to put some text in some boxes
  and get an output of the soql expression.
  so we need to walk over the expression and find all the column references.
  any reference, we will make an input box for


  This function also handles let bindings. expressions of the form

  x + 1
  WITH
    x = y

  actually have two unique column ref nodes in them. x and y. Except
  x isn't *really* an input to the expression - it's self contained,
  so we don't need to give the user an <Input /> for the value of X, since
  it's just a binding.

  so first we will find all the let bindings, then find all the column references,
  and then filter out any column reference which is just the LHS of a binding
*/
export function uniqColumnRefs(expr: Expr): ColumnRef[] {
  const letBindings = traverseExpr(expr, [] as Binding[], (node, acc) => {
    if (node && isBinding(node)) return [...acc, node];
    return acc;
  });

  return uniqWith(
    traverseExpr(expr, [] as ColumnRef[], (node, acc) => {
      if (node && isColumnRef(node)) return [...acc, node];
      return acc;
    }),
    isColumnEqualIgnoringPosition
  ).filter(
    (cref) =>
      !letBindings.find((binding) => binding.qualifier === cref.qualifier && binding.name === cref.value)
  );
}
