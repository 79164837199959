import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { View } from 'common/types/view';
import type { GuidanceSummaryV2 } from 'common/types/approvals';

import I18n from 'common/i18n';
import currentUserCanFederate from 'common/views/current_user_can_federate';
import ManageFederationsModal, {
  ManageFederationsModalProps
} from 'common/components/ManageFederationsModal';

import ActionItem from '../action_item';

export interface ManageFederationsActionItemProps {
  view: View;
  setCurrentModal: (
    modal: typeof ManageFederationsModal,

    // omitting 'onDismiss' because it's passed in by 'setCurrentModal'
    props: Omit<ManageFederationsModalProps, 'onDismiss'>
  ) => void;
  approvalsGuidance: GuidanceSummaryV2;
}

class ManageFederationsActionItem extends Component<ManageFederationsActionItemProps> {
  shouldRender() {
    const { view, approvalsGuidance } = this.props;
    if (isEmpty(view)) {
      return false;
    }

    return currentUserCanFederate(view, approvalsGuidance);
  }

  render() {
    const { view, setCurrentModal } = this.props;

    if (!this.shouldRender()) {
      return null;
    }

    return (
      <ActionItem
        label={I18n.t('shared.components.asset_action_bar.publication_action.federate')}
        onClick={() =>
          setCurrentModal(ManageFederationsModal, {
            view
          })
        }
      />
    );
  }
}

export default ManageFederationsActionItem;
