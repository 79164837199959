import React from 'react';
import { none, some } from 'ts-option';
import { zip as _zip } from 'lodash';
import {
  hasRequiredExprs,
  isIdentifierPrivate,
  isIdentifierRestricted,
  intoMetadataComponents
} from 'common/dsmapi/metadataTemplate';
import { currentUserHasRight } from 'common/current_user';
import LabelAndErrors from 'common/components/LabelAndErrors';
import ExpressionInputFields from './ExpressionInputFields';
import SelectInput, { SelectInputProps } from '../inputs/SelectInput';
import MultiSelectInput, { MultiSelectInputProps } from '../inputs/MultiSelectInput';
import { useErrors } from '../utils/hooks';
import { Json } from 'common/components/ObjectEditor';
import { FieldValue, labelOption, MetadataType, OptionByParent } from 'common/types/metadataTemplate';
import DomainRights from 'common/types/domainRights';
import { ColumnRef } from 'common/types/soql';
import { FieldProps } from '../../types';

export interface CustomFieldProps extends Omit<FieldProps, 'assetMetadata'> {
  getValueForCref: (cref: ColumnRef) => FieldValue;
}

const CustomField: React.FunctionComponent<CustomFieldProps> = ({
  inProgress,
  identifier,
  onUpdateField,
  value,
  hasSubmitted,
  getValueForCref
}) => {
  // TODO: multitemplate
  const [errors, setHasReceivedInput] = useErrors(hasSubmitted, inProgress, value);

  const instance = identifier.instances[0];
  const displayName = instance.display_name;
  const fieldName = instance.field_name;
  const inErrorState = errors.length > 0;
  const isRequired = hasRequiredExprs(identifier.instances, identifier.qualifier);
  const isPrivate = isIdentifierPrivate(identifier);
  const isRestricted = isIdentifierRestricted(identifier);
  const isRestrictedForUser = isRestricted && !currentUserHasRight(DomainRights.edit_restricted_metadata);

  const defaultProps = {
    isPrivate,
    instance,
    value,
    inErrorState,
    isRequired,
    onUpdateField,
    setHasReceivedInput,
    isRestrictedForUser
  };

  const body = intoMetadataComponents(
    identifier.qualifier,
    fieldName,
    instance.parsed_expr,
    instance.labels_options
  )
    .map((metadataComponents) => {
      const { type } = metadataComponents;

      switch (type) {
        case MetadataType.dependentSelectWithSelectParent: {
          const { parentField, optionsByParent } = metadataComponents;
          const parentValue = getValueForCref(parentField);
          const availableOptions = (() => {
            const filteredOptions = optionsByParent.filter((optionByParent) => {
              return (
                parentValue.output.nonEmpty &&
                typeof parentValue.output.get === 'string' &&
                optionByParent.parentValue === parentValue.output.get
              );
            });
            return filteredOptions.length === 1 ? some(filteredOptions[0]) : none;
          })();

          if (availableOptions.isEmpty) {
            return null;
          } else {
            const finalProps: SelectInputProps = {
              ...defaultProps,
              instance: {
                ...instance,
                labels_options: _zip(availableOptions.get.options, availableOptions.get.labels) as labelOption[]
              },
              selectComponents: {
                ...metadataComponents,
                options: availableOptions.get.options
              }
            };
            return <SelectInput {...finalProps} />;
          }
        }
        case MetadataType.dependentSelectWithMultiSelectParent: {
          const { parentField, optionsByParent } = metadataComponents;
          const parentValue = getValueForCref(parentField);

          if (
            parentValue.output.isEmpty ||
            !parentValue.output.get ||
            typeof parentValue.output.get === 'string'
          ) {
            return null;
          }

          const validParentOptions = parentValue.output.get;

          const validChildOptions = (() => {
            const filteredOptions = optionsByParent.filter((optionByParent) => {
              return validParentOptions.includes(optionByParent.parentValue);
            });

            return filteredOptions.reduce(
              (accumulator, optionByParent) => {
                return {
                  parentValue: accumulator.parentValue,
                  options: accumulator.options.concat(optionByParent.options),
                  labels: accumulator.labels.concat(optionByParent.labels)
                };
              },
              { parentValue: '', options: [], labels: [] }
            );
          })();

          if (validChildOptions.options.length === 0) {
            return null;
          }

          const finalProps: SelectInputProps = {
            ...defaultProps,
            instance: {
              ...instance,
              labels_options: _zip(validChildOptions.options, validChildOptions.labels) as labelOption[]
            },
            selectComponents: {
              ...metadataComponents,
              options: validChildOptions.options
            }
          };

          return <SelectInput {...finalProps} />;
        }
        case MetadataType.select: {
          return <SelectInput {...defaultProps} selectComponents={metadataComponents} />;
        }
        case MetadataType.multiSelect: {
          return <MultiSelectInput {...defaultProps} multiSelectComponents={metadataComponents} />;
        }
        case MetadataType.dependentMultiSelect: {
          const { parentField, optionsByParent } = metadataComponents;
          const parentValue = getValueForCref(parentField);
          const availableOptions = (() => {
            const filteredOptions: OptionByParent = {
              parentValue: 'parentValue',
              options: [],
              labels: []
            };
            optionsByParent.forEach((optionByParent) => {
              if (!parentValue.output.nonEmpty || !parentValue.output.get) {
                return;
              }

              const parentIsMultiSelect = typeof parentValue.output.get !== 'string'; // multiselect values are arrays
              if (!parentIsMultiSelect && parentValue.output.get === optionByParent.parentValue) {
                filteredOptions.options.push(...optionByParent.options);
                filteredOptions.labels.push(...optionByParent.labels);
              } else if (parentIsMultiSelect) {
                parentValue.output.get.forEach((item: any) => {
                  if (optionByParent.parentValue === item) {
                    filteredOptions.options.push(...optionByParent.options);
                    filteredOptions.labels.push(...optionByParent.labels);
                  }
                });
              }
            });
            return filteredOptions.options.length > 0 ? some(filteredOptions) : none;
          })();

          if (availableOptions.isEmpty) {
            return null;
          } else {
            const finalProps: MultiSelectInputProps = {
              ...defaultProps,
              instance: {
                ...instance,
                labels_options: _zip(availableOptions.get.options, availableOptions.get.labels) as labelOption[]
              },
              multiSelectComponents: {
                ...metadataComponents,
                options: availableOptions.get.options
              }
            };

            return <MultiSelectInput {...finalProps} />;
          }
        }
      }
    })
    .getOrElseValue(
      <ExpressionInputFields
        isPrivate={isPrivate}
        inProgress={inProgress}
        inputType={'text'}
        qualifier={identifier.qualifier}
        inErrorState={inErrorState}
        isRequired={isRequired}
        field={instance}
        value={value}
        isRestrictedForUser={isRestrictedForUser}
        onUpdateField={(cref: ColumnRef, newValue: Json) => {
          setHasReceivedInput();
          onUpdateField(cref, newValue);
        }}
      />
    );

  // If we're not rendering the component because its parent is empty, don't render its error
  return body === null ? null : (
    <LabelAndErrors
      key={`${identifier.qualifier}-${fieldName}-labels`}
      className="half-width"
      errors={errors}
      isRequired={isRequired}
      name={fieldName}
      label={displayName}
      useLabels={false}
      isPrivate={isPrivate}
    >
      {body}
    </LabelAndErrors>
  );
};

export default CustomField;
