import * as ArchivalAPI from 'common/core/archival';
import { currentUserHasRight } from 'common/current_user';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import Archive from 'common/types/archive';
import DomainRights from 'common/types/domainRights';
import { Revision } from 'common/types/revision';
import { View, ViewFlag, ViewRight } from 'common/types/view';
import hasFlag from 'common/views/has_flag';
import { hasOwnerLikeRights, hasRights } from 'common/views/has_rights';
import { changeRevisionVisibility, navigateToRevision, restoreDsmapiRevision } from 'common/views/helpers';
import * as _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { none, option, Option, some } from 'ts-option';
import Button, { SIZES, VARIANTS } from '../Button';
import SocrataIcon, { IconName } from '../SocrataIcon';
import './TimelineItem.scss';
import { assertUnreachable, Change } from './util';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'shared.components.asset_timeline', ...options });

interface TimelineUserProps {
  createdById: string | null;
  createdBy: string;
}
export function TimelineUser({ createdBy, createdById }: TimelineUserProps) {
  if (createdById) {
    return (
      <a href={`/profile/${createdById}`} className="created-by">
        {createdBy}
      </a>
    );
  }
  return <span className="created-by">{createdBy}</span>;
}

export function TimelineTimestamp({ date }: { date: Date | moment.Moment | string }) {
  return <span className="timeline-timestamp">{moment.utc(date).locale(I18n.locale).fromNow()}</span>;
}

interface ExportVersionButtonProps {
  change: Change;
  view: View;
  versionNumber: Option<number>;
}
interface ExportVersionButtonState {
  interval: Option<NodeJS.Timeout>;
  status: Option<ArchivalAPI.ArchivalStatus>;
  completed: Option<string>;
}
class ExportVersionButton extends React.Component<ExportVersionButtonProps, ExportVersionButtonState> {
  state: ExportVersionButtonState = {
    interval: none,
    status: none,
    completed: none
  };

  componentWillUnmount = () => this.clearInterval();

  onClick = () => {
    this.props.versionNumber.forEach(async (vNum) => {
      this.setState({ status: some({ type: 'not_started' }) });
      const poller = await ArchivalAPI.createArchive(this.props.view, vNum);

      const update = async () => {
        this.updateStatus(await poller());
      };

      update();
      this.setState({ interval: some(setInterval(update, 2000)) });
    });
  };

  updateStatus = (status: ArchivalAPI.ArchivalStatus) => {
    this.setState({ status: some(status) });
    if (status.type === 'done' || status.type === 'failed') {
      this.clearInterval();
    }
  };

  downloadLink = (): Option<string> =>
    this.props.versionNumber.flatMap((vNum) =>
      this.state.status
        .filter((s) => s.type === 'done')
        .map((a) => ArchivalAPI.archiveCSVLink(this.props.view, vNum))
    );

  clearInterval = () => {
    this.state.interval.map(clearInterval);
  };

  isError = () => this.state.status.map((s) => s.type === 'failed').getOrElseValue(false);

  render = () => {
    return this.downloadLink().match({
      some: (link) => (
        <Button size={SIZES.X_SMALL} href={link} variant={VARIANTS.SUCCESS}>
          {t('download')}
        </Button>
      ),
      none: () => (
        <Button
          size={SIZES.X_SMALL}
          variant={this.isError() ? VARIANTS.ERROR : VARIANTS.PRIMARY}
          busy={this.state.status
            .map((s) => s.type === 'running' || s.type === 'not_started')
            .getOrElseValue(false)}
          disabled={this.isError() || this.props.versionNumber.isEmpty}
          onClick={this.onClick}
        >
          {this.isError() ? t('failed') : t('export')}
        </Button>
      )
    });
  };
}

const isChangeVisible = (change: Change) =>
  change.type === 'archive' ? change.value.visible : change.value.archive?.visible || false;

interface ShowHideArchiveButtonProps {
  change: Change;
  view: View;
  isVisible: boolean;
  setVisibility: (isVisible: boolean) => void;
}
interface ShowHideArchiveState {
  inProgress: boolean;
  error: boolean;
}

class ShowHideArchiveButton extends React.Component<ShowHideArchiveButtonProps, ShowHideArchiveState> {
  state: ShowHideArchiveState = {
    inProgress: false,
    error: false
  };

  onChange = (visible: boolean) => {
    this.props.change.type === 'archive'
      ? this.changeArchive(this.props.change.value, visible)
      : this.changeRevision(this.props.change.value, visible);
  };

  onShow = () => this.onChange(true);
  onHide = () => this.onChange(false);

  call = async (visible: boolean, fun: () => Promise<boolean>) => {
    try {
      this.setState({ inProgress: true, error: false });
      await fun();
      this.setState({ inProgress: false });
      this.props.setVisibility(visible);
    } catch (e) {
      this.setState({ inProgress: false, error: true });
    }
  };

  changeArchive = (archive: Archive, visible: boolean) => {
    this.call(visible, async () => {
      await ArchivalAPI.changeArchiveVisibility(this.props.view, archive.version, visible);
      return visible;
    });
  };

  changeRevision = (rev: Revision, visible: boolean) => {
    this.call(visible, async () => {
      await changeRevisionVisibility(rev, visible);
      return visible;
    });
  };

  userChangeChangeArchives = () => !!currentUserHasRight(DomainRights.change_archive_visibility);

  isHHS = (): boolean => {
    const change = this.props.change;
    // special case for integration with the legacy HHS archive code
    if (change.type === 'revision') {
      return !!change.value.archive?.is_hhs_entry;
    }
    return false;
  };

  render() {
    if (!this.userChangeChangeArchives() || this.isHHS()) return null;
    return (
      <Button
        onClick={this.props.isVisible ? this.onHide : this.onShow}
        size={SIZES.X_SMALL}
        variant={VARIANTS.DEFAULT}
        busy={this.state.inProgress}
      >
        {this.props.isVisible ? t('hide_version') : t('unhide_version')}
      </Button>
    );
  }
}

interface ArchivalRevisionButtonProps {
  view: View;
  revision: Revision;
}
interface ArchivalRevisionButtonState {
  failed: boolean;
  loading: boolean;
}
class ArchivalRevisionButton extends React.Component<
  ArchivalRevisionButtonProps,
  ArchivalRevisionButtonState
> {
  state = {
    failed: false,
    loading: false
  };

  openRevision = async () => {
    try {
      this.setState({ loading: true });
      const restorationRevision = await restoreDsmapiRevision(this.props.revision);
      navigateToRevision(restorationRevision.fourfour, restorationRevision.revision_seq);
    } catch (e) {
      console.error(e);
      this.setState({ failed: true, loading: false });
      setTimeout(() => this.setState({ failed: false, loading: false }), 3000);
    }
  };

  render() {
    // dsmapi won't let you open a revision unless you can do these things
    if (!hasOwnerLikeRights(this.props.view)) {
      return null;
    }
    // go to the source domain
    if (hasFlag(this.props.view, ViewFlag.Federated)) {
      return null;
    } else {
      return this.state.failed ? (
        <Button size={SIZES.X_SMALL} variant={VARIANTS.ERROR}>
          {t('failed')}
        </Button>
      ) : (
        <Button
          size={SIZES.X_SMALL}
          disabled={this.state.loading}
          variant={VARIANTS.ALTERNATE_2}
          onClick={this.openRevision}
        >
          {this.state.loading ? <span className="spinner-default" /> : t('open_restore_rev')}
        </Button>
      );
    }
  }
}

interface TimelineItemActionsProps {
  view: View;
  change: Option<Change>;
}
interface TimelineActionsState {
  changedVisibility: Option<boolean>;
}

export class TimelineItemActions extends React.Component<TimelineItemActionsProps, TimelineActionsState> {
  state: TimelineActionsState = {
    changedVisibility: none
  };

  isVisible = () => {
    return this.state.changedVisibility.getOrElseValue(this.props.change.map(isChangeVisible).getOrElseValue(false));
  };

  setVisibility = (isVisible: boolean) => {
    this.setState({ changedVisibility: some(isVisible) });
  };

  render() {
    const { view } = this.props;
    if (!hasRights(view, ViewRight.Read)) {
      return null;
    }
    return (
      <div className="timeline-actions">
        {this.props.change
          .map((change) => {
            switch (change.type) {
              case 'archive': {
                return (
                  <>
                    {this.isVisible() ?
                      (<ExportVersionButton
                        change={change}
                        view={view}
                        versionNumber={option(change.value.version)}
                      />) : null}
                    <ShowHideArchiveButton
                      view={view}
                      change={change}
                      setVisibility={this.setVisibility}
                      isVisible={this.isVisible()}
                    />
                  </>
                );
              }
              case 'revision': {
                const rev = change.value;
                return (
                  <>
                    {_.isNumber(rev.archive?.data_version) && this.isVisible() ? (
                      <ArchivalRevisionButton view={view} revision={rev} />
                    ) : null}
                    {_.isNumber(rev.archive?.data_version) && this.isVisible() ? (
                      <ExportVersionButton
                        change={change}
                        view={view}
                        versionNumber={option(rev.archive?.data_version)}
                      />
                    ) : null}
                    {rev.archive?.export_link && (
                      <Button size={SIZES.X_SMALL} variant={VARIANTS.PRIMARY} href={rev.archive?.export_link}>
                        {t('export')}
                      </Button>
                    )}

                    <ShowHideArchiveButton
                      view={view}
                      change={change}
                      setVisibility={this.setVisibility}
                      isVisible={this.isVisible()}
                    />
                  </>
                );
              }
            }
            return assertUnreachable(change);
          })
          .getOrElseValue(<></>)}
      </div>
    );
  }
}

interface Props {
  icon?: IconName;
}

export default function TimelineItem({ children, icon }: React.PropsWithChildren<Props>) {
  return (
    <div className="timeline-item">
      {icon ? (<div className="timeline-icon">
        <SocrataIcon name={icon} />
      </div>) : null}
      <div className="timeline-body">
        <div className="timeline-details">{children}</div>
      </div>
    </div>
  );
}
