/**
 * Intended to be used as a client-side search-style filter.
 * See required props in propTypes.
 */

import flow from 'lodash/flow';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import './cached-filter-bar.scss';

import { SocrataIcon } from 'common/components/SocrataIcon';

class CachedFilterBar extends React.Component {
  state = {
    query: ''
  };

  static propTypes = {
    dataCached: PropTypes.array.isRequired, // array of objects that represents your data
    searchKeys: PropTypes.array.isRequired, // used by the query to match on values of dataCached objects
    handleQueryMatches: PropTypes.func.isRequired, // callback that takes matches; same shape as dataCached
    onClearSearch: PropTypes.func.isRequired, // callback when query is fully cleared
    className: PropTypes.string,
    caseSensitive: PropTypes.bool,
    ignorePunctuation: PropTypes.bool,
    millisecondsBeforeSearch: PropTypes.number,
    placeholder: PropTypes.string
  };

  static defaultProps = {
    caseSensitive: false,
    ignorePunctuation: true,
    millisecondsBeforeSearch: 200,
    placeholder: 'Filter'
  };

  handleChange = (event) => {
    const query = event.target.value;
    this.setState({ query });
    this.handleQueryChange(query);
  };

  handleQueryChange = debounce((query) => {
    const {
      handleQueryMatches,
      onClearSearch,
    } = this.props;

    if (query.length < 1) {
      onClearSearch();
    } else {
      handleQueryMatches(this.getMatches(query));
    }
  }, this.props.millisecondsBeforeSearch || 100);

  getMatches = (query) => {
    const { dataCached, searchKeys, caseSensitive, ignorePunctuation } = this.props;

    const applyMatchers = flow(
      applySensitive(caseSensitive),
      applyIgnorePunctuation(ignorePunctuation)
    );

    const adjustedQuery = applyMatchers(query);

    return dataCached.filter(data => {
      const searchString = searchKeys.map(key => data[key]).join(' - ');
      return applyMatchers(searchString).includes(adjustedQuery) && searchString;
    });
  };

  render() {
    const { query } = this.state;
    const { autoFocus, className, placeholder } = this.props;
    const barClass = classNames('cached-filter-bar', className);
    const inputClass = classNames('cached-filter-bar-input', `${className}-input`);

    return (
      <div className={barClass}>
        <SocrataIcon name="search" />
        <input type="text"
               autoFocus={autoFocus}
               className={inputClass}
               placeholder={placeholder}
               value={query}
               onChange={this.handleChange} />
      </div>
    );
  }
}

const applySensitive = (caseSensitive) => (str) => caseSensitive ? str : str.toLowerCase();

// Any character that is not a letter or number. Will not match non-English chars.
const applyIgnorePunctuation = (ignorePunctuation) => (str) =>
  ignorePunctuation ? str.replace(/[^a-zA-Z0-9]/g, '') : str;

export default CachedFilterBar;
