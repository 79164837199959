import React from 'react';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import { agentIndex } from 'common/dsmapiLinks';
import { StepProps } from 'common/components/hocs/withStepper/types';
import I18n from 'common/i18n';

const scope = 'shared.components.setup_agent.connection_indicator';

export default class ConnectionIndicator extends React.Component<StepProps> {
  constructor(props: StepProps) {
    super(props);
    this.checkConnectivity = this.checkConnectivity.bind(this);
  }

  checkConnectivity() {
    const { stepState, handleStepStateChange } = this.props;
    const { name } = stepState['step-0-state'];

    fetchJsonWithDefaultHeaders(agentIndex)
      .then((agents: any) => {
        // works so long as agent names are unique
        const onlineAgent = agents.find((agent: any) => {
          return agent.resource.name === name &&
                 agent.resource.went_online_at;
        });

        handleStepStateChange({
          status: onlineAgent ? 'connected' : 'offline',
          disableNext: !onlineAgent
        });
      });
  }

  componentDidMount() {
    const { handleStepStateChange } = this.props;

    handleStepStateChange({
      status: 'offline'
    });

    this.checkConnectivity();
  }

  render() {
    const { stepState, stepIdx } = this.props;
    const { name } = stepState['step-0-state'];
    const { status } = stepState[`step-${stepIdx}-state`];
    const statusText = status === 'connected'
      ? I18n.t('status_connected', { scope })
      : I18n.t('status_offline', { scope });

    return (
      <div className="connection-indicator">
        <span onClick={this.checkConnectivity} className="agent-refresh-btn">
          <SocrataIcon name={IconName.Progress} className="progress-icon" />
          {I18n.t('refresh', { scope })}
        </span>
        <h6>
          {I18n.t('connected_title', { scope })}
        </h6>
        <p>{I18n.t('connected_body', { scope })}</p>
        <p
          dangerouslySetInnerHTML={{ __html: I18n.t(
            'connected_status_message',
            { scope, name, status: statusText, className: status }
          ) }} />
      </div>
    );
  }
}
