import * as React from 'react';

interface Props {
  isDropping: boolean;
  error: string;
}

function ErrorCell({ isDropping, error }: Props): React.ReactElement {
  const classNames = [];
  if (isDropping) {
    classNames.push('dropping');
  }

  return <div className={classNames.join(' ')}>{error || ''}</div>;
}

export default ErrorCell;
