// Helpers for dealing with AssetBrowser + federation scenarios.
import includes from 'lodash/includes';

import {
  TAB_MY_ASSETS,
  TAB_SHARED_TO_ME,
  TAB_MY_QUEUE,
  Tab
} from '../constants';

/**
 * @param activeTab
 * @return {boolean} true if the current tab should not include assets from federation domains
 */
export const tabShouldExcludeFederatedAssets = (activeTab: Tab) => includes([TAB_MY_ASSETS, TAB_MY_QUEUE, TAB_SHARED_TO_ME], activeTab);
