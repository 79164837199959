import React from 'react';
import Fieldset from './Fieldset';
import Builtin from '../fields/BuiltIn';
import { FieldSetProps, OnUploadAttachmentSignature } from '../../types';

export interface BuiltInFieldsetProps extends FieldSetProps {
  onUploadAttachment: OnUploadAttachmentSignature;
}

const BuiltinFieldset: React.FunctionComponent<BuiltInFieldsetProps> = ({
  title,
  subtitle,
  fields,
  hasSubmitted,
  assetMetadata,
  inProgress,
  getValue,
  onUpdateField,
  onUploadAttachment
}) => {
  return (
    <Fieldset title={title} subtitle={subtitle}>
      {fields.map((field) => (
        <Builtin
          inProgress={inProgress}
          assetMetadata={assetMetadata}
          hasSubmitted={hasSubmitted}
          key={`${field.qualifier || '_'}_${field.fieldName}`}
          value={getValue(field)}
          identifier={field}
          onUpdateField={onUpdateField}
          onUploadAttachment={onUploadAttachment}
        />
      ))}
    </Fieldset>
  );
};

export default BuiltinFieldset;
