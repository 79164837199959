import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../reduxStuff/store';
import WithFlashMessage from '../components/utils/flashMessage/WithFlashMessage';

interface OwnProps {
  className?: string;
}

const mapStateToProps = (state: RootState) => ({
  flashVisible: state.flashMessage.flashMessages.some((message) => message.visible)
});

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

// Everything the component will see, NOT the container's props
export type WithFlashMessageDirectProps = PropsFromRedux & OwnProps;

export default connector(WithFlashMessage) as React.ComponentType<OwnProps>;
