import { ForgeBadge, ForgeIcon } from '@tylertech/forge-react';
import React, { FunctionComponent, ReactElement } from 'react';
import I18n, { categoryDisplay } from 'common/i18n';
import FeatureFlag from 'common/feature_flags';
import { Provenance } from 'common/types/view';
import './asset-badge-section.scss';


const t = (k: string, options: { [key: string]: any } = {}) => I18n.t(k, { scope: 'shared.components.asset_badges', ...options });

export type AssetBadgesSectionProps = {
  categories?: string[];
  provenance: string | undefined;
};

const AssetBadgesSection: FunctionComponent<AssetBadgesSectionProps> = ({ categories, provenance }) => {
  const badgeList: ReactElement[] = [];
  const hideProvenanceValue = FeatureFlag.value('disable_authority_badge');
  const showOfficialBadge = FeatureFlag.value('show_provenance_badge_in_catalog');

  if (categories) {
    categories.map((category, index) => {
      // Category can be null if the category metadata field is optional and not filled in.
      if (category) {
        const categoryText = categoryDisplay(category.toLowerCase()) || category;

        badgeList.push((
          <ForgeBadge key={index + 2} theme="info-secondary" className="landing-badge">
            <span>{categoryText}</span>
          </ForgeBadge>
        ));
      }
    });
  }

  if (hideProvenanceValue !== 'all') {
    if (provenance == Provenance.Community && hideProvenanceValue !== 'community') {
      badgeList.push(
        <ForgeBadge key={0} theme="info-primary" className="landing-badge">
          <ForgeIcon name="groups" slot='leading'/>
          <span>{t('community_created')}</span>
        </ForgeBadge>
      );
    }
    if (provenance == Provenance.Official && showOfficialBadge && hideProvenanceValue !== 'official2') {
      badgeList.push(
        <ForgeBadge key={0} theme="info-primary" className="landing-badge">
          <ForgeIcon name="check_decagram" slot='leading'/>
          <span>{t('official')}</span>
      </ForgeBadge>
      );
    }
  }

  return <>{badgeList}</>;
};

export default AssetBadgesSection;

