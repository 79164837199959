import React from 'react';
import Fieldset from './Fieldset';
import CustomField from '../fields/CustomField';
import { ColumnRef } from 'common/types/soql';
import { FieldValue } from 'common/types/metadataTemplate';
import { FieldSetProps } from '../../types';

export type CustomFieldSetProps = Omit<FieldSetProps, 'assetMetadata'>;

const CustomFieldset: React.FunctionComponent<CustomFieldSetProps> = ({
  inProgress,
  title,
  subtitle,
  fields,
  hasSubmitted,
  getValue,
  onUpdateField
}) => {
  const getValueForCref = (cref: ColumnRef): FieldValue => {
    const fieldId = fields.filter(
      (field) => field.fieldName === cref.value && field.qualifier === cref.qualifier
    )[0];
    return getValue(fieldId);
  };

  return (
    <Fieldset title={title} subtitle={subtitle} className="half-width">
      {fields.map((field) => {
        return (
          <CustomField
            inProgress={inProgress}
            hasSubmitted={hasSubmitted}
            key={`${field.qualifier || '_'}_${field.fieldName}`}
            value={getValue(field)}
            identifier={field}
            onUpdateField={onUpdateField}
            getValueForCref={getValueForCref}
          />
        );
      })}
    </Fieldset>
  );
};

export default CustomFieldset;
