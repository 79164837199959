import { fetchTranslation } from 'common/locale';
import {
  CreateColumnOperation,
  DeleteColumnOperation,
  SchemaChangeOperation,
  UpdateColumnOperation
} from 'common/types/taskSet';
import _ from 'lodash';
import React from 'react';
import { Tense, pastOrPresentChangeLabel } from './util';
const scope = 'shared.components.asset_changes.set_schema';
const t = (k: string) => fetchTranslation(k, scope);
const withTense = pastOrPresentChangeLabel(scope);


function ColumnCreation({ op }: { op: CreateColumnOperation }) {
  return (
    <li className="column-creation">
      <span className="text-muted"> {t('with_display_name')} </span>
      {op.attributes.display_name}
      <span className="text-muted"> {t('with_field_name')} </span>
      <span className="field-name">{op.attributes.field_name}</span>
    </li>
  );
}

function ColumnDeletion({ op }: { op: DeleteColumnOperation }) {
  const old = op.prev_attributes;
  if (!old) return null;

  return (
    <li className="column-deletion">
      <span className="text-muted"> {t('with_display_name')} </span>
      <span className="field-name">{old.display_name}</span>
      <span className="text-muted"> {t('with_field_name')} </span>
      <span className="field-name">{op.attributes.field_name}</span>
    </li>
  );
}

function ColumnUpdate({ op, tense }: { op: UpdateColumnOperation, tense: Tense }) {
  const old = op.prev_attributes;
  if (!old) return null;

  const changes = [];
  if (old.field_name !== op.attributes.field_name) {
    changes.push({ name: t('api_field_name'), oldVal: old.field_name, newVal: op.attributes.field_name });
  }
  if (old.position !== op.attributes.position) {
    changes.push({ name: t('position'), oldVal: old.position, newVal: op.attributes.position });
  }
  if (old.display_name !== op.attributes.display_name) {
    changes.push({ name: t('display_name'), oldVal: old.display_name, newVal: op.attributes.display_name });
  }
  if ((old.description || '') !== op.attributes.description) {
    changes.push({
      name: t('description'),
      oldVal: old.description || t('no_description'),
      newVal: op.attributes.description
    });
  }

  if (_.isNumber(op.attributes.width) && old.width !== op.attributes.width) {
    changes.push({
      name: t('width'),
      oldVal: old.width ? `${old.width}px` : t('default_width'),
      newVal: `${op.attributes.width}px`
    });
  }

  if (_.isEmpty(changes)) return null;

  return (
    <li className="column-update">
      <span className="text-muted">{t('changes_to_column')}</span>
      <span className="field-name">{old.display_name}</span>
      <div className="column-update-changes">
        {changes.map((change, i) => (
          <p key={i}>
            {change.name}
            <span className="text-muted"> {withTense('is_changing', tense)} </span>
            {change.oldVal}
            <span className="text-muted"> {t('to')} </span>
            {change.newVal}
          </p>
        ))}
      </div>
    </li>
  );
}

interface Props {
  name: string;
  ops: SchemaChangeOperation[];
  className?: string;
  tense: Tense;
}
export default function ColumnOperations({ name, ops, className, tense }: Props) {
  if (ops.length === 0) return null;
  return (
    <div className="column-operations">
      <p>{name}</p>
      <ul className={className}>
        {ops.map((op, i) => {
          switch (op.type) {
            case 'create_column': {
              return <ColumnCreation key={i} op={op} />;
            }
            case 'update_column': {
              return <ColumnUpdate key={i} op={op} tense={tense} />;
            }
            case 'drop_column': {
              return <ColumnDeletion key={i} op={op} />;
            }
          }
        })}
      </ul>
    </div>
  );
}
