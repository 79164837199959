import PropTypes from 'prop-types';
import values from 'lodash/fp/values';

import { APPROVALS } from 'common/core/approvals_enums';

import ApprovalStatePropType from './ApprovalStatePropType';
import GuidanceV2PropType from './GuidanceV2PropType';

// NOTE: Coming here looking for TypeScript types? Check out `common/types/approvals`!

/**
 * Describes a GuidanceSummary object from core's guidance API.
 */
export default PropTypes.shape({
  currentState: ApprovalStatePropType,
  currentOutcome: PropTypes.oneOf(values(APPROVALS.OUTCOME)),
  currentWorkflowId: PropTypes.number,
  currentOutcomeStatus: PropTypes.oneOf(values(APPROVALS.SUBMISSION_OUTCOME_STATUS)),
  currentSubmissionId: PropTypes.number,
  withdrawUrl: PropTypes.string,
  updateUrl: PropTypes.string,

  ifApproved: GuidanceV2PropType,
  toChangeAudience: GuidanceV2PropType,
  toUpdatePublishedAsset: GuidanceV2PropType,
  toPublishToPublic: GuidanceV2PropType
});
