import PropTypes from 'prop-types';
import React from 'react';
import TypedCell from './TypedCell';
import EditCell from './EditCell';
import { formatForDisplay } from 'common/formatBoolean';

function BooleanCell({ isEditing, value, updateCell, isDropping, format }) {
  const displayValue = formatForDisplay(format.displayStyle, value);

  if (isEditing) {
    const renderer = (name, initialValue, change) => {
      return (
        <input
          key="bool-checkbox"
          className="bool-checkbox"
          type="checkbox"
          checked={initialValue}
          onChange={(event) => change(event.target.checked)} />
      );
    };
    return <EditCell value={value} updateCell={updateCell} renderInput={renderer} />;
  }

  return <TypedCell isDropping={isDropping} value={displayValue} format={format} />;
}

BooleanCell.propTypes = {
  isDropping: PropTypes.bool,
  isEditing: PropTypes.bool,
  updateCell: PropTypes.func,
  value: PropTypes.any,
  format: PropTypes.object
};

export default BooleanCell;
