import _ from 'lodash';

import { getQueryParameter } from 'common/components/AssetBrowser/lib/query_string';
import * as ceteraActions from 'common/components/AssetBrowser/actions/cetera';
import * as sortActions from 'common/components/AssetBrowser/actions/sort_order';
import * as pagerActions from 'common/components/AssetBrowser/actions/pager';
import * as ceteraHelperActions from 'common/components/AssetBrowser/lib/helpers/cetera';
import * as pageSizeActions from 'common/components/AssetBrowser/actions/page_size';
import {APPROVALS} from '../../../core/approvals_enums';

export const UPDATE_AUDIENCE = 'UPDATE_AUDIENCE';
export const TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP';

const defaultOrder = {
  ascending: false,
  value: 'lastUpdatedDate'
};

const order = (() => {
  if (_.isEmpty(getQueryParameter('orderColumn'))) {
    if (_.isEmpty(getQueryParameter('q'))) {
      return defaultOrder;
    } else {
      return {...defaultOrder, value: 'relevance'};
    }
  } else {
    return {
      ascending: getQueryParameter('orderDirection') === 'asc',
      value: getQueryParameter('orderColumn')
    };
  }
})();

const getInitialState = () => ({
  fetchingResults: false,
  fetchingResultsError: false,
  initialResultsFetched: false,
  order,
  pageNumber: parseInt(getQueryParameter('page', 1)),
  approvalRequesters: [],
  results: [],
  resultSetSize: 0
});

export default (state, action) => {
  if (_.isUndefined(state)) {
    return getInitialState();
  }

  if (action.type === 'UPDATE_CATALOG_RESULTS') {
    let sortedResults = [];

    /* If the "Only recently viewed" checkbox is clicked, any sort is removed. We manually
     * re-order the results to be in the order of the recently viewed, from local storage.
     * EN-17000: Note: the user can then apply a new sort onto the recently viewed results, and this
     * section will be bypassed because action.sortByRecentlyViewed will be false. See actions/cetera.js */
    if (action.sortByRecentlyViewed && window.lastAccessed && !_.isEmpty(window.lastAccessed.keys())) {
      const sortedUids = window.lastAccessed.keys();
      sortedUids.forEach((uid) => {
        sortedResults.push(
          action.response.results.find((result) => (result.resource.id === uid))
        );
      });
    } else {
      sortedResults = action.response.results;
    }

    let approvalRequesters = sortedResults.flatMap( (result) => {
      return _.get(result, 'metadata.approvals', []).map((approval) => {
        return {
          value: approval.submitter_id,
          title: approval.submitter_name
        };
      });
    });

    return {
      ...state,
      results: _.compact(sortedResults),
      approvalRequesters: approvalRequesters,
      resultSetSize: action.response.resultSetSize
    };
  }

  if (action.type === ceteraActions.FETCH_RESULTS) {
    return {
      ...state,
      fetchingResults: true,
      fetchingResultsError: false
    };
  }

  if (action.type === ceteraActions.FETCH_RESULTS_SUCCESS) {
    return {
      ...state,
      fetchingResults: false,
      fetchingResultsError: false
    };
  }

  if (action.type === ceteraActions.FETCH_RESULTS_ERROR) {
    return {
      ...state,
      fetchingResults: false,
      fetchingResultsError: true,
      fetchingResultsErrorType: action.details
    };
  }

  if (action.type === sortActions.CHANGE_SORT_ORDER) {
    return {
      ...state,
      order: action.order
    };
  }

  if (action.type === pagerActions.CHANGE_PAGE) {
    return {
      ...state,
      pageNumber: action.pageNumber
    };
  }

  if (action.type === ceteraHelperActions.INITIAL_RESULTS_FETCHED) {
    return {
      ...state,
      initialResultsFetched: true
    };
  }

  if (action.type === pageSizeActions.UPDATE_PAGE_SIZE) {
    return {
      ...state,
      pageSize: action.pageSize
    };
  }

  if (action.type === UPDATE_AUDIENCE) {
    let results = [];
    state.results.forEach((result) => {
      if (result.resource.id === action.updatePayload.assetUid) {
        const isPublic = action.updatePayload.permissions.scope === 'public' &&
            !action.updatePayload.willEnterApprovalQueue;
        const isInternal = action.updatePayload.permissions.scope === 'site' &&
            !action.updatePayload.willEnterApprovalQueue;
        result.metadata.is_public = isPublic;
        result.metadata.visible_to_anonymous = isPublic;
        result.metadata.visible_to_site = isInternal;
        if (action.updatePayload.willEnterApprovalQueue) {
          result.metadata.approvals = [{
            state: APPROVALS.STATUS.PENDING
          }];
        }
      }
      results.push(result);
    });
    return {
      ...state,
      results: results
    };
  }

  if (action.type === TRANSFER_OWNERSHIP) {
    let results = [];
    state.results.forEach((result) => {
      if (result.resource.id === action.updatePayload.assetUid &&
          action.updatePayload.permissions.users.length > 0) {
        const user = action.updatePayload.permissions.users[0];
        result.owner = {
          id: user.id,
          display_name: user.displayName,
          user_type: user.type
        };
      }
      results.push(result);
    });
    return {
      ...state,
      results: results
    };
  }

  return state;
};
