import React from 'react';
import { handleEnter } from 'common/dom_helpers/keyPressHelpers';
import { ForgeIcon, ForgeIconButton, ForgeTextField } from '@tylertech/forge-react';
import I18n from 'common/i18n';

interface SearchInputProps {
  onSearch: (value: string) => void;
  onChange: (value: string) => void;
  title: string;
  id: string;
  value: string | null;
  dataTestId?: string;
}

export default function SearchInput(props: SearchInputProps) {
  const clearSearch = () => {
    props.onChange('');
    props.onSearch('');
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;
    props.onChange(searchText);
  };

  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const searchText = event.currentTarget.value;
    props.onSearch(searchText);
  };
  // the common component doesn't allow us to control its state, so
  // it's kind of useless in this scenario.
  // If you need to sync the state with other components on the page (ie:
  // the query editor) using defaultValue in the text input isn't gonna
  // cut it
  //

  return (
    <ForgeTextField shape="rounded" className="text-input" density="default">
      <ForgeIcon slot="leading" name="search" />
      <input
        data-testid={props.dataTestId}
        autoComplete="off"
        type="text"
        id={props.id}
        onChange={handleChange}
        onKeyDown={handleEnter(handleSearch)}
        value={props.value || ''}
      />
      <label htmlFor={props.id} slot="label">{props.title}</label>
      <ForgeIconButton slot="trailing" data-testid="clear-search-input">
        <button type="button" onClick={clearSearch} aria-label={I18n.t('shared.components.searchbar.clear')}>
          <ForgeIcon name="clear" />
        </button>
      </ForgeIconButton>
    </ForgeTextField>
  );
}
