import * as _ from 'lodash';
import React from 'react';
import * as helpers from 'common/components/ScheduleModal/helpers';
import ErrorMessage, { ValidationErrors } from 'common/components/ScheduleModal/components/ErrorMessage';
import { ScreenType, FORM_STATUS, SCREENS, FormStatus } from 'common/components/ScheduleModal/constants';
import { Schedule } from 'common/types/schedule';

export type ChangeScreen = (s: ScreenType) => () => void;

interface MainButtonProps {
  schedule: Schedule;
  screen: ScreenType;
  status: FormStatus;
  action: () => void;
  validation: ValidationErrors;
}


export function MainButton({ screen, schedule, action, status, validation }: MainButtonProps) {
  const hasErrors = !_.isEmpty(validation);
  const props = {
    text: '',
    classNames: 'btn btn-primary dsmp-schedule-submit',
    disabled: hasErrors || helpers.isRunning(schedule),
    action
  };

  switch (screen) {
    case SCREENS.MAIN:
      props.text = helpers.t('save_schedule');
      props.disabled = hasErrors || schedule.paused || status !== FORM_STATUS.DONE;
      break;
    case SCREENS.DELETE:
      props.text = helpers.t('delete_schedule');
      props.disabled = status === FORM_STATUS.PENDING;
      props.classNames = 'btn btn-error dsmp-schedule-submit';
      break;
    case SCREENS.REDIRECT:
      props.text = helpers.t('change_source');
      props.disabled = status === FORM_STATUS.PENDING;
      break;
    case SCREENS.CONFIRM:
      props.text = helpers.t('close');
      break;
  }

  return <BaseButton {...props} />;
}

interface CancelButtonProps {
  schedule: Schedule;
  screen: ScreenType;
  onDismiss: () => void;
  changeScreen: ChangeScreen;
}

export function CancelButton({ screen, onDismiss, changeScreen }: CancelButtonProps) {
  const action = screen === SCREENS.MAIN ? onDismiss : changeScreen(SCREENS.MAIN);
  return (
    <BaseButton
      disabled={false}
      text={helpers.t('cancel')}
      classNames="btn btn-default dsmp-schedule-cancel"
      action={action} />
  );
}

interface PauseButtonProps {
  schedule: Schedule;
  screen: ScreenType;
  handlePause: () => void;
}
export function PauseButton({ screen, schedule, handlePause }: PauseButtonProps) {
  if (screen !== SCREENS.MAIN) {
    return null;
  }

  const text = schedule.paused ? helpers.t('unpause') : helpers.t('pause');
  return (
    <BaseButton
      text={text}
      disabled={!helpers.exists(schedule) || helpers.isRunning(schedule)}
      classNames="btn btn-alternate-2 btn-inverse dsmp-schedule-pause"
      action={handlePause} />
  );
}


interface DeleteButtonProps {
  schedule: Schedule;
  screen: ScreenType;
  changeScreen: ChangeScreen;
}
export function DeleteButton({ schedule, screen, changeScreen }: DeleteButtonProps) {
  if (screen !== SCREENS.MAIN ||  !helpers.exists(schedule)) {
    return null;
  }

  return (
    <BaseButton
      disabled={!helpers.exists(schedule) || helpers.isRunning(schedule)}
      text={helpers.t('delete')}
      action={changeScreen(SCREENS.DELETE)}
      classNames="btn dsmp-schedule-delete" />
  );
}

interface BaseButtonProps {
  classNames: string;
  text: string;
  action: () => void;
  disabled: boolean;
}
function BaseButton({ classNames, text, action, disabled }: BaseButtonProps) {
  return (
    <button disabled={disabled} className={classNames} onClick={action}>{text}</button>
  );
}
