import { reject as _reject } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { FlashMessage } from '../../types';
import {
  FlashMessageActionType,
  HideFlashMessageAction,
  ShowFlashMessageAction
} from '../actions/flashMessage';

export interface FlashMessageState {
  flashMessages: FlashMessage[];
}

export const initialState: FlashMessageState = {
  flashMessages: []
};

const flashMessageReducer = createReducer<FlashMessageState>(initialState, (builder) => {
  builder
    .addCase(
      FlashMessageActionType.SHOW_FLASH_MESSAGE,
      (state: FlashMessageState, action: ShowFlashMessageAction): FlashMessageState => {
        const { id, message, helpMessage, helpUrl, kind, alignLeft } = action;

        if (!id) {
          throw new Error('showFlashMessage requires a message ID');
        }

        // Don't display duplicate messages
        if (state.flashMessages.some((flashMessage) => flashMessage.id === id)) {
          return {
            ...state
          };
        }

        return {
          ...state,
          flashMessages: [
            ...state.flashMessages,
            {
              id,
              message,
              helpMessage,
              helpUrl,
              kind,
              alignLeft,
              visible: true
            }
          ]
        };
      }
    )
    .addCase(
      FlashMessageActionType.HIDE_FLASH_MESSAGE,
      (state: FlashMessageState, action: HideFlashMessageAction): FlashMessageState => {
        const { id } = action;
        if (!id) {
          throw new Error('hideFlashMessage requires a message ID');
        }
        return {
          ...state,
          flashMessages: _reject(state.flashMessages, { id })
        };
      }
    )
    .addCase(
      FlashMessageActionType.HIDE_ALL_FLASH_MESSAGES,
      (state: FlashMessageState): FlashMessageState => {
        return {
          ...state,
          flashMessages: []
        };
      }
    )
    .addDefaultCase((state: FlashMessageState): FlashMessageState => {
      return state;
    });
});

export default flashMessageReducer;
