import React from 'react';

import Alignment from './Alignment';
import Dropdown from 'common/components/Dropdown';

import { DisplayStyle, FormatOption, formatOptions } from 'common/formatBoolean';
import I18n from 'common/i18n';
import { ColumnFormat } from 'common/types/viewColumn';

const scope = 'shared.dataset_management_ui.format_column';

interface Props {
  onChange: (obj: ColumnFormat) => void;
  format: ColumnFormat;
  showAlignment : boolean;// not shown when hierarchy is choosen
  includeFormTag: boolean;
  defaultAlignment: string;
}

interface DropdownProps {
  value: DisplayStyle;
  placeholder: string;
  onSelection: (e: { value: DisplayStyle }) => void;
  options: FormatOption[];
}

function BoolColumnFormat({
  onChange, format, showAlignment, includeFormTag = true, defaultAlignment
}: Props) {
  const dropdownProps: DropdownProps = {
    value: format.displayStyle || 'default',
    placeholder: I18n.t('render_type', { scope }),
    onSelection: (e) => onChange({ displayStyle: e.value }),
    options: formatOptions
  };

  const renderContent = () => {
    return (
      <>
        {showAlignment && <Alignment onChange={onChange} format={format} defaultAlignment={defaultAlignment}/>}
        <div>
          <label>{I18n.t('render_type', { scope })}</label>
          <Dropdown {...dropdownProps} />
        </div>
      </>
    );
  };

  return (
    <div id="format-column" data-testid='bool-column-format'>
      {includeFormTag ? <form>{renderContent()}</form> : renderContent()}
    </div>
  );
}

export default BoolColumnFormat;
