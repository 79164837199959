import { fetchResults } from 'common/components/AssetBrowser/lib/helpers/cetera';
import { updateQueryString } from 'common/components/AssetBrowser/lib/query_string';

export const CHANGE_PAGE = 'CHANGE_PAGE';

export const changePage = (pageNumber) => (dispatch, getState) => {
  const onSuccess = () => {
    dispatch({ type: CHANGE_PAGE, pageNumber });
    updateQueryString({ getState });
  };

  return fetchResults(dispatch, getState, { action: CHANGE_PAGE, pageNumber }, onSuccess);
};

export const clearPage = (dispatch) => {
  dispatch({ type: CHANGE_PAGE, pageNumber: 1 });
};
