import * as helpers from 'common/components/ScheduleModal/helpers';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { Schedule } from 'common/types/schedule';
import mtz from 'moment-timezone';
import React from 'react';

interface ScheduleStatusProps {
  schedule: Schedule;
  runNow: () => void;
}

export default function ScheduleStatus({ schedule, runNow }: ScheduleStatusProps) {
  if (!schedule.id) return null;

  let nextRunInfo = null;
  let lastRunInfo = null;
  const runNowButton = (<a className="sync-now" onClick={runNow}><span className="sync-icon socrata-icon-redo" />{helpers.t('sync_now')}</a>);

  if (schedule.runstate.state === 'scheduled') {
    const nextRun = mtz(schedule.runstate.runs_at).tz(schedule.cadence.timezone!);
    nextRunInfo = (
      <div>
        <label className="next-run" htmlFor="next-run">{helpers.t('next_run')}</label>
        <span>
          {I18n.t('shared.components.asset_action_bar.schedule_from_url.run_date',
            { date: nextRun.format('YYYY-MM-DD'),
              time: nextRun.format('HH:mm')
            }
          )}
        </span>
      </div>
    );
  }

  if (schedule.last_run) {
    const dateLastRun = mtz(schedule.last_run).tz(schedule.cadence.timezone!);
    lastRunInfo = (
      <div>
        <label className="last-run" htmlFor="last-run">{helpers.t('last_run')}</label>
        {schedule.last_run &&
          <span className="dsmp-schedule-last-run">
            {I18n.t('shared.components.asset_action_bar.schedule_from_url.run_date',
              { date: dateLastRun.format('YYYY-MM-DD'),
                time: dateLastRun.format('HH:mm')
              }
            )}
          </span>}
        {runNowButton}
      </div>
    );
  } else {
    lastRunInfo = (
      <div>
        {runNowButton}
      </div>
    );
  }

  return (
    <div className="dsmp-schedule-status">
      <div>
        {schedule.consecutive_failures > 0 &&
          <span className="dsmp-schedule-failures">
            <SocrataIcon name={IconName.WarningAlt} />
            {I18n.t('shared.components.asset_action_bar.schedule_from_url.status_failed',
              { failures: schedule.consecutive_failures })
            }
          </span>}
      </div>
      {nextRunInfo}
      {lastRunInfo}
    </div>
  );
}
