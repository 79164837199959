import React, { useState } from 'react';
import FlashMessageWrapper from '../../../containers/FlashMessageWrapperContainer';
import { WithFlashMessageDirectProps } from 'common/dsmapi/ManageMetadataComponent/containers/WithFlashMessageContainer';

const WithFlashMessage: React.FunctionComponent<WithFlashMessageDirectProps> = ({
  flashVisible,
  className,
  children
}) => {
  const [flashHeight, setFlashHeight] = useState(0);

  const getHeight = (node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }

    if (node.offsetHeight !== flashHeight) {
      setFlashHeight(node.offsetHeight);
    }
  };

  const formPadding = {
    paddingTop: flashVisible ? flashHeight + 12 : 0
  };

  return (
    <div className={className}>
      <div className="metadata-component-flash-container" ref={(flash) => getHeight(flash)}>
        <FlashMessageWrapper />
      </div>
      <div style={formPadding}>{children}</div>
    </div>
  );
};

export default WithFlashMessage;
