import PropTypes from 'prop-types';
import React from 'react';
import TypedCell from './TypedCell';
import EditCell from './EditCell';
import Geojson2wkt from 'geojson2wkt';

function toWKTLabel(soqlType) {
  return {
    'point': 'POINT',
    'line': 'LINESTRING',
    'polygon': 'POLYGON',
    'multipoint': 'MULTIPOINT',
    'multiline': 'MULTILINESTRING',
    'multipolygon': 'MULTIPOLYGON'
  }[soqlType];
}

function GeospatialCell({ type, value, isError, isEditing, updateCell, isDropping, format }) {
  const text = value ? `${toWKTLabel(type)}(...)` : '';
  if (isEditing) {
    const full = isError ? '' : Geojson2wkt.convert(value);
    return <EditCell value={full} updateCell={updateCell} />;
  }
  return <TypedCell isDropping={isDropping} value={text} format={format} />;
}

GeospatialCell.propTypes = {
  isDropping: PropTypes.bool,
  isEditing: PropTypes.bool,
  updateCell: PropTypes.func,
  value: PropTypes.any,
  format: PropTypes.object,
  type: PropTypes.string.isRequired,
  isError: PropTypes.bool
};

export default GeospatialCell;
