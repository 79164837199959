import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import getOr from 'lodash/fp/getOr';

import FormReCaptcha from '../FormReCaptcha';
import { recaptchaCallback, RecaptchaCallbackAction } from './FormReduxActions';
import { FormState } from './types';

/** Props from mapStateToProps */
interface StateProps {
  valid: boolean | null;
}

/** Props from mapDispatchToProps */
interface DispatchProps {
  callback: (result: string | null) => RecaptchaCallbackAction;
}

type Props = StateProps & DispatchProps;

/**
 * A ReCAPTCHA that stores its validitiy in Redux and handles callbacks automatically.
 *
 * @see common/components/Forms/Redux/FormReducer for how the callback etc. is handled.
 */
class ReduxValidatedFormReCaptcha extends Component<Props> {
  static propTypes = {};

  render(): ReactElement {
    return <FormReCaptcha {...this.props} />;
  }
}

const mapStateToProps = (state: FormState): StateProps => ({
  valid: getOr(null, 'inputs.recaptcha.valid', state)
});

const mapDispatchToProps = {
  callback: recaptchaCallback
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxValidatedFormReCaptcha);
