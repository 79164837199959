import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import { SelectUsersByEmailListState } from './SelectUsersByEmailListTypes';

/** Selectors/Accessors
------------------------------------------------------------------------------- **/
// export const get = (state: SelectUsersByEmailListState, attribute: string) => state[attribute];

export const getSelectUsersByEmailList = (
  state: { [key: string]: any, selectUsersByEmailList: SelectUsersByEmailListState } | SelectUsersByEmailListState
) => {

  const response = get('selectUsersByEmailList')(state) || state;

  return response;
};
export const getEmailListQuery = flow(getSelectUsersByEmailList, get('emailListQuery'));
export const getEmailList = flow(getSelectUsersByEmailList, get('emailList'));
export const getUserSearchResults = flow(getSelectUsersByEmailList, get('userSearchResults'));
export const getFilters = flow(getSelectUsersByEmailList, get('filters'));
export const getLoadData = flow(getSelectUsersByEmailList, get('loadData'));
