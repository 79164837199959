import BigNumber from 'bignumber.js';
import { fetchTranslation } from 'common/locale';
import { Expr, FunCall } from 'common/types/soql';
import { ColumnFormat } from 'common/types/viewColumn';
import { func, isFunction, isNumberLiteral, numberLiteral } from './ast';

import React from 'react';
const t = (k: string) => fetchTranslation(k, 'shared.dataset_management_ui.format_column.percent_scale');

const OP_MULT = 'op$*';
const OP_DIV = 'op$/';
const big100 = new BigNumber(100);
// SoQLNumber values are strings
const is100 = (node: Expr) => isNumberLiteral(node) && new BigNumber(node.value).equals(big100);

function isMultiplyingBy100(ast: Expr): ast is FunCall {
  // if the node is a multiply function where one of the args is 100, then
  // it's a (foo * 100) expr
  return isFunction(ast) && ast.function_name === OP_MULT && (is100(ast.args[0]) || is100(ast.args[1]));
}

function isDividingBy100(ast: Expr): ast is FunCall {
  // if the node is a multiply function where the second arg is 100, then
  // it's a (foo / 100) expr
  return isFunction(ast) && ast.function_name === OP_DIV && is100(ast.args[1]);
}

const makeAST = (origAST: Expr, op: string) => {
  if (op === 'multiply') {
    if (isDividingBy100(origAST)) {
      // We will strip the divide and multiply by 100
      return func(OP_MULT, origAST.args[0], numberLiteral(100));
    }
    // We have an expr like (expr) so we just want to multiply it by 100
    return func(OP_MULT, origAST, numberLiteral(100));
  }

  if (op === 'divide') {
    if (isMultiplyingBy100(origAST)) {
      // We will strip the multiply and divide by 100
      if (is100(origAST.args[0])) {
        // We have an expr like (100 * expr), so we want to return (expr / 100)
        return func(OP_DIV, origAST.args[1], numberLiteral(100));
      }
      if (is100(origAST.args[1])) {
        // We have an expr like (expr * 100), so we want to return expr (expr / 100)
        return func(OP_DIV, origAST.args[0], numberLiteral(100));
      }
    }

    // We have an expr like (expr) with no multiply by 100 to strip away,
    // so we return an expr like (expr / 100)
    return func(OP_DIV, origAST, numberLiteral(100));
  }

  if (op === 'none') {
    // If the current AST looks something like (100 * expr), we'll go down this path
    if (isMultiplyingBy100(origAST)) {
      if (is100(origAST.args[0])) {
        // We have an expr like (100 * expr), so we just want to return expr, which is arg posn 1
        return origAST.args[1];
      }
      if (is100(origAST.args[1])) {
        // We have an expr like (expr * 100), so we just want to return expr, which is arg posn 0
        return origAST.args[0];
      }
    }

    // If the current AST looks something like (expr / 100), we just want to return
    // the first arg of the expr
    if (isDividingBy100(origAST)) {
      return origAST.args[0];
    }
  }

  return origAST;
};

interface Props {
  onChangeAST: (a: Expr) => void;
  format: ColumnFormat;
  ast: Expr | null;
}
function PercentRescaler({ onChangeAST, ast: origAST, format }: Props) {
  if (!origAST) return null;
  if (format.precisionStyle !== 'percentage') return null;

  let which;
  if (isMultiplyingBy100(origAST)) {
    which = 'multiply';
  } else if (isDividingBy100(origAST)) {
    which = 'divide';
  } else {
    which = 'none';
  }

  return (
    <div className="percent-rescaler">
      <label>{t('percent_scale')}</label>
      <div className="choice">
        <input
          type="radio"
          onChange={() => onChangeAST(makeAST(origAST, 'multiply'))}
          id="multiply"
          name="multiply"
          value="multiply"
          checked={which === 'multiply'}
        />
        <label htmlFor="multiply">{t('multiply')}</label>
      </div>
      <div className="choice">
        <input
          type="radio"
          onChange={() => onChangeAST(makeAST(origAST, 'divide'))}
          id="divide"
          name="divide"
          value="divide"
          checked={which === 'divide'}
        />
        <label htmlFor="divide">{t('divide')}</label>
      </div>
      <div className="choice">
        <input
          type="radio"
          onChange={() => onChangeAST(makeAST(origAST, 'none'))}
          id="none"
          name="none"
          value="none"
          checked={which === 'none'}
        />
        <label htmlFor="none">{t('do_nothing')}</label>
      </div>

      <p className="jk-not-actually-formatting">{t('click_save_to_preview')}</p>
    </div>
  );
}

export default PercentRescaler;
