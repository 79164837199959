import { merge as _merge, isUndefined as _isUndefined } from 'lodash';
import { appToken, csrfToken } from 'common/http';
import SaveError from './saveError';

export const socrataFetch = (path: string, options: RequestInit = {}): Promise<Response> => {
  const defaultFetchOptions: RequestInit = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-App-Token': appToken()
    }
  };

  const headersForWrites = {
    'X-CSRF-Token': csrfToken()
  };

  // Only need to add in authenticityToken for non-GET requests
  const mergedBasic = _merge(options, defaultFetchOptions);
  const mergedForWrites =
    !_isUndefined(options.method) && options.method.toUpperCase() !== 'GET'
      ? _merge(mergedBasic, { headers: headersForWrites })
      : mergedBasic;
  return fetch(path, mergedForWrites);
};

export const socrataFetchAndCheck = async (path: string, options?: RequestInit) => {
  try {
    const response = await socrataFetch(path, options);
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }

    const error = new SaveError(await response.json());
    throw error;
  } catch (error) {
    console.log(error);
    if (error instanceof SaveError) {
      throw error;
    }
    throw new SaveError();
  }
};
