import React from 'react';
import cx from 'classnames';
import './style.scss';

// Select background color
const getColor = (type: string) => {
  switch (type) {
    case 'primary':
      return 'pill_badge__primary';
    case 'warning':
      return 'pill_badge__warning';
    case 'error':
      return 'pill_badge__error';
    case 'success':
      return 'pill_badge__success';
    case 'beta':
      return 'pill_badge__beta';
    default:
      return 'pill_badge__default';
  }
};

interface PillBadgeProps {
  shadowed?: boolean;
  type?: string;
  hoverable?: boolean;
  children: any;
}

/**
 *  Create a badge that is pill shaped
 *
 * @param shadowed {boolean} add shadow to the badge
 * @param type="default" {"primary"|"warning"|"error"|"success"} sets the background color and type of the badge
 * @param hoverable {boolean} whether the pill contains an actionable element
 * @param children {object} react element, usually a string within the badge
 */
const PillBadge = ({ shadowed = false, type = 'default', hoverable = false, children }: PillBadgeProps) => {
  const composedClasses = cx(
    {
      'pill_badge__shadow': shadowed,
      'pill_badge__has-hover': hoverable
    },
    'pill_badge',
    getColor(type)
  );

  return <span className={composedClasses}>{children}</span>;
};

export default PillBadge;
