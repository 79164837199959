import { DsmapiUser } from './dsmapi';
import { MetadataChanges } from './revision';
import { SoQLType } from './soql';
import { ColumnFormat } from './viewColumn';

export interface RowsUpsertedResult {
  stage: 'rows_upserted';
  details: {
    count: number;
  };
}
export interface UpsertTaskResult {
  stage: 'upsert_task';
  details: {
    'Rows Created': number;
    'Rows Deleted': number;
    'Rows Updated': number;
    total_rows: number;
    error_count: number;
  };
}

export interface ColumnChangeAttributes {
  soql_type?: SoQLType;
  position: number;
  format: ColumnFormat;
  flags: string[];
  field_name: string;
  display_name: string;
  description: string;
  width: null | number;
}

export interface CreateColumnOperation {
  type: 'create_column';
  attributes: ColumnChangeAttributes;
}
export interface UpdateColumnOperation {
  type: 'update_column';
  attributes: ColumnChangeAttributes & {
    original_field_name: string;
  };
  prev_attributes: ColumnChangeAttributes;
}
export interface DeleteColumnOperation {
  type: 'drop_column';
  attributes: {
    field_name: string;
  };
  prev_attributes: ColumnChangeAttributes
}

export type SchemaChangeOperation = CreateColumnOperation | UpdateColumnOperation | DeleteColumnOperation;

export interface SchemaChangeTaskResult {
  stage: 'set_schema';
  details: {
    operations: SchemaChangeOperation[];
  };
}

export interface ApplyMetadataTaskResult {
  stage: 'apply_metadata',
  details: {
    diff: {
      old: MetadataChanges;
      new: MetadataChanges;
    }
  };
}

export type LogEntry = RowsUpsertedResult | UpsertTaskResult | SchemaChangeTaskResult | ApplyMetadataTaskResult;

export enum TaskSetStatus {
  Initializing = 'initializing',
  CreatingColumns = 'creating_columns',
  Upserting = 'upserting',
  AwaitingUpsertResponse = 'awaiting_upsert_response',
  Invalid = 'invalid', // deprecated; replaced by awaiting_upsert_respons,
  Finishing = 'finishing',
  Successful = 'successful',
  Failure = 'failure',
  UnretryableFailure = 'unretryable_failure',
  Paused = 'paused',
  Unpausing = 'unpausing',
  Running = 'running',
  RowsUpserted = 'rows_upserted'
}

export interface TaskSet {
  id: number;
  log: LogEntry[];
  created_at: string;
  created_by: DsmapiUser;
  finished_at: string | null;
  status: TaskSetStatus;
  request_id: string;
}



