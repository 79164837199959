import React, { useState } from 'react';
import I18n from 'common/i18n';
import {
  ForgeButton,
  ForgeCard,
  ForgeDialog,
  ForgeDivider,
  ForgeIcon,
  ForgeScaffold,
  ForgeToolbar
} from '@tylertech/forge-react';
import WistiaPlayer from './WistiaPlayer';

interface Icon {
  name: string;
  type: string;
}

interface VideoCardProps {
  title: string;
  description: string;
  icon: Icon;
  length: string;
  wistia: {
    embedid: string;
  }
}

function VideoCard(props: VideoCardProps) {
  const { title, description, icon, length, wistia } = props;
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const scope = 'shared.components.videos';

  const showVideo = () => {
    setIsVideoOpen(true);
  };

  const hideVideo = () => {
    setIsVideoOpen(false);
  };

  const renderIcon = () => {
    if (icon?.type === 'forge') {
      return <ForgeIcon className="video-card-icon" name={icon?.name || 'ondemand_video'} aria-hidden="true" />;
    } else if (icon?.type === 'socrata') {
      return <span className={`video-card-socrata-icon socrata-icon-${icon?.name || 'play2'}`}></span>;
    } else {
      return null;
    }
  };

  const renderWatchButton = () => {
    if (!wistia?.embedid) {
      return null;
    }

    return (
      <>
        <ForgeButton slot="end" type="outlined">
          <button type="button" onClick={showVideo}>{I18n.t('launch_button', { scope })}</button>
        </ForgeButton>
        <ForgeDialog open={isVideoOpen} onDismiss={hideVideo}>
          <WistiaPlayer videoId={wistia.embedid} />
          <ForgeToolbar inverted>
            <ForgeButton slot="end" type="outlined">
              <button type="button" onClick={hideVideo}>{I18n.t('close_button', { scope })}</button>
            </ForgeButton>
          </ForgeToolbar>
        </ForgeDialog>
      </>
    );
  };

  return (
    <ForgeCard className="video-guides-video-card">
      <ForgeScaffold>
        <div slot="header">
          <div className="video-card-header">
            {renderIcon()}
            <div className="video-card-title forge-typography--headline5">{title || ''}</div>
          </div>
          <ForgeDivider />
        </div>
        <div slot="body" className="video-card-body forge-typography--body1" tabIndex={0} role="document">
          {description || ''}
        </div>
        <ForgeToolbar slot="footer" inverted>
          <div slot="start" className="forge-typography--body1">{length || 'N/A'} {I18n.t('time_unit', { scope })}</div>
          {renderWatchButton()}
        </ForgeToolbar>
      </ForgeScaffold>
    </ForgeCard>
  );
}

export default VideoCard;
