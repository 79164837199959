import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

import * as constants from 'common/components/AssetBrowser/lib/constants.ts';
import airbrake from 'common/airbrake';
import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import Flannel from 'common/components/Flannel';
import Button from 'common/components/Button';
import SitesFederatedToList from 'common/components/Federation/SitesFederatedToList';
import { fetchApprovalsGuidanceV2, withGuidanceV2 } from 'common/core/approvals/index_new';
import { getView } from 'common/core/views_service';
import { coreUidFromAssetId } from 'common/cetera/catalog_id';

import * as assetActions from '../actions/asset_actions';
import { APPROVALS } from 'common/core/approvals_enums';

const { APPROVED, PENDING, REJECTED } = APPROVALS.STATUS;
const { SUCCESS } = APPROVALS.SUBMISSION_OUTCOME_STATUS;
const { UPDATE_PUBLISHED_ASSET, CHANGE_AUDIENCE } = APPROVALS.OUTCOME;

export class ApprovalActionButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableButtons: [APPROVED, REJECTED].includes(props.approvalStatus),
      flannelIsOpen: false,
      flannelType: null,
      flannelNote: ''
    };
  }

  componentDidMount = async () => {
    // TODO: If this grows in scope, consider porting to a better state management style.

    // uid here is actually a catalog id
    const { uid } = this.props;

    try {
      // Start requests in parallel.
      const guidancePromise = fetchApprovalsGuidanceV2(uid);
      const viewPromise = getView(coreUidFromAssetId(uid));

      this.setState({
        approvalsGuidance: await guidancePromise,
        view: await viewPromise
      });
    } catch (e) {
      airbrake.notify({
        error: e,
        context: { component: 'ApprovalActionButtons' }
      });
      this.setState({ loadError: true });
    }
  };

  getTranslation(key) {
    const scope = 'shared.asset_browser.result_list_table.approval_action_buttons';
    return I18n.t(key, { scope });
  }

  handleApprovalButtonClick(e, flannelType) {
    e.preventDefault();

    if (this.state.disableButtons) {
      return;
    }
    // Defer before opening a flannel to fix an issue when another flannel is already open -
    // The onDismiss from first flannel triggers after the new flannel is opened, causing both to close.
    _.defer(() => {
      this.setState({ flannelIsOpen: true, flannelType });
    });
  }

  handleErrorAlert(error, i18nKey, name) {
    const { showErrorAlert } = this.props;
    airbrake.notify({
      error,
      context: { component: 'ApprovalActionButtons' }
    });
    const alertMessageLocaleScope = `shared.asset_browser.alert_messages.${i18nKey}`;
    const title = I18n.t('error_title', { scope: alertMessageLocaleScope, resourceName: name });
    const body = I18n.t('error_body', { scope: alertMessageLocaleScope });
    showErrorAlert(title, body);
    this.setState({ disableButtons: false });
  }

  handleSuccessAlert(i18nKey, name) {
    const { showInfoAlert } = this.props;
    const alertMessageLocaleScope = `shared.asset_browser.alert_messages.${i18nKey}`;
    const title = I18n.t('title', { scope: alertMessageLocaleScope, resourceName: name });
    const body = I18n.t('body', { scope: alertMessageLocaleScope });
    showInfoAlert(title, body);
    this.setState({ disableButtons: true });
  }

  approveAsset(approvalsHelper, note) {
    const { setApprovalStatus, name, uid } = this.props;
    approvalsHelper.approve(note).then((response) => response.json()).then(
      (json) => {
        switch (json.submissionOutcome) {
          case UPDATE_PUBLISHED_ASSET:
          case CHANGE_AUDIENCE:
            this.handleSuccessAlert('resource_approved', name);
            // triggers polling, see pollForCompletedApproval in result_list_row.js
            setApprovalStatus(uid, SUCCESS);
            break;
          default:
            airbrake.notify({
              error: `Unhandled submission outcome status: ${json.submissionOutcome}`,
              context: { component: 'approval_action_buttons' }
            });
            break;
        }
      },
      () => {
        this.handleErrorAlert('', 'resource_approved', name);
        setApprovalStatus(uid, PENDING);
      }
    ).catch((error) => {
      this.handleErrorAlert(error, 'resource_approved', name);
      setApprovalStatus(uid, PENDING);
    });
  }

  rejectAsset(approvalsHelper, note) {
    const { setApprovalStatus, name, uid } = this.props;
    approvalsHelper.reject(note).then(
      () => {
        this.handleSuccessAlert('resource_rejected', name);
        setApprovalStatus(uid, REJECTED);
      },
      () => {
        this.handleErrorAlert('', 'resource_rejected', name);
        setApprovalStatus(uid, PENDING);
      }
    ).catch((error) => {
      this.handleErrorAlert(error, 'resource_rejected', name);
      setApprovalStatus(uid, PENDING);
    });
  }

  onFlannelConfirm() {
    const { approvalsGuidance, flannelType, flannelNote } = this.state;

    const approvalsHelper = withGuidanceV2(approvalsGuidance);

    this.setState({ disableButtons: true });

    return flannelType === 'approve' ?
      this.approveAsset(approvalsHelper, flannelNote) :
      this.rejectAsset(approvalsHelper, flannelNote);
  }

  renderNoFederationTitleAndDescription(flannelType) {
    return (
      <div>
        <h4>{this.getTranslation(`flannel.${flannelType}.title`)}</h4>
        <p>{this.getTranslation(`flannel.${flannelType}.description`)}</p>
      </div>
    );
  }

  renderFederationTitleAndDescription(flannelType, sitesFederatedToIfMadePublic) {
    const { view } = this.state;
    return (
      <div>
        <h4>{this.getTranslation(`flannel.${flannelType}.title_with_federation`)}</h4>
        <SitesFederatedToList
          view={view}
          siteList={sitesFederatedToIfMadePublic}
          infoText={this.getTranslation(`flannel.${flannelType}.description_with_federation`)}
        />
      </div>
    );
  }

  renderFlannel() {
    const { view, approvalsGuidance, flannelType, flannelNote } = this.state;
    const changeNote = (event) => {
      this.setState({ flannelNote: event.target.value });
    };
    const onFlannelDismiss = () => this.setState({ flannelIsOpen: false, flannelType: null });
    const sitesFederatedToIfMadePublic = withGuidanceV2(approvalsGuidance).sitesThatWillBeFederatedToIfApprovedToPublic();

    // Only show federation information if there is actually an active federation
    // for this asset and we're about to approve it (there is no special
    // federation-related UX for rejecting an approval).
    const noFederation =
      _.isEmpty(sitesFederatedToIfMadePublic) ||
      flannelType !== 'approve';

    const titleAndDescription = noFederation ?
      this.renderNoFederationTitleAndDescription(flannelType) :
      this.renderFederationTitleAndDescription(flannelType, sitesFederatedToIfMadePublic);

    const flannelContent = (
      <div className="flannel-content">
        {titleAndDescription}
        <p>{this.getTranslation(`flannel.${flannelType}.note`)}</p>
        <textarea rows="3" value={flannelNote} onChange={changeNote} />
        <div className="flannel-action-buttons">
          <button onClick={onFlannelDismiss} className="btn btn-default cancel-button">
            {this.getTranslation('flannel.cancel')}
          </button>
          <button className="btn btn-primary ok-button" onClick={() => {
            this.onFlannelConfirm();
            onFlannelDismiss();
          }}>
            {this.getTranslation(`flannel.${flannelType}.action`)}
          </button>
        </div>
      </div>
    );

    const flannelClass = `${flannelType}-flannel`;
    const flannelTitle = this.getTranslation(`flannel.${flannelType}.alt`);
    const flannelTarget = flannelType === 'approve' ? this.approveFlannelTarget : this.rejectFlannelTarget;

    return (
      <Flannel className={flannelClass} onDismiss={onFlannelDismiss} title={flannelTitle} target={flannelTarget}>
        {flannelContent}
      </Flannel>
    );
  }

  renderLoadError() {
    return <div className="alert error">{this.getTranslation('load_error')}</div>;
  }

  render() {
    const { view, approvalsGuidance, disableButtons, loadError } = this.state;

    if (loadError) {
      return this.renderLoadError();
    }

    const fetchComplete = view && approvalsGuidance;

    const approveButton = () => {
      const buttonClasses = classNames('btn btn-sm btn-default');

      return (
        <div>
          <a href="#" onClick={(e) => this.handleApprovalButtonClick(e, APPROVALS.ACTIONS.APPROVE)}>
            <Button className={buttonClasses} disabled={disableButtons || !fetchComplete}>
              {this.getTranslation(APPROVALS.ACTIONS.APPROVE)}
            </Button>
          </a>
          <div ref={(flannelTarget) => { this.approveFlannelTarget = flannelTarget; }} />
        </div>
      );
    };

    const rejectButton = () => {
      const buttonClasses = classNames('btn btn-sm btn-default');

      return (
        <div>
          <a href="#" onClick={(e) => this.handleApprovalButtonClick(e, APPROVALS.ACTIONS.REJECT)}>
            <Button className={buttonClasses} disabled={disableButtons}>
              {this.getTranslation(APPROVALS.ACTIONS.REJECT)}
            </Button>
          </a>
          <div ref={(flannelTarget) => { this.rejectFlannelTarget = flannelTarget; }} />
        </div>
      );
    };

    const flannel = this.state.flannelIsOpen && this.renderFlannel();

    return (
      <div className="approval-action-buttons">
        {approveButton()}
        {rejectButton()}
        {flannel}
      </div>
    );
  }
}

ApprovalActionButtons.propTypes = {
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  showInfoAlert: (title, body) => dispatch(assetActions.showInfoAlert(title, body)),
  showErrorAlert: (title, body) => dispatch(assetActions.showErrorAlert(title, body)),
  setApprovalStatus: (uid, state) => dispatch(assetActions.setApprovalStatus(uid, state))
});

export default connect(null, mapDispatchToProps)(ApprovalActionButtons);
