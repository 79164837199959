import { ForgeChip} from '@tylertech/forge-react';
import React, { FunctionComponent, ReactElement } from 'react';
import I18n from 'common/i18n';
import './TagList.scss';

const t = (k: string, options: { [key: string]: any } = {}) => I18n.t(k, { scope: 'shared.components.asset_card', ...options });

export type TagListProps = {
  tags?: string[];
  maxLength?: number;
  onTagSelect?: (tagName: string) => void;
};


const TagList: FunctionComponent<TagListProps> = ({ tags, maxLength = 5, onTagSelect }) => {
  const tagList: ReactElement[] = [];
  let excessTags;

  const handleTagSelectd = (chipEvent: CustomEvent) => {
    if (onTagSelect) {
      onTagSelect(chipEvent.detail.value);
    }
};

  if (tags) {
    tags.map((tag, index) => {
      if (tag && (!maxLength || index < maxLength)) {
        tagList.push((
            <ForgeChip key={index} value={tag} dense={true} on-forge-chip-select={handleTagSelectd}>{tag}</ForgeChip>
        ));
      }
    });
    if (maxLength && tags.length > maxLength) {
        excessTags = <span data-testid='tag-list-more-tags-span'> {t('more_tags', {numberOfTags: tags.length - maxLength})}</span>;
    }
  }
  return <>{tagList}{excessTags}</>;
};

export default TagList;
