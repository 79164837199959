import _ from 'lodash';
import collapsible from 'common/collapsible';
import velocity from 'velocity-animate';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { formatDateWithLocale } from 'common/dates';
import formatNumber from 'common/js_utils/formatNumber';
import Linkify from 'react-linkify';
import moment from 'moment-timezone';
import I18n from 'common/i18n';
import { ENTER, SPACE, isOneOfKeys } from 'common/dom_helpers/keycodes_deprecated';
import AssociatedAssets from 'common/components/AssociatedAssets';
import HelperTooltip from 'common/components/HelperTooltip';
import { FeatureFlags } from 'common/feature_flags';
import { currentUserIsSiteMember } from 'common/current_user';
import { ForgeIcon, ForgeInlineMessage } from '@tylertech/forge-react';
import * as configApi from 'common/core/configurations';
import { getCurrentDomain } from 'common/currentDomain';
import { isInitialDatasetDraft } from 'common/views/view_types';

import './index.scss';

const renderFullDateTimes = () => FeatureFlags.value('enable_primer_metadata_time_format') === true;

// Checks if event is a space or an enter
const handleInvokeKey = (handler, preventDefault) => (
  (event) => {
    if (isOneOfKeys(event, [ENTER, SPACE])) {
      if (preventDefault) {
        event.preventDefault();
      }

      return handler(event);
    }
  }
);

const getLabelForValue = (options, value) => {
  const labelledValue = _.find(options, { value });
  return _.get(labelledValue, 'label') || null;
};

export const shapeField = (field, customFields) => {
  const { name, options } = field;
  const value = customFields[name];
  if (_.isArray(value)) {
    // if value is an array of values (multiselect), need to get the label for each one
    const label = value.map(v => (getLabelForValue(options, v)));
    return { name, value, label };
  }
  return { name, value, label: getLabelForValue(options, value) };
};

// Takes the result of DatasetHelper#custom_metadata_fieldsets (array of objects) and flattens it
// to a flat simple hash of fieldname -> [ array of field values ].
// For details on what fieldsets are and what their shape is, see the comment on
// DatasetsHelper#custom_metadata_fieldsets. Field values are { name: 'fieldName', value: 'someValue', label: 'someLabel' } pairs
// (there are multiple values because each field set can have subfields).
// (the label is set in the custom field config on the domain)
export function getFieldSetsAsMap(fieldsets) {
  return (fieldsets || []).reduce((accumulator, fieldset) => {
    const currentAvailableFields = fieldset.fields.map(field => field.name);

    // Have to perform this check in case user deletes a field but we still have data for it.
    const customFields = _.pickBy(fieldset.existing_fields, (v, k) =>
      currentAvailableFields.includes(k));

    accumulator[fieldset.name] = _.map(fieldset.fields, (f) => shapeField(f, customFields));

    return accumulator;
  }, {});
}

// TODO: Make an example page for this component.
class MetadataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      associatedAssetsModalIsOpen: false,
      companyName: I18n.t('common.metadata.this_organization')
    };

    _.bindAll(this, 'toggleTable');
  }

  componentDidMount() {
    this.collapseTags();
    this.collapseTable();
    this.patchFirefoxWordBreakBug();
    this.fetchSiteThemeConfig();
  }

  fetchSiteThemeConfig = async () => {
    const themeSiteConfig = await configApi.fetchSiteThemeConfig(getCurrentDomain());
    const themeProperties = configApi.handleSiteThemeConfig(themeSiteConfig);
    const companyName = themeProperties.find(({ name }) => name === 'strings.company');
    this.setState({ companyName: companyName.value });
  };

  // Legendary firefox hack, see https://bugzilla.mozilla.org/show_bug.cgi?id=1266901
  patchFirefoxWordBreakBug() {
    const el = ReactDOM.findDOMNode(this);
    _.toArray(el.querySelectorAll('td.attachment a')).forEach((link) => {
      link.style.display = 'none';
      link.offsetHeight; // eslint-disable-line no-unused-expressions
      link.style.display = '';
    });
  }

  collapseTags() {
    if (_.isEmpty(this.props.coreView.tags)) {
      return;
    }

    const el = ReactDOM.findDOMNode(this);
    collapsible(el.querySelector('.tag-list'), {
      height: 2 * 24, // lines * line height
      expandedCallback: this.props.onExpandTags || _.noop
    });
  }

  collapseTable() {
    const el = ReactDOM.findDOMNode(this);
    const leftColumnHeight = el.querySelector('.metadata-column.fancy').offsetHeight;
    const tableColumn = el.querySelector('.metadata-column.tables');
    const tables = _.toArray(tableColumn.querySelectorAll('.metadata-table'));
    let shouldHideToggles = true;

    // Add a 'hidden' class to tables whose top is below the bottom of the left column.
    // These will be shown and hidden as the tableColumn is expanded and collapsed.
    tables.forEach((table) => {
      if (table.offsetTop > leftColumnHeight) {
        table.classList.add('hidden');
        shouldHideToggles = false;
      }
    });

    // If there is not enough content in the tableColumn, hide the toggles and avoid
    // binding event handlers, as no collapsing is necessary.
    if (shouldHideToggles) {
      const toggleGroups = _.toArray(el.querySelectorAll('.metadata-table-toggle-group'));
      toggleGroups.forEach((group) => group.style.display = 'none');

      tableColumn.classList.remove('collapsed');
      tableColumn.style.paddingBottom = 0;

      return;
    }
  }

  toggleTable(event) {
    event.preventDefault();

    const onExpandMetadataTable = this.props.onExpandMetadataTable || _.noop;
    const el = ReactDOM.findDOMNode(this);
    const tableColumn = el.querySelector('.metadata-column.tables');

    const wasCollapsed = tableColumn.classList.contains('collapsed');
    const originalHeight = tableColumn.getBoundingClientRect().height;
    tableColumn.classList.toggle('collapsed');
    const targetHeight = tableColumn.getBoundingClientRect().height;
    tableColumn.style.height = `${originalHeight}px`;

    if (wasCollapsed) {
      velocity(tableColumn, {
        height: targetHeight
      }, () =>
        tableColumn.style.height = ''
      );

      onExpandMetadataTable();
    } else {
      tableColumn.classList.remove('collapsed');

      tableColumn.style.height = `${originalHeight}px`;
      velocity(tableColumn, {
        height: targetHeight
      }, () => {
        tableColumn.style.height = '';
        tableColumn.classList.add('collapsed');
      });
    }
  }

  getCommunityAssetMessage() {
    const { companyName } = this.state;
    let message = I18n.t('common.metadata.community_disclosure', { assetType: I18n.t('common.metadata.dataset'), companyName: companyName });
    return message;
  }

  renderHeader() {
    const {
      enableAssociatedAssets,
      useDataAssetStrings
    } = this.props;


    const onClickAssociatedAssetsButton = (e) => {
      e.preventDefault();
      this.setState({ associatedAssetsModalIsOpen: true });
    };

    const associatedAssetsButton = enableAssociatedAssets && (
      <a
        href="#"
        className="btn btn-sm btn-default associated-assets-button"
        onClick={onClickAssociatedAssetsButton}>
        {I18n.t('common.metadata.associated_assets.button_text')}
      </a>
    );

    const showButtonGroupDespiteActionButton = enableAssociatedAssets;

    return (
      <div className="landing-page-header-wrapper">
        <h2 className="landing-page-section-header">
          {useDataAssetStrings ?
            I18n.t('common.metadata.title_data_asset')
            : I18n.t('common.metadata.title')
          }
        </h2>
        { showButtonGroupDespiteActionButton &&
          <div className="button-group">
            { associatedAssetsButton }
          </div>
        }
      </div>
    );
  }

  render() {
    const {
      analysisId,
      coreView,
      customMetadataFieldsets,
      enableAssociatedAssets,
      localizeLink,
      onSaveAssociatedAssets,
      associatedAssetsApiCalls,
      statsUrl,
      useDataAssetStrings
    } = this.props;
    const onClickStats = this.props.onClickStats || _.noop;
    let attachments;
    let attribution;
    let attributionLink;
    let category;
    let contactDatasetOwner;
    let customMetadataTable;
    let dataLastUpdated;
    let dateSection;
    let downloads;
    let license;
    let statsSection;
    let statsLink;
    let tags;
    const isBlobby = coreView.viewType === 'blobby';
    const isHref = coreView.viewType === 'href';
    const isMeasure = coreView.viewType === 'measure';
    const showDataLastUpdated = !isBlobby && !isHref && !isMeasure;
    const showDownloadCount = !isMeasure;
    const showStatsSection = !isMeasure;
    const ownerName = _.get(coreView, 'owner.displayName');
    const metadataLastUpdatedAt = coreView.viewLastModified;
    const resourceName = _.get(coreView, 'resourceName');

    // Mirrors view.rb#time_last_updated_at
    const lastUpdatedAt = _([
      coreView.rowsUpdatedAt,
      coreView.createdAt,
      coreView.viewLastModified
    ]).compact().max();

    const header = _.get(
      this.props,
      'header',
      this.renderHeader()
    );

    if (coreView.attribution) {
      attribution = (
        <dd className="metadata-detail-group-value">
          {coreView.attribution}
        </dd>
      );
    } else {
      attribution = (
        <dd className="metadata-detail-group-value empty">
          {I18n.t('common.metadata.no_value')}
        </dd>
      );
    }

    if (!_.isEmpty(customMetadataFieldsets)) {
      const makeRows = (fields = []) => {
        return _.map(fields, (field) => {
          const { name } = field;
          let value = field.value;
          if (_.isBoolean(value)) {
            value = value.toString();
          }

          if (_.isString(value) || _.isNumber(value) || _.isArray(value)) {
            const useLabels = FeatureFlags.value('primer_show_custom_field_label') && field.label;

            if (_.isArray(value) && useLabels) {
              // multiselect with labels
              value = value.map((v, i) => {
                const label = field.label[i];
                return label && label !== v ? `${label} (${v})` : v;
              }).join(', ');
            } else if (_.isArray(value)) {
              // multiselect without labels
              value = value.join(', ');
            } else if (useLabels && field.label !== value) {
              // single value (not multiselect) with labels
              value = `${field.label} (${value})`;
            }

            return (
              <tr key={name}>
                <td role="rowheader">{name}</td>
                <td>
                  <Linkify properties={{ rel: 'nofollow', target: '_blank' }}>
                    {value}
                  </Linkify>
                </td>
              </tr>
            );
          } else {
            return null;
          }
        });
      };
      // If the invoker has specified customMetadataFieldsets as a hash already
      // we assume they know what they're doing and wish to override our automatic behavior.
      const fieldsetsAsMap = _.isArray(customMetadataFieldsets) ?
        getFieldSetsAsMap(customMetadataFieldsets) : // "just make it work" case.
        customMetadataFieldsets; // Already flattened, user of this component has special logic.


      customMetadataTable = _.map(fieldsetsAsMap, (fieldset, fieldsetName) => {
        const tableRows = makeRows(fieldset);

        return (
          <div key={fieldsetName} className="metadata-table">
            <h3 className="metadata-table-title">
              {fieldsetName}
            </h3>

            <table className="table table-condensed table-borderless table-discrete table-striped">
              <tbody>
                {tableRows}
              </tbody>
            </table>
          </div>
        );
      });
    }

    if (coreView.metadata && !_.isEmpty(coreView.metadata.attachments)) {
      const attachmentRows = _.map(coreView.metadata.attachments, (attachment, i) => {
        const displayName = attachment.name || attachment.filename;
        const href = attachment.blobId ?
          `/api/assets/${attachment.blobId}?download=true` :
          `/api/views/${coreView.id}/files/${attachment.assetId}?download=true&filename=${attachment.filename}`;

        return (
          <tr key={i}>
            <td className="attachment">
              <span className="icon-copy-document"></span>
              <span><a href={href}>{displayName}</a></span>
            </td>
          </tr>
        );
      });

      attachments = (
        <div>
          <h3 className="metadata-table-title">
            {I18n.t('common.metadata.attachments')}
          </h3>
          <table className="table table-condensed table-borderless table-discrete table-striped">
            <tbody>
              {attachmentRows}
            </tbody>
          </table>
        </div>
      );
    }

    if (coreView.category) {
      category = <td>{_.upperFirst(coreView.category)}</td>;
    } else {
      category = (<td className="empty">{
        useDataAssetStrings ?
          I18n.t('common.metadata.no_category_value_data_asset')
          : I18n.t('common.metadata.no_category_value')
      }</td>);
    }

    if (!_.isEmpty(coreView.tags)) {
      const tagsShouldLinkToCatalog = {
        open: true,
        internal: currentUserIsSiteMember(),
        off: false
      }[FeatureFlags.value('data_catalog_audience_level')];

      const tagElements = _.map(coreView.tags, (tag, i) => {
        const tagElement = tagsShouldLinkToCatalog ?
          <a href={localizeLink(`/browse?tags=${encodeURIComponent(tag)}`)}>{tag}</a> : <span>{tag}</span>;
        return (
          <span key={i} className="tag-span-elements">
            {tagElement}
            {i === coreView.tags.length - 1 ? '' : ', '}
          </span>
        );
      });

      tags = (
        <td>
          <div className="tag-list-container collapsible">
            <div className="tag-list collapsible-content">
              {tagElements}
            </div>
          </div>
        </td>
      );
    } else {
      tags = (<td className="empty">{
        useDataAssetStrings ?
          I18n.t('common.metadata.no_tags_value_data_asset')
          : I18n.t('common.metadata.no_tags_value')
      }</td>);
    }

    // License will not be translated until we do EN-24645.
    // We do the translating in rails right now. Would need to reproduce in javascript.
    const licenseName = _.get(coreView, 'license.name');
    const licenseLink = _.get(coreView, 'license.termsLink');
    const licenseLogo = _.get(coreView, 'license.logoUrl');
    if (licenseName) {
      if (licenseLogo) {
        license = <img src={`/${licenseLogo}`} alt={licenseName} className="license" />;
      } else {
        license = licenseName;
      }

      if (licenseLink) {
        license = <a href={licenseLink} target="_blank" rel="noreferrer">{license}</a>;
      }

      license = <td>{license}</td>;
    } else {
      license = (<td className="empty">{
        useDataAssetStrings ?
          I18n.t('common.metadata.no_license_value_data_asset')
          : I18n.t('common.metadata.no_license_value')
      }</td>);
    }

    if (coreView.attributionLink) {
      attributionLink = (
        <tr>
          <td role="rowheader">{I18n.t('common.metadata.source_link')}</td>
          <td className="attribution">
            <a href={coreView.attributionLink} target="_blank" rel="nofollow external noreferrer">
              {coreView.attributionLink}
              <span className="icon-external-square" />
            </a>
          </td>
        </tr>
      );
    }

    if (statsUrl) {
      statsLink = (
        <div className="metadata-row middle">
          <a
            className="metadata-detail-group-value"
            href={localizeLink(statsUrl)}
            onClick={onClickStats}>
            {
              useDataAssetStrings ?
                I18n.t('common.metadata.view_statistics_data_asset')
                : I18n.t('common.metadata.view_statistics')
            }
          </a>
        </div>
      );
    }

    if (showDataLastUpdated) {
      dataLastUpdated = (
        <dl className="metadata-detail-group">
          <dt className="metadata-detail-group-title">
            {I18n.t('common.metadata.data_last_updated')}
          </dt>

          <dd className="metadata-detail-group-value">
            {formatDateWithLocale(moment.unix(coreView.rowsUpdatedAt), renderFullDateTimes())}
          </dd>
        </dl>
      );

      const programAnalyticsInfoTooltip = analysisId &&
        <span className="helper-tooltip-span">
          <HelperTooltip
            content={I18n.t('common.program_analytics_datasets.tooltip')}
            id="info-${uid}">
            <span className={'icon-info'} />
          </HelperTooltip>
        </span>;

      dateSection = (
        <div>
          <div className="metadata-section">
            <div className="metadata-row">
              <dl className="metadata-pair">
                <dt className="metadata-pair-key">
                  {I18n.t('common.updated')}
                </dt>

                <dd className="metadata-pair-value with-flyout">
                  {formatDateWithLocale(moment.unix(lastUpdatedAt), renderFullDateTimes())}
                </dd>

                {programAnalyticsInfoTooltip}
              </dl>
            </div>

            <div className="metadata-row middle metadata-flex metadata-detail-groups">
              {dataLastUpdated}

              <dl className="metadata-detail-group">
                <dt className="metadata-detail-group-title">
                  {I18n.t('common.metadata.metadata_last_updated')}
                </dt>

                <dd className="metadata-detail-group-value">
                  {formatDateWithLocale(moment.unix(metadataLastUpdatedAt), renderFullDateTimes())}
                </dd>
              </dl>
            </div>

            <div className="metadata-row metadata-detail-groups">
              <dl className="metadata-detail-group">
                <dt className="metadata-detail-group-title">
                  {I18n.t('common.metadata.creation_date')}
                </dt>

                <dd className="metadata-detail-group-value">
                  {formatDateWithLocale(moment.unix(coreView.createdAt), renderFullDateTimes())}
                </dd>
              </dl>
            </div>
          </div>

          <hr aria-hidden />
        </div>
      );
    }

    if (showDownloadCount && _.isFinite(coreView.downloadCount)) {
      downloads = (
        <dl className="metadata-pair download-count">
          <dt className="metadata-pair-key">
            {I18n.t('common.metadata.downloads')}
          </dt>

          <dd className="metadata-pair-value">
            {formatNumber(coreView.downloadCount)}
          </dd>
        </dl>
      );
    }

    // EN-37396: Temporarily hide measure stats as viewCount is not accurate
    statsSection = (
      <div>
        <div className="metadata-section">
          <div className="metadata-row metadata-flex">
            <dl className="metadata-pair">
              <dt className="metadata-pair-key">
                {I18n.t('common.metadata.views')}
              </dt>

              <dd className="metadata-pair-value">
                {formatNumber(coreView.viewCount)}
              </dd>
            </dl>

            {downloads}
          </div>
          {statsLink}
        </div>

        <hr aria-hidden />
      </div>
    );

    const associatedAssetsProps = {
      apiCalls: associatedAssetsApiCalls,
      isDraft: isInitialDatasetDraft(coreView),
      modalIsOpen: this.state.associatedAssetsModalIsOpen,
      onDismiss: () => { this.setState({ associatedAssetsModalIsOpen: false }); },
      onSave: (associatedAssets) => {
        onSaveAssociatedAssets(associatedAssets);
      },
      uid: coreView.id
    };
    const associatedAssetsModal = enableAssociatedAssets && <AssociatedAssets {...associatedAssetsProps} />;

    const licenseTable = (
      <div className="metadata-table">
        <h3 className="metadata-table-title">{I18n.t('common.metadata.licensing')}</h3>

        <table className="table table-condensed table-borderless table-discrete table-striped">
          <tbody>
            <tr>
              <td role="rowheader">{I18n.t('common.metadata.license')}</td>
              {license}
            </tr>

            {attributionLink}
          </tbody>
        </table>
      </div>
    );

    const showLicenseTable = !!licenseName || !!coreView.attributionLink;

    const resourceNameTable = (
      <div className="metadata-table">
        <h3 className="metadata-table-title">{I18n.t('common.metadata.resource_name')}</h3>

        <table className="table table-condensed table-borderless table-discrete table-striped">
          <tbody>
            <tr>
              <td role="rowheader">{I18n.t('common.metadata.resource_name_label')}</td>
              <td>{resourceName}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    const showResourceNameTable = !!resourceName;

    const disclaimer = this.getCommunityAssetMessage();

    // Community assets disclosure should be shown when the community badge is shown
    const shouldShowCommunityMessage = FeatureFlags.value('disable_authority_badge') == 'none' ||
      FeatureFlags.value('disable_authority_badge') == 'official2';

    return (
      <div className="metadata-table-wrapper">
        <section className="landing-page-section">
          {header}
          {shouldShowCommunityMessage && coreView.provenance == 'community' &&
            <ForgeInlineMessage>
              <ForgeIcon slot="icon" name="account_group" />
              <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
            </ForgeInlineMessage>
          }
          <div className="section-content">
            <div className="metadata-column fancy">
              {dateSection}
              {showStatsSection ? statsSection : null}

              <div className="metadata-section">
                <div className="metadata-row metadata-flex metadata-detail-groups">
                  <dl className="metadata-detail-group">
                    <dt className="metadata-detail-group-title">
                      {I18n.t('common.metadata.data_provided_by')}
                    </dt>
                    {attribution}
                  </dl>

                  <dl className="metadata-detail-group">
                    <dt className="metadata-detail-group-title">
                      {useDataAssetStrings
                        ? I18n.t('common.metadata.data_asset_owner')
                        : I18n.t('common.metadata.dataset_owner')}
                    </dt>

                    <dd className="metadata-detail-group-value">{ownerName}</dd>
                  </dl>
                </div>
                {contactDatasetOwner}
              </div>
            </div>

            <div className="metadata-column tables collapsed">
              {customMetadataTable}

              <div className="metadata-table">{attachments}</div>

              <div className="metadata-table">
                <h3 className="metadata-table-title">{I18n.t('common.metadata.topics')}</h3>

                <table className="table table-condensed table-borderless table-discrete table-striped">
                  <tbody>
                    <tr>
                      <td role="rowheader">{I18n.t('common.metadata.category')}</td>
                      {category}
                    </tr>

                    <tr>
                      <td role="rowheader">{I18n.t('common.metadata.tags')}</td>
                      {tags}
                    </tr>
                  </tbody>
                </table>
              </div>

              {showLicenseTable && licenseTable}
              {showResourceNameTable && resourceNameTable}

              <div className="metadata-table-toggle-group desktop">
                <a
                  className="metadata-table-toggle more"
                  tabIndex="0"
                  role="button"
                  onClick={this.toggleTable}
                  onKeyDown={handleInvokeKey(this.toggleTable)}
                >
                  {I18n.t('common.more')}
                </a>

                <a
                  className="metadata-table-toggle less"
                  tabIndex="0"
                  role="button"
                  onClick={this.toggleTable}
                  onKeyDown={handleInvokeKey(this.toggleTable)}
                >
                  {I18n.t('common.less')}
                </a>
              </div>

              <div className="metadata-table-toggle-group mobile">
                <button
                  className="btn btn-block btn-default metadata-table-toggle more mobile"
                  onClick={this.toggleTable}
                  onKeyDown={handleInvokeKey(this.toggleTable)}
                >
                  {I18n.t('common.more')}
                </button>

                <button
                  className="btn btn-block btn-default metadata-table-toggle less mobile"
                  onClick={this.toggleTable}
                  onKeyDown={handleInvokeKey(this.toggleTable)}
                >
                  {I18n.t('common.less')}
                </button>
              </div>
            </div>
          </div>
        </section>
        {associatedAssetsModal}
      </div>
    );
  }
}

MetadataTable.defaultProps = {
  enableAssociatedAssets: false,
  localizeLink: _.identity
};

const CustomMetadataValuePropType = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.any
});

const CustomMetadataFieldsetPropType = PropTypes.shape({
  name: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  existing_fields: PropTypes.object // The actual field value data goes in here.
});

MetadataTable.propTypes = {
  onClickStats: PropTypes.func,
  onExpandMetadataTable: PropTypes.func,
  onExpandTags: PropTypes.func,

  // Optional function to let containing app add a locale prefix to links generated
  // by MetadataTable. There's no standardized way of doing this for common code,
  // so the easiest way for now is to externalize the concern.
  localizeLink: PropTypes.func,

  // Header content. If unspecified, uses a default header
  header: PropTypes.node,

  // Href for the "view all statistics" link. Null to disable.
  statsUrl: PropTypes.string,

  showCreateAlertButton: PropTypes.bool,
  // show the "mute dataset" button if set to true.
  showMuteDatasetButton: PropTypes.bool,
  // A simple Core view metadata blob from /api/views/xxxx-yyyy.json
  // These PropTypes capture the fields actually used by the component.
  coreView: PropTypes.shape({
    tags: PropTypes.array,
    attribution: PropTypes.string,
    metadata: PropTypes.shape({
      attachments: PropTypes.array
    }),
    category: PropTypes.string,
    license: PropTypes.shape({
      name: PropTypes.string,
      logoUrl: PropTypes.string,
      termsLink: PropTypes.string
    }),
    attributionLink: PropTypes.string,
    viewType: PropTypes.string,

    rowsUpdatedAt: PropTypes.number,
    viewLastModified: PropTypes.number,
    createdAt: PropTypes.number,
    owner: PropTypes.shape({
      displayName: PropTypes.string
    }),
    viewCount: PropTypes.number,
    downloadCount: PropTypes.number,
    resourceName: PropTypes.string,
  }).isRequired,
  // Core provides this in an array, which must be merged with domain fields and the values actually set on this view.
  // DatasetHelper#custom_metadata_fieldsets does this for us. This can be provided directly to this component.
  // Alternatively, an object of fields can be passed directly (DSMP does this because it needs a little more control
  // of what fields get displayed).
  customMetadataFieldsets: PropTypes.oneOfType([
    // Array of fieldsets. Generic usage, pass in value from DatasetHelper#custom_metadata_fieldsets.
    PropTypes.arrayOf(CustomMetadataFieldsetPropType),
    // Map of field name -> array of fieldsets with values. Custom logic like what DSLP uses.
    PropTypes.objectOf(PropTypes.arrayOf(CustomMetadataValuePropType))
  ]),
  analysisId: PropTypes.string, // If view was created by Program Analytics analysis, this is the ID of that analysis

  // EN-19924: USAID special feature
  associatedAssetsApiCalls: PropTypes.array,
  enableAssociatedAssets: PropTypes.bool,
  onSaveAssociatedAssets: PropTypes.func
};

export default MetadataTable;
