import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import isEmpty from 'lodash/fp/isEmpty';
import pickBy from 'lodash/pickBy';

import { getDomain } from 'common/components/AccessManager/Util';


import { FeatureFlags } from 'common/feature_flags';
import UsersApi from 'common/users-api';
import { RIGHTS } from 'common/teams/constants';

import * as Actions from './SelectUsersByEmailListActions';



export function* searchUsersByEmailList({
  payload: { emailList, filterUserIdsFromResults, filters }
}: {
  payload: { emailList: Array<string>; filterUserIdsFromResults: Array<string>; filters: object };
}) {
  if (!isEmpty(emailList)) {
    try {
      const rights = [];
      if (FeatureFlags.value('strict_permissions')) {
        rights.push(RIGHTS.CAN_COLLABORATE);
      }
      filters = {
        ...filters,
        ...pickBy({ rights }),
        emails: emailList
      };
      const { results } = yield call(UsersApi.getUsers, getDomain(), { filters: filters });
      yield put(Actions.selectUsersByEmailListSearchResults({ userSearchResults: results, filterUserIdsFromResults }));
    } catch (error) {
      console.warn('Error searching for users', error);
    }
  }
}



export default [
  takeLatest(Actions.SELECT_USERS_BY_EMAIL_LIST_SEARCH, searchUsersByEmailList)
];
