import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';
import eq from 'lodash/fp/eq';
import isUndefined from 'lodash/fp/isUndefined';

/**
 * Spinner is a component to work around a bug in IE11 (and Edge?) where DOM
 * nodes that have an animation tied to a class, and have that class removed,
 * don't always repaint, thus causing weird animation artifacts.  By making
 * this a separate component, React will not recycle this node but instead
 * create a new one, forcing IE to repaint and not continue animating.
 * (fixes EN-24884)
 */
const Spinner = () => <div className="spinner spinner-default" />;

const TabCount = ({ count }) =>
  isUndefined(count) ? null : <div className="routing-tab-count">{count}</div>;

/**
 * Tab to render at the top of a TabbedPanel
 *
 * When clicked, this will navigate to its "to" prop.
 *
 * When using Redux to `connect` this component, be sure to pass the { pure: false } option to ensure
 * that the tab component(s) will be rerendered when redux state changes.
 */
class RoutingTab extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string
      })
    })
  };

  static propTypes = {
    // component or text to render inside of tab
    children: PropTypes.any.isRequired,

    // where this tab links to
    to: PropTypes.string.isRequired,

    // function to call to determine if this tab is the "current" tab
    // current redux state will be passed in
    computeIsCurrent: PropTypes.func,

    // whether or not to show a loading spinner
    // (i.e. if fectching the "count")
    loading: PropTypes.bool,

    // count to display next to label
    count: PropTypes.number
  };

  static defaultProps = {
    computeIsCurrent: eq
  };

  render() {
    const { children, computeIsCurrent, to, count, loading, ...props } = this.props;
    const {
      router: {
        location: { pathname }
      }
    } = this.context;
    const className = cx('tab-link', {
      current: computeIsCurrent(pathname, to)
    });

    return (
      <li className={className}>
        <Link className="routing-tab-link-anchor" to={to} {...props}>
          {children}
          {loading ? <Spinner /> : <TabCount count={count} />}
        </Link>
      </li>
    );
  }
}

export default RoutingTab;
