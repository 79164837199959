import React, { useEffect } from 'react';
import { ForgeBanner, ForgeIcon } from '@tylertech/forge-react';
import { FlashMessage as FlashMessageType } from 'common/dsmapi/ManageMetadataComponent/types';

export interface FlashMessageProps extends FlashMessageType {
  onCloseClick: () => void;
}

const FlashMessage: React.FunctionComponent<FlashMessageProps> = ({
  kind,
  message,
  helpMessage,
  helpUrl,
  onCloseClick,
  visible
}) => {
  useEffect(() => {
    const onBannerDismissed = () => {
      onCloseClick();
    };

    document.addEventListener('forge-banner-dismissed', onBannerDismissed);

    return () => {
      document.removeEventListener('forge-banner-dismissed', onBannerDismissed);
    };
  }, [onCloseClick]);

  let iconName: string | undefined;

  switch (kind) {
    case 'success': {
      iconName = 'check';
      break;
    }
    case 'error': {
      iconName = 'warning';
      break;
    }
    case 'warning': {
      iconName = 'warning';
      break;
    }
    case 'href': {
      // No icon for href
      break;
    }
    default: {
      iconName = 'info';
      break;
    }
  }

  if (visible) {
    return (
      <div id="flash-message-base-forge">
        <ForgeBanner theme={kind} canDismiss={true}>
          {iconName && (
            <ForgeIcon name={iconName} slot="icon" data-testid={`flash-message-${iconName}-icon`} />
          )}
          {helpMessage && helpUrl ? (
            <span className="message">
              {message}{' '}
              <a href={helpUrl} target="_blank" rel="noreferrer">
                {helpMessage}
              </a>
            </span>
          ) : (
            <span className="message">{message}</span>
          )}
        </ForgeBanner>
      </div>
    );
  } else {
    return null;
  }
};

export default FlashMessage;
