import React from 'react';

import SocrataIcon from 'common/components/SocrataIcon';
import Button from 'common/components/Button';
import GateInfoModal from 'common/components/GateInfoModal';

import './gated-icon.scss';

class GatedIcon extends React.Component {
  state = {
    modalHidden: true
  };

  showModal = () => this.setState({ modalHidden: false });
  hideModal = () => this.setState({ modalHidden: true });

  render() {
    const { allowedUsers, reason, created } = this.props.gate;

    return (
      <div>
        {this.state.modalHidden ||
          <GateInfoModal allowedUsers={allowedUsers}
                         reason={reason}
                         created={created}
                         onDismiss={this.hideModal} />
        }
        <Button variant="transparent" onClick={this.showModal} className="gated-icon">
          <SocrataIcon name="private" />
        </Button>
      </div>
    );
  }
}

export default GatedIcon;
