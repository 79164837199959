/* Imports
================================================================================ */
import I18n from 'common/i18n/index';

/* Predefined Values
================================================================================ */
export type TranslateMethod = ((key: string, params?: Record<string, any>) => string)
  & { noKeyErrorText: string, translationScope: string, getScopedTranslateMethod: GetScopedTranslateMethod };
export type GetScopedTranslateMethod = (baseScope?: string, additionalScope?: string) => TranslateMethod;

/**
 * Returns a I18n translated string within a provided scope. Refer to the
 * README for examples.
 * @param baseScope (Optional) The base scope string to which to bind the
 * returned function.
 * @param additionalScope String to be added to the base scope to provide
 * additional scoping
 * @returns (TranslateMethod) A translate function which is bound to the base +
 * additional scopes provided.
 */
const getScopedTranslateMethod: GetScopedTranslateMethod = (baseScope = '', additionalScope = '') => {
  const translationScope = baseScope + (additionalScope ? '.' + additionalScope : '');
  const translateMethod: TranslateMethod = Object.assign(
    function (key: string, params: Record<string, any> = {}) {
      if (!key) throw new Error(translateMethod.noKeyErrorText);

      return I18n.t(key, { ...params, scope: translateMethod.translationScope });
    },
    {
      noKeyErrorText: `No translation key provided to translate method with scope '${translationScope}'`,
      translationScope: translationScope,
      getScopedTranslateMethod: getScopedTranslateMethod.bind(null, translationScope)
    }
  );

  return translateMethod;
};

export default getScopedTranslateMethod;
