import React, { FunctionComponent } from 'react';

import './index.scss';

interface Props {
  content?: string;
  'aria-live'?: 'off' | 'assertive' | 'polite';
}

const ScreenReaderOnly: FunctionComponent<Props> = ({content, ['aria-live']: ariaLive}) => {
  return (
    <div className='screenreader-only' aria-live={ariaLive ? ariaLive : 'polite'}>
      {content}
    </div>
  );
};

export default ScreenReaderOnly;
