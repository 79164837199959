import React from 'react';
import { ForgeOption, ForgeSelect } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { lookupLabel, fieldValueForCref } from '../utils/helpers';
import {
  FieldT,
  FieldValue,
  SelectComponents,
  SelectWithParentComponents
} from 'common/types/metadataTemplate';
import { ColumnRef } from 'common/types/soql';
import PrivateFieldIcon from 'common/FormatColumn/PrivateFieldIcon/PrivateFieldIcon';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.dataset_tab') =>
  I18n.t(key, { scope });

type SelectFieldComponents = SelectComponents | SelectWithParentComponents;

export interface SelectInputProps {
  instance: FieldT;
  value: FieldValue;
  selectComponents: SelectFieldComponents;
  inErrorState: boolean;
  isRequired: boolean;
  isRestrictedForUser: boolean;
  isPrivate: boolean;
  setHasReceivedInput: () => void;
  onUpdateField: (cref: ColumnRef, value: any) => void;
}

const SelectInput: React.FunctionComponent<SelectInputProps> = ({
  instance,
  value,
  selectComponents,
  inErrorState,
  isRequired,
  isRestrictedForUser,
  isPrivate,
  setHasReceivedInput,
  onUpdateField
}) => {
  const options = selectComponents.options.map((optionValue, index) => (
    <ForgeOption key={index} value={optionValue}>
      {lookupLabel(index, instance, optionValue)}
    </ForgeOption>
  ));

  return (
    <ForgeSelect
      disabled={isRestrictedForUser}
      label={instance.display_name}
      value={fieldValueForCref(value.inputs, selectComponents.inputCref).getOrElseValue('')}
      invalid={inErrorState}
      required={isRequired}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        onUpdateField(selectComponents.inputCref, event.currentTarget.value);
        setHasReceivedInput();
      }}
    >
      {isPrivate && (
        <span slot="leading">
          <PrivateFieldIcon />
        </span>
      )}
      {[
        <ForgeOption key={'top-option'} value={''}>
          {t('select_a_value')}
        </ForgeOption>,
        ...options
      ]}
      {isRestrictedForUser && <span slot="helper-text">{t('subtitles.restricted_field')}</span>}
    </ForgeSelect>
  );
};

export default SelectInput;
