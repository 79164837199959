import { RevisionMetadata, Revision } from 'common/types/revision';
import { socrataFetchAndCheck } from '../../helpers/fetchHelpers';

// TODO: Looks like the standard Revision Type doesn't cover the fact
// that the created_at can be a string...
interface ReceivedRevision extends Omit<Revision, 'created_at'> {
  created_at: string | Date;
}

const parseDate = (dateString: string | Date): Date => {
  if (typeof dateString === 'string' && dateString.indexOf('Z') === -1) {
    return new Date(dateString);
  }
  return new Date(dateString);
};

export const updateRevision = async (
  metadata: RevisionMetadata,
  fourfour: string,
  revisionSeq: number
): Promise<Revision> => {
  const dsmapiUrl = `/api/publishing/v1/revision/${fourfour}/${revisionSeq}`;
  const updateBody = JSON.stringify({ metadata, attachments: metadata.attachments });

  const { resource: revision }: { resource: ReceivedRevision } = await socrataFetchAndCheck(dsmapiUrl, {
    method: 'PUT',
    body: updateBody
  });
  return {
    ...revision,
    created_at: parseDate(revision.created_at)
  };
};
