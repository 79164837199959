import React from 'react';
import Stepper from './Stepper';
import {
  WithStepperOptions
} from './types';

/**
 * TODO: make this not a higher order component. There's no need for it to be one. Most users of it
 * are making new classes on each render when there's no need. also it breaks state tracking since
 * things get destroyed/created unnecessarily :(
 * This can just a be a regular old component.
 *
 * Higher Order Component which creates a modal with the specified steps and provides utilities
 * for managing state between steps and navigating between steps.
 *
 * A simple example would look like:
 *   function StepOne { ... }
 *   function StepTwo { ... }
 *   const steps = [{ title: 'one', component: StepOne}, { title: 'two', component: StepTwo }];
 *   const Stepper = withStepper(steps, () => {}, 'Some Title');
 *   <Stepper />
 */
function withStepper(props: WithStepperOptions) {
  return (otherProps: any): React.ReactElement => (
    <Stepper {...props} />
  );
}

export default withStepper;
