/**
 * This file's default export is a generic form reducer that handles the following:
 * - Input updates
 * - Recaptcha callback
 * - Required input validation
 * - Full form validation
 *
 * See FormReduxUtils for utility functions for generating a default state that works well with this reducer.
 *
 * If you require something more specific, everything in here is exported and can be used in other reducers.
 */
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import reduce from 'lodash/reduce';
import isFunction from 'lodash/isFunction';
import every from 'lodash/every';
import update from 'lodash/fp/update';
import set from 'lodash/fp/set';
import flow from 'lodash/fp/flow';

import I18n from 'common/i18n';

import { FormState, InputState, Validation, Validations } from './types';
import {
  INPUT_CHANGED,
  InputChangedAction,
  RECAPTCHA_CALLBACK,
  RecaptchaCallbackAction,
  VALIDATE_INPUT,
  ValidateInputAction,
  VALIDATE_FORM,
  ValidateFormAction,
  CHECKBOX_CHANGED,
  FormActionTypes
} from './FormReduxActions';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

/**
 * Called every time an input changes (i.e. typing in a textbox)
 * @param {string} valueIdentifier Key to use in object to check for value (i.e. 'checked' for checkboxes)
 */
export const inputChanged = (
  state: FormState,
  { payload: { name, value } }: InputChangedAction,
  valueIdentifier: 'value' | 'checked' = 'value'
): FormState =>
  // if the value has changed, we re-mark it as valid and clear the errorMessage
  // (when the user blurs from the input, it will re-run the validation)
  update(
    `inputs['${name}']`,
    (input: InputState): InputState => {
      const valid = input[valueIdentifier] !== value;
      return {
        ...input,
        [valueIdentifier]: value,
        valid,
        errorMessage: valid ? '' : input.errorMessage
      };
    }
  )(state);

/**
 * Validates that the recaptcha has been passed
 * If it hasn't been passed, sets the recaptcha input to invalid which will show an error message
 */
export const validateRecaptcha = update('inputs.recaptcha.valid', (valid): boolean => valid || false);

/**
 * Callback when the recaptcha is clicked, or expires
 * If action.response is empty it means that the recaptcha is changing from valid -> invalid
 */
export const recaptchaCallback = (
  state: FormState,
  { payload: { response } }: RecaptchaCallbackAction
): FormState => set('inputs.recaptcha.valid', !isEmpty(response))(state);

const reduceObjectValues = (validations: Validations, state: FormState): FormState =>
  reduce(
    validations,
    (reducedState: FormState, fn: Validation): FormState =>
      isFunction(fn) ? fn(reducedState) : reducedState,
    {
      ...state
    }
  );

/**
 * Given an input, will validate that it is non-empty if it is required.
 * NOTE: This mutates the input given as a parameter.
 *
 * @param input Input to validate
 */
const validateRequiredInput = (input: InputState) => {
  const requiredInputErrorMessage = I18n.t('core.validation.enter_a_value');

  if (input.required) {
    // required checkboxes have to be checked
    if (input.type === 'checkbox') {
      if (input.checked !== true) {
        input.valid = false;
        input.errorMessage = requiredInputErrorMessage;
      }
    } else if (isEmpty(input.value)) {
      input.valid = false;
      input.errorMessage = requiredInputErrorMessage;
    }
  }
};

/**
 * Validates that all inputs in the state that are marked as required have been filled in
 */
export const validateRequiredInputs = (state: FormState): FormState => {
  const inputs = { ...state.inputs };

  forOwn(inputs, (input) => validateRequiredInput(input));

  return { ...state, inputs };
};

/**
 * Validate the entire form
 *
 * This will run the onValidate function for every input in the state, and make sure all required
 * inputs have been filled in.
 *
 * If the form passes validation, an optional action.callback function will be called
 * (useful for submitting the form when validation passes)
 */
export const validateForm = (
  state: FormState,
  { payload: { callback, event } }: ValidateFormAction,
  validations: Validations
): FormState => {
  // run all the validations
  const validatedState = flow([reduceObjectValues, validateRequiredInputs])(
    {
      ...validations,
      // if we have a recaptcha, validate that too
      recaptcha: state.inputs.recaptcha && validateRecaptcha
    },
    state
  ) as FormState;

  const { inputs } = validatedState;

  // invalidate form if any inputs are invalid
  const isFormValid = every(inputs, ({ valid }): boolean => !!valid);

  if (isFormValid) {
    validatedState.formSubmitted = true;

    // call the callback if the form is valid and we have a callback
    if (callback) {
      callback(validatedState.inputs);
    }
  } else {
    const invalidInputName = Object.keys(inputs).find((input) => !inputs[input].valid);

    // find our invalid input and focus on it
    // this is mainly so that screen readers will read the error message out loud
    // NOTE: The `Form` component will also do this, but only `onSubmit` which gets called _before_
    // all of the validation in this reducer happens.
    //
    // The "recaptcha" input is special and is rendered in an iframe, so we can't focus on it...
    if (invalidInputName && invalidInputName !== 'recaptcha') {
      const domNode = event.currentTarget.querySelectorAll<HTMLInputElement>(
        `input[name="${invalidInputName}"]`
      );

      if (domNode.length !== 0) {
        domNode[0].focus();
      } else {
        console.error(
          `Tried to focus on input named ${invalidInputName} but no input with that name was found!`
        );
      }
    }
  }

  return validatedState;
};

/**
 * Validates one specific input using the validations passed in when creating the reducer
 */
export const validateInput = (
  state: FormState,
  { payload: { name } }: ValidateInputAction,
  validations: Validations
): FormState => {
  const validation = validations[name];

  let newState = { ...state };

  // only run a validation for an input if we actually have one...
  if (validation) {
    newState = validation(state);
  }

  // if the input is required, also validate that it's not empty
  // we only run this if the previous validation passed
  const input = newState.inputs[name];
  if (input.required && input.valid) {
    validateRequiredInput(input);
    newState.inputs[name] = { ...input };
  }

  return newState;
};

/*
 * "validations" should be an object with a map of input name -> validation function
 *
 * The validations are called automatically on blur and when the form is submitted.
 * If any inputs fail validation, the form does not submit.
 *
 * The validation functions must take in the current redux state, and return a changed (or the same) state.
 *
 * Validations are passed in this way in an attempt to avoid putting functions into the redux state.
 */
export default (validations = {}): ((state: FormState, action: FormActionTypes) => FormState) => (
  state: FormState,
  action: FormActionTypes
): FormState => {
  switch (action.type) {
    case INPUT_CHANGED:
      return inputChanged(state, action as InputChangedAction);
    case CHECKBOX_CHANGED:
      return inputChanged(state, action as InputChangedAction, 'checked');
    case VALIDATE_INPUT:
      return validateInput(state, action as ValidateInputAction, validations);
    case VALIDATE_FORM:
      return validateForm(state, action as ValidateFormAction, validations);
    case RECAPTCHA_CALLBACK:
      return recaptchaCallback(state, action as RecaptchaCallbackAction);
    default:
      return state;
  }
};
