import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';

import * as filters from 'common/components/AssetBrowser/actions/filters';

export class EnrolledInArchivalFilter extends Component {
  render() {
    const { enrolledInArchival, toggleEnrolledInArchival } = this.props;

    const inputId = 'filter-enrolled-in-archival';

    return (
      <div className="filter-section enrolled-in-archival">
        <div className="checkbox checkbox-filter">
          <input id={inputId} type="checkbox" onChange={toggleEnrolledInArchival} checked={enrolledInArchival} />
          <label htmlFor={inputId}>
            <span className="fake-checkbox">
              <SocrataIcon name="checkmark3" />
            </span>
            {I18n.t('shared.asset_browser.filters.enrolled_in_archival.label')}
          </label>
        </div>
      </div>
    );
  }
}

EnrolledInArchivalFilter.propTypes = {
  enrolledInArchival: PropTypes.bool,
  toggleEnrolledInArchival: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  enrolledInArchival: state.filters.enrolledInArchival
});

const mapDispatchToProps = (dispatch) => ({
  toggleEnrolledInArchival: (value) => dispatch(filters.toggleEnrolledInArchival(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrolledInArchivalFilter);
