import 'ace-builds/src-noconflict/mode-text';

/**
 * This is derived from the default SQL mode for the ace editor
 * See https://github.com/ajaxorg/ace/blob/master/lib/ace/mode/sql_highlight_rules.js for the original
 */
export class SoqlHighlightRules extends window.ace.acequire('ace/mode/text_highlight_rules').TextHighlightRules {
  constructor() {
    super();

    // Note that Ace isn't case sensitive so these actually all show up as lowercase in autocomplete :(
    const keywords = [
      'SELECT',
      'WHERE',
      'ORDER',
      'GROUP',
      'BY',
      'HAVING',
      'DESC',
      'ASC',
      'BETWEEN',
      'AND',
      'OR',
      'NOT',
      'IN',
      'JOIN',
      'AS',
      'ON',
      'IS',
      'LEFT',
      'FULL',
      'OUTER',
      'DISTINCT'
    ].join('|');

    const builtinConstants = 'true|false|$id|null';

    const builtinFunctions = [
      'avg',
      'case',
      'coalesce',
      'convex_hull',
      'count',
      'date_extract_d',
      'date_extract_dow',
      'date_extract_hh',
      'date_extract_m',
      'date_extract_mm',
      'date_extract_ss',
      'date_extract_woy',
      'date_extract_y',
      'date_trunc_y',
      'date_trunc_ym',
      'date_trunc_ymd',
      'distance_in_meters',
      'extent',
      'intersects',
      'like',
      'lower',
      'max',
      'min',
      'num_points',
      'simplify',
      'simplify_preserve_topology',
      'starts_with',
      'stddev_pop',
      'stddev_samp',
      'sum',
      'upper',
      'within_box',
      'within_circle',
      'within_polygon'
    ].join('|');

    const keywordMapper = this.createKeywordMapper(
      {
        'support.function': builtinFunctions,
        'keyword': keywords,
        'constant.language': builtinConstants
      },
      'identifier',
      true
    );

    this.$rules = {
      'start' : [
        {
          // " string
          token : 'string',
          regex : '".*?"'
        },
        {
          // ' string
          token : 'string',
          regex : "'.*?'"
        },
        {
          // ` string
          token : 'string',
          regex : '`.*?`'
        },
        {
          // float
          token : 'constant.numeric',
          regex : '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b'
        },
        {
          // keywords listed above
          token : keywordMapper,
          regex : '[a-zA-Z_$][a-zA-Z0-9_$]*\\b'
        },
        {
          // operators
          token : 'keyword.operator',
          regex : '\\+|\\-|\\/|\\/\\/|%|&|\\^|~|<|>|<=|=>|==|!=|<>|='
        },
        {
          // left parenthesis
          token : 'paren.lparen',
          regex : '[\\(]'
        },
        {
          // right parenthesis
          token : 'paren.rparen',
          regex : '[\\)]'
        },
        {
          // any old text
          token : 'text',
          regex : '\\s+'
        }
      ]
    };

    this.normalizeRules();
  }
}

export default class SoQLMode extends window.ace.acequire('ace/mode/text').Mode {
  constructor() {
    super();
    this.HighlightRules = SoqlHighlightRules;
  }
}
