import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LabeledIconProps } from './types';

import SocrataIcon from 'common/components/SocrataIcon';

import './index.scss';

export function LabeledIcon({
  name,
  labelText,
  className,
  otherAttributes
}: LabeledIconProps): ReactElement {

  const socrataIconProps = { name };

  const classes = classNames('labeled-icon', className);
  // labelled icons are "decorative" (aria-hidden) because they always have a textual label
  return (
    <div className={classes} {...otherAttributes}>
      <SocrataIcon decorative={true} {...socrataIconProps} />
      <span className="labeled-icon-text">{labelText}</span>
    </div>
  );
}

export default LabeledIcon;
