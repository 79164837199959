import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import airbrake from 'common/airbrake';
import { defaultHeaders, checkStatus } from 'common/http';
import I18n from 'common/i18n';
import { fetchApprovalsGuidanceV2 } from 'common/core/approvals/index_new';

import { coreUidFromAssetId, revisionSeqFromCatalogId } from 'common/cetera/catalog_id';
import ActionItemList from './action_item_list';

export class ActionDropdown extends Component {
  state = {
    fetchedApprovalsGuidance: null,
    currentModal: null,
    dropdownIsOpen: false,
    failedViewFetch: false,
    fetchingViewAndGuidance: false,
    viewFetched: false
  };

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, false);
  }

  getTranslation = (key) => {
    return I18n.t(`shared.asset_browser.result_list_table.action_dropdown.${key}`);
  };

  /**
   * (Most) action items will call this to set the currently rendered modal
   * An "onDismiss" is passed into the modal that can be called to close it
   */
  setCurrentModal = (Modal, modalProps) => {
    const { view } = this.state;

    this.setState({
      currentModal: <Modal view={view} onDismiss={this.closeCurrentModal} {...modalProps} />,
      dropdownIsOpen: false
    });
  };

  closeCurrentModal = () => {
    this.setState({ currentModal: null });
  };

  fetchViewAndGuidance = async () => {
    const { uid: catalogId } = this.props;
    const fourByFour = coreUidFromAssetId(catalogId);
    const fetchOptions = {
      method: 'GET',
      credentials: 'same-origin',
      headers: defaultHeaders
    };

    this.setState({
      fetchingViewAndGuidance: true
    });

    const guidancePromise = fetchApprovalsGuidanceV2(catalogId);
    const viewMetadataPromise = fetch(`/api/views/${fourByFour}.json`, fetchOptions)
      .then(checkStatus)
      .then((response) => response.json());

    try {
      this.setState({
        fetchingViewAndGuidance: false,
        viewFetched: true,
        view: await viewMetadataPromise,
        fetchedApprovalsGuidance: await guidancePromise
      });
    } catch (error) {
      console.error('Error fetching view or guidance', error);
      airbrake.notify({ error });
      this.setState({
        dropdownIsOpen: false,
        failedViewFetch: true,
        fetchingViewAndGuidance: false
      });
    }
  };

  setNeedsViewUpdate = () => {
    this.setState({ viewFetched: false });
  };

  handleDocumentClick = (event) => {
    if (this.domNode && !this.domNode.contains(event.target)) {
      this.setState({ dropdownIsOpen: false });
    }
  };

  handleButtonClick = (event) => {
    const { dropdownIsOpen, failedViewFetch, viewFetched } = this.state;
    const { isCatalogFederatedView, isDataFederatedView } = this.props;

    event.stopPropagation();
    event.preventDefault();

    // NOTE: if this is federated, the only actions available are "Go to source",
    // which is available from the props already passed to this, so we do not
    // fetch guidance or the view object.
    if (
      !isCatalogFederatedView &&
      !isDataFederatedView &&
      viewFetched === false &&
      failedViewFetch === false
    ) {
      this.fetchViewAndGuidance();
    }

    this.setState({ dropdownIsOpen: dropdownIsOpen === false });
  };

  renderContent = () => {
    const { fetchedApprovalsGuidance, failedViewFetch, fetchingViewAndGuidance, view } = this.state;
    const {
      uid,
      link,
      updateAudience,
      transferOwnership,
      type,
      isCatalogFederatedView,
      isDataFederatedView,
      sourceLink
    } = this.props;

    if (fetchingViewAndGuidance) {
      // render a spinner
      return (
        <div className="action-dropdown-spinner-container">
          <span className="spinner-default"></span>
        </div>
      );
    } else {
      // show an error if we failed to fetch the view
      if (failedViewFetch) {
        return <div className="error-message">{this.getTranslation('permissions_error')}</div>;
      }

      const isRevision = !!(uid && uid.match(/^\w{4}-\w{4}:\d+$/));
      const isStoryDraft = !!(uid && uid.match(/^\w{4}-\w{4}:draft$/));
      const revision = isRevision ? { revisionSeq: revisionSeqFromCatalogId(uid), link } : null;

      // render the list of possible actions
      return (
        <ActionItemList
          approvalsGuidance={fetchedApprovalsGuidance}
          view={view}
          revisionInfo={revision}
          setCurrentModal={this.setCurrentModal}
          closeCurrentModal={this.closeCurrentModal}
          assetIsStoryDraft={isStoryDraft}
          assetType={type}
          updateView={this.setNeedsViewUpdate}
          updateAudience={updateAudience}
          transferOwnership={transferOwnership}
          isCatalogFederatedView={isCatalogFederatedView}
          isDataFederatedView={isDataFederatedView}
          sourceLink={sourceLink}
          link={link}
        />
      );
    }
  };

  // button that actually opens the action item list
  renderButton = (dropdownIsOpen) => (
    <button
      aria-label={this.getTranslation('title')}
      className={classNames('action-dropdown-button', { active: dropdownIsOpen })}
      onClick={this.handleButtonClick}
      role="button"
    >
      <span className="socrata-icon-waiting" alt={this.getTranslation('title')} />
    </button>
  );

  render() {
    const { dropdownIsOpen, currentModal } = this.state;

    if (this.props.type == 'system_dataset') {
      return null;
    }

    return (
      <div className="action-dropdown" ref={(domNode) => (this.domNode = domNode)}>
        {this.renderButton(dropdownIsOpen)}

        {dropdownIsOpen && <div className="action-dropdown-content">{this.renderContent()}</div>}
        {currentModal}
      </div>
    );
  }
}

ActionDropdown.propTypes = {
  uid: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  updateAudience: PropTypes.func,
  transfer_ownership: PropTypes.func,
  type: PropTypes.string,
  isCatalogFederatedView: PropTypes.bool,
  isDataFederatedView: PropTypes.bool
};

export default ActionDropdown;
