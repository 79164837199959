import React from 'react';

import Button from 'common/components/Button';
import GateInfoModal from 'common/components/GateInfoModal';

class GatedButton extends React.Component {
  state = {
    modalHidden: true
  };

  showModal = () => this.setState({ modalHidden: false });
  hideModal = () => this.setState({ modalHidden: true });

  render() {
    const {
      children,
      allowedUsers,
      reason,
      created,
      onClick,
    } = this.props;

    const onClickSuccess = () => onClick() && this.hideModal();
    const ConfirmButton = () => <Button variant="primary" onClick={onClickSuccess}>{children}</Button>;

    const modalProps = {
      allowedUsers,
      reason,
      created,
      onDismiss: this.hideModal,
      ConfirmButton
    };

    return (
      <div>
        {this.state.modalHidden || <GateInfoModal {...modalProps} />}
        <Button onClick={this.showModal}>{children}</Button>
      </div>
    );
  }
}

export default GatedButton;
