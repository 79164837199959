import React from 'react';
import _ from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sh';
import 'ace-builds/src-noconflict/theme-monokai';
import PendoIdWrapper, { DisplayStyle } from 'common/components/PendoIdWrapper';

import NavHelpModal from './NavHelpModal';
import { getName } from './lib';
import { ProvisionPluginProps } from './types';
import { fetchTranslation } from 'common/locale';
const t = (k: string) => fetchTranslation(k, 'shared.components.setup_plugin.instructions');

interface RunPluginState {
  showNavHelpModal: boolean;
}

class RunPlugin extends React.Component<ProvisionPluginProps, RunPluginState> {
  state = { showNavHelpModal: false };

  componentDidMount() {
    const { handleStepStateChange } = this.props;
    handleStepStateChange({
      copied: false,
      disableNext: true
    });
  }

  handleCopy = () => {
    const { handleStepStateChange } = this.props;
    handleStepStateChange({
      copied: true,
      disableNext: false
    });
  };

  toggleNavHelpModal = () => {
    const { showNavHelpModal } = this.state;
    this.setState({
      showNavHelpModal: !showNavHelpModal
    });
  };

  render() {
    const { pageOptions, stepState, stepIdx } = this.props;
    const { showNavHelpModal } = this.state;
    const plugin = pageOptions.plugin;
    const name = getName(stepState);
    const cleanedName = name.replace(/([^A-Za-z0-9])+/g, '_');
    const copyText = `java -jar socrata-ingress-agent.jar --configure-plugin ${plugin.type}:${cleanedName}`;
    const copied = _.get(stepState, `step-${stepIdx}-state.copied`, false);
    const copyButtonText = copied ? t('copied') : t('copy');
    const copyButtonClass = copied ? 'btn-success' : 'btn-primary';

    const defaultEditorProps = {
      width: '100%',
      minLines: 2,
      maxLines: 5,
      fontSize: 15,
      showGutter: false,
      showPrintMargin: false,
      name: 'setup-plugin-ace-editor',
      focus: true,
      readOnly: true,
      theme: 'monokai'
    };

    return (
      <div className="provision-plugin-step">
        <h5>{t('title')}</h5>
        <div className="stepper-content">
          <ol>
            <li>{t('note_file_path')}</li>
            <li>
              {t('open_terminal')}
              <a className="nav-help" onClick={() => this.toggleNavHelpModal()}>
                {` ${t('how_do')}`}
              </a>
            </li>
            <li>
              {t('copy_command')}
              <div className="inmodal-code-window">
                <PendoIdWrapper id="run-plugin-command" displayStyle={DisplayStyle.BLOCK}>
                  <CopyToClipboard text={copyText} onCopy={this.handleCopy}>
                    <button className={`btn btn-xs copy-btn ${copyButtonClass}`}>{copyButtonText}</button>
                  </CopyToClipboard>
                </PendoIdWrapper>
                <AceEditor {...defaultEditorProps} mode="sh" readOnly={true} value={copyText} />
              </div>
            </li>
            <li>
              <p>{t('run_command')}</p>
              <p>{t('command_info')}</p>
            </li>
            <li>
              <p dangerouslySetInnerHTML={{ __html: t('config_info') }} />
            </li>
            <li>
              <p>{t('fill_in_config')}</p>
            </li>
          </ol>
        </div>
        {showNavHelpModal ? <NavHelpModal close={() => this.toggleNavHelpModal()} /> : null}
      </div>
    );
  }
}

export default RunPlugin;
