/* eslint react/prop-types: 0 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TypedCell from './TypedCell';
import EditCell from './EditCell';
import { commaify } from 'common/formatNumber';
import BigNumber from 'bignumber.js';
import { CURRENCY_SYMBOLS } from 'common/DataTypeFormatter';

class NumberCell extends Component {
  getPrecision() {
    // core stores precision as a string O_o
    return _.toNumber(this.props.format.precision);
  }

  rawNumber() {
    return new BigNumber(this.props.value);
  }

  // this returns a string
  toFixed() {
    const num = this.rawNumber();
    if (_.isInteger(this.getPrecision())) {
      return num.toFixed(this.getPrecision());
    }
    return `${num}`;
  }

  toText() {
    // commaify has ',' as group separator default
    // and '.' as decimal separator default
    const { format } = this.props;
    // format.noCommas sometimes appears as the string 'false' which was formerly being considered truthy
    const useCommas = (_.isString(format.noCommas) && format.noCommas.toLowerCase() === 'false') || !format.noCommas;
    return commaify(this.toFixed(), useCommas ? format.groupSeparator : '', format.decimalSeparator);
  }

  render() {
    return <TypedCell isDropping={this.props.isDropping} value={this.toText()} format={this.props.format} />;
  }
}

NumberCell.propTypes = {
  isDropping: PropTypes.bool,
  value: PropTypes.string.isRequired,
  format: PropTypes.shape({
    precisionStyle: PropTypes.string
  })
};

class ScientificCell extends NumberCell {
  render() {
    const decimalPlaces = this.props.format.decimalPlaces;
    const num = this.rawNumber();
    let text = '';
    if (decimalPlaces !== undefined) {
      text = num.toExponential(decimalPlaces);
    } else {
      text = num.toExponential();
    }

    return <TypedCell isDropping={this.props.isDropping} value={text} format={this.props.format} />;
  }
}

class PercentageCell extends NumberCell {
  rawNumber() {
    return super.rawNumber().times(this.props.format.percentScale === '1' ? 100 : 1);
  }

  render() {
    const text = `${this.toText()}%`;
    return <TypedCell isDropping={this.props.isDropping} value={text} format={this.props.format} />;
  }
}


class CurrencyCell extends NumberCell {
  getPrecision() {
    const p = super.getPrecision();
    // default to 2 if there is no precision specified
    return _.isInteger(p) ? p : 2;
  }

  render() {
    // default to USD
    const currency = CURRENCY_SYMBOLS[this.props.format.currencyStyle] || '$';
    const text = `${currency}${this.toText()}`;
    return <TypedCell isDropping={this.props.isDropping} value={text} format={this.props.format} />;
  }
}

class FinancialCell extends NumberCell {
  getPrecision() {
    const p = super.getPrecision();
    // default to 2 if there is no precision specified
    return _.isInteger(p) ? p : 2;
  }

  render() {
    return <TypedCell isDropping={this.props.isDropping} value={this.toText()} format={this.props.format} />;
  }
}

const numbers = {
  scientific: ScientificCell,
  percentage: PercentageCell,
  currency: CurrencyCell,
  financial: FinancialCell
};

export default function renderNumber(props) {
  if (props.isEditing) {
    return <EditCell value={props.value} updateCell={props.updateCell} />;
  }
  if (props.value === null) {
    return <TypedCell isDropping={props.isDropping} value={''} format={props.format} />;
  }
  return React.createElement(numbers[props.format.precisionStyle] || NumberCell, props);
}
