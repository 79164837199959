import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import I18n from 'common/i18n';
import { handleKeyPress } from 'common/dom_helpers/keyPressHelpers';

import * as selectionActions from 'common/components/AssetBrowser/actions/selection';

export class ResultListRowSelectButton extends Component {
  static propTypes = {
    clearSelectedAsset: PropTypes.func.isRequired,
    closeOnSelect: PropTypes.bool,
    isSelected: PropTypes.bool.isRequired,
    // Callback instantiator prop for what to do when an asset is selected
    onAssetSelected: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    // Updates redux store with the selected asset
    selectAsset: PropTypes.func.isRequired,
    // UID of the current row's asset
    uid: PropTypes.string.isRequired
  };

  onClick = () => {
    const { clearSelectedAsset, isSelected, onAssetSelected, selectAsset } = this.props;

    if (isSelected) {
      // User clicked the asset they already selected, clear selection.
      clearSelectedAsset();
    } else {
      // Select a new asset, clearing any previous selection.
      selectAsset(this.ceteraResultProps());

      onAssetSelected(this.ceteraResultProps());

      if (this.props.closeOnSelect) {
        this.props.onClose();
      }
    }
  };

  /**
   * @return {
   *   name: string,
   *   description: string,
   *   domain: string Where the asset was created,
   *   updatedAt: string,
   *   viewCount,
   *   displayType: string Corresponds to Catalog Search's types, not Core's,
   *   id: string 4x4,
   *   imageUrl: string Preview image url which only exists for viz (and even then is sparse),
   *   isPrivate: boolean,
   *   url: string Link to the asset on the domain which it should be accessed on (for internal to public federation, this is the current domain)
   * }
   */
  ceteraResultProps = () => _.merge(
    _.pick(this.props, 'name', 'description', 'domain', 'updatedAt', 'viewCount'),
    {
      displayType: this.props.type,
      id: this.props.uid,
      imageUrl: this.props.previewImageUrl,
      isPrivate: !this.props.isPublic,
      url: this.props.link
    }
  );

  render() {
    const { isSelected } = this.props;

    const scope = 'shared.asset_browser.result_list_table';

    const buttonClassNames = classNames('select-button btn btn-primary btn-sm', {
      'btn-inverse': !isSelected
    });

    const buttonTextLocaleString = isSelected ? 'select_mode.selected' : 'select_mode.select';

    return (
      <div
        className="result-list-row-select-button"
        role="button"
        tabIndex={0}
        onClick={this.onClick}
        onKeyDown={handleKeyPress(this.onClick)}>
        <button
          alt={`${I18n.t('select_mode.select', { scope })} ${this.props.name}`}
          type="button"
          className={buttonClassNames}>
          {I18n.t(buttonTextLocaleString, { scope })}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isSelected: _.get(state, 'selection.selectedAsset.id') === props.uid
});

const mapDispatchToProps = dispatch => ({
  clearSelectedAsset: () => dispatch(selectionActions.clearSelectedAsset()),
  selectAsset: (asset) => dispatch(selectionActions.selectAsset(asset))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultListRowSelectButton);
