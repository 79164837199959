import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { SocrataIcon } from '../SocrataIcon';
import Scrolls from './Scrolls';

interface IAccordionPaneProps extends PropsWithChildren<any> {
  className?: string;
  title: string;
  onToggle?: (paneId: string) => void;
  onOpen?: () => void;
  isOpen?: boolean;
  paneId?: string;
  toggleOnCaretOnly?: boolean;
}

class AccordionPane extends React.Component<IAccordionPaneProps> {
  shouldScroll: boolean;
  paneTitleElement: HTMLElement | null;

  public static defaultProps = {
    onToggle: _.noop,
    onOpen: _.noop,
    isOpen: false,
    toggleOnCaretOnly: false
  };

  constructor(props: IAccordionPaneProps) {
    super(props);

    this.shouldScroll = false;
  }

  handleKeyDown = (event: React.KeyboardEvent) => {
    const firstPaneTitle = document.querySelector(
      '.customization-tab-pane:not(.customization-tab-pane_hidden) .socrata-accordion-pane-title'
    );
    const isFirstPaneTitle = this.paneTitleElement === firstPaneTitle;
    const isShiftTab = event.shiftKey && event.keyCode === 9;

    if (event.keyCode == 13 || event.keyCode == 32) {
      this.props.onToggle?.(this.props.paneId!);
      event.preventDefault();
    } else if (isShiftTab && isFirstPaneTitle) {
      this.focusCurrentCustomizationTab();
      event.preventDefault();
    }
  };

  focusCurrentCustomizationTab() {
    const anchorElement = document.querySelector('.tab-link.current a') as HTMLAnchorElement;
    anchorElement?.focus?.();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IAccordionPaneProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.shouldScroll = true;
    }
  }

  componentDidUpdate() {
    if (this.shouldScroll) {
      this.props.scroll.toView(this.refs.content);
      this.shouldScroll = false;
    }
  }

  render() {
    const { title, children, isOpen, className, toggleOnCaretOnly } = this.props;

    const paneClasses = classNames(className, 'socrata-accordion-pane', {
      open: isOpen
    });

    const toggle = () => {
      if (!isOpen) {
        this.props.onOpen?.();
      }
      this.props.onToggle?.(this.props.paneId!);
    };
    const caretClickHandler = toggleOnCaretOnly ? toggle : _.noop;
    const titleClickHandler = toggleOnCaretOnly ? _.noop : toggle;

    const caretClasses = classNames('dropdown-caret', { clickable: toggleOnCaretOnly });

    return (
      <div className={paneClasses}>
        <div
          className="socrata-accordion-pane-title"
          role="button"
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-label={this.props['aria-label'] || title}
          onClick={titleClickHandler}
          tabIndex={0}
          onKeyDown={this.handleKeyDown}
          ref={(ref) => (this.paneTitleElement = ref)}
        >
          <span>{title}</span>
          <SocrataIcon
            name={'arrow-down' as any}
            className={caretClasses}
            key="dropdown-caret"
            onClick={caretClickHandler}
          />
        </div>
        <div className="socrata-accordion-pane-content legacy" ref="content">
          {children}
        </div>
      </div>
    );
  }
}

export default Scrolls(AccordionPane);
