export interface DsmapiAttachmentUploadResponse {
  file_id: string;
  filename: string;
  name: string;
}

export interface CoreAttachmentUploadResponse {
  file: string;
  nameForOutput: string;
}

interface XhrError extends Error {
  response?: XMLHttpRequest;
  body?: any;
}

export const getContentType = (fileType: string): string => {
  // Substitute .geojson for .json content type
  // because that's the only json variant we support -
  // if the user has a file `foo.json`, we'll translate
  // the content type to geojson for them
  if (fileType.indexOf('application/json') > -1) {
    return 'application/vnd.geo+json';
  }
  return fileType;
};

export const xhrPromise = (
  url: string,
  payload: FormData | File,
  onProgress: (event: ProgressEvent) => void
): Promise<XMLHttpRequest> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', url);

    if (xhr.upload) {
      xhr.upload.onprogress = (event: ProgressEvent) => {
        onProgress(event);
      };
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr);
      } else {
        const error: XhrError = new Error(xhr.statusText);
        error.response = xhr;

        try {
          error.body = JSON.parse(xhr.response);
        } catch (errorForError) {
          // This is probably wrong
          error.body = { message: xhr.response };
        }
        reject(error);
      }
    };

    xhr.onerror = (error: unknown) => {
      const hackyError = error as XhrError; // Sorry TypeScript
      hackyError.response = xhr;
      reject(hackyError);
    };

    if (payload instanceof File) {
      const contentTypeHeader = payload.type;
      const xFileNameHeader = payload.name;
      xhr.setRequestHeader('Content-type', getContentType(contentTypeHeader));
      xhr.setRequestHeader('X-File-Name', encodeURIComponent(xFileNameHeader));
    }

    xhr.send(payload);
  });
};
