import React from 'react';
import { Modal, ModalHeader, ModalContent } from 'common/components/Modal';
import I18n from 'common/i18n';

interface PasswordHintProps {
  onDismiss: () => void;
  passwordRequirements: {
    minLength: number;
    maxLength: number;
  };
}

const PasswordHintModal = ({ passwordRequirements, onDismiss }: PasswordHintProps) => (
  <Modal onDismiss={onDismiss}>
    <ModalHeader onDismiss={onDismiss} />

    <ModalContent>
      <div role="alert"
        dangerouslySetInnerHTML={{
          __html: (passwordRequirements = I18n.t('account.common.form.password_requirements_html', {
            min: passwordRequirements.minLength,
            max: passwordRequirements.maxLength
          }))
        }} />
    </ModalContent>
  </Modal>
);

export default PasswordHintModal;
