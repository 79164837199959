import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { View } from 'common/types/view';
import type { GuidanceSummaryV2 } from 'common/types/approvals';

import I18n from 'common/i18n';
import { reload } from 'common/http';
import currentUserCanDeleteView from 'common/views/current_user_can_delete';
import { AssetStatus, assetStatusFor } from 'common/views/asset_status';
import DeleteAssetModal, { DeleteAssetModalProps } from 'common/components/DeleteAssetModal';

import ActionItem from '../action_item';

export interface DeleteAssetActionItemProps {
  view: View;
  assetIsStoryDraft: boolean;
  revisionInfo?: {
    link: string;
    revisionSeq: number;
  };
  setCurrentModal: (
    modal: typeof DeleteAssetModal,

    // omitting 'onDismiss' because it's passed in by 'setCurrentModal'
    props: Omit<DeleteAssetModalProps, 'onDismiss'>
  ) => void;
  approvalsGuidance: GuidanceSummaryV2;
}

class DeleteAssetActionItem extends Component<DeleteAssetActionItemProps> {
  shouldRender() {
    const { view, approvalsGuidance, assetIsStoryDraft } = this.props;
    if (isEmpty(view)) {
      return false;
    }

    return !view.locked && currentUserCanDeleteView(view, approvalsGuidance, assetIsStoryDraft);
  }

  render() {
    const { view, approvalsGuidance, assetIsStoryDraft, revisionInfo, setCurrentModal } = this.props;

    if (!this.shouldRender()) {
      return null;
    }

    // this will return `AssetStatus.Draft` for unpublished assets, but for story drafts and revisions we have to defer to catalog
    // (which is what gives us assetIsStoryDraft/revisionInfo here)
    const assetStatus = assetStatusFor(view, approvalsGuidance);

    const label =
      assetStatus === AssetStatus.Draft || assetIsStoryDraft || revisionInfo
        ? I18n.t('shared.asset_browser.result_list_table.action_dropdown.discard_draft')
        : I18n.t('shared.asset_browser.result_list_table.action_dropdown.delete_asset');

    const onDeleted = () => {
      reload();
    };

    return (
      <ActionItem
        label={label}
        onClick={() =>
          setCurrentModal(DeleteAssetModal, {
            view,
            assetIsStoryDraft,
            onDeleted,
            revisionSeq: revisionInfo?.revisionSeq
          })
        }
      />
    );
  }
}

export default DeleteAssetActionItem;
