import * as assetActions from 'common/components/AssetBrowser/actions/asset_actions';

const initialState = {
  alert: {},
  approvalStatus: {},
  performingAction: false,
  performingActionSuccess: false,
  performingActionFailure: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case assetActions.SET_APPROVAL:
      return {
        ...state,
        approvalStatus: {
          ...state.approvalStatus,
          [action.uid]: action.status
        }
      };
    case assetActions.SHOW_INFO_ALERT:
      return {
        ...state,
        alert: {
          title: action.title,
          body: action.body,
          time: action.time,
          alertType: action.alertType || 'info'
        }
      };
    case assetActions.SHOW_ERROR_ALERT:
      return {
        ...state,
        alert: {
          title: action.title,
          body: action.body,
          time: action.time,
          alertType: action.alertType || 'error'
        }
      };
    case assetActions.HIDE_ALERT:
      return {
        ...state,
        alert: {}
      };
    default:
      return state;
  }
};
