import PropTypes from 'prop-types';
import React from 'react';
import TypedCell from './TypedCell';
import EditCell from './EditCell';
import Geojson2wkt from 'geojson2wkt';

function PointCell({ isEditing, isError, value, updateCell, isDropping, format }) {
  if (isEditing) {
    let text = isError ? value : Geojson2wkt.convert(value);
    return <EditCell value={text} updateCell={updateCell} />;
  } else {
    let text = value ? Geojson2wkt.convert(value) : '';
    return <TypedCell isDropping={isDropping} value={text} format={format} />;
  }
}

PointCell.propTypes = {
  isError: PropTypes.bool,
  isDropping: PropTypes.bool,
  isEditing: PropTypes.bool,
  updateCell: PropTypes.func,
  value: PropTypes.any,
  format: PropTypes.object
};

export default PointCell;
