import React from 'react';

import ColorPicker from 'common/components/ColorPicker';

import { COLORS } from 'common/authoring_workflow/constants';

export interface ColorPickerWithLabelProps {
  keyId: string;
  color: string;
  label: string;
  palette: string[];
  onChangeColor: (newColor: string) => void;
}

const ColorPickerWithLabel = (props: ColorPickerWithLabelProps): JSX.Element => {
  const { keyId, color, onChangeColor, label, palette = COLORS } = props;
  const colorPickerAttributes = {
    handleColorChange: onChangeColor,
    palette: palette,
    value: color,
    id: 'ag-grid-color-picker'
  };

  return (
    <div data-testid={`ag-grid-color-selector-${keyId}`}>
      <label className="block-label" htmlFor="primary-color">
        {label}
      </label>
      <ColorPicker {...colorPickerAttributes} />
    </div>
  );
};

export default ColorPickerWithLabel;
