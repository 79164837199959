import { Expr } from 'common/types/soql';
import { ColumnFormat } from 'common/types/viewColumn';
import React from 'react';
import Alignment from './Alignment';
import { DecimalPrecision } from './DecimalPrecision';
import PrecisionStyle from './PrecisionStyle';
import NumberSeparators from './NumberSeparators';
import PercentRescaler from './PercentRescaler';

interface Props {
  onUpdateFormat: (f: ColumnFormat) => void;
  onUpdateAST: (a: Expr) => void;
  onRemoveFormat: (k: string) => void;
  format: ColumnFormat;
  ast: Expr | null;
  formatIndex: number;
  defaultPrecision: string;
  showAlignment : boolean;// not shown when hierarchy is choosen
  includeFormTag: boolean;
  defaultAlignment: string;
}
function NumberColumnFormat({
  onUpdateFormat,
  onRemoveFormat,
  onUpdateAST,
  format,
  ast,
  formatIndex,
  defaultPrecision,
  showAlignment,
  includeFormTag = true,
  defaultAlignment
}: Props) {
  const renderContent = () => {
    return (
      <>
        {showAlignment &&
          <Alignment
            onChange={onUpdateFormat}
            format={format}
            defaultAlignment={defaultAlignment}
          />
        }
        <PrecisionStyle
          onChange={onUpdateFormat}
          format={format}
          defaultPrecision={defaultPrecision}
        />
        <DecimalPrecision
          onChange={onUpdateFormat}
          onRemove={onRemoveFormat}
          format={format}
          formatIndex={formatIndex}
        />
        <NumberSeparators
          onChange={onUpdateFormat}
          onRemove={onRemoveFormat}
          format={format}
          formatIndex={formatIndex}
        />
        <PercentRescaler
          onChangeAST={onUpdateAST}
          ast={ast}
          format={format}
        />
      </>
    );
  };

  return (
    <div id="format-column" data-testid='number-column-format'>
      {includeFormTag ? <form>{renderContent()}</form> : renderContent()}
    </div>
  );
}

export default NumberColumnFormat;
