import { AccordionContainer, AccordionPane } from 'common/components/Accordion';
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import * as ArchivalAPI from 'common/core/archival';
import { formatDateWithLocale } from 'common/dates';
import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import Archive from 'common/types/archive';
import { Revision } from 'common/types/revision';
import {
  ApplyMetadataTaskResult,
  SchemaChangeTaskResult,
  TaskSet,
  TaskSetStatus,
  UpsertTaskResult
} from 'common/types/taskSet';
import { View, ViewRight } from 'common/types/view';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { none, Option, option, some } from 'ts-option';
import DataChange from '../AssetChanges/DataChange';
import DomainRights from 'common/types/domainRights';
import { currentUserHasRight } from 'common/current_user';
import MetadataChange from '../AssetChanges/MetadataChange';
import SchemaChange from '../AssetChanges/SchemaChange';
import { Tense } from '../AssetChanges/util';
import Button, { VARIANTS } from '../Button';
import IndeterminatePager from '../IndeterminatePager';
import { IconName } from '../SocrataIcon';
import './AssetTimeline.scss';
import TimelineItem, { TimelineItemActions, TimelineTimestamp, TimelineUser } from './TimelineItem';
import { assertUnreachable, Change } from './util';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'shared.components.asset_timeline', ...options });

const accordionTitle = (time: string, c: Change) => {
  return t('change_title', {
    date: formatDateWithLocale(moment.utc(time), true)
  });
};

interface SubtaskProps<T> {
  revision: Revision;
  task: TaskSet;
  result: T;
}

function getSubtaskResult<T>(task: TaskSet, name: string): T | undefined {
  return task.log.find((le) => le.stage === name) as T | undefined;
}

function MetadataChangesSubtask({ result }: SubtaskProps<ApplyMetadataTaskResult>) {
  return (
    <div className="subtask">
      <MetadataChange step={result.details} tense={Tense.Past} />
    </div>
  );
}

function DataChangesSubtask({ task, result }: SubtaskProps<UpsertTaskResult>) {
  return (
    <div className="subtask">
      <DataChange step={result.details} />
    </div>
  );
}

function SchemaChangesSubtask({ result }: SubtaskProps<SchemaChangeTaskResult>) {
  if (!result.details.operations) return null;
  return (
    <div className="subtask">
      <SchemaChange step={result.details} tense={Tense.Past} />
    </div>
  );
}

interface Props {
  view: View;
  getChanges: (cursor: Option<string>, limit: number) => Promise<{ changes: Change[]; next: Option<string> }>;
}

interface State {
  changes: Option<Change[]>;
  error: Option<string>;
  showEnrollment: boolean;
  inProgress: boolean;
  enrolling: boolean;
  unenrolling: boolean;
  showConfirmation: boolean;
  cursors: Option<string>[];
  cursorOffset: number;
  latest: Option<Change>;
}

const PAGE_SIZE = 10;
const NoChanges = <div className="alert default bootstrap-alert">{t('no_changes_yet')}</div>;

class AssetTimeline extends React.Component<Props, State> {
  state: State = {
    changes: none,
    error: none,
    inProgress: false,
    showEnrollment: false,
    enrolling: false,
    unenrolling: false,
    showConfirmation: false,
    cursors: [none],
    cursorOffset: 0,
    latest: none
  };

  componentDidMount() {
    this.list(this.currentCursor(), 0);
  }

  componentDidUpdate(prevProps: Partial<Props>) {
    if (prevProps.view?.id !== this.props.view.id) this.list(this.currentCursor(), 0);
  }

  currentCursor = () => {
    return this.state.cursors[this.state.cursorOffset];
  };

  onPrev = async () => {
    const newOffset = this.state.cursorOffset - 1;
    if (newOffset < 0) return;
    await this.list(this.state.cursors[newOffset], newOffset);
  };

  onNext = async () => {
    const newOffset = this.state.cursorOffset + 1;
    const next = this.state.cursors[newOffset];
    if (next.isDefined) {
      await this.list(next, newOffset);
    }
  };

  hasNext() {
    return (this.state.cursors[this.state.cursorOffset + 1] || none).isDefined;
  }

  hasPrev() {
    return this.state.cursorOffset > 0;
  }

  async list(cursor: Option<string>, newOffset: number) {
    try {
      this.setState({ inProgress: true });
      const isKnownBySecondary = await ArchivalAPI.isEnrolled(this.props.view);
      if (isKnownBySecondary) {
        const { changes, next } = await this.props.getChanges(cursor, PAGE_SIZE);

        if (this.state.latest.isEmpty) {
          this.setState({ latest: option(changes[0]) });
        }

        let cursors = this.state.cursors;
        if (newOffset < this.state.cursorOffset) {
          cursors = [...cursors.slice(0, newOffset + 1), next];
        } else {
          cursors = [...cursors, next];
        }
        this.setState({
          showEnrollment: false,
          changes: some(changes),
          cursors,
          inProgress: false,
          cursorOffset: newOffset
        });
      } else {
        this.setState({
          // we shouldn't be here if the flag is disabled, but check again anyway
          showEnrollment: FeatureFlags.value('enable_asset_archival'),
          inProgress: false,
          changes: none
        });
      }
    } catch (e) {
      // 404 means the archival secondary doesn't know about the fourfour yet, which
      // means it hasn't spotted the secondary manifest and started replaying versions
      const error = e?.response?.status === 404 ? some(t('not_available_yet')) : some(t('failure'));
      console.error(e);
      this.setState({
        inProgress: false,
        showEnrollment: false,
        error
      });
    }
  }

  enrollDataset = async () => {
    try {
      this.setState({
        enrolling: true
      });
      await ArchivalAPI.enrollDataset(this.props.view);
      this.setState({
        showEnrollment: false,
        enrolling: false,
        error: none
      });
      this.list(this.currentCursor(), 0);
    } catch (e) {
      this.setState({
        showEnrollment: false,
        enrolling: false,
        error: some(t('enrollment_failure'))
      });
    }
  };

  unenrollDataset = async () => {
    try {
      this.setState({
        unenrolling: true
      });
      await ArchivalAPI.unenrollDataset(this.props.view);
      this.setState({
        showEnrollment: true,
        showConfirmation: false,
        unenrolling: false,
        error: none
      });
    } catch (e) {
      this.setState({
        showEnrollment: true,
        showConfirmation: false,
        unenrolling: false,
        error: some(t('unenrollment_failure'))
      });
    }
  };

  requestConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  cancelUnenroll = () => {
    this.setState({ showConfirmation: false });
  };

  unenrollButton = (showConfirmation: boolean) => {
    if (showConfirmation) {
      return (
        <div>
          <Modal onDismiss={this.cancelUnenroll}>
            <ModalHeader title={t('are_you_sure_unenroll')} onDismiss={this.cancelUnenroll} />
            <ModalContent>{t('irreversible_message')}</ModalContent>
            <ModalFooter>
              <Button
                className="unenroll-confirm-wrap"
                busy={this.state.unenrolling}
                variant={VARIANTS.ERROR}
                onClick={this.unenrollDataset}
              >
                {t('unenroll_confirm')}
              </Button>
              <Button
                className="unenroll-confirm-wrap"
                variant={VARIANTS.DEFAULT}
                onClick={this.cancelUnenroll}
              >
                {t('unenroll_cancel')}
              </Button>
            </ModalFooter>
          </Modal>
          <Button className="unenroll-wrap" variant={VARIANTS.PRIMARY} onClick={this.requestConfirmation}>
            {t('unenroll_now')}
          </Button>
        </div>
      );
    }
    return (
      <Button className="unenroll-wrap" variant={VARIANTS.PRIMARY} onClick={this.requestConfirmation}>
        {t('unenroll_now')}
      </Button>
    );
  };

  renderChangeAsRevision = (change: Change, revision: Revision) => {
    const task = revision.task_sets.find((ts) => ts.status === TaskSetStatus.Successful);
    if (!task) return null;

    const isLatest = this.state.latest.map((latest) => _.isEqual(latest, change)).getOrElseValue(false);

    const metadataChanges = getSubtaskResult<ApplyMetadataTaskResult>(task, 'apply_metadata');
    const schemaChanges = getSubtaskResult<SchemaChangeTaskResult>(task, 'set_schema');
    const dataChanges = getSubtaskResult<UpsertTaskResult>(task, 'upsert_task');
    return (
      <AccordionPane
        key={`revision_${revision.closed_at}`}
        title={accordionTitle(revision.closed_at!, change)}
      >
        <TimelineItem icon={IconName.Draft}>
          <div className="revision-title">
            <p>
              {t('published_by', revision)}{' '}
              <TimelineUser createdBy={task.created_by.display_name} createdById={task.created_by.user_id} />{' '}
              <TimelineTimestamp date={task.created_at} />
            </p>
          </div>
          {revision.notes && (
            <div className="revision-notes">
              <p>{revision.notes}</p>
            </div>
          )}

          {metadataChanges && (
            <MetadataChangesSubtask revision={revision} task={task} result={metadataChanges} />
          )}
          {schemaChanges && <SchemaChangesSubtask revision={revision} task={task} result={schemaChanges} />}
          {dataChanges && <DataChangesSubtask revision={revision} task={task} result={dataChanges} />}

          {isLatest ? null : <TimelineItemActions view={this.props.view} change={some(change)} />}
        </TimelineItem>
      </AccordionPane>
    );
  };

  renderChangeAsUpsert = (change: Change, archive: Archive) => {
    return (
      <AccordionPane key={`archive_${archive.created_at}`} title={accordionTitle(archive.created_at, change)}>
        <TimelineItem>
          <TimelineItemActions view={this.props.view} change={some(change)} />
        </TimelineItem>
      </AccordionPane>
    );
  };

  render() {

    if (this.state.showEnrollment) {
      if (!currentUserHasRight(DomainRights.enroll_in_archival)) {
        // User does not have correct domain right
        return NoChanges;
      }
      if (!this.props.view.rights.includes(ViewRight.UpdateView)) {
        // Dataset is not enrolled, and the user can't do anything about it. Just render nothing.
        return NoChanges;
      }
      return (
        <div className="asset-timeline">
          <div className="asset-timeline-accordion">
            <div className="alert default bootstrap-alert">
              {t('not_enrolled_yet')}
              <Button busy={this.state.enrolling} variant={VARIANTS.PRIMARY} onClick={this.enrollDataset} data-testid="enroll-archival-button">
                {t('enroll_now')}
              </Button>
            </div>
          </div>
        </div>
      );
    }

    const timeline = this.state.error
      // ffs eslint it's just a function called map...
      // eslint-disable-next-line react/jsx-key
      .map<React.ReactElement | null>((message) => <div className="alert alert-error">{message}</div>)
      .getOrElse(() =>
        this.state.changes.match({
          none: () => (
            <div className="alert default">
              <span className="spinner-default" />
            </div>
          ),
          some: (changes) => {
            if (changes.length === 0) {
              return NoChanges;
            }

            return (
              <>
                <AccordionContainer>
                  {changes.map((change) => {
                    switch (change.type) {
                      case 'revision': {
                        return this.renderChangeAsRevision(change, change.value);
                      }
                      case 'archive': {
                        return this.renderChangeAsUpsert(change, change.value);
                      }
                    }
                    // hehe - exhaustivity checks in TS
                    return assertUnreachable(change);
                  })}
                </AccordionContainer>
              </>
            );
          }
        })
      );
    return (
      <div className="asset-timeline">
        <div className="section-content">
          <div className="asset-timeline-accordion">{timeline}</div>
        </div>
        <IndeterminatePager
          onNext={this.onNext}
          onPrev={this.onPrev}
          hasNext={this.hasNext()}
          hasPrev={this.hasPrev()}
        />

        {(this.props.view.rights.includes(ViewRight.UpdateView) && currentUserHasRight(DomainRights.enroll_in_archival))
          ? this.unenrollButton(this.state.showConfirmation)
          : null}
      </div>
    );
  }
}

export default AssetTimeline;
