import Modal, { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import * as helpers from 'common/components/ScheduleModal/helpers';
import { Running, Schedule, Entity } from 'common/types/schedule';
import * as _ from 'lodash';
import React from 'react';
import { option, Option } from 'ts-option';
import { OutputSchema } from 'common/types/dsmapiSchemas';

interface CurrentlyRunningProps {
  schedule: Schedule;
  onDismiss: () => void;
}
export default function CurrentlyRunning({ schedule, onDismiss }: CurrentlyRunningProps) {
  if (!helpers.isRunning(schedule)) return null;
  const rs = schedule.runstate as Running;
  const source = option(_.find(rs.entities, e => e.type == 'source'));
  const outputSchema = option(_.find(rs.entities, e => e.type == 'output_schema')) as Option<Entity<'output_schema', OutputSchema>>;
  const task = option(_.find(rs.entities, e => e.type == 'task_set'));
  const total: Option<number> = outputSchema.flatMap(os => option(os.value.total_rows));
  // common icon isn't easily resizable
  const icon = (name: string) => (<span className={`running-icon socrata-icon-${name}`}></span>);

  let content = null;
  if (task.isDefined && total.isDefined) {

    content = (
      <div className="schedule-progress">
        {icon('public-open')}
        <p>{helpers.t('running.publishing', { total: total.get })}</p>
      </div>
    );
  } else if (outputSchema.isDefined && total.isDefined) {
    content = (
      <div className="schedule-progress">
        {icon('data')}
        <p>{helpers.t('running.imported', { total: total.get })}</p>
      </div>
    );
  } else if (source.isDefined) {
    content = (
      <div className="schedule-progress">
        {icon('data')}
        <p>{helpers.t('running.source')}</p>
      </div>
    );
  } else {
    content = (
      <div className="schedule-progress">
        {icon('pulse')}
        <p>{helpers.t('running.contacting')}</p>
      </div>
    );
  }

  return (
    <Modal className="scheduler-modal" containerStyle={{ width: 800, maxWidth: 800, padding: 0 }} onDismiss={onDismiss}>
      <ModalHeader title={helpers.t('in_progress')} onDismiss={onDismiss} />
      <ModalContent className="schedule-running">
        {content}
      </ModalContent>
      <ModalFooter />
    </Modal>
  );
}
