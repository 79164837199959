import PropTypes from 'prop-types';
import React from 'react';

import Pager from 'common/components/Pager';
import ResultCount from 'common/components/ResultCount';

import './pager-group.scss';

const PagerGroup = ({ currentPage, onChangePage, resultCount, resultsPerPage }) => (
  <div className="pager-group">
    <Pager
      changePage={onChangePage}
      currentPage={currentPage}
      resultCount={resultCount}
      resultsPerPage={resultsPerPage} />
    <ResultCount pageNumber={currentPage} resultsPerPage={resultsPerPage} total={resultCount} />
  </div>
);

PagerGroup.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  resultCount: PropTypes.number.isRequired,
  resultsPerPage: PropTypes.number.isRequired
};

export default PagerGroup;
