import React from 'react';

// Why the display style? Originally we were just going to have this use `contents`
// for the display style. The definition of `contents` is "Makes the container disappear,
// making the child elements children of the element the next level up in the DOM."
// However, `contents` is not yet supported in Edge or IE. Thus when PendoIdWrapper
// wraps nodes, we must be able to set the display style to whatever type maintains
// the behavior previous to the PendoIdWrapper wrapping it.


// TODO: Remove once we can just use `contents`
export enum DisplayStyle {
  INLINE = 'inline',
  BLOCK = 'block',
  CONTENTS = 'contents',
  FLEX = 'flex',
  GRID = 'grid',
  INLINE_BLOCK = 'inline-block',
  INLINE_FLEX = 'inline-flex',
  INLINE_GRID = 'inline-grid',
  LIST_ITEM = 'list-item',
  RUNIN = 'run-in',
  TABLE = 'table',
  TABLE_CAPTION = 'table-caption',
  TABLE_COLUMN_GROUP = 'table-column-group',
  TABLE_HEADER_GROUP = 'table-header-group',
  TABLE_FOOTER_GROUP = 'table-footer-group',
  TABLE_ROW_GROUP = 'table-row-group',
  TABLE_CELL = 'table-cell',
  NONE = 'none',
  INITIAL = 'initial',
  INHERIT = 'inherit'
}

interface PendoIdWrapperProps {
  id: string;
  children: React.ReactElement | React.ReactElement[];
  displayStyle: DisplayStyle; // TODO: Unnecessary once we can use `contents`
}

function PendoIdWrapper({ id, children, displayStyle }: PendoIdWrapperProps) {

  // TODO: Hardcode `displayStyle` to `'contents'` when we can use `contents`
  // without worry
  return (
    <div id={id} style={{ display: displayStyle }}>
      {children}
    </div>
  );
}

export default PendoIdWrapper;
