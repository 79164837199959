import React, { Component } from 'react';

import I18n from 'common/i18n';
import mixIntoComponentInstance from 'common/components/mixIntoComponentInstance';
import { AlertListSubAlertComponent, AlertListSubAlertProps } from 'common/components/AlertList/AlertListTypes';





/* Predefined Values
============================================================================= */
const defaultAlertType = 'notice';
let defaultId = 0;





/* Component Definition
============================================================================= */
export const component = {
  /* Attributes
  ----------------------------------------------------------------- */
  props: {} as AlertListSubAlertProps,



  /* Methods
  ----------------------------------------------------------------- */

  /* Renderers
  ------------------------------------------------------- */
  /**
   * Render the sub alert message
   * @returns JSX.Element | null
   */
  renderMessage: function () {
    const { translationKey, interpolations } = (this).props;
    const message = I18n.t(translationKey, interpolations);
    let response = null;

    if (message) {
      if (translationKey.endsWith('_html')) {
        response = <div className="alert-message" dangerouslySetInnerHTML={{ __html: message }} />;
      } else {
        response = <div className="alert-message">{message}</div>;
      }
    }

    return response;
  },

  /**
   * Render a sub alert based on the provided properties object
   * @param subAlert The SubAlert properties object
   * @param i A number to use as the SubAlert's key if no alertId property is set on the properties object
   * @returns JSX.Element
   */
  renderSubAlert: function (subAlert: AlertListSubAlertProps, i: number) {
    subAlert.alertId = subAlert.alertId || i;

    return <AlertListSubAlert key={subAlert.alertId} {...subAlert} />;
  },

  /**
   * Renders a list of sub alerts if any are provided
   * @returns JSX.Element | null
   */
  renderSubAlertsList: function () {
    const { alerts } = this.props;
    let response = null;

    if (alerts?.length) {
      response = <ul className="alert-list">{alerts.map(this.renderSubAlert)}</ul>;
    }

    return response;
  }
} as AlertListSubAlertComponent;





/* Class Definition
============================================================================= */
export class AlertListSubAlert extends Component<AlertListSubAlertProps> {

  /* Attributes
  ----------------------------------------------------------------- */




  /* Static & Builtins
  ----------------------------------------------------------------- */
  static defaultProps = {
    interpolations: {},
    alerts: [],
    alertId: defaultId
  };



  /* Methods
  ----------------------------------------------------------------- */
  constructor(props: AlertListSubAlertProps) {
    super(props);

    // Extend the component object onto our class instance
    mixIntoComponentInstance.call(this, this, [component]);

    if (props.alertId == defaultId) {
      defaultId++;
    }
  }


  /* Renderers
  ------------------------------------------------------- */
  renderMessage: () => JSX.Element | null;

  renderSubAlert: (subAlert: AlertListSubAlertProps, i: number) => JSX.Element;

  renderSubAlertsList: () => JSX.Element | null;

  render() {
    const { alerts, alertId } = this.props;

    return (
      <li className="alert-list-item" key={alertId}>
        {this.renderMessage()}
        {alerts?.length ? this.renderSubAlertsList() : null}
      </li>
    );
  }
}

export default AlertListSubAlert;
