import { isEmpty, cloneDeep } from 'lodash';

/**
 * Extends attributes and methods from the provided mixinObj onto the function's bound 'this' property
 * @param mixinObj The object to extend functionality from
 * @returns The function's bound 'this' property
 */
 const mixIntoSelf = function (mixinObj: any = {}) {
  Object.entries(mixinObj).forEach((entry) => {
    const [key, value] = entry;

    if (key !== 'props') {
      this[key] = value instanceof Function ? value.bind(this) : cloneDeep(value);
    }
  }, this);

  return this;
};

/**
 * Extends attributes and methods from the provided list of mixinObjs onto the provided component instance
 * @param componentInstance Instance of a component to extend the mixin objects onto
 * @param mixinObjs Array of objects containing functionality to extend onto a given component instance
 * @returns The provided component instance
 */
const mixIntoComponentInstance = function (componentInstance: any, mixinObjs: Array<any> = []) {
  if (componentInstance && mixinObjs.length) {
    mixinObjs.forEach((mixinObj) => {
      if (!isEmpty(mixinObj)) {
        mixIntoSelf.call(componentInstance, mixinObj);
      }
    });
  }

  return componentInstance;
};

export default mixIntoComponentInstance;
