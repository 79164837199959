import React from 'react';

import Button from 'common/components/Button';
import { getCurrentUser } from 'common/current_user';

import GatedButton from './GatedButton';

// If no 'gate' prop is passed, this is a normal button - see ActionButton.
// If 'gate' is passed, button may be disabled (based on current user) or may open a modal.
const GateableButton = ({ gate, onClick, children }) => {
  const UngatedButton = (props) => <Button onClick={onClick} {...props}>{children}</Button>;

  if (!gate) {
    return <UngatedButton />;
  }

  const {
    allowedUsers,
    reason,
    created,
  } = gate;

  const currentUser = getCurrentUser();
  const disabled = allowedUsers && !allowedUsers.some((user) => currentUser.id === user.id);

  if (disabled) {
    return <UngatedButton disabled={disabled} />;
  }

  const gatedButtonProps = {
    children,
    allowedUsers,
    reason,
    created,
    onClick
  };

  return <GatedButton {...gatedButtonProps} />;
};

export default GateableButton;
