import React from 'react';

import utcToString from 'common/utcToString';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from 'common/components/Modal';

const GateInfoModal = (props) => {
  const {
    allowedUsers,
    reason,
    created,
    onDismiss,
    ConfirmButton
  } = props;

  const userList = allowedUsers && allowedUsers.map((user) =>
    <li key={user.email} className="allowed-users">{user.email}</li>
  );

  const users = userList && (
    <div>
      <div className="field-name">Allowed Users:</div>
      <ul className="user-list">
        {userList}
      </ul>
    </div>
  );

  return (
    <Modal onDismiss={onDismiss} className="gate-info-modal">
      <ModalHeader title="Gate Info" onDismiss={onDismiss}/>
      <ModalContent className="gate-modal-content">
        <div className="field-name">Reason:</div>
        <div className="gate-reason">{reason}</div>
        <div className="field-name">Created At:</div>
        <div>{utcToString(created.at)}</div>
        <div className="field-name">Created By:</div>
        <div>{created.by?.email}</div>
        {users}
      </ModalContent>
      {ConfirmButton && <ModalFooter><ConfirmButton /></ModalFooter>}
    </Modal>
  );
};

export default GateInfoModal;
