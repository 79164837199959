import React from 'react';

import DropdownWithIcon from './DropdownWithIcon';
import Dropdown from 'common/components/Dropdown';

import I18n from 'common/i18n';
import { ColumnFormat } from 'common/types/viewColumn';

const t = (k: string) => I18n.t(k, { scope: 'shared.dataset_management_ui.format_column' });
interface Props {
  onChange: (f: ColumnFormat) => void;
  format: ColumnFormat;
  defaultAlignment: string;
}

function Alignment({ onChange, format, defaultAlignment }: Props) {
  const dropdownProps = {
    onSelection: (e: { value: string }) => onChange({ align: e.value }),
    value: format.align || defaultAlignment,
    options: [
      {
        title: t('left'),
        value: 'left',
        icon: 'socrata-icon-paragraph-left',
        render: DropdownWithIcon
      },
      {
        title: t('right'),
        value: 'right',
        icon: 'socrata-icon-paragraph-right3',
        render: DropdownWithIcon
      },
      {
        title: t('center'),
        value: 'center',
        icon: 'socrata-icon-paragraph-center3',
        render: DropdownWithIcon
      }
    ]
  };

  return (
    <div>
      <label>{t('alignment')}</label>
      <Dropdown {...dropdownProps} />
    </div>
  );
}

export default Alignment;
