import * as helpers from 'common/components/ScheduleModal/helpers';
import React from 'react';

export interface ValidationErrors {
  [k: string]: string;
}
interface ErrorMessageProps { validation: ValidationErrors; name: string }
export default function ErrorMessage({ validation, name }: ErrorMessageProps) {
  const error = validation[name];
  if (!error) return null;

  return (
    <div className="scheduling-form-control">
      <label className="scheduling-form-placeholder">&nbsp;</label>
      <span className="dsmp-form-error-message">{helpers.t(name)} {error}</span>
    </div>
  );
}
