import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import eq from 'lodash/fp/eq';
import stubTrue from 'lodash/fp/stubTrue';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { IconName, SocrataIcon } from 'common/components/SocrataIcon';

interface Props {
  checkedState: boolean | 'partial';
}

/**
 * A three-state checkbox that isn't interactive. Not screenreader friendly by itself.
 * If you need iteractivity, use a11y-compliant common/components/ThreeStateCheckbox instead.
 */
class TristateIndicator extends Component<Props> {
  static propTypes = {
    checkedState: PropTypes.oneOf([true, false, 'partial']).isRequired
  };

  render() {
    const { checkedState } = this.props;
    return cond([
      [eq(true), constant(<SocrataIcon name={IconName.CheckmarkAlt} />)],
      [eq(false), constant(null)],
      [stubTrue, constant(<SocrataIcon name={IconName.IndeterminateStateIcon} />)]
    ])(checkedState);
  }
}

export default TristateIndicator;
