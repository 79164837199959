import React, { useEffect } from 'react';
import { ForgeCircularProgress } from '@tylertech/forge-react';

declare global {
  interface Window {
    _wq: any[];
  }
}

type Props = {
  videoId: string;
};

function WistiaPlayer(props: Props) {
  const { videoId } = props;

  const DEFAULT_VIDEO_WIDTH = 640;
  const ASPECT_RATIO = 9 / 16;

  useEffect(() => {
    const embedScript = document.createElement('script');
    const assetScript = document.createElement('script');

    // This is required to enable auto caption
    window._wq = window._wq || [];
    window._wq.push({
      id: videoId,
      options: {
        plugin: {
          'captions-v1': {
            onByDefault: true,
            language: 'eng',
          }
        }
      }
    });

    embedScript.src = `//fast.wistia.com/embed/medias/${videoId}.jsonp`;
    embedScript.async = true;

    assetScript.src = '//fast.wistia.com/assets/external/E-v1.js';
    assetScript.async = true;

    document.body.appendChild(embedScript);
    document.body.appendChild(assetScript);

    return () => {
      document.body.removeChild(embedScript);
      document.body.removeChild(assetScript);
    };
  }, []);

  // Multiply innerWidth by 0.9 to give some padding on the sides
  const videoWidth = Math.round(Math.min(DEFAULT_VIDEO_WIDTH, window.innerWidth * 0.9));
  const videoHeight = Math.round(videoWidth * ASPECT_RATIO);

  return (
    <div
      className={`wistia_embed wistia_async_${videoId} autoPlay=true`}
      style={{
        height: `${videoHeight}px`,
        width: `${videoWidth}px`,
        display: 'grid',
        alignItems: 'center',
        textAlign: 'center',
        '--forge-circular-progress-size': '150px',
        '--forge-circular-progress-stroke-width': '1px',
      }}
    >
      <ForgeCircularProgress />
    </div>
  );
}

export default WistiaPlayer;
