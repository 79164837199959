import React, { Component } from 'react';
import { get, uniqueId, extend } from 'lodash';
import { ForgeInlineMessage, ForgeIcon } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import isMissingTranslation from 'common/js_utils/isMissingTranslation';
import mixIntoComponentInstance from 'common/components/mixIntoComponentInstance';
import { SocrataIcon, IconName } from 'common/components/SocrataIcon';
import { alertTypeDisplayOrder } from 'common/components/AlertList';
import { AlertListSubAlert } from 'common/components/AlertList/AlertListSubAlert';
import { AlertListAlertProps, AlertListAlertState, AlertListAlertComponent, AlertListSubAlertProps, AlertForgeMap, AlertTypeForgeMap } from 'common/components/AlertList/AlertListTypes';





/* Predefined Values
============================================================================= */
const defaultAlertType = 'notice';





/* Component Definition
============================================================================= */
export const component = {
  props: {} as AlertListAlertProps,



  /* Attributes
  ----------------------------------------------------------------- */
  alertForgeMap: {
    success: {
      theme: 'success',
      icon: 'check_circle_outline'
    } as AlertTypeForgeMap,
    info: {
      theme: 'info-primary',
      icon: 'info_outline'
    } as AlertTypeForgeMap,
    notice: {
      theme: 'info-secondary',
      icon: 'alert_circle_outline'
    } as AlertTypeForgeMap,
    warning: {
      theme: 'warning',
      icon: 'warning'
    } as AlertTypeForgeMap,
    error: {
      theme: 'danger',
      icon: 'dangerous'
    } as AlertTypeForgeMap,
  } as AlertForgeMap,
  autoDismissTimeoutId: undefined as undefined | number,
  alertId: 0,




  /* Methods
  ----------------------------------------------------------------- */

  /* Helpers
  ------------------------------------------------------- */

  /**
   * Set the Alert's alertId attribute
   * @param props The properties the Alert was passed
   * @returns AlertListAlert
   */
  setAlertId: function (props: AlertListAlertProps) {
    if (!this.alertId) {
      this.alertId = props.alertId || uniqueId('AlertListAlert-');
    }

    return this;
  },

  /**
   * Map the AlertDisplayType to the forge alert theme name
   * @param type The alert type from common/components/AlertList/AlertListTypes -> AlertDisplayTypes
   * @returns string
   */
  mapForgeTheme: function (type: string) {
    return get(this.alertForgeMap, `${type}.theme`) || get(this.alertForgeMap, `${defaultAlertType}.theme`);
  },

  /**
   * Map the AlertDisplayType to the forge alert icon name
   * @param type The alert type from common/components/AlertList/AlertListTypes -> AlertDisplayTypes
   * @returns string
   */
  mapForgeIcon: function (type: string) {
    return get(this.alertForgeMap, `${type}.icon`) || get(this.alertForgeMap, `${defaultAlertType}.icon`);
  },


  /* State Management
  ------------------------------------------------------- */
  /**
   * Dismiss the alert
   * @returns AlertListAlert
   */
  dismiss: function () {
    const { dismissAlert } = this.props;

    this.clearAutoDismissTimeoutId();
    this.setState({ dismissed: true });

    if (typeof dismissAlert === 'function') {
      dismissAlert(this.props);
    }

    return this;
  },

  /**
   * Clear the autoDismissTimeoutId
   * @returns AlertListAlert
   */
  clearAutoDismissTimeoutId: function () {
    this.autoDismissTimeoutId = window.clearTimeout(this.autoDismissTimeoutId);

    return this;
  },

  /**
   * Call dismiss() after a given delay
   * @param delay (Optional) The number of milliseconds to delay before dismissing the alert
   * @returns AlertListAlert
   */
  setAutoDismiss: function (delay?: number) {
    this.autoDismissTimeoutId = window.setTimeout(this.dismiss, delay);

    return this;
  },


  /* Renderers
  ------------------------------------------------------- */
  /**
   * Renders the forge icon based on the forge theme
   * @returns JSX.Element
   */
  renderForgeIcon: function () {
    const { type } = this.props;
    const iconName = this.mapForgeIcon(type);

    return (<ForgeIcon slot="icon" name={iconName}></ForgeIcon>);
  },

  /**
   * Renders the dismiss button
   * @returns JSX.Element
   */
  renderDismissButton: function () {
    return (
      <div className="alert-close" onClick={this.dismiss}>
        <SocrataIcon name={IconName.Close} />
      </div>
    );
  },

  /**
   * Render the message or dismiss the alert if no message exists
   * @returns JSX.Element | null
   */
  renderMessage: function () {
    const { translationKey, interpolations } = this.props;
    const message = I18n.t(translationKey, interpolations);
    let response = null;

    if (message && !isMissingTranslation(message, translationKey)) {
      if (translationKey.endsWith('_html')) {
        response = (
          <div
            slot="title"
            className="alert-message"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        );
      } else {
        response = (
          <div slot="title" className="alert-message">
            {message}
          </div>
        );
      }
    } else {
      this.setAutoDismiss(0);
    }

    return response;
  },

  /**
   * Render a sub alert based on the provided properties object
   * @param subAlert The SubAlert properties object
   * @returns JSX.Element
   */
  renderSubAlert: function (subAlert: AlertListSubAlertProps) {
    subAlert.alertId = subAlert.alertId || uniqueId('AlertListSubAlert-');

    return <AlertListSubAlert key={subAlert.alertId} {...subAlert} />;
  },

  /**
   * Renders a list of sub alerts if any are provided
   * @returns JSX.Element | null
   */
  renderSubAlertsList: function () {
    const { alerts } = this.props;
    let response = null;

    if (alerts?.length) {
      response = <ul className="alert-list">{alerts.map(this.renderSubAlert)}</ul>;
    }

    return response;
  },
} as AlertListAlertComponent;





/* Class Definition
============================================================================= */
export class AlertListAlert extends Component<AlertListAlertProps, AlertListAlertState> {

  /* Attributes
  ----------------------------------------------------------------- */
  alertForgeMap: AlertForgeMap;
  autoDismissTimeoutId: undefined | number;
  alertId: string | number;




  /* Static & Builtins
  ----------------------------------------------------------------- */
  static defaultProps = {
    interpolations: {},
    dismissable: true,
    autoDismiss: false,
    autoDismissDelay: 5000,
    alerts: [],
  };



  /* Methods
  ----------------------------------------------------------------- */
  constructor(props: AlertListAlertProps) {
    super(props);

    // Extend the component object onto our class instance
    mixIntoComponentInstance.call(this, this, [component]);

    this.state = {
      dismissed: false
    };
    this.setAlertId(props);
  }

  componentWillUnmount(): void {
    this.clearAutoDismissTimeoutId();
  }


  /* Helpers
  ------------------------------------------------------- */
  setAlertId: (props: AlertListAlertProps) => AlertListAlert | AlertListAlertComponent;

  mapForgeTheme: (type: string) => string;

  mapForgeIcon: (type: string) => string;


  /* State Management
  ------------------------------------------------------- */
  clearAutoDismissTimeoutId: () => AlertListAlert | AlertListAlertComponent;

  dismiss: () => AlertListAlert | AlertListAlertComponent;

  setAutoDismiss: (delay?: number) => AlertListAlert | AlertListAlertComponent;


  /* Renderers
  ------------------------------------------------------- */
  renderForgeIcon: () => JSX.Element;

  renderDismissButton: () => JSX.Element;

  renderMessage: () => JSX.Element | null;

  renderSubAlert: (alert: AlertListSubAlertProps, i: number) => JSX.Element;

  renderSubAlertsList: () => JSX.Element | null;

  render() {
    const { type, alerts, autoDismiss, autoDismissDelay } = this.props;
    let response = null;

    if (!this.state.dismissed) {
      response = (
        <ForgeInlineMessage
          id={this.alertId}
          key={this.alertId}
          theme={this.mapForgeTheme(type)}
          role={alertTypeDisplayOrder.indexOf(type) <= 2 ? 'polite' : 'alert'}
        >
          {this.renderForgeIcon()}
          <div className="alert-body">
            <div className="alert-content">
              {this.renderMessage()}
              {alerts?.length ? this.renderSubAlertsList() : null}
            </div>
            {this.renderDismissButton()}
          </div>
        </ForgeInlineMessage>
      );

      if (autoDismiss) {
        this.setAutoDismiss(autoDismissDelay);
      }
    }

    return response;
  }
}

export default AlertListAlert;
