import { fetchJsonWithParsedError } from 'common/http';

/**
 * PUBLIC API
 */

/**
 * @param userUid: uid of the user chose status should be changed
 * @param active: User status to be set
 * @returns success
 */

export const ACTIVATE = true;
export const DEACTIVATE = false;

export const changeOrgUserActive = (userUid, active) => {
  return fetchJsonWithParsedError(
    `/api/organizations/current/users/${userUid}?active=${active}`,
    { method: 'PUT' }
  );
};

/**
 * Change an organization users role
 * @see https://organizationusers.docs.apiary.io/#reference/0/organization-users-api/update-organization-user-status-and-role
 */
export const changeOrgUserRole = (userUid, roleId) => {
  return fetchJsonWithParsedError(
    `/api/organizations/current/users/${userUid}?roleId=${roleId}`,
    { method: 'PUT' }
  );
};
