import { Expr, FunCall, isColumnRef, isFunCall, isNumberLiteral, isStringLiteral, SoQLNumberLiteral } from 'common/types/soql';
import _ from 'lodash';

const stripToTextAst = (ast: FunCall) => (ast && ast.function_name === 'to_text' ? ast.args[0] : ast);
const stripToNumberAst = (ast: FunCall) => (ast && ast.function_name === 'to_number' ? ast.args[0] : ast);
const stripToBooleanAst = (ast: FunCall) => (ast && ast.function_name === 'to_boolean' ? ast.args[0] : ast);
const stripToDatetimeAst = (ast: FunCall) =>
  ast && ast.function_name === 'to_floating_timestamp' ? ast.args[0] : ast;

function func(name: string, ...args: Expr[]): FunCall {
  return {
    type: 'funcall',
    function_name: name,
    window: null,
    args
  };
}

// Our AST will look like this:
// {
//   "type": "funcall",
//   "function_name": "forgive",
//   "args": [
//     {
//       "type": "funcall",
//       "function_name": "geocode",
//       "args": [
//         {
//           "value": "address",
//           "type": "column_ref"
//         },
//         {
//           "value": "city",
//           "type": "column_ref"
//         },
//         {
//           "value": "state",
//           "type": "column_ref"
//         },
//         {
//           "value": "zip",
//           "type": "column_ref"
//         }
//       ]
//     }
//   ]
// }
//

// def traverse(items, acc, fun) when is_list(items) do
//   Enum.reduce(items, acc, fn node, acc -> traverse(node, acc, fun) end)
// end
// def traverse({:funcall, _funcspec, args, _row, _col} = node, acc, fun) do
//   acc = traverse(args, acc, fun)
//   fun.(node, acc)
// end
// def traverse(node, acc, fun), do: fun.(node, acc)
function traverse<T>(node: Expr | Expr[], acc: T, fun: (n: Expr, acc: T) => T): T {
  if (_.isArray(node)) {
    return node.reduce((nodeAcc, subnode) => traverse(subnode, nodeAcc, fun), acc);
  } else if (node && isFunCall(node)) {
    return fun(node, traverse(node.args, acc, fun));
  } else {
    return fun(node, acc);
  }
}

function map(node: Expr, fun: (n: Expr) => Expr): Expr {
  if (node && isFunCall(node)) {
    const mappedNode = {
      ...node,
      args: node.args.map((arg) => map(arg, fun))
    };
    return fun(mappedNode);
  } else {
    return fun(node);
  }
}

// This is for backwards compatibility with JS modules
// that i'm afraid to touch.
// When we moved SoQL AST things up to common, we left duplicates in DSMP,
// and then the AST definitions diverged. Ideally this module would be gone, but
// this is a step towards that.
const isFunction = isFunCall;
const numberLiteral = (value: number): SoQLNumberLiteral => ({ type: 'number_literal', value: `${value}` });


export {
  traverse,
  map,
  stripToTextAst,
  stripToNumberAst,
  stripToBooleanAst,
  stripToDatetimeAst,
  isColumnRef,
  isFunction,
  isStringLiteral,
  isNumberLiteral,
  numberLiteral,
  func
};
