import DropdownFactory from './Dropdown';
import FlannelFactory from './Flannel';
import FlyoutFactory from './Flyout';
import MenuFactory from './Menu';
import ModalFactory from './Modal';
import TabsFactory from './Tabs';
import ToggleFactory from './Toggle';
import TourFactory from './Tour';

const factories = [
  DropdownFactory,
  FlannelFactory,
  FlyoutFactory,
  MenuFactory,
  ModalFactory,
  TabsFactory,
  ToggleFactory,
  TourFactory
];

export function attachTo(element) {
  factories.forEach((Factory) => {
    try {
      new Factory(element);
    } catch (err) {
      console.error('Unable to create factory', err);
    }
  });
}

export {
  DropdownFactory,
  FlannelFactory,
  FlyoutFactory,
  MenuFactory,
  ModalFactory,
  TabsFactory,
  ToggleFactory,
  TourFactory
};
