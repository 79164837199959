import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { isNumber as _isNumber } from 'lodash';
import { RootState } from '../store';
import { FlashMessage } from '../../types';

export enum FlashMessageActionType {
  HIDE_FLASH_MESSAGE = 'HIDE_FLASH_MESSAGE',
  SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE',
  HIDE_ALL_FLASH_MESSAGES = 'HIDE_ALL_FLASH_MESSAGES'
}

export interface HideFlashMessageAction {
  type: FlashMessageActionType.HIDE_FLASH_MESSAGE;
  id: string;
}

export interface ShowFlashMessageAction extends Omit<FlashMessage, 'visible'> {
  type: FlashMessageActionType.SHOW_FLASH_MESSAGE;
}

export interface HideAllFlashMessagesAction {
  type: FlashMessageActionType.HIDE_ALL_FLASH_MESSAGES;
}

export type FlashMessageActions =
  | HideFlashMessageAction
  | ShowFlashMessageAction
  | HideAllFlashMessagesAction;

type Dispatch = ThunkDispatch<RootState, void, FlashMessageActions>;

export const hideFlashMessage = (id: string): HideFlashMessageAction => ({
  type: FlashMessageActionType.HIDE_FLASH_MESSAGE,
  id
});

export interface ShowFlashMessageActionInput extends Omit<FlashMessage, 'visible'> {
  hideAfterMS?: number;
}

export const showFlashMessage =
  ({
    kind,
    id,
    message,
    hideAfterMS,
    helpMessage,
    helpUrl,
    alignLeft
  }: ShowFlashMessageActionInput): ThunkAction<void, RootState, void, FlashMessageActions> =>
  (dispatch: Dispatch) => {
    dispatch({
      type: FlashMessageActionType.SHOW_FLASH_MESSAGE,
      id,
      kind,
      message,
      helpMessage,
      helpUrl,
      alignLeft
    });

    if (hideAfterMS && _isNumber(hideAfterMS)) {
      setTimeout(() => {
        dispatch(hideFlashMessage(id));
      }, hideAfterMS);
    }
  };

export const hideAllFlashMessages = (): HideAllFlashMessagesAction => ({
  type: FlashMessageActionType.HIDE_ALL_FLASH_MESSAGES
});
