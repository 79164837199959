import 'ace-builds/src-noconflict/mode-text';

/**
 * This is derived from the default SQL mode for the ace editor
 * See https://github.com/ajaxorg/ace/blob/master/lib/ace/mode/sql_highlight_rules.js for the original
 */
export const KEYWORDS = [
  // Keywords
  'SELECT',
  'DISTINCT',
  'EXCEPT',
  'FROM',
  'AS',
  'WHERE',
  'GROUP',
  'HAVING',
  'ORDER',
  'BY',
  'LIMIT',
  'OFFSET',
  'SEARCH',

  // Joins
  'JOIN',
  'ON',
  'INNER',
  'OUTER',
  'LEFT',
  'RIGHT',
  'FULL',
  'LATERAL',

  // Order direction
  'ASC',
  'DESC',

  // Set operators
  'UNION',
  'INTERSECT',
  'MINUS',
  'ALL',

  // Primitive Boolean operators
  'IS',
  'BETWEEN',
  'LIKE',
  'IN',
  'AND',
  'OR',
  'NOT'
];

export class SoqlHighlightRules extends window.ace.acequire('ace/mode/text_highlight_rules').TextHighlightRules {
  constructor() {
    super();

    // Note that Ace isn't case sensitive so these actually all show up as lowercase in autocomplete :(
    const keywords = KEYWORDS.join('|');

    const builtinConstants = 'true|false|$id|null';

    const keywordOperators = 'and|or';

    const keywordMapper = this.createKeywordMapper(
      {
        'keyword': keywords,
        'constant.language': builtinConstants,
        'keyword.operator': keywordOperators
      },
      'identifier',
      true
    );

    this.$rules = {
      'start' : [
        {
          // " string
          token : 'string',
          regex : '".*?"'
        },
        {
          // ' string
          token : 'string',
          regex : "'.*?'"
        },
        {
          // [cast|any-column-field-name]::[type]
          token : 'identifier',
          regex : '\\w+::\\w+'
        },
        {
          // float
          token : 'constant.numeric',
          regex : '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b'
        },
        {
          // keywords listed above
          token : keywordMapper,
          regex : '[a-zA-Z_$][a-zA-Z0-9_$]*\\b'
        },
        {
          // operators
          token : 'keyword.operator',
          regex : [
            '\\+', // +
            '\\-', // -
            '\\/',
            '\\/\\/',
            '%',
            '&',
            '\\^',
            '~',
            '<',
            '>',
            '<=',
            '=>',
            '==',
            '!=',
            '<>',
            '=',
            '\\|\\|', // operator: ||
            '\\|>' // operator: |>
          ].join('|')
        },
        {
          // left parenthesis
          token : 'paren.lparen',
          regex : '[\\(]'
        },
        {
          // right parenthesis
          token : 'paren.rparen',
          regex : '[\\)]'
        },
        {
          // any old text
          token : 'text',
          regex : '\\s+'
        }
      ]
    };

    this.normalizeRules();
  }
}

export default class SoQLMode extends window.ace.acequire('ace/mode/text').Mode {
  constructor() {
    super();
    this.HighlightRules = SoqlHighlightRules;
  }
}
