// We have both a mobile and a desktop version of the filters. We don't want to duplicate the logic
// that figures out what's available when.
import {FeatureFlags} from 'common/feature_flags';
import {
    Tab,
    TAB_ALL_ASSETS,
    TAB_FEDERATED,
    TAB_MY_ASSETS,
    TAB_MY_QUEUE
} from 'common/components/AssetBrowser/lib/constants';
import { summarizeCanReviewWorkflow } from 'common/core/approvals/index_new';

interface EnabledFilters {
    intendedAudience: boolean
    enrolledInArchival: boolean;
    recentlyViewed: boolean;
    version: boolean;
    authority: boolean;
    ownedBy: boolean;
    visibility: boolean;
    source: boolean;
    category: boolean;
    tag: boolean;
    customFacet: boolean;
    requester: boolean;
    // awaitingApproval is also a filter, but its visibility is controlled by a prop.
}

const defaultFilters: EnabledFilters = {
    intendedAudience: false,
    enrolledInArchival: FeatureFlags.valueOrDefault('enable_asset_archival', false),
    recentlyViewed: true,
    version: true,
    authority: true,
    ownedBy: true,
    visibility: true,
    source: false,
    category: true,
    tag: true,
    customFacet: true,
    requester: false
};

const allAssetsFilters: EnabledFilters = {
    intendedAudience: false,
    enrolledInArchival: FeatureFlags.valueOrDefault('enable_asset_archival', false),
    recentlyViewed: true,
    version: true,
    authority: true,
    ownedBy: true,
    visibility: true,
    source: false,
    category: true,
    tag: true,
    customFacet: true,
    requester: false
};

const myAssetsFilters: EnabledFilters = {
    intendedAudience: false,
    enrolledInArchival: FeatureFlags.valueOrDefault('enable_asset_archival', false),
    recentlyViewed: true,
    version: true,
    authority: false,
    ownedBy: false,
    visibility: true,
    source: false,
    category: true,
    tag: true,
    customFacet: true,
    requester: false
};

const federatedFilters: EnabledFilters = {
    intendedAudience: false,
    enrolledInArchival: FeatureFlags.valueOrDefault('enable_asset_archival', false),
    recentlyViewed: true,
    version: true,
    authority: true,
    ownedBy: true,
    visibility: true,
    source: true,
    category: true,
    tag: true,
    customFacet: true,
    requester: false
};

const approvalsFilters: EnabledFilters = {
    intendedAudience: !!(FeatureFlags.valueOrDefault('strict_permissions', false) && summarizeCanReviewWorkflow().length > 1),
    enrolledInArchival: false,
    recentlyViewed: false,
    version: false,
    authority: false,
    ownedBy: false,
    visibility: false,
    source: false,
    category: false,
    tag: false,
    customFacet: false,
    requester: true
};

export const getEnabledFilters: (activeTab: Tab) => EnabledFilters = (activeTab: Tab) => {
    if (activeTab === TAB_ALL_ASSETS) {
        return allAssetsFilters;
    }

    if (activeTab === TAB_MY_ASSETS) {
        return myAssetsFilters;
    }

    if (activeTab === TAB_FEDERATED) {
        return federatedFilters;
    }

    if (activeTab === TAB_MY_QUEUE) {
        return approvalsFilters;
    }

    return defaultFilters;
};
