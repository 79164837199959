import { createReducer } from '@reduxjs/toolkit';
import { MetadataForm } from '../../types';
import {
  FormActionType,
  SetFormErrorsAction,
  SetFormStateAction,
  SetTemplateErrorsAction
} from '../actions/forms';

export interface FormsState {
  templateErrors: string[];
  metadataForm: MetadataForm;
}

export const initialState: FormsState = {
  templateErrors: [],
  metadataForm: {
    isDirty: false,
    showErrors: false,
    errors: {},
    submitted: false,
    state: undefined
  }
};

const formsReducer = createReducer<FormsState>(initialState, (builder) => {
  builder
    .addCase(
      FormActionType.markFormDirty,
      (state: FormsState): FormsState => ({
        ...state,
        metadataForm: {
          ...state.metadataForm,
          isDirty: true
        }
      })
    )
    .addCase(
      FormActionType.setFormErrors,
      (state: FormsState, action: SetFormErrorsAction): FormsState => ({
        ...state,
        metadataForm: {
          ...state.metadataForm,
          errors: action.errors
        }
      })
    )
    .addCase(
      FormActionType.setTemplateErrors,
      (state: FormsState, action: SetTemplateErrorsAction): FormsState => ({
        ...state,
        templateErrors: action.templateErrors
      })
    )
    .addCase(
      FormActionType.setFormState,
      (state: FormsState, action: SetFormStateAction): FormsState => ({
        ...state,
        metadataForm: {
          ...state.metadataForm,
          state: action.state
        }
      })
    )
    .addCase(
      FormActionType.markFormClean,
      (state: FormsState): FormsState => ({
        ...state,
        metadataForm: {
          ...state.metadataForm,
          isDirty: false
        }
      })
    )
    .addCase(
      FormActionType.markFormSubmitted,
      (state: FormsState): FormsState => ({
        ...state,
        metadataForm: {
          ...state.metadataForm,
          submitted: true
        }
      })
    )
    .addCase(
      FormActionType.markFormUnsubmitted,
      (state: FormsState): FormsState => ({
        ...state,
        metadataForm: {
          ...state.metadataForm,
          submitted: false
        }
      })
    )
    .addDefaultCase((state: FormsState): FormsState => {
      return state;
    });
});

export default formsReducer;
