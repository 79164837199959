import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import * as filters from 'common/components/AssetBrowser/actions/filters';

export class IntendedAudienceFilter extends Component {
  render() {
    const {
      showPublicTargetAudience,
      showInternalTargetAudience,
      togglePublicTargetAudience,
      toggleInternalTargetAudience
    } = this.props;

    const publicInputId = 'filter-public-target-audience';
    const internalInputId = 'filter-internal-target-audience';

    return (
      <div className="filter-section">
        <div className="checkbox checkbox-filter" data-testid="ab-filter-public-target">
          <input id={publicInputId} type="checkbox" onChange={togglePublicTargetAudience} checked={showPublicTargetAudience} />
          <label htmlFor={publicInputId}>
            <span className="fake-checkbox">
              <SocrataIcon name="checkmark3" />
            </span>
            {I18n.t('shared.asset_browser.filters.approval_types.public')}
          </label>
        </div>
        <div className="checkbox checkbox-filter" data-testid="ab-filter-internal-target">
          <input id={internalInputId} type="checkbox" onChange={toggleInternalTargetAudience} checked={showInternalTargetAudience} />
          <label htmlFor={internalInputId}>
            <span className="fake-checkbox">
              <SocrataIcon name="checkmark3" />
            </span>
            {I18n.t('shared.asset_browser.filters.approval_types.internal')}
          </label>
        </div>
      </div>
    );
  }
}

IntendedAudienceFilter.propTypes = {
  showPublicTargetAudience: PropTypes.bool,
  showInternalTargetAudience: PropTypes.bool,
  togglePublicTargetAudience: PropTypes.func.isRequired,
  toggleInternalTargetAudience: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  showPublicTargetAudience: state.filters.showPublicTargetAudience,
  showInternalTargetAudience: state.filters.showInternalTargetAudience
});

const mapDispatchToProps = (dispatch) => ({
  togglePublicTargetAudience: () => dispatch(filters.togglePublicTargetAudience()),
  toggleInternalTargetAudience: () => dispatch(filters.toggleInternalTargetAudience())
});

export default connect(mapStateToProps, mapDispatchToProps)(IntendedAudienceFilter);
