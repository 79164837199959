import React, { Component } from 'react';
import PickList from '../Picklist';

import './index.scss';

export class HorizontalPicklist extends Component {
  render() {
    return (
      <div className="horizontal-picklist">
        <PickList {...this.props} horizontal />
      </div>
    );
  }
}

export default HorizontalPicklist;
