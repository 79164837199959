import { configureStore } from '@reduxjs/toolkit';
import formsReducer from './reducers/forms';
import metadataChannelReducer from './reducers/metadataChannel';
import flashMessageReducer from './reducers/flashMessage';
import uploadFileReducer from './reducers/uploadFile';

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  reducer: {
    forms: formsReducer,
    metadataChannel: metadataChannelReducer,
    flashMessage: flashMessageReducer,
    uploadFiles: uploadFileReducer
  }
});

export type RootState = ReturnType<typeof store['getState']>;
export type AppDispatch = typeof store['dispatch'];

export default store;
