import { formatDateWithLocale } from 'common/dates';
import { defaultHeaders } from 'common/http';
import * as _ from 'lodash';
import { isArray } from 'lodash';
import { Socket, LongPoll } from 'phoenix';
import { none, Option, some } from 'ts-option';
import { isIE11 } from 'common/notifications/api/helper';
import { FeatureFlags } from 'common/feature_flags';
import { Json } from 'common/components/ObjectEditor';


const IE11 = isIE11();

export interface DsmapiResource<T> {
  resource: T;
  links: {
    [name: string]: string;
  };
}

interface ValidationError {
  path: string;
  reasons: string[];
}

type FieldErrors = string[];
interface BadRequestDetails {
  [k: string]: BadRequestDetails | FieldErrors;
}


export interface TransformOk {
  ok: Json;
}
export interface TransformError {
  // wut...is this sum type really true....if so that's dumb
  error: { message: string | { english: string }}
}
export type TransformResult = TransformOk | TransformError;
export const isOkTransformResult = (c: TransformResult): c is TransformOk => _.has(c, 'ok');
export const isErrorTransformResult = (c: TransformResult): c is TransformError => _.has(c, 'error');

export const isDsmapiBadRequest = (e: any): e is BadRequest => _.has(e, 'key') && e.key === 'validation_failed';

export interface BadRequest {
  params: {
    // ugh ugh ugh
    [k: string]: BadRequestDetails | string[];
  };
  message: string;
  english: string;
  key: 'validation_failed';
}

type SimpleHeader = { value: string, key: string };

type OkHandler<T> = (t: T, headers: SimpleHeader[], body: any) => void;
type ErrorHandler = (e: ResourceError) => void;
type FailureHandler = () => void;

export class ResourceError {
  public status: number;
  public response: any;

  constructor(status: number, response: any) {
    this.status = status;
    this.response = response;
  }

  public validationErrors(): ValidationError[] {
    if (_.get(this.response, 'key') === 'validation_failed') {
      const params = _.get(this.response, 'params');
      const paths = (params.invalid_keys as string[]) || [];
      return paths.map(path => {
        return {
          path,
          reasons: _.get(params, path, []) as string[]
        };
      });
    }
    return [];
  }
}

export interface ResourceProvider<T> {
  ok(h: OkHandler<T>): ResourceProvider<T>;
  error(h: ErrorHandler): ResourceProvider<T>;
  failure(h: FailureHandler): ResourceProvider<T>;
  run(): void;
}

function isResource<T>(b: any): b is DsmapiResource<T> {
  return 'resource' in b;
}

export class DsmapiHttpResource<T> implements ResourceProvider<T> {
  private _p: Option<Promise<Response>> = none;
  private _onOk: Option<OkHandler<T>> = none;
  private _onError: Option<ErrorHandler> = none;
  private _onFailure: Option<FailureHandler> = none;

  constructor(p: Promise<Response>) {
    this._p = some(p);
  }

  public run(): void {
    this._p.map((p) => p.then(async response => {

      const body = await response.json();
      if (response.ok) {
        // argh this was a mistake
        // gahhhhhhh this was a horrible mistake
        // why
        let targetType: T;
        if (isResource<T>(body)) {
          targetType = body.resource;
        } else if (isArray(body)) {
          targetType = body.map(b => b.resource) as unknown as T;
        }
        const headers: SimpleHeader[] = [];
        response.headers.forEach((value, key) => {
          headers.push({ value, key });
        });

        this._onOk.map(h => h(targetType, headers, body));
      } else {
        this._onError.map(h => h(new ResourceError(response.status, body)));
      }
    }).catch(e => {
      this._onFailure.map(f => f());
    }));
  }

  public ok(h: OkHandler<T>): ResourceProvider<T> {
    this._onOk = some(h);
    return this;
  }

  public error(h: ErrorHandler): ResourceProvider<T> {
    this._onError = some(h);
    return this;
  }

  public failure(h: FailureHandler): ResourceProvider<T> {
    this._onFailure = some(h);
    return this;
  }
}

export class WrappedResource<T> implements ResourceProvider<T> {
  private _t: Option<T>;
  private _onOk: Option<OkHandler<T>> = none;

  constructor(t: T) {
    this._t = some(t);
  }

  public run(): void {
    this._t.map((resource: T) => {
      this._onOk.forEach(ok => ok(resource, [], { resource }));
    });
  }

  public ok(h: OkHandler<T>): ResourceProvider<T> {
    this._onOk = some(h);
    return this;
  }

  public error(h: ErrorHandler): ResourceProvider<T> {
    return this;
  }

  public failure(h: FailureHandler): ResourceProvider<T> {
    return this;
  }
}


const resource = <T>(path: string, method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH', body: any | null = null): ResourceProvider<T> => {
  const options: any = {}; // argh tsc
  if (body) {
    options.body = JSON.stringify(body);
  }
  return new DsmapiHttpResource<T>(fetch(`/api/publishing/v1${path}`, {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method,
    ...options
  }));
};

export const get = <T>(route: string): ResourceProvider<T> => resource(route, 'GET');
export const put = <T>(route: string, body: any | null = null): ResourceProvider<T> => resource(route, 'PUT', body);
export const post = <T>(route: string, body: any | null = null): ResourceProvider<T> => resource(route, 'POST', body);
export const del = <T>(route: string): ResourceProvider<T> => resource(route, 'DELETE');

export interface PhxSocket {
  connect: () => void;
  channel: (topic: string) => PhxChannel;
  channels: PhxChannel[];
  onOpen: (callback: () => void) => void;
  onError: (callback: () => void) => void;
}
export type PhxResponseStatus = 'ok' | 'error' | 'timeout';
export type PhxResponseCb = (resp: any) => void;
export interface PhxResponder {
  receive: (status: PhxResponseStatus, cb: PhxResponseCb) => PhxResponder;
}
export interface PhxChannel {
  join: (timeout?: number) => PhxResponder;
  push: (event: string, payload?: any, timeout?: number | null) => PhxResponder;
  receive: <T>(event: string, t: T) => void;
  on: <T>(event: string, t: T) => void;
  off: (event: string) => void;
  leave: () => void;
  topic: string;
}

export const socket = (fourfour?: string, token?: string, forceLongPolling = false): PhxSocket => {
  if (window['socket']) return window['socket'] as PhxSocket;
  const params = {
    token: token || _.get(window, 'initialState.websocketToken')
  };
  if (fourfour) {
    params['fourfour'] = fourfour;
  }

  let options: any = { params };
  const reallyForceLongPolling = IE11 || forceLongPolling || FeatureFlags.valueOrDefault('force_longpolling_due_to_proxies', false);
  if (reallyForceLongPolling) {
    options = {
      ...options,
      transport: LongPoll
    };
  }

  /* eslint @typescript-eslint/no-shadow: "warn" */
  const socket = new Socket('/api/publishing/v1/socket', options) as unknown as PhxSocket;
  window['socket'] = socket;
  socket.connect();
  return socket;
};

export function formatDsmapiDateWithLocale(ts: string | undefined, withTime = true) {
    let utc = ts;
    if (ts && !ts.endsWith('Z')) {
      // urgh this is a dumb dsmapi thing
      // if we ever fix serialization to actually add the Z, then this function can
      // just not do anything.
      // naivedatetime which we know is UTC to actual ISO format :(
      utc = `${ts}Z`;
    }
    return formatDateWithLocale(utc, withTime);
}

export interface DsmapiUser {
  display_name: string;
  user_id: string | null;
  email?: string;
}
