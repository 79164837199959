import $ from 'jquery';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import collapsible from 'common/collapsible';
import I18n, { categoryDisplay } from 'common/i18n';
import { purify } from 'common/purify';

import EditableText from '../EditableText';
import SocrataIcon from '../SocrataIcon';
import Footer from './Footer';
import { FOOTER, BODY } from './constants';

import './index.scss';

/**
 * The InfoPane is a component that is designed to render a hero element with useful information
 * about a dataset.  The component prominently features the title of the asset, a description that
 * is automatically ellipsified, various badges indicating the privacy, provenance, and category
 * of the asset, several areas for custom metadata to be displayed, and an area meant to be used
 * for buttons.
 */
class InfoPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paneCollapsed: props.isPaneCollapsible
    };

    _.bindAll(this, [
      'shouldEllipsify',
      'ellipsify',
      'toggleInfoPaneVisibility',
      'renderCollapsePaneToggle',
      'renderDescription',
      'renderFooter',
      'renderMetadata',
      'renderContent'
    ]);
  }

  componentDidMount() {
    this.$description = $(this.description);
    if (!this.props.isPaneCollapsible) {
      this.ellipsify();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.shouldEllipsify(prevProps, this.props)) {
      this.ellipsify();
    }
  }

  shouldEllipsify(prevProps, nextProps) {
    return (prevProps.description !== nextProps.description) && !this.props.isPaneCollapsible;
  }

  ellipsify() {
    if (_.isEmpty(this.description)) {
      return;
    }

    const { descriptionLines, onExpandDescription } = this.props;
    const descriptionLineHeight = 24;
    const height = descriptionLines * descriptionLineHeight;

    collapsible(this.description, {
      height,
      expandedCallback: onExpandDescription,
      toggleText: {
        more: I18n.t('shared.components.info_pane.more'),
        less: I18n.t('shared.components.info_pane.less')
      }
    });
  }

  toggleInfoPaneVisibility() {
    this.togglePaneButton.blur();

    this.setState({
      paneCollapsed: !this.state.paneCollapsed
    });
  }

  renderCollapsePaneToggle() {
    const { paneCollapsed } = this.state;
    const { isPaneCollapsible } = this.props;

    if (!isPaneCollapsible) {
      return null;
    }

    const buttonClassName = classNames('btn-transparent collapse-info-pane-btn', {
      'hide': false
    });
    const buttonContent = paneCollapsed ?
      <span>{I18n.t('shared.components.info_pane.more_info')} <SocrataIcon name="arrow-down" /> </span> :
      <span>{I18n.t('shared.components.info_pane.less_info')} <SocrataIcon name="arrow-up" /> </span>;

    return (
      <div className="collapse-info-pane-wrapper">
        <button
          className={buttonClassName}
          ref={(el) => this.togglePaneButton = el}
          onClick={this.toggleInfoPaneVisibility}>
          {buttonContent}
        </button>
      </div>
    );
  }

  renderDescription() {
    const { description, isPaneCollapsible } = this.props;
    if (_.isEmpty(description)) {
      return null;
    }

    const descriptionContainerClassName = classNames('entry-description-container collapsible', {
      'pane-collapsible': isPaneCollapsible
    });

    return (
      <div className={descriptionContainerClassName}>
        <div className="collapsible-content entry-description" ref={(el) => this.description = el}>
          <div dangerouslySetInnerHTML={{ __html: purify(description) }} />
        </div>
      </div>
    );
  }

  renderFooter() {
    const { dataSourceDetails } = this.props;
    const { location, primaryDataSource } = dataSourceDetails || {};
    if (_.isEmpty(primaryDataSource) || location != FOOTER) {
      return null;
    }

    return (
      <div className="entry-meta first">
        <div className="entry-meta topics">
          <Footer dataSourceDetails={dataSourceDetails} />
        </div>
      </div>
    );
  }

  renderMetadata() {
    const { metadata, subscribed, showWatchDatasetFlag, onWatchDatasetFlagClick } = this.props;

    if (!metadata) {
      return null;
    }

    const enableNotifications = _.get(window, 'serverConfig.enableNotifications');
    const watchDatasetFlagIcon = classNames('flag-icon', subscribed ? 'socrata-icon-watched' : 'socrata-icon-watch');
    const watchDatasetFlag = (showWatchDatasetFlag && enableNotifications) ?
      <div className="watch-dataset-flag">
        <label
          className="inline-label manage-prompt-button"
          onClick={(event) => onWatchDatasetFlagClick(this.props, event)}>
          <span className={watchDatasetFlagIcon}></span>
        </label>
      </div> : null;

    // TODO: The .updated and .views class names are confusing. They originally
    // made sense when the metadata fields were always updated-at and view-count,
    // but that is no longer the case.
    const metadataLeft = metadata.first ?
      <div className="entry-meta updated">
        <div className="update-content">
          <span className="meta-title">{metadata.first.label}</span>
          {' '}
          <span className="date">{metadata.first.content}</span>
        </div>
        {watchDatasetFlag}
      </div> : null;

    const metadataRight = metadata.second ?
      <div className="entry-meta views">
        <div className="update-content">
          <span className="meta-title">{metadata.second.label}</span>
          {' '}
          <span className="date">{metadata.second.content}</span>
        </div>
        {metadata.first ? null : watchDatasetFlag}
      </div> : null;

    // TODO: Why is there a class of 'second' here? This element contains
    // both the first and second metadata entries.
    return (
      <div className="entry-meta second" ref={(el) => this.metadataPane = el}>
        {metadataLeft}
        {metadataRight}
      </div>
    );
  }

  renderContent() {
    const { isPaneCollapsible } = this.props;
    const { paneCollapsed } = this.state;

    const contentClassName = classNames('entry-content', {
      'hide': paneCollapsed && isPaneCollapsible
    });

    const description = this.renderDescription();
    const footer = this.renderFooter();
    const metadata = this.renderMetadata();

    if (description || footer || metadata) {
      return (
        <div className={contentClassName}>
          <div className="entry-main">
            {description}
            {footer}
          </div>

          {metadata}
        </div>
      );
    } else {
      return null;
    }
  }

  static iconToRender(isInternal, isPrivate) {
    if (isInternal) {
      return (<span
        className="socrata-icon-my-org"
        // Hide from aria since the screen reader is unable to focus on a span within a header
        // The information about dataset visibility is conveyed in publication-state-container
        aria-hidden="true"
        title={I18n.t('shared.components.info_pane.internal_notice')} />
      );
    } else if (isPrivate) {
      return (<span
        className="socrata-icon-private"
        aria-hidden="true"
        title={I18n.t('shared.components.info_pane.private_notice')} />
      );
    }
  }

  static getBodyDataSourceLink(dataSourceDetails) {
    const { location, primaryDataSource } = dataSourceDetails || {};

    if (_.isEmpty(primaryDataSource) || location != BODY) {
      return null;
    }

    const url = dataSourceDetails.primaryDataSource.url;
    const name = dataSourceDetails.primaryDataSource.name;
    return (<div className="based-on-parent">{I18n.t('shared.components.info_pane.view_based_on')} <a className="based-on-parent-link" href={url}>{name}</a></div>);
  }

  render() {
    const {
      category,
      dataSourceDetails,
      isInternal,
      isPrivate,
      name,
      onNameChanged,
      renderButtons,
      provenance,
      provenanceIcon,
      hideProvenance,
      isViewer,
      handleViewButtonClick,
      isDraft
    } = this.props;

    const categoryBadge = category ? <span className="tag-category">{categoryDisplay(category.toLowerCase()) || category}</span> : null;
    const buttons = renderButtons ? <div className="entry-actions">{renderButtons(this.props)}</div> : null;
    const parentViewSourceLink = InfoPane.getBodyDataSourceLink(dataSourceDetails);
    const provenanceBadge = (hideProvenance || !provenance) ? null :
      <span className={`tag-${provenance}`}>
        <span aria-hidden className={`socrata-icon-${provenanceIcon}`}></span>
        {I18n.t(`shared.components.info_pane.${provenance}`)}
      </span>;

    const nameElement = onNameChanged ?
      <EditableText onTextChanged={onNameChanged} text={name} /> :
      <span>{name}</span>;
    const showViewerButton = isDraft && isViewer && handleViewButtonClick;

    return (
      <div className="info-pane result-card">
        <div className="container">
          <div className="entry-header">
            <div className="entry-header-contents">
              <div className="entry-title">
                {provenanceBadge}
                <h1 className="info-pane-name">
                  {InfoPane.iconToRender(isInternal, isPrivate)}
                  <span>
                    {nameElement}
                  </span>
                </h1>
                {parentViewSourceLink}
                {categoryBadge}
              </div>
              {showViewerButton && <button
                className="info-pane-viewer-button btn btn-primary"
                onClick={handleViewButtonClick}>
                Review Data
              </button>}
              {buttons}
            </div>
            {this.renderCollapsePaneToggle()}
          </div>

          {this.renderContent()}
        </div>
      </div>
    );
  }
}

InfoPane.propTypes = {
  /**
   * An optional string representing the category of the asset.
   */
  category: PropTypes.string,

  /**
   * A string containing the description of the asset.  It will be ellipsified
   * and will have a control for expanding and collapsing the full description.  HTML is allowed
   * in the description; it will be sanitized to prevent security vulnerabilities.
   */
  description: PropTypes.string,

  /**
   * The number of lines to truncate the description to.  If unspecified, defaults to 4.
   */
  descriptionLines: PropTypes.number,

  // TODO: Decide if description should also be optionally editable.
  //       If yes, we need to update EditableText to allow multiple lines.

  /**
   * An optional object that represents the asset's data source.
   */
  dataSourceDetails: PropTypes.shape({

    /** The location of where in InfoPane to display the data source(s). */
    location: PropTypes.oneOf([BODY, FOOTER]),

    /** The asset's primary data source, or "parent view". */
    primaryDataSource: PropTypes.shape({
      /** The asset's primary data source's name. */
      name: PropTypes.string,
      /** The asset's primary data source's full url. */
      url: PropTypes.string
    }),

    /** An optional list of other data sources used by a visualization. */
    otherDataSources: PropTypes.arrayOf(
      PropTypes.shape({
        /** A dataset's full url. */
        url: PropTypes.string,
        /** A dataset's domain. */
        domain: PropTypes.string,
        /** A dataset's uid. */
        datasetUid: PropTypes.string
      })
    )
  }),

  /**
   * The provenance is used to display an authority badge icon and text.
   */
  provenance: PropTypes.oneOf(['official', 'community', null]),

  /**
   * The provenanceIcon is used to display the appropriate icon for the authority badge
   */
  provenanceIcon: PropTypes.oneOf(['official2', 'community', null]),

  /**
   * The hideProvenance is used to conditionally hide the authority badge depending on feature flags.
   */
  hideProvenance: PropTypes.bool,

  /**
   * If the isInternal prop is true, a badge indicating Internal visibility is displayed.
   */
  isInternal: PropTypes.bool,

  /**
   * If the isPrivate prop is true, a badge indicating Private visibility is displayed.
   */
  isPrivate: PropTypes.bool,

  /**
   * The metadata prop is an object meant to contain two arbitrary pieces of metadata about the
   * asset.  The two sections are named "first" and "second" and should be objects, each
   * containing a "label" and "content" key.  They are rendered to the right of the description.
   */
  metadata: PropTypes.shape({
    first: PropTypes.shape({
      label: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired
    }),
    second: PropTypes.shape({
      label: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired
    })
  }),

  /**
   * If the showWatchDatasetFlag prop is true, only then the watch-dataset-flag will be shown
   */
  showWatchDatasetFlag: PropTypes.bool,

  /**
   * A function that is called when the watch dataset flag clicked.
   */
  onWatchDatasetFlagClick: PropTypes.func,

  /**
   * The title of the asset, displayed in an h1 tag.
   */
  name: PropTypes.string,

  /**
   * Optional handler to enable editable name.
   */
  onNameChanged: PropTypes.func,

  /**
   * A function that is called when the full description is expanded.
   */
  onExpandDescription: PropTypes.func,

  /**
   * An optional function that should return content to be rendered in the upper-right hand corner
   * of the info pane.
   */
  renderButtons: PropTypes.func,

  /**
   * If the isPaneCollapsible prop is true, only the the InfoPane header is initially visible
   * and a More Info/Less Info toggle allows the InfoPane content to be shown
   */
  isPaneCollapsible: PropTypes.bool,

  /**
   * Does the user have a view-only grant on the dataset
   */
  isViewer: PropTypes.bool,

  /**
   * Is this component being used on a draft or a published dataset
   */
  isDraft: PropTypes.bool,

  /**
   * Callback for button that appears for view-only users when viewer draft
   */
  handleViewButtonClick: PropTypes.func
};

InfoPane.defaultProps = {
  descriptionLines: 4,
  onExpandDescription: _.noop,
  isPaneCollapsible: false
};

export default InfoPane;
