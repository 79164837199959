/* Imports */
/* ============================================================================= */
import { forEach } from 'lodash';

import { CheckMethod, GuidanceCheckSummary } from 'common/core/approvals/Types';
import { WORKFLOWS, createDefaultGuidanceCheckSummary } from '.';





/* Method */
/* ============================================================================= */
/**
 * Runs the provided check methods
 * @param method CheckMethod to run for each workflow
 * @param defaultValue The default/falsey value to use for each workflow that fails the check
 * @returns GuidanceCheckSummary object with attribute values representing the result of running the provided check method for each workflow.
 */
export const runCheckMethodForWorkflows = <T, D = T>(
  method: CheckMethod<T>,
  defaultValue: D
): GuidanceCheckSummary<T | D> => {
  const response = createDefaultGuidanceCheckSummary<T | D>(defaultValue);

  // Run the check method against each workflow
  forEach(WORKFLOWS, (workflow) => {
    const result = method(workflow);

    if (result) {
      response[workflow] = result;
      response.length++;
    }
  });

  return response;
};

export default runCheckMethodForWorkflows;
