/*
* From the MDN docs on encodeURIComponent:
* To be more stringent in adhering to RFC 3986 (which reserves !, ', (, ), and *),
* even though these characters have no formalized URI delimiting uses, the following can be safely used:
*/
export default (str) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
};
