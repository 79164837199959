import React from 'react';
import { Provider } from 'react-redux';
import ManageMetadata from '../containers/ManageMetadataContainer';
import store from './store';

export type ReduxWrapperProps = React.ComponentProps<typeof ManageMetadata>;

const MetadataModalComponentReduxWrapper: React.FunctionComponent<ReduxWrapperProps> = (ownProps) => {
  return (
    <Provider store={store}>
      <ManageMetadata {...ownProps} />
    </Provider>
  );
};

export default MetadataModalComponentReduxWrapper;
