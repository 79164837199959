import { DomainRolesMaskApi, Configuration } from '@socrata/core-domain-roles-mask-api';
import { defaultHeaders } from 'common/http';

/**
 * Core DomainRoleMask API Client
 *
 * Generated from OpenAPI Specification that lives at:
 * https://github.com/socrata/apis/blob/main/specs/domain_roles_mask/domain_roles_mask.yaml
 */
export default new DomainRolesMaskApi(
  new Configuration({
    credentials: 'same-origin',
    basePath: window.location.origin,
    headers: defaultHeaders
  })
);
