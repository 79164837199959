export const QUERY_CHANGED = 'QUERY_CHANGED';
export const queryChanged = query => ({
  type: QUERY_CHANGED,
  query
});

export const ACTION_LIST_ITEM_CLICKED = 'ACTION_LIST_ITEM_CLICKED';
export const actionListItemClicked = action => ({
  type: ACTION_LIST_ITEM_CLICKED,
  action
});

export const FETCH_VIEW = 'FETCH_VIEW';
export const fetchView = () => ({
  type: FETCH_VIEW
});

export const FETCH_VIEW_SUCCESS = 'FETCH_VIEW_SUCCESS';
export const fetchViewSuccess = view => ({
  type: FETCH_VIEW_SUCCESS,
  view
});

export const FETCH_VIEW_FAIL = 'FETCH_VIEW_FAIL';
export const fetchViewFail = error => ({
  type: FETCH_VIEW_FAIL,
  error
});

export const FETCH_ROWS = 'FETCH_ROWS';
export const fetchRows = (collocating) => ({
  type: FETCH_ROWS,
  collocating
});

export const FETCH_ROWS_SUCCESS = 'FETCH_ROWS_SUCCESS';
export const fetchRowsSuccess = (columns, rows) => ({
  type: FETCH_ROWS_SUCCESS,
  columns,
  rows
});

export const FETCH_ROWS_FAIL = 'FETCH_ROWS_FAIL';
export const fetchRowsFail = error => ({
  type: FETCH_ROWS_FAIL,
  error
});

export const SUCCESS_CALLBACK_CALLED = 'SUCCESS_CALLBACK_CALLED';
export const successCallbackCalled = () => ({
  type: SUCCESS_CALLBACK_CALLED
});

export const SHOW_DOCUMENTATION = 'SHOW_DOCUMENTATION';
export const showDocumentation = (docType, name) => ({
  type: SHOW_DOCUMENTATION,
  docType,
  name
});

export const HIDE_DOCUMENTATION = 'HIDE_DOCUMENTATION';
export const hideDocumentation = () => ({
  type: HIDE_DOCUMENTATION
});

export const CLOSE_QUERY_EDITOR = 'CLOSE_QUERY_EDITOR';
export const closeQueryEditor = () => ({
  type: CLOSE_QUERY_EDITOR
});

export const EDITOR_LOADED = 'EDITOR_LOADED';
export const editorLoaded = (editorInstance) => ({
  type: EDITOR_LOADED,
  editorInstance
});
