import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

import ActionItem from '../action_item';
import { currentUserHasRight } from 'common/current_user';
import { ViewFlag } from 'common/types/view';
import hasFlag from 'common/views/has_flag';
import { showToastNow, showToastOnPageReload, ToastType } from 'common/components/ToastNotification/Toastmaster';
import { setHideFromPublicCatalogs } from 'common/core/views_service';
import I18n from 'common/i18n';

import type { View } from 'common/types/view';
import DomainRights from 'common/types/domainRights';

const scope = 'shared.asset_browser.result_list_table';

class ToggleCatalogVisibility extends Component<{ view: View }> {
  shouldRender = () => {
    const { view } = this.props;
    if (isEmpty(view)) {
      return false;
    }

    // user must have the appropriate right and the asset must not be federated
    return (
      currentUserHasRight(DomainRights.edit_others_datasets) &&
      !hasFlag(view, ViewFlag.InternalToPublicFederatedView) &&
      isEmpty(view?.sourceDomainCName)
    );
  };

  onClick = (view: View): void => {
    setHideFromPublicCatalogs(view.id, !isHidden(view))
      .then(() => {
        showToastOnPageReload({
          type: ToastType.SUCCESS,
          content: I18n.t(`toasts.${actionLabel(view)}_success`, { name: view.name, scope })
        });

        window.location.reload();
      })
      .catch((err: ErrorEvent) => {
        showToastNow({
          type: ToastType.ERROR,
          content: I18n.t('toasts.unknown_error', { scope })
        });

        console.error(`Error updating catalog visibility for ${view.id}`, err);
      });
  };

  render() {
    if (!this.shouldRender()) {
      return null;
    }

    const { view } = this.props;

    return (
      <ActionItem
        label={I18n.t(`action_dropdown.${actionLabel(view)}`, { scope })}
        onClick={() => {
          this.onClick(view);
        }}
      />
    );
  }
}

const isHidden = (view: View) => view?.hideFromCatalog === true;
const actionLabel = (view: View) => (view?.hideFromCatalog ? 'show_in_catalog' : 'hide_from_catalog');

export default ToggleCatalogVisibility;
