import React from 'react';
import { getIconNameForDisplayType } from 'common/displayTypeMetadata';
import SocrataIcon from '../SocrataIcon';

import './index.scss';
import _ from 'lodash';

interface AssetTypeIconProps {
  displayType: string;
  className: string;
  isPublished: boolean;
  tooltip: string;
}

class AssetTypeIcon extends React.Component<AssetTypeIconProps> {
  public static defaultProps = {
    className: '',
    isPublished: true
  };

  getSvgName() {
    const { displayType, isPublished } = this.props;
    return getIconNameForDisplayType(displayType, isPublished);
  }

  render() {
    const { displayType, className, tooltip } = this.props;
    const name = this.getSvgName();
    const accesssible_id = name + '-' + _.uniqueId();

    return (<SocrataIcon
      accesssibleId = {accesssible_id}
      name={name}
      className={`asset-type-icon ${className}`}
      data-type={displayType}
      title={tooltip} />);
  }
}

export default AssetTypeIcon;
