import formatString from 'common/js_utils/formatString';
import { fetchTranslation } from 'common/locale';
import { Attachment, License, MetadataChanges, SortBy, Tag } from 'common/types/revision';
import _ from 'lodash';
import React from 'react';
import { IconName } from '../SocrataIcon';
import './AssetChanges.scss';
import ChangesWithDetails from './ChangesWithDetails';
import { pastOrPresentChangeLabel, Tense } from './util';

const scope = 'shared.components.asset_changes.apply_metadata';
const t = (k: string) => fetchTranslation(k, scope);
const withTense = pastOrPresentChangeLabel(scope);

type Changes = (JSX.Element | null)[] | JSX.Element | null;

function diff<T>(
  name: string,
  oldv: T | undefined,
  newv: T | undefined,
  label: (t: T) => string,
  tense: Tense
): Changes {
  const oldOrNull = oldv === undefined ? null : oldv;
  const newOrNull = newv === undefined ? null : newv;
  if (_.isEqual(oldOrNull, newOrNull)) return [];

  const key = `change-${name}`;

  if (oldOrNull && newOrNull) {
    return [
      <li key={key}>
        {formatString(withTense('changed_from', tense), {
          name,
          oldValue: label(oldOrNull),
          newValue: label(newOrNull)
        })}
      </li>
    ];
  }

  if (oldOrNull && !newOrNull) {
    return [<li key={key}>{formatString(withTense('was_removed', tense), { name })}</li>];
  }

  if (newOrNull && !oldOrNull) {
    return [
      <li key={key}>
        {formatString(withTense('was_set_to', tense), {
          name,
          newValue: label(newOrNull)
        })}
      </li>
    ];
  }


  return [];
}

const allKeysOf = (oldv: any, newv: any) => _.uniq(Object.keys(oldv || {}).concat(Object.keys(newv || {})));

const customFieldDiffer: Differ = (_keyname: string, oldv: any, newv: any, tense: Tense) => {
  const fieldSetNames = allKeysOf(oldv, newv);

  return _.flatMap(fieldSetNames, (key) => {
    const oldSet = (oldv || {})[key];
    const newSet = (newv || {})[key];

    if (!_.isEqual(oldSet, newSet)) {
      const setMembers = allKeysOf(oldSet, newSet);
      return _.flatMap(setMembers, (m) => {
        return diff(m, (oldSet || {})[m], (newSet || {})[m], (v) => v, tense);
      });
    } else {
      return [];
    }
  });
};

function diffTags(_keyname: string, oldv: Tag[], newv: Tag[], tense: Tense) {
  const removed = _.difference(oldv || [], newv || []);
  const added = _.difference(newv || [], oldv || []);

  const changes = [];
  if (removed.length) {
    changes.push(
      <li key="tags-removed">
        {formatString(withTense('tags_removed', tense), { tags: removed.join(', ') })}
      </li>
    );
  }
  if (added.length) {
    changes.push(
      <li key="tags-added">{formatString(withTense('tags_added', tense), { tags: added.join(', ') })}</li>
    );
  }

  return changes;
}

function sortByDiffer(_keyname: string, oldv: SortBy[], newv: SortBy[], tense: Tense) {
  const hasSort = (s: SortBy[]) => s.length === 1;
  let label;

  if (!hasSort(oldv) && hasSort(newv)) {
    // a default sort has been added
    const [{ field_name: newName, ascending: newDir }] = newv;

    label = formatString(withTense('sort_added', tense), {
      newName,
      newDirection: newDir ? t('ascending') : t('descending')
    });
  } else if (hasSort(oldv) && !hasSort(newv)) {
    // default sort has been removed
    label = withTense('sort_removed', tense);
  } else if (hasSort(oldv) && hasSort(newv)) {
    // the default sort has been changed
    const [{ field_name: oldName, ascending: oldDir }] = oldv;
    const [{ field_name: newName, ascending: newDir }] = newv;

    label = formatString(withTense('sort_changes', tense), {
      oldName,
      newName,
      oldDirection: oldDir ? t('ascending') : t('descending'),
      newDirection: newDir ? t('ascending') : t('descending')
    });
  }

  return label ? <li key="sort-changes">{label}</li> : null;
}

function attachmentsDiffer(_keyname: string, oldv: Attachment[], newv: Attachment[], tense: Tense) {
  const deletions = _.differenceBy(oldv, newv, (a) => a.asset_id).map((a) =>
    formatString(withTense('attachment_removed', tense), { name: a.name })
  );
  const additions = _.differenceBy(newv, oldv, (a) => a.asset_id).map((a) =>
    formatString(withTense('attachment_added', tense), { name: a.name })
  );
  const changes = _.flatMap(
    _.intersectionBy(newv, oldv, (a) => a.asset_id),
    (newAttachment) => {
      // it shouldn't really ever be undefined, but just for fun...
      const oldAttachment: Attachment | undefined = _.find(
        oldv,
        (a) => a.asset_id === newAttachment.asset_id
      );
      if (oldAttachment && oldAttachment.name !== newAttachment.name) {
        return [
          formatString(withTense('attachment_changes', tense), {
            oldName: oldAttachment.name,
            newName: newAttachment.name
          })
        ];
      } else {
        return [];
      }
    }
  );

  return _.concat(
    additions.map((label) => ({ label, klass: 'created-item' })),
    deletions.map((label) => ({ label, klass: 'deleted-item' })),
    changes.map((label) => ({ label, klass: 'changed-item' }))
  ).map(({ label, klass }, i) => (
    <li className={klass} key={`attachment-${i}`}>
      {label}
    </li>
  ));
}

type Differ = (keyname: string, oldv: any, newv: any, tense: Tense) => Changes;

const simpleDiffer = (keyname: string, oldv: string, newv: string, tense: Tense) =>
  diff(t(keyname), oldv, newv, (value) => value, tense);
const licenseDiffer = (_k: string, oldv: License, newv: License, tense: Tense) =>
  diff(t('license'), oldv, newv, (license) => license.name, tense);
const rowLabelDiffer = (_k: string, oldv: string, newv: string, tense: Tense) =>
  diff(t('row_label'), oldv, newv, (v) => v, tense);
const contactEmailDiffer = (_k: string, oldv: string, newv: string, tense: Tense) =>
  diff(t('contact_email'), oldv, newv, (v) => v, tense);

const queryStringDiffer = (_k: string, oldv: string, newv: string, tense: Tense) => {
  return (
    <div>
      <p>{withTense('query_string', tense)}</p>
      <pre>{oldv}</pre>
      <p>{t('to')}</p>
      <pre>{newv}</pre>
    </div>
  );
};

const differs: Record<string, Differ> = {
  name: simpleDiffer,
  description: simpleDiffer,
  tags: diffTags,
  license: licenseDiffer,
  category: simpleDiffer,
  attributionLink: simpleDiffer,
  queryString: queryStringDiffer,
  'metadata.custom_fields': customFieldDiffer,
  'metadata.rowLabel': rowLabelDiffer,
  'privateMetadata.contactEmail': contactEmailDiffer,
  'privateMetadata.custom_fields': customFieldDiffer,
  attachments: attachmentsDiffer,
  sorts: sortByDiffer,
  resourceName: simpleDiffer,
};

function getChanges(step: MetadataDiff, tense: Tense): Changes {
  const d = step.diff;
  if (!d) return [];
  return _.compact(
    _.flatMap(Object.keys(differs), (keyname) => {
      const newv = _.get(d['new'], keyname, null); // eslint-disable-line
      const oldv = _.get(d.old, keyname, null);

      if (_.isEqual(oldv, newv)) return [];
      return differs[keyname](keyname, oldv, newv, tense);
    })
  );
}

function hasChanges(changes: Changes) {
  return changes && _.isArray(changes) && changes.length > 0;
}

interface MetadataDiff {
  diff?: {
    old: MetadataChanges;
    new: MetadataChanges;
  };
}
function MetadataChange({ step, tense }: { step: MetadataDiff; tense: Tense }) {
  const changes = getChanges(step, tense);
  if (!hasChanges(changes)) return null;

  const contents = <ul className="changes change-list">{changes}</ul>;

  const details = t('details');

  return (
    <div className="asset-change-step">
      <ChangesWithDetails
        icon={IconName.Info}
        name={t('apply_metadata')}
        details={details}
        contents={contents}
      />
    </div>
  );
}

export default MetadataChange;
