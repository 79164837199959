import { connect, ConnectedProps } from 'react-redux';
import {
  uploadAttachment,
  editAttachment,
  removeAttachment,
  reorderAttachments,
  initializeFilesList
} from '../reduxStuff/actions/uploadFile';
import { RootState, AppDispatch } from '../reduxStuff/store';
import AttachmentsInput from '../components/inputs/attachments/attachmentsInput/AttachmentsInput';
import { Attachment } from 'common/types/revision';
import { FileUploadStatus, OnUploadAttachmentSignature } from '../types';

interface OwnProps {
  handleAttachmentChange: (changedAttachments: Attachment[]) => void;
  onUploadAttachment: OnUploadAttachmentSignature;
  attachments: Attachment[];
  buttonText: string;
}

const mapStateToProps = (state: RootState) => ({
  files: state.uploadFiles.files
});

// exported for testing only
export const mapDispatchToProps = (
  dispatch: AppDispatch,
  { handleAttachmentChange, attachments, onUploadAttachment }: OwnProps
) => ({
  initializeAttachmentsList: (): void => {
    dispatch(initializeFilesList(attachments));
  },
  uploadAttachment: async (file: File): Promise<void> => {
    try {
      const newAttachments = await dispatch(uploadAttachment(file, onUploadAttachment));
      handleAttachmentChange(newAttachments);
    } catch (error) {
      console.error(error);
    }
  },
  reorderAttachments: (reorderedFiles: FileUploadStatus[]) => {
    const updatedAttachments = dispatch(reorderAttachments(reorderedFiles));
    handleAttachmentChange(updatedAttachments);
  },
  removeAttachment: (fileToRemove: string) => {
    const updatedAttachments = dispatch(removeAttachment(fileToRemove));
    handleAttachmentChange(updatedAttachments);
  },
  editAttachment: (uploadId: string, newName: string) => {
    const updatedAttachments = dispatch(editAttachment(uploadId, newName));
    handleAttachmentChange(updatedAttachments);
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

// Everything the actual component will see, NOT the container's props
export type AttachmentsInputDirectProps = Pick<OwnProps, 'buttonText'> & PropsFromRedux;

export default connector(AttachmentsInput) as React.ComponentType<OwnProps>;
