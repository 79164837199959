import _ from 'lodash';
import { TABLE_SCREEN_BREAKPOINT } from 'common/visualizations/views/SvgConstants';
import { isMobileOrTablet } from 'common/visualizations/helpers/MediaQueryHelper';

const getInitialState = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
  isMobile: isMobileOrTablet()
});

export default (state, action) => {
  if (_.isUndefined(state)) {
    return getInitialState();
  }

  if (action.type === 'CHANGE_DIMENSIONS') {
    return {
      ...state,
      height: action.height,
      width: action.width,
      isMobile: action.width <= TABLE_SCREEN_BREAKPOINT
    };
  }

  return state;
};
