import React, { FunctionComponent } from 'react';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import useScreenSize from 'common/screen_size/useScreenSize';

interface MobileFacetSidebarTriggerProps {
  activeFacetsCount: number;
  onMobileDrawerOpen: () => void;
}

/** This component displays only when in mobile view, and its purpose is to open
 * the FacetSidebar drawer (which is hidden by default in mobile). The text of the
 * button displays the number of facets being filtered on, for example "Filters (2)".
 */
const MobileFacetSidebarTrigger: FunctionComponent<MobileFacetSidebarTriggerProps> = ({
  activeFacetsCount,
  onMobileDrawerOpen
}) => {
  const scope = 'shared.components.facet_sidebar.mobile_facet_sidebar_trigger';
  const isMobileView = useScreenSize();

  const mobileFacetsButtonContent =
    activeFacetsCount > 0
      ? I18n.t('filters_count', { scope, count: activeFacetsCount })
      : I18n.t('filters', { scope });

  return (
    <div>
      {isMobileView && (
        <ForgeButton type="outlined">
          <button type="button" onClick={onMobileDrawerOpen}>
            <ForgeIcon name="filter_variant" />
            <span>{mobileFacetsButtonContent}</span>
          </button>
        </ForgeButton>
      )}
    </div>
  );
};

export default MobileFacetSidebarTrigger;
