import InstallAgent from './InstallAgent';
import NameDownloadAgent from './NameDownloadAgent';
import SummaryNext from './SummaryNext';
import { FinishOptions, StepOptions } from 'common/components/hocs/withStepper/types';
import { fetchTranslation } from 'common/locale';

import './index.scss';

const t = (k: string) => fetchTranslation(k, 'shared.components.setup_agent');

export function finishOptions(
  showAvailablePlugins: () => void,
  hideModal: () => void
): FinishOptions[] {
  return [
    {
      key: t('finish_options.show_available_plugins.key'),
      title: t('finish_options.show_available_plugins.title'),
      description: t('finish_options.show_available_plugins.description'),
      action: () => showAvailablePlugins(),
      actionLabel: t('finish_options.show_available_plugins.action_label')
    },
    {
      key: t('finish_options.done.key'),
      title: t('finish_options.done.title'),
      description: null,
      action: hideModal,
      actionLabel: t('finish_options.done.action_label')
    }
  ];
}

export const steps: StepOptions[] = [
  { title: t('step_1'), component: NameDownloadAgent },
  { title: t('step_2'), component: InstallAgent },
  { title: t('step_3'), component: SummaryNext }
];

export const title = t('title');
