import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SocrataIcon from 'common/components/SocrataIcon';

// The user_type and display_name properties come directly from catalog service API responses
/* eslint-disable camelcase */

export class UserCell extends Component {
  isTeam = () => this.props.user.user_type === 'team';

  isInteractive = () => this.props.user.user_type === 'interactive';

  icon = () => {
    if (this.isTeam()) {
      return <SocrataIcon name="team" />;
    }
    if (this.isInteractive()) {
      return <SocrataIcon name="user" />;
    }
    console.warn('Unable to determine user_type for user', this.props.user);
    return <SocrataIcon name="user" />;
  };

  render() {
    const { user: { display_name } } = this.props;

    return <div className="user-cell">{this.icon()} {display_name}</div>;
  }
}

UserCell.propTypes = {
  user: PropTypes.shape({
    user_type: PropTypes.oneOf(['interactive', 'team']),
    display_name: PropTypes.string
  }).isRequired
};

export default UserCell;
