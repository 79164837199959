import ColumnOperations from 'common/components/AssetChanges/ColumnOperations';
import formatString from 'common/js_utils/formatString';
import { fetchTranslation } from 'common/locale';
import { SchemaChangeOperation } from 'common/types/taskSet';
import _ from 'lodash';
import React from 'react';
import { IconName } from '../SocrataIcon';
import './AssetChanges.scss';
import ChangesWithDetails from './ChangesWithDetails';
import { pastOrPresentChangeLabel, Tense } from './util';
const scope = 'shared.components.asset_changes.set_schema';
const t = (k: string) => fetchTranslation(k, scope);
const withTense = pastOrPresentChangeLabel(scope);


interface Props {
  step: {
    operations: SchemaChangeOperation[];
  };
  derivedViewWarning?: JSX.Element;
  tense: Tense;
}

function SetSchemaStep(props: Props) {
  const { step, derivedViewWarning } = props;
  if (step.operations.length === 0) return null;

  const operations = _.groupBy(step.operations, 'type');
  const creates = (operations.create_column || []);
  const deletes = (operations.drop_column || []);
  const updates = (operations.update_column || []);

  const contents = (
    <div className="column-operations">
      <ColumnOperations
        ops={creates}
        className="changes creation-list"
        tense={props.tense}
        name={withTense('additions', props.tense)} />
      <ColumnOperations
        ops={deletes}
        className="changes deletion-list"
        tense={props.tense}
        name={withTense('deletions', props.tense)} />
      <ColumnOperations
        ops={updates}
        className="changes change-list"
        tense={props.tense}
        name={withTense('updates', props.tense)} />
    </div>
  );

  const details = [];
  if (creates.length) {
    details.push(
      formatString(t(`count_column_additions.${creates.length > 1 ? 'plural' : 'singular'}`),{
        count: creates.length
      })
    );
  }
  if (deletes.length) {
    details.push(
      formatString(t(`count_column_deletions.${deletes.length > 1 ? 'plural' : 'singular'}`),{
        count: deletes.length
      })
    );
  }
  if (updates.length) {
    details.push(
      formatString(t(`count_column_updates.${updates.length > 1 ? 'plural' : 'singular'}`),{
        count: updates.length
      })
    );
  }

  return (
    <div className="asset-change-step">
      <ChangesWithDetails
        icon={IconName.ColumnInfo}
        name={t('schema_changes')}
        details={details.join(', ')}
        contents={contents}
        warnings={derivedViewWarning}/>
    </div>
  );
}


export default SetSchemaStep ;
