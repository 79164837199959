import * as _ from 'lodash';
import * as React from 'react';
import { Modal, ModalContent, ModalHeader } from 'common/components/Modal';
import PendoIdWrapper, { DisplayStyle } from 'common/components/PendoIdWrapper';
import PluginIcon from 'common/components/PluginIcon';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { Plugin } from 'common/types/gateway';
import { fetchTranslation } from 'common/locale';
const t = (k: string) => fetchTranslation(k, 'shared.components.available_plugins_modal');

import './index.scss';

interface PluginSearchProps {
  onChange: (val: string) => void;
  value: string;
}

function PluginSearch({ onChange, value }: PluginSearchProps) {
  return (
    <div className="plugin-control plugin-search">
      <SocrataIcon className="search-icon" name={IconName.Search} />
      <input
        type="text"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        name={t('search_plugins')}
        aria-label={t('search_available_plugins')}
        placeholder={t('search_available_plugins')}
        className="text-input"
      />
    </div>
  );
}

interface PluginTileProps {
  plugin: Plugin;
  setupPlugin: () => void;
  canSetupPlugin: boolean;
}

function PluginTile({ plugin, setupPlugin, canSetupPlugin }: PluginTileProps) {
  return (
    <PendoIdWrapper id={plugin.type} displayStyle={DisplayStyle.INLINE_BLOCK}>
      <div className="plugin">
        <PluginIcon plugin={plugin} />
        <div>
          <p>{plugin.display_name}</p>
          <a className={canSetupPlugin ? '' : 'disabled'} onClick={setupPlugin}>
            {t('show_setup')}
          </a>
        </div>
      </div>
    </PendoIdWrapper>
  );
}

interface ClassProps {
  showPluginHelp: (plugin: Plugin) => void;
  plugins: Plugin[];
  canSetupPlugins: boolean;
  hideModal: () => void;
}

interface StateProps {
  filterText: string;
}

class AvailablePluginsModal extends React.Component<ClassProps, StateProps> {
  state = {
    filterText: ''
  };

  setFilter = (v: string) => {
    this.setState({ filterText: v });
  };

  selectedPlugin = (plugin: Plugin) => {
    this.props.showPluginHelp(plugin);
  };

  filterPlugins = (plugin: Plugin) => {
    const ft = this.state.filterText.toLowerCase();
    if (ft === '') {
      return true;
    }
    return _.includes(plugin.display_name.toLowerCase(), ft) || _.includes(plugin.type.toLowerCase(), ft);
  };

  render() {
    const { hideModal, plugins, canSetupPlugins } = this.props;
    const filteredPlugins = _.filter(plugins, this.filterPlugins);
    const sortedFilteredPlugins = _.sortBy(filteredPlugins, 'display_name');

    let warning;
    if (!canSetupPlugins) {
      warning = <div className="alert info">{t('disabled')}</div>;
    }

    return (
      <PendoIdWrapper id="available-plugins-modal" displayStyle={DisplayStyle.BLOCK}>
        <Modal onDismiss={hideModal} className="available-plugins-modal">
          <ModalHeader title={t('title')} onDismiss={hideModal}>
            {warning}
            <div className="plugin-controls">
              <PluginSearch onChange={this.setFilter} value={this.state.filterText} />
            </div>
          </ModalHeader>
          <ModalContent>
            <div className="plugins">
              {sortedFilteredPlugins.map((p) => (
                <PluginTile
                  key={p.type}
                  plugin={p}
                  setupPlugin={() => this.selectedPlugin(p)}
                  canSetupPlugin={canSetupPlugins} />
              ))}
            </div>
          </ModalContent>
        </Modal>
      </PendoIdWrapper>
    );
  }
}

export default AvailablePluginsModal;
