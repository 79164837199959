import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import { fetchJson } from 'common/http';
import I18n from 'common/i18n';
import ScanResult, { ScanStatus } from 'common/components/ScanResult';
import { getEnvironment } from 'common/environment';

export class BlobDownload extends Component {
  constructor(props) {
    super(props);

    const { scanResult, scanResultUrl } = props;
    this.state = { scanResult };

    if (get(scanResult, 'state', ScanStatus.Unscanned) === ScanStatus.Unscanned && scanResultUrl) {
      this.pollForScanStatus();
    }
  }

  pollForScanStatus = (times = 0) => {
    const { scanResultUrl } = this.props;
    if (getEnvironment() === 'development') { // We do not scan in development.
      return;
    }
    if (times > 30) { // After 120 seconds, we probably won't get one, so stop polling.
      return;
    }
    fetchJson(scanResultUrl)
      .then(scanResult => {
        if (get(scanResult, 'state', ScanStatus.Unscanned) === ScanStatus.Unscanned) {
          setTimeout(() => this.pollForScanStatus(times + 1), 4000);
        } else {
          this.setState({ scanResult });
        }
      });
  };

  render() {
    const {
      showDownloadSection,
      blobFilename,
      downloadLink
    } = this.props;
    const { scanResult } = this.state;

    if (!showDownloadSection) {
      return null;
    }

    return (
      <section className="blob-download dataset-download-section">
        <h2>{I18n.t('shared.components.blobs.download.title')}</h2>

        <div className="section-content">
          <div className="download-object">
            <div className="download-title">
              <ScanResult result={scanResult} />
              <span className="download-filename">{blobFilename}</span>
            </div>

            <div className="download-buttons">
              <a
                className="btn btn-primary download"
                href={downloadLink}
                target="_blank" rel="noreferrer">
                <span className="icon-download" />
                {I18n.t('shared.components.blobs.download.download')}
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

BlobDownload.propTypes = {
  showDownloadSection: PropTypes.bool.isRequired,
  blobFilename: PropTypes.string,
  downloadLink: PropTypes.string,
  scanResult: PropTypes.shape({
    state: PropTypes.string,
    scannedAt: PropTypes.string
  }),
  scanResultUrl: PropTypes.string
};

export default BlobDownload;
