import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import * as filters from 'common/components/AssetBrowser/actions/filters';

export class IdsFilter extends Component {
  render() {
    const {
      ids,
      idsFilterEnabled,
      toggleIdsFilter,
    } = this.props;

    const inputId = 'filter-ids';

    const onChange = () => toggleIdsFilter(ids);
    return (
      <form className="ids-filter filter-section">
        <div className="checkbox checkbox-filter">
          <input id={inputId} type="checkbox" onChange={onChange} checked={idsFilterEnabled} />
          <label htmlFor={inputId}>
            <span className="fake-checkbox">
              <SocrataIcon name="checkmark3" />
            </span>
            {I18n.t('shared.asset_browser.filters.global_filter_compatible')}
          </label>
        </div>
      </form>
    );
  }
}

IdsFilter.propTypes = {
  ids: PropTypes.array,
  idsFilterEnabled: PropTypes.bool,
  toggleIdsFilter: PropTypes.func.isRequired
};

IdsFilter.defaultProps = {
  idsFilterEnabled: true
};

const mapStateToProps = (state) => ({
  idsFilterEnabled: state.filters.idsFilterEnabled
});

const mapDispatchToProps = (dispatch) => ({
  toggleIdsFilter: (ids) => dispatch(filters.toggleIdsFilter(ids))
});

export default connect(mapStateToProps, mapDispatchToProps)(IdsFilter);
