import React, { useContext } from 'react';
import I18n from 'common/i18n';
import {
  ForgeButton,
  ForgeCheckbox,
  ForgeDivider,
  ForgeIcon,
  ForgeIconButton,
  ForgeList,
  ForgeListItem,
  ForgeSwitch,
  ForgeToolbar,
} from '@tylertech/forge-react';
import { VideoSourcesContext, ITopic } from './VideoSourcesProvider';

type Props = {
  hideDrawer?: () => void;
};

function Filters(props: Props) {
  const { hideDrawer } = props;
  const {
    availableTopics = [],
    selectedTopics = [],
    setSelectedTopics = () => {},
    filterByAudience = true,
    setFilterByAudience = () => {},
  } = useContext(VideoSourcesContext);
  const scope = 'shared.components.videos';

  const renderTopicCheckbox = (topic: ITopic, index: number) => {
    const isSelected = selectedTopics.indexOf(topic.key) > -1;
    const handleSelect = () => {
      let newTopics = [];
      if (isSelected) {
        newTopics = selectedTopics.filter(t => t !== topic.key);
      } else {
        newTopics = [...selectedTopics, topic.key];
      }
      setSelectedTopics(newTopics);
    };

    return (
      <ForgeListItem key={index} selected={isSelected} on-forge-list-item-select={handleSelect}>
        <ForgeCheckbox slot="leading">
          <input type="checkbox" aria-label={`Select ${topic.label}`} />
        </ForgeCheckbox>
        <span className="forge-typography--body1" aria-hidden="true">{topic.label}</span>
      </ForgeListItem>
    );
  };

  const resetFilters = () => {
    setSelectedTopics([]);
  };

  const countSelectedTopics = selectedTopics.length;

  return (
    <>
      <ForgeToolbar>
        <div slot="start" className="forge-typography--title">{I18n.t('filters', { scope })}</div>
        {hideDrawer && (
          <ForgeIconButton slot="end">
            <button type="button" onClick={hideDrawer} aria-label="close">
              <ForgeIcon name="close" />
            </button>
          </ForgeIconButton>
        )}
      </ForgeToolbar>
      <ForgeToolbar>
        <div slot="start" className="forge-typography--caption">
          {countSelectedTopics}{' '}
          {I18n.t('applied_filters', { scope, count: countSelectedTopics })}
        </div>
        <ForgeButton slot="end">
          <button type="button" onClick={resetFilters}>{I18n.t('reset_all', { scope })}</button>
        </ForgeButton>
      </ForgeToolbar>
      <div className="video-guides-filter-section-title forge-typography--body1">{I18n.t('topics', { scope })}</div>
      <ForgeList className="video-guides-filters">
        {availableTopics.map(renderTopicCheckbox)}
      </ForgeList>
      <ForgeDivider />
      <div className="video-guides-filter-toggle-section">
        <p className="forge-typography--caption">{I18n.t('show_all_videos', { scope })}</p>
        <div>
          <ForgeSwitch
            selected={filterByAudience}
            on-forge-switch-select={(event: CustomEvent) => { setFilterByAudience(event.detail); }}
          >
            <span className="forge-typography--body2">{I18n.t('show_all_toggle', { scope })}</span>
          </ForgeSwitch>
        </div>
      </div>
    </>
  );
}

export default Filters;
