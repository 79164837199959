import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { fetchTranslation } from 'common/locale';
const t = (k) => fetchTranslation(k, 'shared.table_cell');

class EditCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      hasError: false,
      value: undefined
    };
    this.save = this.save.bind(this);
    this.maybeSave = this.maybeSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    this.setState({ value: this.state.value || value });
  }

  onChange(value) {
    this.setState({ value });
  }

  getValue() {
    return _.isUndefined(this.state.value) ? this.props.value : this.state.value;
  }

  maybeSave(e) {
    if (e.key === 'Enter') {
      this.save();
    }
  }

  save() {
    this.setState({
      hasError: false,
      inProgress: true
    }, () => {
      this.props.updateCell(this.getValue())
        .catch(() => {
          this.setState({
            hasError: true,
            inProgress: false
          });
        });
    });
  }

  cancelEdit() {
    this.props.updateCell();
  }

  render() {
    if (this.state.inProgress) {
      return (
        <div className="editing in-progress">
          <span className="progress-spinner spinner-default" />
        </div>
      );
    }

    const value = this.getValue();
    const inputRenderer = this.props.renderInput || ((name, initialValue, onChange) => {
      return (
        <input
          name={name}
          key={name}
          type="text"
          onKeyPress={this.maybeSave}
          onChange={(e) => { onChange(e.target.value); }}
          value={_.isNull(initialValue) ? '' : initialValue} />
      );
    });

    let fields;
    if (_.isObject(value)) {
      // If the thing that we're editing is a compound column,
      // then we'll allow the user to edit each individual key,
      // with a label of what the key is.


      fields = _.flatMap(Object.keys(value), (k) => {
        return [
          (<label
            key={`label-${k}`}
            htmlFor={k}>
            {k}
          </label>),

          inputRenderer(
            k,
            this.getValue()[k] || '',
            (newValue) => {
              const newCompoundValue = { ...value, [k]: newValue };
              this.onChange(newCompoundValue);
            }
          )
        ];
      });
    } else {
      // Otherwise, no labels, because the thing is just the thing
      fields = [inputRenderer('field', value, (newValue) => this.onChange(newValue))];
    }

    let klass = `editing ${this.state.hasError ? 'edit-error' : ''}`;

    return (
      <div className={klass}>
        <fieldset>
          {fields}
        </fieldset>
        <div className="edit-cell-actions">
          <button onClick={this.cancelEdit} className="btn btn-xs btn-simple">
            {t('cancel')}
          </button>
          <button onClick={this.save} className="btn btn-xs btn-primary">
            {t('save')}
          </button>
        </div>
        <div className="edit-cell-warning">
          <section>
            <b>{t('warning')}</b>
            <p>{t('changes_warning')}</p>
            <p>{t('changes_workflow')}</p>
          </section>
        </div>
      </div>
    );
  }
}

EditCell.propTypes = {
  value: PropTypes.any,
  updateCell: PropTypes.func,
  renderInput: PropTypes.func
};

export default EditCell;
