import { TeamsApi, TeamMembersApi as BaseTeamMembersApi, Configuration } from '@socrata/core-teams-api';
import { defaultHeaders } from 'common/http';

/**
 * Core Teams API Client
 *
 * Generated from OpenAPI Specification that lives at:
 * https://github.com/socrata/apis/blob/main/specs/teams/teams.yaml
 */
export default new TeamsApi(
  new Configuration({
    credentials: 'same-origin',
    basePath: window.location.origin,
    headers: defaultHeaders
  })
);

export const TeamMembersApi = new BaseTeamMembersApi(
  new Configuration({
    credentials: 'same-origin',
    basePath: window.location.origin,
    headers: defaultHeaders
  })
);

export const parseError = (error: Response): Promise<any> =>
  new Promise((resolve, reject) => {
    error
      .json()
      .then((parsed: any) => resolve(parsed))
      .catch((e: any) => reject(`Error parsing error JSON: ${e}`));
  });
