import PropTypes from 'prop-types';

import ApprovalStatePropType from './ApprovalStatePropType';

// NOTE: Coming here looking for TypeScript types? Check out `common/types/approvals`!

/**
 * Describes a GuidanceV2 Queue object from core's guidanceV2 API.
 */
export default PropTypes.shape({
  workflowId: PropTypes.number,
  displayName: PropTypes.string,
  submissionUrl: PropTypes.string,
  expectedState: ApprovalStatePropType
});
