import React from 'react';
import I18n from 'common/i18n';

function OtherResources() {
  const RESOURCE_TYPES = ['how_to', 'enroll', 'more'];
  const LINKS = {
    how_to: 'https://support.socrata.com/hc/en-us/categories/360000713713',
    enroll: 'https://learn.socrata.com/',
    more: 'https://support.socrata.com',
  };

  const renderLink = (key: string, index: number) => {
    const scope = `shared.components.videos.resources.${key}`;
    return (
      <li key={index}>
        <a href={LINKS[key] || 'https://support.socrata.com/'} target="_blank" rel="noopener noreferrer">
          <strong>{I18n.t('title', { scope })}</strong>: {I18n.t('label', { scope })}
        </a>
      </li>
    );
  };

  return (
    <ul className="video-guides-other-resources">
      {RESOURCE_TYPES.map(renderLink)}
    </ul>
  );
}

export default OtherResources;
