import cx from 'classnames';
import { ForgeIcon, ForgeList, ForgeListItem } from '@tylertech/forge-react';
import React, { PropsWithChildren, ReactNode, RefObject } from 'react';
import { none, Option, some } from 'ts-option';
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DropTarget,
  DropTargetConnector
} from 'react-dnd';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import './index.scss';
import { ForgeCard } from '@tylertech/forge-react';
import _ from 'lodash';

interface DraggableProps {
  onDrag: () => void;
  isDropped: boolean;
}
interface DroppableProps {
  onHovering: (idx: number) => void;
  isHoveringTop: boolean;
  isHoveringBottom: boolean;
  onDrop: (idx: number) => void;
  beingDragged?: boolean;
}
interface BaseItemProps {
  idx: number;
  element: ReactNode;
  itemRef: RefObject<ReactNode>;
  trailingElement?: ReactNode;
  className?: string;
  type: DragDropContainerType;
  useHoverColor?: boolean;
  useForgeIcon?: boolean;
  additionalItemProps?: any;
  mainElement?: ReactNode;
}

type DraggableItemProps = DraggableProps & DroppableProps & BaseItemProps;
type DroppableItemProps = DroppableProps & BaseItemProps;

interface DragInjectedProps {
  connectDragSource: ConnectDragSource;
  connectDragPreview: ConnectDragPreview;
}
interface DropInjectedProps {
  connectDropTarget: ConnectDropTarget;
}
type DnDInjectedProps = DragInjectedProps & DropInjectedProps;

const dragActions = {
  beginDrag(props: DraggableItemProps, monitor: any): { idx: number } {
    props.onDrag();
    return { idx: props.idx };
  },
  canDrag(props: DraggableItemProps, monitor: any): boolean {
    return true;
  },
  endDrag(props: DraggableItemProps) {}
};

const dragCollect = (connectDrag: DragSourceConnector) => {
  return {
    connectDragSource: connectDrag.dragSource(),
    connectDragPreview: connectDrag.dragPreview()
  };
};

const dropActions = {
  hover(props: DraggableItemProps, monitor: any) {
    props.onHovering(props.idx);
  },
  drop(props: DraggableItemProps) {
    props.onDrop(props.idx);
  }
};

const dropCollect = (connectDrop: DropTargetConnector) => {
  return {
    connectDropTarget: connectDrop.dropTarget()
  };
};

const mainContainerForType = (type: DragDropContainerType) => {
  switch (type) {
    case DragDropContainerType.TABLE:
      return 'tbody';
    case DragDropContainerType.LIST:
      return 'ul';
    case DragDropContainerType.FORGE_LIST:
      return ForgeList;
    case DragDropContainerType.CONDITIONS:
    case DragDropContainerType.FORGE_CARD:
      return 'div';
  }
};

// if you want everything to be draggable and don't want to construct the interface
export const makeDefaultDragDropElementWrapper = (els: ReactNode[]): DragDropElementWrapper[] => {
  return els.map((el) => {
    return {
      dragAndDroppable: true,
      element: el
    };
  });
};

// if you don't want everything to be draggable
export const makeCustomDragDropElementWrapper = (
  els: ReactNode[],
  isDraggableFunc: any
): DragDropElementWrapper[] =>
  els.map((el) => ({
    dragAndDroppable: isDraggableFunc(el),
    element: el
  }));

interface ElementProps {
  /** Pass additional props to the element container */
  additionalContainerProps?: any;
  /** Classes for the element container */
  className?: string;
  /** Content for the element */
  element: ReactNode;
  /** Ref for the element, stored in the container state */
  itemRef: RefObject<ReactNode>;
  /** Content for element that will go in 'trailing' slot rather than 'title' slot on a ForgeList only */
  trailingElement?: ReactNode;
  dragContainerClassName: string;
  idx: number;
  useForgeIcon?: boolean;
  /** If undefined, then the element will not be connected */
  connectDropTarget?: ConnectDropTarget;
  /** If undefined, then the element will not be connected */
  connectDragSource?: ConnectDragSource;
  /** If undefined, then the element will not be connected */
  connectDragPreview?: ConnectDragPreview;
  /** When not undefined, element will be the condition's title and mainElement will be the content */
  mainElement?: ReactNode;
  beingDragged?: boolean; // currently only used by forge-card
}
const ForgeListElement: React.FC<ElementProps> = ({
  additionalContainerProps = {},
  className,
  element,
  trailingElement,
  dragContainerClassName,
  idx,
  itemRef,
  connectDragPreview = (node) => node,
  connectDragSource = (node) => node,
  connectDropTarget = (node) => node
}) => {
  return (
    <ForgeListItem
      {...additionalContainerProps}
      data-testId={additionalContainerProps.dataTestId}
      id={`drag-element-${idx}`}
      className={cx('drag-element', className)}
      ref={itemRef}
    >
      {connectDropTarget(
        connectDragPreview(
          <div className="drag-element-forge-list-content">
            {connectDragSource(
              <div className={cx('drag-handle', dragContainerClassName)}>
                <ForgeIcon name="drag_handle" slot="leading" />
              </div>
            )}
            <span slot="title">{element}</span>
            {trailingElement && <span slot="trailing">{trailingElement}</span>}
          </div>
        )
      )}
    </ForgeListItem>
  );
};

const ForgeCardElement: React.FC<ElementProps> = ({
  additionalContainerProps = {},
  className,
  element,
  dragContainerClassName,
  idx,
  itemRef,
  connectDragPreview = (node) => node,
  connectDragSource = (node) => node,
  connectDropTarget = (node) => node,
  beingDragged
}) => {
  return (
    <div>
      {connectDropTarget(
        connectDragPreview(
          <div className={`forge-card ${beingDragged ? 'dragging' : ''}`}>
            <ForgeCard
              {...additionalContainerProps}
              data-testId={additionalContainerProps.dataTestId}
              id={`drag-element-${idx}`}
              className={cx('drag-element', className)}
              ref={itemRef}
              outlined
            >
              <div className="forge-card">
                {connectDragSource(
                  <div className={cx('forge-card-drag-handle', dragContainerClassName)}>
                    <ForgeIcon name="drag_horizontal" />
                  </div>
                )}
                {element}
              </div>
            </ForgeCard>
          </div>
        )
      )}
    </div>
  );
};

const ListElement: React.FC<ElementProps> = ({
  additionalContainerProps = {},
  className,
  element,
  dragContainerClassName,
  useForgeIcon,
  itemRef,
  connectDragPreview = (node) => node,
  connectDragSource = (node) => node,
  connectDropTarget = (node) => node
}) => {
  const icon = useForgeIcon ? (
    <ForgeIcon name="drag_handle" slot="leading" />
  ) : (
    <SocrataIcon name={IconName.GrabHandle} />
  );

  return (
    <>
      {connectDropTarget(
        connectDragPreview(
          <li {...additionalContainerProps} className={cx('drag-element', className)} itemRef={itemRef}>
            {connectDragSource(<div className={cx('drag-handle', dragContainerClassName)}>{icon}</div>)}
            {element}
          </li>
        )
      )}
    </>
  );
};

const TableElement: React.FC<ElementProps> = ({
  additionalContainerProps = {},
  className,
  element,
  dragContainerClassName,
  itemRef,
  connectDragPreview = (node) => node,
  connectDragSource = (node) => node,
  connectDropTarget = (node) => node
}) => {
  return (
    <>
      {connectDropTarget(
        connectDragPreview(
          <tr {...additionalContainerProps} className={cx('drag-element', className)} itemRef={itemRef}>
            {connectDragSource(
              <td className={cx('drag-handle', dragContainerClassName)}>
                <SocrataIcon name={IconName.GrabHandle} />
              </td>
            )}
            {element}
          </tr>
        )
      )}
    </>
  );
};

const ConditionElement: React.FC<ElementProps> = ({
  additionalContainerProps = {},
  className,
  element,
  dragContainerClassName,
  itemRef,
  connectDragPreview = (node) => node,
  connectDragSource = (node) => node,
  connectDropTarget = (node) => node,
  mainElement
}) => {
  return (
    <>
      {connectDropTarget(
        connectDragPreview(
          <div
            {...additionalContainerProps}
            className={cx('drag-element', 'conditional-config', className)}
            itemRef={itemRef}
          >
            <div>
              {connectDragSource(
                <div className={cx('drag-handle', 'conditional-config-header', dragContainerClassName)}>
                  <ForgeIcon name="drag_handle" slot="leading" />
                  {element}
                </div>
              )}
            </div>
            <div>{mainElement}</div>
          </div>
        )
      )}
    </>
  );
};

const UnDraggableElement: React.FC<BaseItemProps> = ({
  className,
  type,
  element,
  trailingElement,
  useForgeIcon,
  idx,
  itemRef
}) => {
  const props = {
    element,
    trailingElement,
    className,
    dragContainerClassName: 'drag-disabled',
    idx,
    itemRef
  };

  switch (type) {
    case DragDropContainerType.FORGE_LIST:
      return <ForgeListElement {...props} additionalContainerProps={{ disabled: true, selected: false }} />;
    case DragDropContainerType.LIST:
      return <ListElement {...props} useForgeIcon={useForgeIcon} />;
    case DragDropContainerType.TABLE:
      return <TableElement {...props} />;
    case DragDropContainerType.CONDITIONS:
      return <ConditionElement {...props} />;
    case DragDropContainerType.FORGE_CARD:
      return <ForgeCardElement {...props} />;
  }
};

class DraggableElement extends React.Component<DraggableItemProps & DnDInjectedProps> {
  render() {
    const {
      element,
      trailingElement,
      mainElement,
      type,
      className,
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
      isHoveringTop,
      isHoveringBottom,
      isDropped,
      useForgeIcon,
      useHoverColor,
      additionalItemProps = {},
      idx,
      itemRef,
      beingDragged
    } = this.props;

    const isHovering = isHoveringTop || isHoveringBottom;

    const props = {
      element,
      trailingElement,
      mainElement,
      className: cx('drop-enabled', className, {
        hovering: isHovering && useHoverColor,
        dropped: isDropped,
        'hover-top': isHoveringTop,
        'hover-bottom': isHoveringBottom
      }),
      dragContainerClassName: 'drag-enabled',
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      idx,
      itemRef
    };

    switch (type) {
      case DragDropContainerType.FORGE_LIST:
        return <ForgeListElement {...props} additionalContainerProps={additionalItemProps} />;
      case DragDropContainerType.LIST:
        return <ListElement {...props} useForgeIcon={useForgeIcon} />;
      case DragDropContainerType.TABLE:
        return <TableElement {...props} />;
      case DragDropContainerType.CONDITIONS:
        return <ConditionElement {...props} />;
      case DragDropContainerType.FORGE_CARD:
        return <ForgeCardElement {...props} beingDragged={beingDragged} />;
    }
  }
}
class DroppableElement extends React.Component<DroppableItemProps & DropInjectedProps> {
  render() {
    const {
      className,
      type,
      element,
      trailingElement,
      mainElement,
      connectDropTarget,
      isHoveringTop,
      isHoveringBottom,
      useForgeIcon,
      useHoverColor,
      idx,
      itemRef
    } = this.props;
    const isHovering = useHoverColor && (isHoveringTop || isHoveringBottom);
    const props = {
      element,
      idx,
      trailingElement,
      mainElement,
      className: cx('drop-enabled', className, {
        hovering: isHovering,
        'hover-top': isHoveringTop,
        'hover-bottom': isHoveringBottom
      }),
      dragContainerClassName: 'drag-disabled',
      connectDropTarget,
      itemRef
    };

    switch (type) {
      case DragDropContainerType.FORGE_LIST:
        return <ForgeListElement {...props} />;
      case DragDropContainerType.LIST:
        return <ListElement {...props} useForgeIcon={useForgeIcon} />;
      case DragDropContainerType.TABLE:
        return <TableElement {...props} />;
      case DragDropContainerType.CONDITIONS:
        return <ConditionElement {...props} />;
      case DragDropContainerType.FORGE_CARD:
        return <ForgeCardElement {...props} />;
    }
  }
}

const DRAG_ITEM_TYPE = 'DRAG_DROP_LIST';

const ConnectedDraggableElement = DropTarget(
  DRAG_ITEM_TYPE,
  dropActions,
  dropCollect
)(
  // eslint-disable-line
  DragSource(DRAG_ITEM_TYPE, dragActions, dragCollect)(DraggableElement) // eslint-disable-line
);
const ConnectedDroppableElement = DropTarget(DRAG_ITEM_TYPE, dropActions, dropCollect)(DroppableElement);

export interface DragDropElementWrapper {
  className?: string;
  id?: string;
  dragAndDroppable: boolean;
  droppable?: boolean;
  element: ReactNode;
  trailingElement?: ReactNode;
  additionalItemProps?: any;
  mainElement?: ReactNode;
}

export enum DragDropContainerType {
  LIST = 'LIST',
  TABLE = 'TABLE',
  FORGE_LIST = 'FORGE_LIST',
  CONDITIONS = 'CONDITIONS',
  FORGE_CARD = 'FORGE_CARD'
}

interface DragDropContainerProps<T> {
  className?: string;
  type: DragDropContainerType;
  items: T[];
  childElements: DragDropElementWrapper[];
  onDrop: (newItems: T[]) => void;
  useForgeIcon?: boolean;
  dense?: boolean;
  useHoverColor?: boolean; // defaults to true
}

interface PositionChange<T> {
  startPosn: number;
  endPosn: number;
  items: T[];
}

interface DragDropContainerState<T> {
  dragging: Option<PositionChange<T>>;
  dropped: Option<number>;
  childRefs: RefObject<ReactNode>[];
}

class DragDropContainer<T> extends React.Component<
  PropsWithChildren<DragDropContainerProps<T>>,
  DragDropContainerState<T>
> {
  state: DragDropContainerState<T> = {
    dragging: none,
    dropped: none,
    childRefs: this.props.childElements.map((_c) => React.createRef<ReactNode>())
  };

  static defaultProps = {
    useHoverColor: true
  };

  onDrag = (idx: number) => {
    const draggingRef = this.state.childRefs[idx]?.current as any;
    // this deactivates the 'pressed' state on the forge list item, which otherwise stays on the index position we dragged from
    if (draggingRef?.deactivateRipple) draggingRef.deactivateRipple();

    // we store the items in the dragging state because if the component
    // re-renders or something, we don't want to be firing drop events using
    // indices on a list which has changed out from under us
    this.setState({ dragging: some({ startPosn: idx, endPosn: idx, items: this.props.items }) });
  };

  onHovering = (idx: number) => {
    this.state.dragging.forEach((drag) => {
      this.setState({ dragging: some({ ...drag, endPosn: idx }) });
    });
  };

  onDrop = () => {
    this.state.dragging.forEach((drag) => {
      const item = this.props.items[drag.startPosn];
      const others = this.props.items.filter((_item, idx) => idx !== drag.startPosn);
      const endPosn = Math.max(0, drag.endPosn);

      const left = others.slice(0, endPosn);
      const right = others.slice(endPosn);

      const changes = [...left, item, ...right];

      this.props.onDrop(changes);
      const droppedItem: HTMLElement | null = document.querySelector(`#drag-element-${endPosn}`);
      if (droppedItem) droppedItem.focus();
      this.setState({ dragging: none, dropped: some(endPosn) });

      // clear the just dropped indicator
      setTimeout(() => {
        this.setState({ dropped: none });
      }, 1000);
    });
  };

  render() {
    const contents = this.props.childElements.map((child: DragDropElementWrapper, idx: number) => {
      const key = child.id || idx.toString();
      let isDragged;
      if (this.state.dragging.isDefined) {
        isDragged = idx === this.state.dragging.get.startPosn;
      }
      if (child.dragAndDroppable) {
        return (
          <ConnectedDraggableElement
            element={child.element}
            itemRef={this.state.childRefs[idx]}
            trailingElement={child.trailingElement}
            type={this.props.type}
            className={child.className}
            idx={idx}
            key={key}
            onHovering={this.onHovering}
            isDropped={this.state.dropped.map((d) => d === idx).getOrElseValue(false)}
            isHoveringTop={this.state.dragging
              .map(({ startPosn, endPosn }) => startPosn > endPosn && endPosn === idx)
              .getOrElseValue(false)}
            isHoveringBottom={this.state.dragging
              .map(({ startPosn, endPosn }) => startPosn < endPosn && endPosn === idx)
              .getOrElseValue(false)}
            onDrop={this.onDrop}
            onDrag={() => this.onDrag(idx)}
            useForgeIcon={this.props.useForgeIcon}
            useHoverColor={this.props.useHoverColor}
            additionalItemProps={child.additionalItemProps}
            mainElement={child.mainElement}
            beingDragged={isDragged}
          />
        );
      } else if (child.droppable) {
        return (
          <ConnectedDroppableElement
            element={child.element}
            itemRef={this.state.childRefs[idx]}
            trailingElement={child.trailingElement}
            mainElement={child.mainElement}
            type={this.props.type}
            className={child.className}
            idx={idx}
            key={key}
            onHovering={this.onHovering}
            isHoveringTop={this.state.dragging
              .map(({ startPosn, endPosn }) => startPosn > endPosn && endPosn === idx)
              .getOrElseValue(false)}
            isHoveringBottom={this.state.dragging
              .map(({ startPosn, endPosn }) => startPosn < endPosn && endPosn === idx)
              .getOrElseValue(false)}
            onDrop={this.onDrop}
            useForgeIcon={this.props.useForgeIcon}
            useHoverColor={this.props.useHoverColor}
            beingDragged={isDragged}
          />
        );
      } else {
        return (
          <UnDraggableElement
            className={child.className}
            itemRef={this.state.childRefs[idx]}
            element={child.element}
            trailingElement={child.trailingElement}
            mainElement={child.mainElement}
            idx={idx}
            type={this.props.type}
            key={key}
            useForgeIcon={this.props.useForgeIcon}
          />
        );
      }
    });
    const Container = mainContainerForType(this.props.type);
    const dense = this.props.dense === undefined ? true : this.props.dense;
    const props = {
      className: cx('dnd-container', this.props.className, {
        active: this.state.dragging !== none,
        'forge-dnd-container': this.props.type === DragDropContainerType.FORGE_LIST
      }),
      dense: this.props.type === DragDropContainerType.FORGE_LIST ? dense : undefined
    };

    return <Container {...props}>{contents}</Container>;
  }
}

/**
 * Here's an example.
 *
 * Note that each item will have a grab handle in the <li></li> element
 * that it gets rendered in. So it'll be like <li><grab-handle/><your-stuff /></li>
 *
 *
 * Important Note:
 * One super dumb thing about react-dnd is that it relies on a global
 * DragDropContext there can only be one of, or else it crashes.
 *
 * Another super dumb thing is that this DragDropContext will only take
 * react elements that are classes as an argument. This means you need to do something like
 * import { DragDropContext } from 'react-dnd';
 * import HTML5Backend from 'react-dnd-html5-backend';
 *
 * class MyList extends React.Component {
 *   render() {
 *     const items = ['foo', 'bar', 'baz'];
 *     const listItems = items.map(item => <span>{item}</span>);
 *     const wrappedItems = makeDefaultDragDropElementWrapper(listItems);
 *
 *     const onChangeOrder = (newItems) => {
 *       // might be something like  ['bar', 'baz', 'foo']
 *     }
 *     <DragDropContainer items={items} className="whatever" childElements={wrappedItems} onDrop={onChangeOrder}/>
 *   }
 * }
 *
 * export default DragDropContext(HTML5Backend)(MyList);
 */
export default DragDropContainer;
