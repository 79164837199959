import { getCurrentUser } from 'common/current_user';
import hash from 'object-hash';

// creates an array of 28 colors (indices) based off of the current user's email address to color the
// 28 triangles of their default profile image
export const createColors = () => {
  return randomizeColors((getCurrentUser() && getCurrentUser().email) || 'test@tylertech.com');
};

const Shades = {
  DARKEST: 0,
  DARK: 1,
  LIGHT: 2,
  LIGHTEST: 3,
  ORANGE: 4
};

// returns an array of `numChoices` ints in range [0-mod) by slicing `charsPerChoice` hex chars off of
// `hash` and moding that value by `mod` `numChoices` times. also returns the unused remainder of `hash`.
// NOTE: 2^(`charsPerChoice`*4) is ideally > 100*`mod` for a more uniform distribution, unless `mod` is a power of 2
const chooseFromHash = (hash, charsPerChoice, mod, numChoices) => {
  return Array(numChoices)
    .fill(0)
    .reduce(
      ([accChoices, remainingHash], _) => {
        const [firstChars, restOfChars] = removePrefix(remainingHash, charsPerChoice);
        const choice = parseInt(firstChars, 16) % mod;
        return [accChoices.concat([choice]), restOfChars];
      },
      [[], hash]
    );
};

// partitions string `s` at index `len`, returning both parts
const removePrefix = (s, len) => {
  return [s.substring(0, len), s.substring(len, s.length)];
};

// converts an email address string into the coloring choices necessary to construct the profile image
const getChoices = (email) => {
  // represents the choices to be made w.r.t. coloring the 28 triangles in the image
  class Choices {
    constructor(upTrisLight, remainingColors, orangeIndex) {
      // boolean: true => the 14 up-pointing triangless are light, false => the 14 down tris are light
      this.upTrisLight = upTrisLight;
      // array of 14 ints in range [0-2] representing 14 choices of 3 colors for the 14 remaining tris
      this.remainingColors = remainingColors;
      // index (out of the 20 large triangles) of the tri to be colored orange, int in range [0-19]
      this.orangeIndex = orangeIndex;
    }
  }

  const h = hash(email);
  const choiceParams = [
    [1, 2, 1], // up or down triangles are light: take 1 hex char, mod 2, 1 time
    [2, 3, 14], // color of the other 14 triangles: 2 hex chars per choice, mod 3, 14 times
    [4, 20, 1] // position of the large orange triangle: 4 hex chars, mod 20, once
  ];

  const allChoices = choiceParams.reduce(
    ([choicesAcc, hash], [chars, mod, times]) => {
      const [choices, remainingHash] = chooseFromHash(hash, chars, mod, times);
      return [choicesAcc.concat([choices]), remainingHash];
    },
    [[], h]
  )[0];

  return new Choices(allChoices[0][0] == 0, allChoices[1], allChoices[2][0]);
};

// turns an email address string into an array of colors (indices) for the 28 image triangles
const randomizeColors = (email) => {
  const choices = getChoices(email);

  const light = Array(14).fill(Shades.LIGHTEST);
  const otherColors = choices.remainingColors;
  const colors = choices.upTrisLight ? otherColors.concat(light) : light.concat(otherColors);
  colors[choices.orangeIndex + 4] = Shades.ORANGE; // add 4 to orangeIndex (large triangles begin at index 4 in Points)

  return colors;
};
