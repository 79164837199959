import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { none } from 'ts-option';
import ScheduleSource from './components';
import * as helpers from './helpers';

export function launchScheduleModal(fourfour, automationParams) {
  let targetNode = document.querySelector('#asset-action-bar-modal-target');

  if (!targetNode) {
    targetNode = document.createElement('div');
    targetNode.setAttribute('id', 'asset-action-bar-modal-target');
    document.body.appendChild(targetNode);
  }

  // defer avoids weird react console error
  const closeModal = () => _.defer(() => ReactDOM.unmountComponentAtNode(targetNode));

  ReactDOM.render(
    <ScheduleSource fourfour={fourfour} scheduleProvider={helpers.getSchedule} automationParams={automationParams} schedule={none} onDismiss={closeModal} />,
    targetNode
  );
}

export default ScheduleSource;
