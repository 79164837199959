import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';

import type { Role } from '@socrata/core-roles-api';

interface LocalizedRoleNameProps {
  role?: Role;
}

/**
 * A localized role name.
 */
const LocalizedRoleName: FunctionComponent<LocalizedRoleNameProps> = ({ role }) => {
  if (!role) {
    return null;
  }

  // default roles have translation strings
  if (role.isDefault) {
    return <span>{I18n.t(`roles.default_roles.${role.name}.name`)}</span>;
  }

  // non-default role means that it's a user-supplied name
  return <span>{role.name}</span>;
};

export default LocalizedRoleName;
