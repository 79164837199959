import React from 'react';
import { isUndefined, toNumber } from 'lodash';
import I18n from 'common/i18n';
import { ColumnFormat } from 'common/types/viewColumn';
import { ForgeIcon, ForgeIconButton, ForgeQuantityField, ForgeTextField } from '@tylertech/forge-react';
import './DecimalPrecision.scss';

// Constants
const scope = 'shared.dataset_management_ui.format_column';

interface Props {
  onChange: (f: ColumnFormat) => void;
  onRemove: (k: string) => void;
  format: ColumnFormat;
  formatIndex: number;
}

interface IDecimalPrecisionProps {
  onChange: (f: ColumnFormat) => void;
  format: ColumnFormat;
  hideArrows?: boolean;
}

const onUpdateFormat = (oldFormat: ColumnFormat, newFormat: ColumnFormat) => {
  const updatedPrecision =
    newFormat.precisionStyle === 'financial' && isUndefined(newFormat.precision)
      ? (newFormat.precision = 2)
      : newFormat.precision;
  const updatedCurrencyStyle =
    newFormat.precisionStyle === 'currency' && isUndefined(newFormat.precision)
      ? (newFormat.currencyStyle = 'USD')
      : newFormat.currencyStyle;
  return {
    ...oldFormat,
    updatedPrecision,
    updatedCurrencyStyle,
    ...newFormat
  };
};

export function ForgeDecimalPrecision({ onChange, format, hideArrows }: IDecimalPrecisionProps) {
  const precision = format.precision ?? 0;

  const updatePrecision = (newPrecision: number) => {
    const updatedFormat = onUpdateFormat(format, { precision: Math.min(Math.max(newPrecision, 0), 10) });
    onChange(updatedFormat);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePrecision(toNumber(e.target.value));
  };

  const handleDecrease = () => {
    updatePrecision(+precision - 1);
  };

  const handleIncrease = () => {
    updatePrecision(+precision + 1);
  };

  return (
    <ForgeQuantityField>
      <label slot="label" htmlFor="decimal-precision">
        <span
          className="forge-typography--body2"
          style={{ color: 'var(--mdc-theme-text-primary-on-background,rgba(0,0,0,.87))' }}
        >
          {I18n.t('decimal_precision', { scope })}
        </span>
      </label>
      <div className="decimal-precision-wrapper">
        <ForgeIconButton id="decrease-action-button">
          <button
            type="button"
            data-testid="decrease-precision-button"
            aria-label={I18n.t('decrease_decimal_precision', { scope })}
            className="tyler-icons"
            onClick={handleDecrease}
          >
            <ForgeIcon name="remove_circle_outline" />
          </button>
        </ForgeIconButton>
        <ForgeTextField>
          <input
            data-testid="decimal-precision-input"
            id="decimal-precision-input"
            min="0"
            max="10"
            step="1"
            type="number"
            value={precision}
            onChange={handleChange}
            className={hideArrows ? 'hidden-arrows' : ''}
          />
        </ForgeTextField>
        <ForgeIconButton id="increase-action-button">
          <button
            type="button"
            data-testid="increase-precision-button"
            aria-label={I18n.t('increase_decimal_precision', { scope })}
            className="tyler-icons"
            onClick={handleIncrease}
          >
            <ForgeIcon name="add_circle_outline" />
          </button>
        </ForgeIconButton>
      </div>
    </ForgeQuantityField>
  );
}

export function DecimalPrecision({ onChange, format, onRemove, formatIndex }: Props) {
  const isSpecifyingPrecision = !isUndefined(format.precision);
  const toggleSpecifyPrecision = () => {
    if (isSpecifyingPrecision) {
      onRemove('precision');
    } else {
      onChange({ precision: 2 });
    }
  };

  const slider = isSpecifyingPrecision ? (
    <input
      type="range"
      min="0"
      max="10"
      step="1"
      defaultValue={format.precision}
      onChange={(e) => onChange({ precision: toNumber(e.target.value) })}
    />
  ) : null;

  const value = isSpecifyingPrecision ? <span className="precision-value">{format.precision}</span> : null;

  return (
    <div>
      <div className="checkbox no-commas">
        <input
          id={`decimal-precision-${formatIndex}`}
          type="checkbox"
          checked={isSpecifyingPrecision}
          onChange={toggleSpecifyPrecision}
        />
        <label htmlFor={`decimal-precision-${formatIndex}`}>
          <span className="fake-checkbox">
            <span className="socrata-icon-checkmark3" />
          </span>
          {I18n.t('specify_precision', { scope })}
        </label>
      </div>

      {slider}
      {value}
    </div>
  );
}
