import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import I18n from 'common/i18n';
import { DOWN, ESCAPE, isolateEventByKeys } from 'common/dom_helpers/keycodes_deprecated';
import ColorBuckets from 'common/components/ColorPicker/ColorBuckets';
import CharmBuckets from 'common/components/ColorAndCharmPicker/CharmBuckets';
import Tabs from 'common/components/ColorAndCharmPicker/Tabs';
import { getCharmSvgSrc } from 'common/resources/charms';

import './index.scss';
import { ForgePopup } from '@tylertech/forge-react';

/**
  Shows overlay with two tabs (color | icon-charm) below the field.
  In Color picker tab is an extracted part of colorPicker component.
  In Charm picker tab the user can search and pick a charm.
  The selected charm and color will be shown in the field.
*/
export class ColorAndCharmPicker extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      selectedColor: this.props.color,
      selectedCharmName: this.props.charmName,
      showingColorAndCharmPicker: false,
      selectedTab: 'Color'
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.color !== this.props.color) {
      this.setState({
        selectedColor: nextProps.color,
        selectedCharmName: nextProps.charmName
      });
    }
  };

  onClickColorAndCharmFrame = () => {
    this.setState({
      showingColorAndCharmPicker: !this.state.showingColorAndCharmPicker
    });
  };

  onClickCharmBucket = (selectedCharmName) => {
    this.setState({
      showingColorAndCharmPicker: false,
      selectedCharmName
    });

    this.props.handleCharmChange(selectedCharmName);
    this.colorAndCharmPickerRef.focus();
  };

  onClickColorBucket = (selectedColor) => {
    this.setState({
      showingColorAndCharmPicker: false,
      selectedColor
    });

    this.props.handleColorChange(selectedColor);
    this.colorAndCharmPickerRef.focus();
  };

  onClose = () => {
    this.setState({
      showingColorAndCharmPicker: false
    });
  };

  onKeyDownColorAndCharmFrame = (event) => {
    isolateEventByKeys(event, [DOWN]);
  };

  onKeyUpColorAndCharmFrame = (event) => {
    const { keyCode } = event;
    isolateEventByKeys(event, [DOWN, ESCAPE]);

    if (keyCode === DOWN) {
      this.setState({ showingColorAndCharmPicker: true });
    } else if (keyCode === ESCAPE) {
      this.onClose();
    }
  };

  onTabChange = (selectedTab) => {
    this.setState({ selectedTab });
  };

  // Renders a field with dropdown down box. Shows the currently selected icon
  // and charm in the field. On click the field, the picker overlay appears.
  renderColorAndCharmFrame = () => {
    const { selectedColor } = this.state;
    const { charmName } = this.props;
    const openColorAndCharmPicker = I18n.t('shared.components.color_and_charm_picker.open_color_and_charm_picker');
    const withCurrentSelection = I18n.t('shared.components.color_and_charm_picker.with_currently_selected_color');
    const hasCharm = !_.isEmpty(charmName);
    const label = selectedColor ?
      `${openColorAndCharmPicker} ${withCurrentSelection} ${selectedColor}` :
      openColorAndCharmPicker;
    const colorFrameAttributes = {
      className: classNames('color-charm-frame', { 'view': hasCharm }),
      onClick: this.onClickColorAndCharmFrame,
      role: 'button',
      tabIndex: 0,
      onKeyUp: this.onKeyUpColorAndCharmFrame,
      onKeyDown: this.onKeyDownColorAndCharmFrame,
      ref: (ref) => this.colorAndCharmPickerRef = ref,
      'aria-label': label
    };
    const selectedColorAndCharmFrameAttributes = {
      className: classNames('selected-color-charm-row', {
        'preview': hasCharm
      }),
      style: { backgroundColor: selectedColor }
    };
    const charmCaretClassName = classNames('charm-caret', { 'charm-caret-view': hasCharm });

    return (
      <div {...colorFrameAttributes}>
        <div className="selected-color-charm-wrapper">
          <div {...selectedColorAndCharmFrameAttributes}>
            {hasCharm ? <img src={getCharmSvgSrc(charmName)} key={charmName} alt={charmName} /> : ''}
          </div>
        </div>
        <div className={charmCaretClassName} role="presentation">
          <span className="socrata-icon-arrow-down" />
        </div>
      </div>
    );
  };

  // Renders the overlay behind the ColorAndCharmPicker dropdown section. On clicking this (meaning clicking outside the content),
  // we close the dropdown section.
  renderColorAndCharmPickerOverlay = () => {
    const overlayClassName = classNames('color-charm-picker-overlay', {
      'hidden': !this.state.showingColorAndCharmPicker
    });

    return <div className={overlayClassName} onClick={this.onClose} />;
  };

  // Renders color-buckets|charm-buckets along with the tabs.
  renderColorAndCharmPickerContent = () => {
    const { selectedTab, showingColorAndCharmPicker } = this.state;
    const tabsAttributes = {
      tabs: ['Color', 'Icon'],
      selectedTab: selectedTab,
      onTabChange: this.onTabChange
    };
    const isColorTab = selectedTab === 'Color';

    return (
      <ForgePopup
        targetElementRef={this.ref}
        open={showingColorAndCharmPicker}
        onDismiss={() => {
          this.setState({
            showingColorAndCharmPicker: false
          });
        }}
      >
        <div className="color-charm-picker-wrapper">
          <div className={classNames('color-charm-picker', { 'hidden': !showingColorAndCharmPicker })}>
            <Tabs {...tabsAttributes} />
            {isColorTab ? this.renderColorContent() : this.renderCharmContent()}
          </div>
        </div>
      </ForgePopup>
    );
  };

  renderColorContent = () => {
    const colorBucketsAttributes = {
      bucketRevealDirection: this.props.bucketRevealDirection,
      palette: this.props.colorPalette,
      selectedColor: this.state.selectedColor,
      showingBuckets: true,
      onClickBucket: this.onClickColorBucket,
      onClose: this.onClose,
      value: this.state.selectedColor
    };

    return (
      <div className="tab-content current">
        <ColorBuckets {...colorBucketsAttributes} />
      </div>
    );
  };

  renderCharmContent = () => {
    const charmBucketsAttributes = {
      bucketRevealDirection: this.props.bucketRevealDirection,
      selectedCharmName: this.props.charmName,
      showingCharmName: true,
      onClickBucket: this.onClickCharmBucket,
      onClose: this.onClose
    };

    return (
      <div className="tab-content current">
        <CharmBuckets {...charmBucketsAttributes} />
      </div>
    );
  };

  render() {
    const colorAndCharmPickerAttributes = {
      className: classNames('color-and-charm-picker', { 'show-charm': !_.isEmpty(this.props.charmName) }),
      id: this.props.id,
      ref: this.ref,
      'data-testid': 'color-and-charm-picker'
    };

    return (
      <div {...colorAndCharmPickerAttributes}>
        {/* {this.renderColorAndCharmPickerOverlay()} */}
        {this.renderColorAndCharmFrame()}
        {this.renderColorAndCharmPickerContent()}
      </div>
    );
  }
}

ColorAndCharmPicker.propTypes = {
  bucketRevealDirection: PropTypes.string,
  // Currently selected color. Refer colorPicker for more details.
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  charmName: PropTypes.string, // Currently selected charm
  colorPalette: PropTypes.array, // Pallete of colors to be given to the user for selection.
  id: PropTypes.string,
  handleColorChange: PropTypes.func, // Called when the selected color changes
  handleCharmChange: PropTypes.func // Called when the selected charm changes
};

ColorAndCharmPicker.defaultProps = {
  color: '#204490',
  handleColorChange: _.noop,
  handleCharmChange: _.noop
};

export default ColorAndCharmPicker;
