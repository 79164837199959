/* eslint-disable camelcase */
// snake_casing these constants to try to remain consistent with core

// NOTE:
// This is a list of DOMAIN rights granted to users
// This needs to reflect:
//  - ALL_RIGHTS in frontend/lib/user_rights.rb
//  - Right enum in core at unobtainium/src/main/java/com/blist/models/account/roles/DomainRights.java

export const APPROVE_NOMINATIONS = 'approve_nominations';
export const CHANGE_CONFIGURATIONS = 'change_configurations';
export const CHOWN_DATASETS = 'chown_datasets';
export const CREATE_GATEWAY_AGENTS = 'create_gateway_agents';
export const CREATE_DATASETS = 'create_datasets';
export const CREATE_MEASURES = 'create_measures';
export const CREATE_PAGES = 'create_pages';
export const CREATE_STORY = 'create_story';
export const EDIT_OTHERS_DATASETS = 'edit_others_datasets';
export const EDIT_PAGES = 'edit_pages';
export const EDIT_SDP = 'edit_sdp';
export const EDIT_NOMINATIONS = 'edit_nominations';
export const EDIT_SITE_THEME = 'edit_site_theme';
export const FEATURE_ITEMS = 'feature_items';
export const FEDERATIONS = 'federations';
export const MANAGE_GATEWAYS = 'manage_gateways';
export const MANAGE_PROVENANCE = 'manage_provenance';
export const MANAGE_ROLE_DEFINITIONS = 'manage_role_definitions';
export const MANAGE_SPATIAL_LENS = 'manage_spatial_lens';
export const MANAGE_STORIES = 'manage_stories';
export const MANAGE_USERS = 'manage_users';
export const MODERATE_COMMENTS = 'moderate_comments';
export const USE_AS_RAPID_DEPLOY_SOURCE = 'use_as_rapid_deploy_source';
export const USE_AS_RAPID_DEPLOY_TARGET = 'use_as_rapid_deploy_target';
export const USE_DATA_CONNECTORS = 'use_data_connectors';
export const VIEW_ALL_DATASET_STATUS_LOGS = 'view_all_dataset_status_logs';
export const VIEW_DOMAIN = 'view_domain';
export const VIEW_OTHERS_DATASETS = 'view_others_datasets';
