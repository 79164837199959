/* istanbul ignore file */
import { FieldIdentifier, FieldInput, FieldValue } from 'common/types/metadataTemplate';
import { Attachment } from 'common/types/revision';
import { ColumnRef } from 'common/types/soql';

export interface FieldProps {
  inProgress: boolean;
  identifier: FieldIdentifier;
  onUpdateField: (cref: ColumnRef, value: any) => void; // TODO: soql-cell-generic
  value: FieldValue;
  hasSubmitted: boolean;
  assetMetadata: AssetMetadata;
}

export interface FieldSetProps {
  inProgress: boolean;
  assetMetadata: AssetMetadata;
  title: string;
  subtitle: string;
  fields: FieldIdentifier[];
  hasSubmitted: boolean;
  getValue: (identifier: FieldIdentifier) => FieldValue;
  onUpdateField: (cref: ColumnRef, value: any) => void; // TODO: soql-cell-generic
}

export interface UserInputField<ValueType> {
  name: string; // display name
  label: string;
  value: ValueType;
  placeholder: string;
  isRequired: boolean;
  disabled?: boolean;
}

export type DatasetCategories = { title: string; value: string }[];
export type DatasetLicenses = { title: string; value: string }[];

export type FlashMessageKinds = 'success' | 'error' | 'warning' | 'href' | 'info';

export type FlashMessage = {
  kind: FlashMessageKinds;
  id: string;
  message: string;
  helpMessage?: string;
  helpUrl?: string;
  visible: boolean;
  alignLeft?: boolean;
};

export interface MetadataForm {
  isDirty: boolean;
  showErrors: boolean;
  errors: FormErrors;
  submitted: boolean;
  state: AssetMetadata | undefined;
}

// Because of *course* it switches between an array and an empty object. No, I'm not mad.
// TODO: Fix this
export type FormErrors = string[] | Record<string, any>;

// The metadata object in a view, or in revisions the metadata.metadata blob ( yep >:[ ), is typed as an object with any values.
export interface InnerMetadataObject {
  rowLabel?: string;
  rdfSubject?: string;
  rdfClass?: string;
  custom_fields?: {
    // This IS actually an object with any values
    [key: string]: {
      [key: string]: string;
    };
  };
  availableDisplayTypes?: string[];
  attachments?: CoreAttachment[]; // Only here in a view, though!
  [key: string]: any; // Ew.
}

export interface AssetMetadata {
  builtIn: {
    name: string;
    rowLabel?: string;
    rdfSubject?: string;
    rdfClass?: string;
    tags: string[] | null;
    resourceName?: string;
    attachments: Attachment[];
    license: {
      licenseId: string | null;
      name: string;
      termsLink: string;
    };
    attribution: {
      name: string | null;
      link: string | null;
    };
    description: string | null;
    category: string | null;
  };
  privateMetadata: {
    contactEmail: string | null;
    customFields: {
      [key: string]: {
        [key: string]: string;
      };
    };
  };
  customFields?: {
    [key: string]: {
      [key: string]: string;
    };
  };
  results?: {
    inputs?: FieldInput[];
    blob?: any;
    privateBlob?: any;
  };
}

export type NestedFieldInputs = {
  [qualifier: string]: {
    [fieldName: string]: any; //TODO: SoQL-cell-generics
  };
};

export enum UploadStatuses {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export interface CoreAttachment {
  name: string;
  filename: string;
  assetId: string;
}

export interface UploadingAttachment {
  name: string;
}

export interface FileUploadStatus {
  status: UploadStatuses;
  percent: number;
  uploadId: string;
  uploadedFile: Attachment | UploadingAttachment;
  error?: { message: string };
}

export type OnUploadAttachmentSignature = (
  file: File,
  onProgress: (event: ProgressEvent) => void
) => Promise<Attachment>;
