import React, { Component, ReactElement } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

import Checkbox, { CheckboxProps } from '../Checkbox';
import { checkboxChanged, InputChangedAction, validateInput, ValidateInputAction } from './FormReduxActions';
import { FormState, InputState } from './types';

/** Props passed in to the component */
interface OwnProps {
  /**
   * The name of this input
   * Used by the reducer to track state
   */
  name: string;
}

/** Props from mapDispatchToProps */
interface DispatchProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => InputChangedAction;
  onValidate: (name: string) => ValidateInputAction;
}

type Props = OwnProps & DispatchProps & InputState;

/**
 * An Input that handles all of its validation in redux.
 *
 * The "name" prop passed in here will also be used to grab its state out of the "inputs" part of the Redux state.
 *
 *
 * @see common/components/Forms/redux/FormReducer for how the events are handled, and FormReduxUtils for an easy way to generate the expected state.
 */
class ReduxValidatedCheckbox extends Component<Props> {
  validate = (): void => {
    const { onValidate, name } = this.props;

    // basically just tell the reducer to run the validation for this input
    // (if this input doesn't have one, this is a no-op)
    onValidate(name);
  };

  render(): ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputProps = (omit(this.props, ['onValidate']) as any) as CheckboxProps;

    return <Checkbox {...inputProps} onBlur={this.validate} />;
  }
}

const mapStateToProps = (state: FormState, { name }: OwnProps): InputState => ({
  ...state.inputs[name],
  name,
  id: name
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any, { name }: OwnProps): DispatchProps =>
  bindActionCreators(
    {
      onChange: (event): InputChangedAction => checkboxChanged(name, event.target.checked),
      onValidate: validateInput
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxValidatedCheckbox);
