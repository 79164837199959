import React, { Component } from 'react';

import './default-profile-image.scss';
import I18n from 'common/i18n';
import { createColors } from './createColors';

/* describes the 28 triangles of the default profile image
 * 0 - 13 are downward-pointing triangles (rest are upwards-pointing)
 * 4 - 23 are large triangles (rest are small triangles)
 *
 * |¯¯/\¯¯¯¯/\¯¯¯¯/\¯¯|
 * |0/  \ 4/  \ 5/  \1|
 * |/_14_\/_15_\/_16_\|
 * |\    /\    /\    /|
 * | \ 6/  \ 7/  \ 8/ |
 * |24\/ 17 \/ 18 \/25|
 * |¯¯/\¯¯¯¯/\¯¯¯¯/\¯¯|
 * |2/  \9 /  \10/  \3|
 * |/_19_\/_20_\/_21_\|
 * |\    /\    /\    /|
 * | \11/  \12/  \13/ |
 * |26\/_22_\/_23_\/27|
 */
const Points = [
  '121.8,120.4 29.7,120.4 29.7,253 ', // 0
  '582.6,120.4 489.9,120.4 582.6,255.8 ', // 1
  '120.7,395.3 29.7,395.3 29.7,533.8 ', // 2
  '582.6,395.3 489.9,395.3 582.6,533.8 ', // 3
  '304.9,120.4 121.5,120.4 213.2,255.8 ', // 4
  '489.1,120.4 305.7,120.4 397.4,255.8 ', // 5
  '213.8,256.8 29.7,256.8 122.1,395.3 ', // 6
  '398,256.8 214.7,256.8 306.3,395.3 ', // 7
  '582.6,256.8 398.8,256.8 490.5,395.3 ', // 8
  '304.9,395.3 121.5,395.3 213.2,533.8 ', // 9
  '489.1,395.3 305.7,395.3 397.4,533.8 ', // 10
  '213.8,534.8 29.7,534.8 122.1,673.3 ', // 11
  '398,534.8 214.7,534.8 306.3,673.3 ', // 12
  '582.6,534.8 398.8,534.8 490.5,673.3 ', // 13
  '29.7,255.8 213.8,255.8 122.1,120.4 ', // 14
  '214.7,255.8 398,255.8 306.3,120.4 ', // 15
  '398.8,255.8 582.6,255.8 490.5,120.4 ', // 16
  '123.6,395.3 307,395.3 215.3,256.8 ', // 17
  '307.8,395.3 491.2,395.3 399.5,256.8 ', // 18
  '29.7,533.8 213.8,533.8 122.1,395.3 ', // 19
  '214.7,533.8 398,533.8 306.3,395.3 ', // 20
  '398.8,533.8 582.6,533.8 490.5,395.3 ', // 21
  '123.6,673.3 307,673.3 215.3,534.8 ', // 22
  '307.8,673.3 491.2,673.3 399.5,534.8 ', // 23
  '29.7,395.3 122.8,395.3 29.7,256.8 ', // 24
  '492,395.3 582.6,395.3 582.6,256.8 ', // 25
  '29.7,673.3 122.8,673.3 29.7,534.8 ', // 26
  '492,673.3 582.6,673.3 582.6,534.8 ' // 27
];

export class DefaultProfileImage extends Component {
  state = {
    height: '100',
    width: '100'
  };

  constructor(props) {
    super(props);
    const { height, width } = this.props;
    this.state = {
      ...this.state,
      height: height || this.state.height,
      width: width || this.state.width
    };
  }

  render() {
    const colors = createColors();
    const { height, width } = this.state;
    return (
      <svg
        id="profile-image"
        version="1.1"
        x="0px"
        y="0px"
        height={height}
        width={width}
        viewBox="0 0 552.79999 552.90002"
        alt={I18n.t('screens.profile.edit.image.alt_description')}
      >
        {Points.map((point, index) => (
          <polygon
            transform="translate(-29.8,-120.4)"
            key={`${index}`}
            className={`st${colors[index]}`}
            points={`${point}`}
          />
        ))}
      </svg>
    );
  }
}

export default DefaultProfileImage;
