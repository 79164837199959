/**
 * @module common/functional_helpers
 * A collection of functions that expand lodash functionality
 */

import apply from 'lodash/fp/apply';
import flow from 'lodash/fp/flow';
import identity from 'lodash/fp/identity';
import isBoolean from 'lodash/fp/isBoolean';
import isEmpty from 'lodash/fp/isEmpty';
import isFunction from 'lodash/fp/isFunction';
import isNil from 'lodash/fp/isNil';
import isNumber from 'lodash/fp/isNumber';
import isUndefined from 'lodash/fp/isUndefined';
import keys from 'lodash/fp/keys';
import mapValues from 'lodash/fp/mapValues';
import multiply from 'lodash/fp/multiply';
import negate from 'lodash/fp/negate';
import omitBy from 'lodash/fp/omitBy';
import pick from 'lodash/fp/pick';

/**
 * isNotNil
 * @param input
 * @returns {boolean} false if input is null or undefined, otherwise true
 */
export const isNotNil = negate(isNil);

/**
 * omitNilValues
 * @param {object} input
 * @returns {object} the input object where properties whose value is either null or undefined have been removed
 */
export const omitNilValues = omitBy(isNil);

/**
 * pickKeys
 * Given an object, returns a function that takes another object and picks the
 * properties of that object corresponding to the keys of the first object.
 * @param {Object} sourceObj - object whose keys should be used as the source to be picked
 * @returns {Function} a function that given an object, returns an object with just the keys of previous provided sourceObj
 */
export const pickKeys = flow(
  keys,
  pick
);

/**
 * tupleProduct
 * @param {number[]} a pair of numbers to by multiplied together
 * @returns {number} the result of multiplying the pair of numbers together
 */
export const tupleProduct = apply(multiply);

/**
 * Given an object whose properties are functions, invoke each function with the
 * given arguments mapping the results to the same object structure.
 * Example:
 * ```
 * const stateObj = { blah: 'hello' };
 *
 * const example1 = invokeArgsMapValue({
 *   bar: get('blah')
 * })(stateObj);
 * assert({ bar: 'hello', }, example1);
 *
 * // Equivalent to:
 * const example2 = (state) => ({
 *   bar: get('blah')(state)
 * })(stateObj);
 * assert({ bar: 'hello', }, example2);
 * ```
 *
 * @param obj
 * @returns {function(...[*]): *}
 */
export const invokeArgsMapValues = (obj) => (...args) => mapValues((f) => f(...args))(obj);

/**
 * isPresent
 * @param object
 * @returns {boolean} false if the input is null, undefined, NaN, empty, or the number 0, otherwise returns false.
 */
export const isPresent = (object) => {
  if (isBoolean(object) || isFunction(object)) {
    return true;
  } else if (isNumber(object)) {
    // Just cast the number to a boolean.
    // If it's 0, we don't consider it 'present'
    // If it's NaN, we also don't consider it 'present'
    return !!object;
  } else {
    return !isEmpty(object);
  }
};

/**
 * @see isNotNil
 */
export const hasValue = isNotNil;

/**
 * instead
 * If the provided value is present, return it, otherwise return the fallback
 * @param value
 * @param insteadValue
 * @returns {*}
 */
export const instead = (value, insteadValue) => (isPresent(value) ? value : insteadValue);

/**
 * @see instead
 */
export const otherwise = instead;

/**
 * @param value
 * @returns {boolean} the boolean negation of that value
 */
export const negateValue = negate(identity);

/**
 * @param value
 * @returns {boolean} false if the value is undefined, otherwise returns true
 */
export const isDefined = negate(isUndefined);
