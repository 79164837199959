import _ from 'lodash';

import assertHasProperty from 'common/assertions/assertHasProperty';

import DataProvider from './DataProvider';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import { appToken } from 'common/http';

/**
 * `RegionCodingProvider` is an implementation of `DataProvider` that enables
 * users to initiate region coding and get region coding status of a shape file on the current domain.
 *
 * @constructor
 *
 * @param {Object} config
 *  @property {String} domain - The domain against which to make the query.
 *  @property {String} datasetUid - The uid of the dataset against which
 *    the user intends to query.
 */
export default function RegionCodingProvider(config) {
  config.domain = config.domain || getDefaultDomain();

  _.extend(this, new DataProvider(config));

  assertHasProperty(config, 'domain');
  assertHasProperty(config, 'datasetUid');

  const { domain, datasetUid } = config;
  const requestOptions = { credentials: 'include' };
  const apiUrl = `https://${domain}/geo`;
  let abortPolling = false;

  /**
   * Private methods
   */

  const handleJSON = (response) => response.json();
  const handleStatus = (response) => {
    if (response.ok) {
      return response;
    } else {
      throw new Error(response.statusText);
    }
  };

  /**
   * Public methods
   */

  /**
   * `.getRegionCodingStatus()` fetches the current status of the region coding process.
   *
   *  @property {String} shapefileId - The uid of the curated region used for fetching
   *    the status details of the region coding process.
   *
   *  @property {String} jobId - Unique id generated for each region coding process used for fetching
   *    the status details of the region coding process.
   *
   * @return {Object}
   *   @property {Object{}} data - An object with dataset, jobId and timestamp details
   *   @property {String} details - detailed status message.
   *   @property {String} status - completed|processing|failed.
   *   @property {Boolean} success - true|false.
   */
  this.getRegionCodingStatus = ({ shapefileId, jobId }) => {
    var url = `${apiUrl}/status?datasetId=${datasetUid}`;

    if (jobId) {
      url += `&jobId=${jobId}`;
    } else if (shapefileId) {
      url += `&shapefileId=${shapefileId}`;
    } else {
      throw new Error('Expected an Object with either shapefileId or jobId to check status.');
    }

    return fetch(url, requestOptions).
      then(handleStatus).
      then(handleJSON);
  };

  this.abortPollingForRegionCodingStatus = () => {
    abortPolling = true;
  };

  /**
   * `.awaitRegionCodingCompletion()` polls for a region coding in process and updates the status every 5000 ms.
   *  It keeps polling for status of current processing region coding job until it completes are aborted by user
   *
   *  @property {String} shapefileId - The uid of the curated region used for fetching
   *    the status details of the region coding process.
   *
   *  @property {String} jobId - Unique id generated for each region coding process used for fetching
   *    the status details of the region coding process.
   *
   *  @param {Function} processingCallback The function to be called upon every successful
   *    response to update the user with last checked time stamp.
   *
   * @return {Promise}
   */

  this.awaitRegionCodingCompletion = ({ shapefileId, jobId }, processingCallback) => {
    return new Promise((resolve, reject) => {
      var handleResponse = (response) => {
        if (abortPolling) {
          return;
        }

        switch (response.status) {
          case 'completed':
            // As described in [EN-23357] there is a hole exists between time CRJ has notified the job
            // has finished vs when the computed column is available causes SOQL call to fail and Region Map
            // not rendering because of it adding a temporary hack as requested to wait for 5 seconds to be
            // on safer side, currently it has 2 seconds delay until the computed column is available.
            setTimeout(() => {
              resolve(response);
            }, 90000);
            break;

          case 'processing':
            if (_.isFunction(processingCallback)) {
              processingCallback({ status: 'await' });
            }

            awaitCompletion();
            break;

          case 'failed':
            reject(new Error(`The region coding job for ${shapefileId} failed.`));
            break;

          default:
            reject(new Error(`We cannot determine the region coding status of ${shapefileId}.`));
            break;
        }
      };

      var awaitCompletion = () => {
        _.delay(() => {
          this.getRegionCodingStatus({ shapefileId, jobId }).
            then(handleResponse).
            catch(handleResponse);
        }, 5000);
      };

      awaitCompletion();
    });
  };

  /**
   * `.initiateRegionCoding()` initiates the region coding process.
   *  It keeps polling for status of current processing region coding job until it completes are aborted by user
   *
   *  @property {String} shapefileId - The uid of the curated region against which
   *    the user intends to process region coding.
   *
   *  @property {String} sourceColumn - The dimension columnName in the dataset to be computed.
   *
   * @return {Object}
   */
  this.initiateRegionCoding = (shapefileId, sourceColumn) => {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-App-Token': appToken()
      },
      body: JSON.stringify({
        datasetId: datasetUid,
        shapefileId,
        sourceColumn
      })
    };

    abortPolling = false;

    return fetch(`${apiUrl}/initiate`, _.merge(options, requestOptions)).
      then(handleStatus).
      then(handleJSON);
  };
}
