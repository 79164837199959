import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import QueryEditorReducer from './QueryEditorReducer';
import QueryEditorSagas from './QueryEditorSagas';

/**
 * Creates a store for the QueryEditor component to use
 *
 * An initial state can be passed in.
 */
export default (initialState) => {
  // use the redux devtool's composeEnhancers to keep them around
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'QueryEditor' })) ||
    compose;

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    combineReducers({
      editor: QueryEditorReducer
    }),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(QueryEditorSagas);

  return store;
};
