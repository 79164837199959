import React from 'react';
import ExternalWindowPortal, { Props as PortalProps } from './ExternalWindowPortal';

export interface Props {
  undocked: boolean;
  portalProps: PortalProps;
}

class UndockableComponent extends React.Component<Props> {
  render() {
    const { children, portalProps, undocked } = this.props;

    if (undocked) {
      return (<ExternalWindowPortal {...portalProps}>
        {children}
      </ExternalWindowPortal>);
    } else {
      return <React.Fragment>{children}</React.Fragment>;
    }
  }
}

export default UndockableComponent;
