import React from 'react';
import { ForgeTextField } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { UserInputField } from '../../types';
import PrivateFieldIcon from 'common/FormatColumn/PrivateFieldIcon/PrivateFieldIcon';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.dataset_tab') =>
  I18n.t(key, { scope });

export type TextUserInputField = UserInputField<string | undefined>;

export interface TextAreaProps {
  isRequired?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  field: TextUserInputField;
  inErrorState: boolean;
  isPrivate?: boolean;
  isRestrictedForUser?: boolean;
}

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  isRequired,
  handleChange,
  field,
  inErrorState,
  isPrivate,
  isRestrictedForUser
}) => {
  const baseClasses = ['text-area', 'metadata-component-text-area', 'forge-metadata-component-text-area'];

  const errorClasses = ['text-input-error', 'metadata-component-text-area-error'];

  if (inErrorState) {
    baseClasses.concat(errorClasses);
  }

  const baseTextArea = (
    <textarea
      id={field.name}
      name={field.name}
      placeholder={field.placeholder}
      className={baseClasses.join(' ')}
      aria-label={field.label}
      aria-required={field.isRequired}
      value={field.value}
      onChange={handleChange}
      disabled={field.disabled}
    />
  );

  return (
    <ForgeTextField
      invalid={inErrorState}
      floatLabelType="always"
      required={isRequired}
      className="metadata-modal-width"
    >
      {isPrivate && <PrivateFieldIcon />}
      {baseTextArea}
      <label htmlFor={field.name} slot="label">
        {field.label || field.name}
      </label>
      {isRestrictedForUser && <span slot="helper-text">{t('subtitles.restricted_field')}</span>}
    </ForgeTextField>
  );
};

export default TextArea;
