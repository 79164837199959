/**
 * TabbedPanelInterface - A slightly opinionated component for rendering our
 * tabbed interfaces where tabs are associated with routes
 *
 * Takes as children <TabbedPanel>s
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory, Redirect, Route, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import map from 'lodash/fp/map';

import TabbedPanel from './TabbedPanel';
import TabbedView from './TabbedView';

import './index.scss';

class TabbedPanelInterface extends Component {
  static propTypes = {
    // base path to use for route
    basePath: PropTypes.string.isRequired,

    // a list of TabbedPanels to render
    children: PropTypes.array,

    // default path to redirect to when initially rendering the component
    defaultPath: PropTypes.string,

    // function to call to get default path; used if defaultPath is not passed in
    defaultPathSelector: PropTypes.func,

    // redux store to use for managing route changes
    store: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const { store } = props;
    this.history = syncHistoryWithStore(browserHistory, store);
  }

  render() {
    const { basePath, children, defaultPath, defaultPathSelector, store } = this.props;

    const state = store.getState();

    // create a list of panel configs for all the TabbedPanels we have as children
    const panelConfigs = React.Children.map(children, ({ props }) =>
      /*
       * TabbedPanel can either be passed some props (i.e. isVisible, path) directly
       * or given selector functions that grab things from state
       *
       * We cannot directly connect these to state since Router requires a list of Route components
       * and doesn't know how to traverse children to find Routes
       */
      TabbedPanel.connectTabPanelToState(state, props)
    );

    // list of routes passed to react-router, generated from our list of panels
    const routes = [
      ...map(TabbedPanel.generateRoute, panelConfigs),
      <Redirect key="default" from="*" to={defaultPath || defaultPathSelector(state)} />
    ];

    return (
      <Router history={this.history}>
        {/* "component" here is combined list of tabs at the top and the "active" main component */}
        <Route path={basePath} component={TabbedView.withPanelConfigs(panelConfigs)}>
          {routes}
        </Route>
      </Router>
    );
  }
}

export default TabbedPanelInterface;
