import React, { memo, useState } from 'react';
import { ForgeTable, ForgeCard, ForgePaginator, ForgeScaffold } from '@tylertech/forge-react';
import _ from 'lodash';
import { ViewColumn } from 'common/types/viewColumn';
import JsxToHtmlElementService from 'common/tyler_forge/js_utilities/jsxToHtmlElementService/JsxToHtmlElementService';
import ColumnTypeIcon from './ColumnTypeIcon';
import { getDocumentationLinkForDataType } from 'common/views/dataTypeMetadata';
import I18n from 'common/i18n';
import { IPaginatorChangeEvent, ITableSortEventData } from '@tylertech/forge';
import ExpandableTextSection from './ExpandableTextSection';

const t = (translationKey: string) => I18n.t(translationKey, { scope: 'common.schema_preview' });
type Props = {
  columns: ViewColumn[];
};

const SchemaColumnPreview = ({ columns }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [sortedData, setSortedData] = useState(columns);

  const jsxToHtmlElementService = new JsxToHtmlElementService();

  const nameTemplate = (_index: number, _div: HTMLElement, column: ViewColumn) => {
    const jsx = (
      <div className="schema-column-name-cell">
        <ColumnTypeIcon type={column.dataTypeName} forge={true} />
        <span>{column.name}</span>
      </div>
    );
    return jsxToHtmlElementService.wrapJsx(jsx, `${column.id}-name`);
  };

  const apiFieldNameTemplate = (_index: number, _div: HTMLElement, column: ViewColumn) => {
    const jsx = (
      <div className="schema-column-field-name-cell">
        <span>{column.fieldName}</span>
      </div>
    );
    return jsxToHtmlElementService.wrapJsx(jsx, `${column.id}-api-field-name`);
  };

  const dataTypeTemplate = (_index: number, _div: HTMLElement, column: ViewColumn) => {
    const documentationUrl = getDocumentationLinkForDataType(column.dataTypeName);
    const documentationText = t(`data_types.${column.dataTypeName}`);
    let documentationLink;

    if (documentationUrl) {
      documentationLink = (
        <a href={documentationUrl} target="_blank" rel="noreferrer">
          {documentationText}
        </a>
      );
    } else {
      documentationLink = documentationText;
    }

    const jsx = <div className="schema-column-data-type-cell">{documentationLink}</div>;
    return jsxToHtmlElementService.wrapJsx(jsx, `${column.id}-datatype`);
  };

  const descriptionTemplate = (_index: number, _div: HTMLElement, column: ViewColumn) => {
    const jsx = (
      <div className="schema-expandable-wrapper">
        <ExpandableTextSection bodyText={column.description} countOfLinesToShow={3} additionalClasses='description-expandable-text-section' />
      </div>
    );
    return jsxToHtmlElementService.wrapJsx(jsx, `${column.id}-description`);
  };

  const columnConfig = [
    { property: 'name', header: t('column_name'), template: nameTemplate, sortable: true },
    { property: 'description', header: t('description'), template: descriptionTemplate },
    { property: 'fieldName', header: t('api_field_name'), template: apiFieldNameTemplate, sortable: true },
    { property: 'dataTypeName', header: t('data_type'), template: dataTypeTemplate, sortable: true }
  ];
  // pagination
  const indexOfLastData = currentPage * pageSize;
  const indexOfFirstData = indexOfLastData - pageSize;
  const currentData = sortedData.slice(indexOfFirstData, indexOfLastData);
  // sorting
  const onSort = (evt: CustomEvent<ITableSortEventData>): void => {
    const direction = evt.detail.direction === 'UNSET' ? 'ASC' : evt.detail.direction;
    const propertyName =
      evt.detail.direction === 'UNSET' ? 'position' : columnConfig[evt.detail.columnIndex].property;

    setSortedData(sortData(direction, propertyName));
  };

  const getPropertyToCompare = (column: ViewColumn, propertyName: string) => {
    if (propertyName === 'dataTypeName') {
      return t(`data_types.${column.dataTypeName}`);
    } else {
      return column[propertyName].toString();
    }
  };

  const sortData = (direction: string, propertyName: string) => {
    if (propertyName.length) {
      return [...sortedData].sort(function (a, b) {
        const propertyToCompareA = getPropertyToCompare(a, propertyName);
        const propertyToCompareB = getPropertyToCompare(b, propertyName);
        if (direction === 'DESC') {
          return propertyToCompareB.localeCompare(propertyToCompareA);
        } else if (direction === 'ASC') {
          return propertyToCompareA.localeCompare(propertyToCompareB);
        } else {
          return 0;
        }
      });
    } else {
      return [...sortedData];
    }
  };
  if (_.isEmpty(columns)) {
    return null;
  }

  return (
    <section id="schema-column-preview" data-testid="schema-column-preview" className="landing-page-section">
      <ForgeCard>
        <ForgeScaffold>
          <h2 slot='header' className="landing-page-section-header">
            {t('new_title')} ({columns.length})
          </h2>
          <div slot="body">
            <div className='schema-column-preview-table'>
              <ForgeTable data={currentData} columnConfigurations={columnConfig} on-forge-table-sort={onSort} />
            </div>
            <div className="table-paginator">
              <ForgePaginator
                pageSize={pageSize}
                total={columns.length}
                on-forge-paginator-change={(e: { detail: IPaginatorChangeEvent }) => {
                  setCurrentPage(e.detail.pageIndex + 1);
                  setPageSize(e.detail.pageSize);
                }}
              />
            </div>
          </div>
        </ForgeScaffold>
      </ForgeCard>
    </section>
  );
};

// We want this to be a pure component where we only re-render when the columns actually change, this prevents if flickering every time the parent state updates
const propsAreEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return _.isEqual(prevProps.columns, nextProps.columns);
};

export default memo(SchemaColumnPreview, propsAreEqual);
