import classnames from 'classnames';
import { assertIsNotNil } from 'common/assertions';
import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';
import ResultsTable from 'common/components/ResultsTable';
import React, { PropsWithChildren } from 'react';
import { none, Option, some } from 'ts-option';
import { IconName } from '../SocrataIcon';
type SearchPredicate<T> = (term: string, item: T) => boolean;
export interface SortState<S> {
  by: S;
  direction: 'ASC' | 'DESC';
}

interface ColumnProps<T, S> {
  header: string;
  sortKey?: S;
  render: (t: T) => JSX.Element | string | number | null;
}
export class Column<T, S> extends React.Component<ColumnProps<T, S>> {
  render() {
    return null;
  }
}

interface Props<S> {
  searchPlaceholder: string;
  searchLabel?: string;
  rowKey: string;
  noResultsMessage: string;
  rowClassFunction: () => string;
  id: string;
  defaultSort: SortState<S>;
  embedded?: boolean;
}
interface State<T, S> {
  sort: Option<SortState<S>>;
  filter: Option<string>;
}

type SimpleProps<T, S> = {
  items: T[];
  mapSortKeyToValue: (s: S) => (i: T) => string | number;
  searchPredicate: SearchPredicate<T>;
} & Props<S>;

export default class SimpleFilterSortResultsTable<T, S> extends React.Component<
  SimpleProps<T, S>,
  State<T, S>
> {
  state: State<T, S> = {
    sort: none,
    filter: none
  };

  onSortChange = (next: SortState<S>) => {
    this.setState({ sort: some(next) });
  };

  getSort = () => {
    return this.state.sort.getOrElseValue(this.props.defaultSort);
  };

  getSortedAndFilteredItems = () => {
    const filteredItems = this.state.filter.match({
      some: (term) => this.props.items.filter((item) => this.props.searchPredicate(term, item)),
      none: () => this.props.items
    });
    return this.performSort(filteredItems);
  };

  performSort = (items: T[]): T[] => {
    const key = this.props.mapSortKeyToValue(this.getSort().by);
    const inversion = this.getSort().direction === 'ASC' ? 1 : -1;
    return items.sort((a, b) => {
      const ka = key(a);
      const kb = key(b);
      if (!ka) return 1 * inversion;
      if (!kb) return -1 * inversion;
      if (ka === kb) return 0;
      return (ka > kb ? 1 : -1) * inversion;
    });
  };

  onSearchChange = (term: string) => {
    this.setState({ filter: some(term) });
  };

  render() {
    return (
      <StatelessTable
        embedded={this.props.embedded}
        searchPlaceholder={this.props.searchPlaceholder}
        searchLabel={this.props.searchLabel}
        rowKey={this.props.rowKey}
        noResultsMessage={this.props.noResultsMessage}
        rowClassFunction={this.props.rowClassFunction}
        id={this.props.id}
        search={this.state.filter.getOrElseValue('')}
        onSearchChange={this.onSearchChange}
        sort={this.getSort()}
        inProgress={false}
        onSortChange={this.onSortChange}
        items={this.getSortedAndFilteredItems()}
      >
        {this.props.children}
      </StatelessTable>
    );
  }
}

interface StatelessTable<T, S> {
  embedded?: boolean;
  searchPlaceholder: string;
  searchLabel?: string;
  rowKey: string;
  noResultsMessage: string;
  rowClassFunction: () => string;
  id: string;
  inProgress: boolean;

  items: T[];

  search: string;
  onSearchChange: (f: string) => void;

  sort: SortState<S>;
  onSortChange: (s: SortState<S>) => void;
}
export function StatelessTable<T, S>(props: PropsWithChildren<StatelessTable<T, S>>) {
  const klasses = classnames({
    'sorted-results-table': true,
    embedded: props.embedded
  });

  const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onSearchChange(e.currentTarget.value);
  };

  const onSort = (by: S, current: SortState<S>) => {
    let next = current;
    if (by === current.by) {
      const direction = current.direction === 'ASC' ? 'DESC' : 'ASC';
      next = { ...current, direction };
    } else {
      next = { ...current, by };
    }
    props.onSortChange(next);
  };

  return (
    <div className={klasses}>
      <div className="search-bar">
        <Form onSubmit={onSearchSubmit}>
          <Input
            onChange={onSearchChange}
            value={props.search}
            placeholder={props.searchPlaceholder}
            label={props.searchLabel || ''}
            name="search"
            iconName={IconName.Search}
          />
        </Form>
      </div>
      <div className="sorted-results-table-inner">
        <ResultsTable
          data={props.items}
          rowKey={props.rowKey}
          loadingData={props.inProgress}
          noResultsMessage={props.noResultsMessage}
          rowClassFunction={props.rowClassFunction}
          id={props.id}
        >
          {React.Children.map<JSX.Element, React.ReactNode>(props.children, (elm) => {
            assertIsNotNil(elm);

            const element = elm as Column<T, S>;
            const columnProps = element.props;
            const sort = props.sort;

            return (
              <ResultsTable.Column
                dataIndex={columnProps.sortKey || columnProps.header}
                header={columnProps.header}
                sortDirection={sort.direction}
                isActive={sort.by === columnProps.sortKey}
                onSort={() => (columnProps.sortKey ? onSort(columnProps.sortKey, sort) : null)}
              >
                {(unused: never, item: T) => columnProps.render(item)}
              </ResultsTable.Column>
            );
          })}
        </ResultsTable>
      </div>
    </div>
  );
}
