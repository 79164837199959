import React, { Component, ReactElement } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

import Input, { InputProps } from '../Input';
import { inputChanged, validateInput, InputChangedAction, ValidateInputAction } from './FormReduxActions';
import { FormState, InputState } from './types';

/** Props passed in to the component */
interface OwnProps {
  /**
   * The name of this input
   * Used by the reducer to track state
   */
  name: string;
  id?: string;
  autoFocus?: boolean;
  children?: any;
  tabIndex?: number;
}

/** Props from mapDispatchToProps */
interface DispatchProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => InputChangedAction;
  onValidate: (name: string) => ValidateInputAction;
}

type Props = OwnProps & InputState & DispatchProps;

/**
 * An Input that handles all of its validation in redux.
 *
 * The "name" prop passed in here will also be used to grab its state out of the "inputs" part of the Redux state.
 *
 * @see common/components/Forms/Redux/FormReducer for how the events are handled, and FormReduxUtils for an easy way to generate the expected state.
 */
class ReduxValidatedInput extends Component<Props> {
  static defaultProps = {
    type: 'text',
    autoFocus: false,
    required: true,
    valid: true,
    disabled: false,
    tabIndex: 0
  };

  validate = (): void => {
    const { onValidate, name } = this.props;

    // basically just tell the reducer to run the validation for this input
    // (if this input doesn't have one, this is a no-op)
    onValidate(name);
  };

  render(): ReactElement {
    const inputProps = (omit(this.props, ['onValidate']) as unknown as any) as InputProps;

    return <Input {...inputProps} onBlur={this.validate} />;
  }
}

const mapStateToProps = (state: FormState, ownProps: OwnProps): InputState => ({
  ...state.inputs[ownProps.name]
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any, { name }: OwnProps): DispatchProps =>
  bindActionCreators(
    {
      onChange: (event): InputChangedAction => inputChanged(name, event.target.value),
      onValidate: validateInput
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxValidatedInput);
