import AlertListSubAlert from 'common/components/AlertList/AlertListSubAlert';
import AlertListAlert from 'common/components/AlertList/AlertListAlert';




/* Enums
------------------------------------------------------------------------ */
export enum AlertDisplayType {
  Info = 'info',
  Notice = 'notice',
  Warning = 'warning',
  Error = 'error',
  Success = 'success'
}




/* Misc Interfaces & Types
------------------------------------------------------------------------ */
export interface extentionObject {
  [key: string]: any;
}

export interface AlertTypeForgeMap {
  theme: string;
  icon: string;
}

export interface AlertForgeMap {
  success: AlertTypeForgeMap,
  info: AlertTypeForgeMap,
  notice: AlertTypeForgeMap,
  warning: AlertTypeForgeMap,
  error: AlertTypeForgeMap,
}




/* Properties
------------------------------------------------------------------------ */
// AlertList
export interface AlertListAcceptedProps {
  alerts: AlertListAlertProps[];

  // Handle alert dismissal, passed the props of the alert instance
  dismissAlert?: (alert: AlertListAlertProps) => void;
}
export type AlertListProps = AlertListAcceptedProps;

// AlertListAlert
export interface AlertListAlertAcceptedProps {
  /* Required
  ------------------------------------------------------------ */

  // The I18n key for the alert message string
  translationKey: string;

  // The type of alert to display
  type: AlertDisplayType;

  /* Optional
  ------------------------------------------------------------ */

  /* Values
  -------------------------------------------------- */

  // An ID value to use to identify the alert. Recommend {type-number}
  alertId?: string | number;

  // Any interpolations which should be applied to the translationKey's text
  interpolations?: object;

  // Should the alert be dismissable by the user
  dismissable?: boolean;

  // Should the alert be dismissable by the user
  autoDismiss?: boolean;

  // How long (in ms) should the alert be visible before auto-dismissing? Only respected if autoDismiss is true
  autoDismissDelay?: number;

  // Allows for multiple alerts of the same type to be displayed in a list
  alerts?: Array<AlertListSubAlertProps>;

  /* Methods
  -------------------------------------------------- */

  // Handle alert dismissal, passed the props of the alert instance
  dismissAlert?: (alert: AlertListAlertProps) => void;
}
export type AlertListAlertProps = AlertListAlertAcceptedProps;

// AlertListSubAlert
export interface AlertListAcceptedSubAlertProps {
  // The I18n key for the alert message string
  translationKey: string;

  // Any interpolations which should be applied to the translationKey's text
  interpolations?: object;

  // Allows for multiple alerts of the same type to be displayed in a list
  alerts?: Array<AlertListSubAlertProps>;

  // An ID value to use to identify the alert. Recommend {type-number}
  alertId?: string | number;
}
export type AlertListSubAlertProps = AlertListAcceptedSubAlertProps;




/* States
------------------------------------------------------------------------ */
export interface AlertListAlertState {
  dismissed: boolean;
}




/* Components
------------------------------------------------------------------------ */
export interface AlertListComponent {
  /* Attributes
  ------------------------------------------------------------ */
  props: AlertListProps;



  /* Methods
  ------------------------------------------------------------ */
  sortAlerts: () => AlertListAlertProps[];

  renderAlert: (alert: AlertListAlertProps) => JSX.Element;

  renderAlertList: (alertList: AlertListAlertProps[]) => Array<JSX.Element> | null;
}

export interface AlertListAlertComponent {
  props: AlertListAlertProps;



  /* Attributes
  ------------------------------------------------------------ */
  alertForgeMap: AlertForgeMap;
  autoDismissTimeoutId: undefined | number;
  alertId: string | number;



  /* Methods
  ------------------------------------------------------------ */

  /* Helpers
  ------------------------------------------------------- */
  setAlertId: (props: AlertListAlertProps) => AlertListAlert | AlertListAlertComponent;

  mapForgeTheme: (type: string) => string;

  mapForgeIcon: (type: string) => string;


  /* State Management
  ------------------------------------------------------- */
  clearAutoDismissTimeoutId: () => AlertListAlert;

  dismiss: () => AlertListAlert;

  setAutoDismiss: (delay?: number) => AlertListAlert;


  /* Renderers
  ------------------------------------------------------- */
  renderForgeIcon: () => JSX.Element;

  renderDismissButton: () => JSX.Element;

  renderMessage: () => JSX.Element | null;

  renderSubAlert: (alert: AlertListSubAlertProps, i: number) => JSX.Element;

  renderSubAlertsList: () => JSX.Element | null;
}

export interface AlertListSubAlertComponent {
  /* Attributes
  ------------------------------------------------------------ */
  props: AlertListSubAlertProps;



  /* Methods
  ------------------------------------------------------------ */
  renderMessage: () => JSX.Element | null;

  renderSubAlert: (subAlert: AlertListSubAlertProps, i: number) => JSX.Element;

  renderSubAlertsList: () => JSX.Element | null;
}
