// Approvals
export const SET_APPROVAL = 'SET_APPROVAL';
export const setApprovalStatus = (uid, status) => ({ type: SET_APPROVAL, uid, status });

// Time to display alert is in ms
export const SHOW_INFO_ALERT = 'SHOW_INFO_ALERT';
export const showInfoAlert = (title, body, time = 7000, alertType = 'info') => ({ type: SHOW_INFO_ALERT, title, body, time, alertType });

export const SHOW_ERROR_ALERT = 'SHOW_ERROR_ALERT';
export const showErrorAlert = (title, body, time = 7000, alertType = 'error') => ({ type: SHOW_ERROR_ALERT, title, body, time, alertType });

export const HIDE_ALERT = 'HIDE_ALERT';
export const hideAlert = () => ({ type: HIDE_ALERT });
