import isEmpty from 'lodash/fp/isEmpty';

import { defaultHeaders, checkStatus, fetchJsonWithDefaultHeaders } from './http';
import { omitNilValues } from './functional_helpers';

const API_PATH = '/api/platform_admin_access';
const FETCH_OPTIONS = {
  credentials: 'same-origin'
};

const generateSearchParams = (params) => {
  const reducedParams = omitNilValues(params);
  if (isEmpty(reducedParams)) { return ''; }
  const searchParams = new URLSearchParams(reducedParams);
  return `?${searchParams.toString()}`;
};

export const getAll = (includeExpired = false) => {
  const apiPath = `${API_PATH}${generateSearchParams({ includeExpired })}`;
  return fetchJsonWithDefaultHeaders(apiPath, FETCH_OPTIONS);
};

export const grantAccess = (targetUserEmail, duration) => {
  const body = { targetUserEmail, duration };
  const fetchOptions = {
    ...FETCH_OPTIONS,
    body: JSON.stringify(body),
    method: 'POST'
  };
  return fetchJsonWithDefaultHeaders(API_PATH, fetchOptions);
};

export const revokeAccess = (email) => {
  const fetchOptions = {
    ...FETCH_OPTIONS,
    headers: defaultHeaders,
    method: 'DELETE'
  };
  const apiPath = `${API_PATH}/${encodeURIComponent(email)}`;
  return fetch(apiPath, fetchOptions).then(checkStatus);
};

export const ERROR_CODES = {
  GRANT_MUST_BE_IN_FUTURE: 'TEMPORARY_ACCESS_GRANT.GRANT_MUST_BE_IN_FUTURE',
  INSUFFICIENT_PERMISSIONS: 'TEMPORARY_ACCESS_GRANT.INSUFFICIENT_PERMISSIONS',
  INVALID_DURATION: 'TEMPORARY_ACCESS_GRANT.INVALID_DURATION',
  MISSING_FIELD: 'TEMPORARY_ACCESS_GRANT.MISSING_FIELD',
  NO_ACTIVE_GRANT: 'TEMPORARY_ACCESS_GRANT.NO_ACTIVE_GRANT',
  USER_NOT_FOUND: 'TEMPORARY_ACCESS_GRANT.USER_NOT_FOUND',
  USER_ALREADY_HAS_GRANT: 'TEMPORARY_ACCESS_GRANT.USER_ALREADY_HAS_GRANT'
};
