import React from 'react';
import { fetchTranslation } from 'common/locale';
import { ForgeDialog, ForgeIconButton, ForgeButton, ForgeToolbar } from '@tylertech/forge-react';
import './UnappliedChangesModal.scss';

const t = (k: string) => fetchTranslation(k, 'shared.components.unapplied_changes_modal');

export interface NavigationOptions {
  navigateOnApply: boolean,
  navigateOnDiscard: boolean
}
export type NavigateWithUnappliedChanges = (callback: () => void, partialOptions?: Partial<NavigationOptions>) => void;
export const buildNavigationOptions = (partialOptions: Partial<NavigationOptions> = {}) => ({
  navigateOnApply: false,
  navigateOnDiscard: true,
  ...partialOptions
});

export interface UnappliedChangesModalProps {
  isOpen: boolean;
  onPrimaryAction: () => void;
  onDiscardChanges: () => void;
  onDismiss: () => void;
  title?: string;
  bodyText?: string;
  affirmativeButtonText?: string;
  affirmativeButtonType?: 'raised' | 'outlined';
}

export default function UnappliedChangesModal(props: UnappliedChangesModalProps) {
  const {
    isOpen,
    onPrimaryAction,
    onDiscardChanges,
    onDismiss,
    title = t('title'),
    bodyText = t('body'),
    affirmativeButtonText = t('apply_changes'),
    affirmativeButtonType = 'outlined'
  } = props;

  const dialogAttributes = new Map([
    ['aria-labelledby', 'unapplied-changes-dialog-title'],
    ['aria-describedby', 'unapplied-changes-dialog-message']
  ]);

  return (
    <ForgeDialog onDismiss={onDismiss} open={isOpen} options={{dialogAttributes}}>
      <ForgeToolbar>
        <h1 slot="start" id='unapplied-changes-dialog-title' className="forge-typography--title forge-header-title">
          {title}
        </h1>
        <ForgeIconButton slot="end">
          <button className="tyler-icons" onClick={onDismiss} aria-label={t('cancel')} data-testid='unapplied-changes-modal-x-button'>
            close
          </button>
        </ForgeIconButton>
      </ForgeToolbar>

      <section className="forge-dialog__body modal-body-text">
        <p id='unapplied-changes-dialog-message' className="forge-typography--body2">
          {bodyText}
        </p>
      </section>

      <footer>
        <ForgeToolbar inverted={true}>
          <ForgeButton
            type="outlined"
            onClick={onDiscardChanges}
            className="discard-changes"
            slot="end"
            style={{marginRight: '16px'}}
          >
            <button type="button">
              {t('discard_changes')}
            </button>
          </ForgeButton>
          <ForgeButton
            type={affirmativeButtonType}
            className="apply-changes"
            slot="end"
            onClick={onPrimaryAction}>
            <button type="button">
              {affirmativeButtonText}
            </button>
          </ForgeButton>
        </ForgeToolbar>
      </footer>
    </ForgeDialog>
  );
}
