// Since we rely on typesafe-actions to create return types here, we don't want to specify them
/* eslint @typescript-eslint/explicit-function-return-type: off */

import { action } from 'typesafe-actions';

import { Inputs } from './types';

// see FormReducer and ReduxValidatedInput for how these are used
export const INPUT_CHANGED = 'INPUT_CHANGED';
export const CHECKBOX_CHANGED = 'CHECKBOX_CHANGED';
export const inputChanged = (name: string, value: string) =>
  action(INPUT_CHANGED, {
    name,
    value
  });

export const checkboxChanged = (name: string, value: boolean) =>
  action(CHECKBOX_CHANGED, {
    name,
    value
  });

export type InputChangedAction = ReturnType<typeof inputChanged> | ReturnType<typeof checkboxChanged>;

export const RECAPTCHA_CALLBACK = 'RECAPTCHA_CALLBACK';
export const recaptchaCallback = (response: string | null) => action(RECAPTCHA_CALLBACK, { response });
export type RecaptchaCallbackAction = ReturnType<typeof recaptchaCallback>;

export const VALIDATE_INPUT = 'VALIDATE_INPUT';
export const validateInput = (name: string) =>
  action(VALIDATE_INPUT, {
    name
  });
export type ValidateInputAction = ReturnType<typeof validateInput>;

export const VALIDATE_FORM = 'VALIDATE_FORM';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateForm = (callback: (inputs: Inputs) => void, event: React.FormEvent<HTMLFormElement>, payload: any) =>
  action(VALIDATE_FORM, {
    callback,
    event,
    payload
  });
export type ValidateFormAction = ReturnType<typeof validateForm>;

export type FormActionTypes =
  | InputChangedAction
  | RecaptchaCallbackAction
  | ValidateInputAction
  | ValidateFormAction;
