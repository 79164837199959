import React, { ChangeEvent, useState } from 'react';
import { option } from 'ts-option';
import { isEqual as _isEqual } from 'lodash';
import I18n from 'common/i18n';
import ObjectEditor, { Json } from 'common/components/ObjectEditor';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { Flannel, FlannelContent, FlannelHeader } from 'common/components/Flannel';
import ReadOnlySoQLEditor from 'common/components/SoQLEditor/ReadOnlySoQLEditor';
import { uniqColumnRefs } from 'common/soql/soql-helpers';
import { fieldValueForCref } from '../utils/helpers';
import TextInput from '../inputs/TextInput';
import TextArea from '../inputs/TextArea';
import { ColumnRef, SoQLType, TableQualifier } from 'common/types/soql';
import { FieldT, FieldValue } from 'common/types/metadataTemplate';
import { UserInputField } from '../../types';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.dataset_tab') =>
  I18n.t(key, { scope });

type InputType = 'text' | 'textarea';

export interface ExpressionInputFieldsProps {
  inputType: InputType;
  inErrorState: boolean;
  isRequired: boolean;
  inProgress: boolean;
  qualifier: TableQualifier;
  field: FieldT;
  value: FieldValue;
  isPrivate: boolean;
  isRestrictedForUser: boolean;
  onUpdateField: (cref: ColumnRef, value: Json) => void;
  getLabel?: (fieldName: string) => string | null;
  getPlaceholder?: (fieldName: string) => string | null;
}

const ExpressionInputFields: React.FunctionComponent<ExpressionInputFieldsProps> = ({
  inputType,
  field,
  qualifier,
  value,
  inErrorState,
  inProgress,
  isRequired,
  isPrivate,
  isRestrictedForUser,
  onUpdateField,
  getLabel,
  getPlaceholder
}) => {
  const [isFlannelShowing, setFlannel] = useState(false);
  // Alright, so a metadata field can have 0 or N inputs.
  // Because dependencies can be expressed between metadata inputs, ex:
  // "When using license X, attribution needs to be filled in"
  // expressions can reference more than just the metadata field that they apply
  // to. This is fine. But we only want to supply the user with one input field,
  // and we want to take the other field values from the other places in the form where
  // they get filled in.

  // Example: we expose to the user a dropdown for license for the "License"
  // metadata field. The "Attribution" field depends on which license was set,
  // but for the attribution field, we just want to expose a text box for the attribution,
  // rather than a second field that shows the value of the column reference (ie: license_id)
  // because it's set elsewhere in the form.

  // Ultimately, we just want to show the metadata input field(s) that concerns this
  // output field.
  const isIndependentCref = (cref: ColumnRef) =>
    cref.qualifier === qualifier && cref.value === field.field_name;

  const crefs = uniqColumnRefs(field.parsed_expr).filter(isIndependentCref);

  const label = (cref: ColumnRef) => {
    // The label is displayed as follows:
    //    * the caller of this component can pass in a getLabel function, which we use
    //    * if that isn't supplied or it returns null, and there is only one input field
    //      for this expression, we just use the display name
    //    * if there are multiple input fields for this expression, we will display each one as
    //      the label
    if (getLabel) {
      return getLabel(cref.value) || cref.value;
    }
    return crefs.length === 1 ? field.display_name : cref.value;
  };

  let result = null;
  const soqlValue = value.output.flatMap((ok) => option(ok));

  if (
    // This metadata field references has more than one input fields, so we'll show the transform
    // result
    crefs.length > 1 ||
    // This metadata field references only one input field, but the value produced by the transform
    // differs from the value in the input field
    (crefs.length === 1 &&
      fieldValueForCref(value.inputs, crefs[0])
        .flatMap((inputValue) => soqlValue.map((valueToCompare) => !_isEqual(valueToCompare, inputValue)))
        .getOrElseValue(false) &&
      !inProgress)
  ) {
    let transformDisplayRef: React.ReactNode | null = null;
    result = soqlValue
      .map(
        (
          theSoQLValue // confusing name 'theSoQLValue' is because of lint rules about shadowing
        ) => (
          <div key={1} className="transform-result-display" aria-details={t('transform_explanation')}>
            <a
              onClick={() => setFlannel(true)}
              ref={(ref) => {
                transformDisplayRef = ref;
              }}
            >
              <SocrataIcon name={IconName.QuestionInverse} />
            </a>
            <pre>{JSON.stringify(theSoQLValue, null, 2)}</pre>
            {isFlannelShowing ? (
              <Flannel title={t('transform_explanation')} target={() => transformDisplayRef}>
                <FlannelHeader title={t('transform_explanation')} onDismiss={() => setFlannel(false)} />
                <FlannelContent>
                  <ReadOnlySoQLEditor soql={field.expr} />
                </FlannelContent>
              </Flannel>
            ) : null}
          </div>
        )
      )
      .getOrElseValue(<div></div>);
  }

  return (
    <>
      {crefs.map((cref) => {
        const onInputChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          onUpdateField(cref, event.currentTarget.value);
        };

        if (field.input_soql_type === SoQLType.SoQLJsonT) {
          const inputValue = fieldValueForCref(value.inputs, cref).getOrElseValue(null);
          return (
            <div key={cref.value}>
              <ObjectEditor
                id={cref.value}
                displayName={field.display_name}
                thing={inputValue}
                onChange={(updatedValue: Json) => onUpdateField(cref, updatedValue)}
                isRestrictedForUser={isRestrictedForUser}
              />
            </div>
          );
        } else {
          const inputValue = fieldValueForCref(value.inputs, cref).getOrElseValue('');
          const fieldProps: UserInputField<any> = {
            name: label(cref),
            value: inputValue,
            label: label(cref),
            placeholder: (getPlaceholder && getPlaceholder(cref.value)) || '',
            isRequired
          };

          switch (inputType) {
            case 'text':
              return (
                <div key={cref.value}>
                  <TextInput
                    isPrivate={isPrivate}
                    isRequired={isRequired}
                    isRestrictedForUser={isRestrictedForUser}
                    field={{ ...fieldProps, disabled: fieldProps.disabled || isRestrictedForUser }}
                    inErrorState={inErrorState}
                    handleChange={onInputChanged}
                  />
                </div>
              );
            case 'textarea':
              return (
                <div key={cref.value}>
                  <TextArea
                    key={cref.value}
                    field={{ ...fieldProps, disabled: fieldProps.disabled || isRestrictedForUser }}
                    inErrorState={inErrorState}
                    handleChange={onInputChanged}
                    isRestrictedForUser={isRestrictedForUser}
                  />
                </div>
              );
          }
        }
      })}
      {result}
    </>
  );
};

export default ExpressionInputFields;
