import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import { ModalFooter } from 'common/components/Modal';
import Button from 'common/components/Button';

/**
 * Footer with cancel and confirm buttons
 */
class QueryEditorModalFooter extends Component {
  static propTypes = {
    confirmButtonText: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    query: PropTypes.string
  };

  state = {
    busy: false
  };

  onConfirmButtonClicked = async () => {
    const { onConfirm, query } = this.props;

    // Trailing semicolons aren't valid soql, silently remove them.
    /* eslint-disable no-empty-character-class */
    const trailingSemicolon = /^((.|\s)*?)(\s*;\s*)?$/g;

    // ((.|\s)*?) guarantees a match, even on an empty string. There will always be an element 1.
    const cleanedQuery = trailingSemicolon.exec(query)[1];

    this.setState({ busy: true });
    await onConfirm(cleanedQuery);
    this.setState({ busy: false });
  };

  render() {
    const { confirmButtonText, onCancel } = this.props;
    const { busy } = this.state;

    return (
      <ModalFooter>
        <Button
          className="query-editor-cancel-button"
          disabled={busy}
          onClick={onCancel}>
          {I18n.t('shared.query_editor.cancel')}
        </Button>
        <Button
          className="query-editor-confirm-button"
          busy={busy}
          disabled={busy}
          dark
          variant="primary"
          onClick={this.onConfirmButtonClicked}>
          {confirmButtonText}
        </Button>
      </ModalFooter>
    );
  }
}

const mapStateToProps = state => ({
  query: state.editor.query
});

export default connect(mapStateToProps)(QueryEditorModalFooter);
