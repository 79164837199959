import React from 'react';
import { DraggableCore, DraggableEvent } from 'react-draggable';
import _ from 'lodash';

import './index.scss';

type Direction = 'n' | 's' | 'e' | 'w';

interface Style {
  flexGrow: number;
  height: number | string;
  width: number | string;
}

interface UI {
  deltaX: number;
  deltaY: number;
}

interface ResizeProps {
  direction: Direction;
  minInitialSize?: number;
  borderClass?: string;
  handleClass?: string;
  style?: Style;
  containerClass?: string;
  onResize?: (size: number) => void;
}

interface ResizeState {
  size: number;
}

type RefSetter = (e: HTMLDivElement | null) => void;

class ResizePanel extends React.Component<ResizeProps, ResizeState> {
  private contentRef: HTMLDivElement | null;
  private wrapperRef: HTMLDivElement | null;
  private setContentRef: RefSetter;
  private setWrapperRef: RefSetter;

  constructor(props: ResizeProps) {
    super(props);
    this.state = { size: 0 };
    this.contentRef = null;
    this.wrapperRef = null;
    this.setContentRef = (elem) => (this.contentRef = elem);
    this.setWrapperRef = (elem) => (this.wrapperRef = elem);
    this.validateSize = _.debounce(this.validateSize, 100).bind(this);
  }

  isHorizontal(): boolean {
    const { direction } = this.props;
    return direction === 'w' || direction === 'e';
  }

  componentDidMount(): void {
    // Set initial size based on the content that's wrapped. If minInitialSize
    // is passed as a prop and wrapped content size is less than that, use
    // minIniitalSize instead
    const minSize = _.get(this.props, 'minInitialSize', 0);
    const content = this.contentRef;
    let contentSize = 0;
    if (content) {
      contentSize = this.isHorizontal() ? content.clientWidth : content.clientHeight;
    }
    this.setState({ size: contentSize < minSize ? minSize : contentSize });
    this.validateSize();
  }

  validateSize(): void {
    const isHorizontal = this.isHorizontal();
    const wrapper = this.wrapperRef;
    const content = this.contentRef;

    if (!wrapper || !content) {
      return;
    }

    const containerParent: Element | null = wrapper.parentElement;

    // Or if our size doesn't equal the actual content size, then we
    // must have pushed past the min size of the content, so resize back
    let minSize = isHorizontal ? content.scrollWidth : content.scrollHeight;

    const margins = isHorizontal
      ? content.clientWidth - content.clientWidth
      : content.clientHeight - content.clientHeight;
    minSize += margins;

    if (this.state.size !== minSize) {
      this.setState({
        ...this.state,
        size: minSize
      });
    } else if (containerParent) {
      // If our resizing has left the parent container's content overflowing
      // then we need to shrink back down to fit
      const overflow = isHorizontal
        ? containerParent.scrollWidth - containerParent.clientWidth
        : containerParent.scrollHeight - containerParent.clientHeight;

      if (overflow) {
        this.setState({
          ...this.state,
          size: isHorizontal ? content.clientWidth - overflow : content.clientHeight - overflow
        });
      }
    }
  }

  onResize(size: number) {
    this.props.onResize ? this.props.onResize(size) : _.noop();
  }

  handleDrag = (e: DraggableEvent, ui: UI): void => {
    const { direction } = this.props;
    const factor = direction === 'e' || direction === 's' ? -1 : 1;

    // modify the size based on the drag delta
    const delta = this.isHorizontal() ? ui.deltaX : ui.deltaY;
    this.setState((s) => {
      const size = Math.max(10, s.size - delta * factor);
      this.onResize(size);
      return { size };
    });
  };

  handleDragEnd = (): void => {
    this.validateSize();
    this.onResize(this.state.size);
  };

  render() {
    const dragHandlers = {
      onDrag: this.handleDrag,
      onStop: this.handleDragEnd
    };
    const { direction } = this.props;
    const isHorizontal = this.isHorizontal();

    const classNames = ['resize-container'];

    if (isHorizontal) {
      classNames.push('resize-container-horizontal');
    } else {
      classNames.push('resize-container-vertical');
    }

    if (this.props.containerClass) {
      classNames.push(this.props.containerClass);
    }

    const containerStyle = { ...this.props.style } || { flexGrow: 0 };

    if (this.state.size !== 0) {
      containerStyle.flexGrow = 0;
      containerStyle[isHorizontal ? 'width' : 'height'] = 'auto';
    }

    const handleClasses =
      this.props.handleClass || (isHorizontal ? 'resize-handle-horizontal' : 'resize-handle-vertical');

    const resizeBarClasses =
      this.props.borderClass || (isHorizontal ? 'resize-bar-horizontal' : 'resize-bar-vertical');

    const contentStyle = isHorizontal
      ? { width: this.state.size + 'px' }
      : { height: this.state.size + 'px' };

    const contentClassNames = ['resize-content'];

    if (isHorizontal) {
      contentClassNames.push('resize-content-horizontal');
    } else {
      contentClassNames.push('resize-content-vertical');
    }

    const content = [
      <div
        key="content"
        ref={this.setContentRef}
        className={contentClassNames.join(' ')}
        style={contentStyle}
      >
        {React.Children.only(this.props.children)}
      </div>
    ];

    const handle = (
      <DraggableCore key="handle" {...dragHandlers}>
        <div className={resizeBarClasses}>
          <div className={handleClasses}>
            <span />
          </div>
        </div>
      </DraggableCore>
    );

    // Insert the handle at the beginning of the content if our directio is west or north
    if (direction === 'w' || direction === 'n') {
      content.unshift(handle);
    } else {
      content.push(handle);
    }

    return (
      <div ref={this.setWrapperRef} className={classNames.join(' ')} style={containerStyle}>
        {content}
      </div>
    );
  }
}

export default ResizePanel;
