

import type {
  UsersCatalogSearchResult,
  CatalogUserOrTeam,
  UsersCatalogSearchResults
} from 'common/types/users/catalogUsers';
import { action } from 'typesafe-actions';



/** Called to update the email list query */
export const SELECT_USERS_BY_EMAIL_LIST_QUERY_CHANGED = 'SELECT_USERS_BY_EMAIL_LIST_QUERY_CHANGED';
export const selectUsersByEmailListQueryChanged = (emailListQuery: string) => {
  return action(SELECT_USERS_BY_EMAIL_LIST_QUERY_CHANGED, { emailListQuery });
};

/** Called to clear the email list query */
export const SELECT_USERS_BY_EMAIL_LIST_CLEAR_QUERY = 'SELECT_USERS_BY_EMAIL_LIST_CLEAR_QUERY';
export const selectUsersByEmailListClearQuery = () => {
  return action(SELECT_USERS_BY_EMAIL_LIST_CLEAR_QUERY, { emailListQuery: '' });
};

/**  Called to add an email string to the list of parsed email addresses */
export const SELECT_USERS_BY_EMAIL_LIST_ADD_TO_EMAIL_LIST = 'SELECT_USERS_BY_EMAIL_LIST_ADD_TO_EMAIL_LIST';
export const selectUsersByEmailListAddToEmailList = (email: string) => {
  return action(SELECT_USERS_BY_EMAIL_LIST_ADD_TO_EMAIL_LIST, { email });
};

/** Called to remove an email string from the list of parsed email addresses */
export const SELECT_USERS_BY_EMAIL_LIST_REMOVE_FROM_EMAIL_LIST = 'SELECT_USERS_BY_EMAIL_LIST_REMOVE_FROM_EMAIL_LIST';
export const selectUsersByEmailListRemoveFromEmailList = (email: string) => {
  return action(SELECT_USERS_BY_EMAIL_LIST_REMOVE_FROM_EMAIL_LIST, { email });
};

/** Called to search for users by the list of parsed email addresses */
export const SELECT_USERS_BY_EMAIL_LIST_SEARCH = 'SELECT_USERS_BY_EMAIL_LIST_SEARCH';
export const selectUsersByEmailListSearch = (emailList: Array<string>, filterUserIdsFromResults: Array<string>, filters: object) => {
  return action(SELECT_USERS_BY_EMAIL_LIST_SEARCH, { emailList, filterUserIdsFromResults, filters });
};

export const SELECT_USERS_BY_EMAIL_LIST_SEARCH_RESULTS = 'SELECT_USERS_BY_EMAIL_LIST_SEARCH_RESULTS';
export const selectUsersByEmailListSearchResults = (
  { userSearchResults, filterUserIdsFromResults }: { userSearchResults: any[], filterUserIdsFromResults: Array<string> }) => {
  return action(SELECT_USERS_BY_EMAIL_LIST_SEARCH_RESULTS, { userSearchResults, filterUserIdsFromResults });
};
