import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

const formatMapping = {
  'align.left': 'format-align-left',
  'align.right': 'format-align-right',
  'align.center': 'format-align-center',
  'background.selected': 'format-bg-selected',
  'background.success': 'format-bg-success'
};

class TypedCell extends Component {
  lookupClasses() {
    return _.compact(
      _.map(this.props.format, (value, key) => {
        return formatMapping[`${key}.${value}`];
      })
    );
  }

  render() {
    const classNames = this.lookupClasses();

    if (this.props.isDropping) {
      classNames.push('dropping');
    }
    const value = this.props.value;
    const result = _.isUndefined(value) || _.isNull(value) ? '' : value;
    return <div className={classNames.join(' ')}>{result}</div>;
  }
}

TypedCell.propTypes = {
  value: PropTypes.any,
  format: PropTypes.object,
  isDropping: PropTypes.bool
};

export default TypedCell;
