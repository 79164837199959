import PropTypes from 'prop-types';

import WorkflowGuidancePropType from './WorkflowGuidancePropType';


// NOTE: Coming here looking for TypeScript types? Check out `common/types/approvals`!

/**
 * Describes a GuidanceSummaryV2 object from core's guidance API.
 */
export default PropTypes.shape({
  public: WorkflowGuidancePropType,
  internal: WorkflowGuidancePropType
});
