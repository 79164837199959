import * as selectionActions from 'common/components/AssetBrowser/actions/selection';

export default (state = {}, action = {}) => {
  if (action.type === selectionActions.SELECT_ASSET) {
    return {
      ...state,
      selectedAsset: action.selectedAsset
    };
  }

  if (action.type === selectionActions.CLEAR_SELECTED_ASSET) {
    return {
      ...state,
      selectedAsset: null
    };
  }

  return state;
};
