import React, { useEffect } from 'react';
import {
  ForgeFilePicker,
  ForgeButton,
  ForgeButtonToggleGroup,
  ForgeButtonToggle,
  ForgeIcon
} from '@tylertech/forge-react';
import { orderBy as _orderBy } from 'lodash';
import I18n from 'common/i18n';
import DragDropContainer, {
  makeDefaultDragDropElementWrapper,
  DragDropContainerType
} from 'common/components/DragDropContainer';
import { FeatureFlags } from 'common/feature_flags';
import Attachment from '../attachment/Attachment';
import { AttachmentsInputDirectProps } from '../../../../containers/AttachmentsInputContainer';
import { FileUploadStatus } from 'common/dsmapi/ManageMetadataComponent/types';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.attachments_input') =>
  I18n.t(key, { scope });

type alphabetizeOrderings = 'asc' | 'desc';

const AttachmentsInput: React.FunctionComponent<AttachmentsInputDirectProps> = ({
  files,
  buttonText,
  uploadAttachment,
  removeAttachment,
  editAttachment,
  reorderAttachments,
  initializeAttachmentsList
}) => {
  useEffect(() => {
    if (files.length === 0) {
      initializeAttachmentsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listItems = files.map((file, index) => (
    <Attachment
      key={index}
      idx={index}
      file={file}
      onRemove={() => removeAttachment(file.uploadId)}
      onEdit={(newName) => editAttachment(file.uploadId, newName)}
    />
  ));

  const onChangeOrder = (newOrder: FileUploadStatus[]) => {
    reorderAttachments(newOrder);
  };

  const alphabetize = (direction: alphabetizeOrderings) => () => {
    reorderAttachments(_orderBy(files, (file) => file.uploadedFile.name.toLowerCase(), direction));
  };

  const addAttachment = (
    <div>
      <div className="add-attachment">
        <ForgeFilePicker
          compact={true}
          id="attachment-file-picker"
          on-forge-file-picker-change={(event: CustomEvent) => {
            if (event.detail.legalFiles.length) {
              uploadAttachment(event.detail.legalFiles[0]);
            }
          }}
        >
          <ForgeButton className="add-attachment-button" type="outlined">
            <button
              id="add-attachment-button"
              type="button"
              data-testid="metadata-component-add-attachment-button"
            >
              <span>{buttonText}</span>
              <ForgeIcon name="attach_file" />
            </button>
          </ForgeButton>
        </ForgeFilePicker>
        {FeatureFlags.valueOrDefault('enable_attachment_reorder_alphabet', false) ? (
          <ForgeButtonToggleGroup data-testid="metadata-component-attachments-sorting-button-group">
            <ForgeButtonToggle
              title={t('sort_asc')}
              onClick={alphabetize('asc')}
              data-testid="metadata-component-attachments-sorting-ascending"
            >
              <ForgeIcon name="sort_alphabetical_ascending" />
            </ForgeButtonToggle>
            <ForgeButtonToggle
              title={t('sort_desc')}
              onClick={alphabetize('desc')}
              data-testid="metadata-component-attachments-sorting-descending"
            >
              <ForgeIcon name="sort_alphabetical_descending" />
            </ForgeButtonToggle>
          </ForgeButtonToggleGroup>
        ) : null}
      </div>
    </div>
  );

  return (
    <div>
      {addAttachment}
      <DragDropContainer
        type={DragDropContainerType.LIST}
        items={files}
        className="metadata-component-attachment-list"
        onDrop={onChangeOrder}
        childElements={makeDefaultDragDropElementWrapper(listItems)}
        useForgeIcon={true}
      />
    </div>
  );
};

export default AttachmentsInput;
