import React, { FunctionComponent } from 'react';
import { ForgeIcon, ForgeTooltip } from '@tylertech/forge-react';
import I18n from 'common/i18n';

const t = (k: string, scope = 'shared.dataset_management_ui') => I18n.t(k, { scope });
const PrivateFieldIcon: FunctionComponent = () => {
  return (<>
      <ForgeIcon name="lock" slot="leading" />
      <ForgeTooltip position="right">
        {t('private_icon_tooltip')}
      </ForgeTooltip>
    </>);
};

export default PrivateFieldIcon;
