import omit from 'lodash/omit';
import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import getLocale from 'common/js_utils/getLocale';
import * as formats from '../config/momentjs-formats';

/**
 * Get the format to use with a date
 * @param {boolean} withTime Whether or not to include the time
 * @param {boolean} includeSeconds Whether or not to include seconds
 * @param {string} locale Locale to use
 */
const getFormat = (withTime, includeSeconds, locale) => {
  if (withTime) {
    if (includeSeconds) {
      return get(formats, `withTimeIncludingSeconds[${locale}]`, formats.withTimeIncludingSeconds.en);
    } else {
      return 'LLL';
    }
  } else {
    return 'LL';
  }
};

export default function LocalizedDate(props) {
  const { date, withTime, includeSeconds } = props;
  const locale = getLocale();

  const format = getFormat(withTime, includeSeconds, locale);

  const formattedDate = moment(date, moment.ISO_8601)
    .locale(locale)
    .format(format);

  const spanProps = omit(props, ['date', 'withTime', 'includeSeconds']);

  return <span {...spanProps}>{formattedDate}</span>;
}

LocalizedDate.propTypes = {
  date: PropTypes.any.isRequired,
  withTime: PropTypes.bool,
  includeSeconds: PropTypes.bool
};
