import React from 'react';
import ManageMetadataComponent from '../../reduxStuff/reduxWrapper';
import { viewToAssetMetadata, assetMetadataToView } from './conversion/views';
import { socrataFetchAndCheck } from '../helpers/fetchHelpers';
import { xhrPromise, CoreAttachmentUploadResponse } from '../helpers/attachmentUploadHelpers';
import { View } from 'common/types/view';
import { AssetMetadata, OnUploadAttachmentSignature } from '../../types';
import { isTabular } from 'common/views/view_types';

const APP_TOKEN = 'U29jcmF0YS0td2VraWNrYXNz0';

export interface ViewWrapperForManageMetadataComponentProps
  extends Omit<
    React.ComponentProps<typeof ManageMetadataComponent>,
    'metadata' | 'onSave' | 'attachmentsUploadUrl' | 'onUploadAttachment' | 'showFederatedHrefMessage' | 'isTabular'
  > {
  metadata: View;
  onSave: (view: View) => void;
}

export const ViewWrapperForManageMetadataComponent: React.FunctionComponent<
  ViewWrapperForManageMetadataComponentProps
> = ({ datasetLicenses, onSave, onClose, metadata: view, isModal }) => {
  const convertedAssetMetadata = viewToAssetMetadata(view);

  const onUploadAttachment: OnUploadAttachmentSignature = async (file, onProgress) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const authenticityToken = document.querySelector('meta[name="csrf-token"]')!.getAttribute('content')!;
    const attachmentsUploadUrl = `/api/views/${view.id}/files.txt?app_token=${APP_TOKEN}&authenticity_token=${authenticityToken}`;

    const formData = new FormData();
    formData.append('userfile', file);
    formData.append('authenticity_token', authenticityToken);

    const response = await xhrPromise(attachmentsUploadUrl, formData, onProgress);

    const parsedResponse: CoreAttachmentUploadResponse = JSON.parse(response.responseText);

    return {
      asset_id: parsedResponse.file,
      filename: parsedResponse.nameForOutput,
      name: parsedResponse.nameForOutput,
      created_at: 'now'
    };
  };

  const onSaveToCore = async (assetMetadata: AssetMetadata) => {
    const convertedView = assetMetadataToView(view, assetMetadata);

    const coreUrl = `/api/views/${view.id}.json`;
    const updateBody = JSON.stringify(convertedView);

    const savedView: View = await socrataFetchAndCheck(coreUrl, {
      method: 'PUT',
      body: updateBody
    });

    await onSave(savedView);
    return true;
  };

  return (
    <ManageMetadataComponent
      isTabular={isTabular(view)}
      datasetLicenses={datasetLicenses}
      onSave={onSaveToCore}
      onClose={onClose}
      metadata={convertedAssetMetadata}
      isModal={isModal}
      showFederatedHrefMessage={false}
      onUploadAttachment={onUploadAttachment}
    />
  );
};
