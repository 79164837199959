/* Imports
============================================================================= */
import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ForgeIcon, ForgeTooltip } from '@tylertech/forge-react';

import mixIntoComponentInstance from 'common/components/mixIntoComponentInstance';
import { AlertDisplayType, AlertListAlertProps, AlertListSubAlertProps } from 'common/components/AlertList/AlertListTypes';
import Button from 'common/components/Button';
import I18n from 'common/i18n';
import isMissingTranslation from 'common/js_utils/isMissingTranslation';
import type { CatalogUserOrTeam } from 'common/types/users/catalogUsers';
import constants from 'common/utilities/Constants';
import * as SelectUsersByEmailListActions from './SelectUsersByEmailListActions';
import * as SelectUsersByEmailListSelectors from './SelectUsersByEmailListSelectors';
import { extentionObject, SelectUsersByEmailListComponent, SelectUsersByEmailListDispatchProps, SelectUsersByEmailListProps, SelectUsersByEmailListState } from './SelectUsersByEmailListTypes';

import './select-users-by-email-list.scss';





/* Predefined Values
============================================================================= */
const classNameScope = 'common-components-AccessManager--components-SelectUsersByEmailList';
const i18nScope = 'shared.access_manager.teams.add_team_members.select_by_email_list';

const defaultEmailStringValidator = (email: string, validateEmailRegex: RegExp) => {
  return validateEmailRegex.test(email);
};

const defaultGetSelectUsersByEmailListState = (state: any) => state as SelectUsersByEmailListState;





/* Properties
============================================================================= */





/* Component Definition
============================================================================= */
export const component = {
  props: {} as SelectUsersByEmailListProps,



  /* Attributes
  ----------------------------------------------------------------- */

  /* Text Translation Keys
  ------------------------------------------------------- */
  ALERT_ALREADY_SELECTED_USER: `${i18nScope}.alerts.already_selected_user`,
  ALERT_ALREADY_SELECTED_USERS: `${i18nScope}.alerts.already_selected_users`,
  ALERT_CANNOT_SELECT_USER: `${i18nScope}.alerts.cannot_select_user`,
  ALERT_CANNOT_SELECT_USERS: `${i18nScope}.alerts.cannot_select_users`,
  ALERT_IDENTITY_VALUE: `${i18nScope}.identities.value`,
  ALERT_INVALID_EMAIL: `${i18nScope}.alerts.invalid_email`,
  ALERT_INVALID_EMAILS: `${i18nScope}.alerts.invalid_emails`,
  ALERT_NO_MATCHING_USER_FOUND: `${i18nScope}.alerts.no_matching_user_found`,
  ALERT_NO_MATCHING_USERS_FOUND: `${i18nScope}.alerts.no_matching_users_found`,
  ALERT_NO_USER_RESULTS_FOUND: `${i18nScope}.alerts.no_user_results_found`,
  ALERT_SUCCESSFULLY_SELECTED: `${i18nScope}.alerts.successfully_selected`,
  ALERT_TOO_MANY_EMAILS: `${i18nScope}.alerts.too_many_emails`,
  INPUT_LABEL: `${i18nScope}.labels.input`,



  /* Pre-define our alert properties to streamline logic later
  ------------------------------------------------------- */
  defaultAlertProps: {
    translationKey: `${i18nScope}.alerts.default`,
    type: AlertDisplayType.Error,
    dismissable: true,
    autoDismiss: false,
    alerts: [],
    interpolations: {}
  } as AlertListAlertProps,
  defaultSubAlertProps: {
    translationKey: `${i18nScope}.alerts.default`,
    alerts: [],
    interpolations: {}
  } as AlertListSubAlertProps,
  alreadySelectedUserAlertProps: {} as AlertListAlertProps,
  alreadySelectedUsersAlertProps: {} as AlertListAlertProps,
  cannotSelectUserAlertProps: {} as AlertListAlertProps,
  cannotSelectUsersAlertProps: {} as AlertListAlertProps,
  identityValueSubAlertProps: {} as AlertListSubAlertProps,
  invalidEmailAlertProps: {} as AlertListAlertProps,
  invalidEmailsAlertProps: {} as AlertListAlertProps,
  noMatchingUserFoundAlertProps: {} as AlertListAlertProps,
  noMatchingUsersFoundAlertProps: {} as AlertListAlertProps,
  noUserResultsFoundAlertProps: {} as AlertListAlertProps,
  successfullySelectedAlertProps: {} as AlertListAlertProps,
  tooManyEmailsAlertProps: {} as AlertListAlertProps,
  rawEmailList: [] as Array<string>,
  generatedAlertsQueue: [] as Array<AlertListAlertProps>,




  /* Methods
  ----------------------------------------------------------------- */

  /* Helpers
  ------------------------------------------------------- */
  /**
   * Pre-define the alert property objects for
   * the various alerts generated by the component
   * @param props SelectUsersByEmailListProps
   * @returns SelectUsersByEmailList
   */
  preDefineAlertProperties: function (props: SelectUsersByEmailListProps) {
    this.defaultAlertProps.dismissAlert = props.removeFromSelectUserAlerts;
    this.alreadySelectedUserAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_ALREADY_SELECTED_USER,
      type: AlertDisplayType.Warning
    };
    this.alreadySelectedUsersAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_ALREADY_SELECTED_USERS,
      type: AlertDisplayType.Warning
    };
    this.cannotSelectUserAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_CANNOT_SELECT_USER,
      type: AlertDisplayType.Warning
    };
    this.cannotSelectUsersAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_CANNOT_SELECT_USERS,
      type: AlertDisplayType.Warning
    };
    this.identityValueSubAlertProps = {
      ...this.defaultSubAlertProps,
      translationKey: this.ALERT_IDENTITY_VALUE
    };
    this.invalidEmailAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_INVALID_EMAIL
    };
    this.invalidEmailsAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_INVALID_EMAILS
    };
    this.noMatchingUserFoundAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_NO_MATCHING_USER_FOUND,
      type: AlertDisplayType.Warning
    };
    this.noMatchingUsersFoundAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_NO_MATCHING_USERS_FOUND,
      type: AlertDisplayType.Warning
    };
    this.noUserResultsFoundAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_NO_USER_RESULTS_FOUND,
      type: AlertDisplayType.Warning
    };
    this.successfullySelectedAlertProps = {
      ...this.defaultAlertProps,
      autoDismiss: true,
      autoDismissDelay: 10000,
      translationKey: this.ALERT_SUCCESSFULLY_SELECTED,
      type: AlertDisplayType.Success
    };
    this.tooManyEmailsAlertProps = {
      ...this.defaultAlertProps,
      translationKey: this.ALERT_TOO_MANY_EMAILS
    };

    return this;
  },

  /**
   * Logic to perform when the component updates
   * @returns SelectUsersByEmailList
   */
  whenComponentUpdates: function () {
    const {
      selectUsersByEmailListClearQuery,
      loadData,
      userSearchResults,
      emailListQuery
    } = this.props;

    if (loadData === 'loaded') {
      if (userSearchResults?.length && emailListQuery) {
        this.processUserSearchResults();
      } else {
        this.createAlert(this.noUserResultsFoundAlertProps);
        this.dispatchGeneratedAlertsQueue();
      }

      selectUsersByEmailListClearQuery();
    }

    return this;
  },


  /* State Handling
  ------------------------------------------------------- */
  /**
   * Set the generatedAlertsQueue to an empty array
   * @returns SelectUsersByEmailList
   */
  resetGeneratedAlertsQueue: function () {
    this.generatedAlertsQueue = [];

    return this;
  },

  /**
   * Add the generatedAlertsQueue to the SelectUserAlerts in bulk
   * @returns SelectUsersByEmailList
   */
  dispatchGeneratedAlertsQueue: function () {
    const { addToSelectUserAlerts, clearSelectUserAlerts } = this.props;

    if (!_.isEmpty(this.generatedAlertsQueue)) {
      clearSelectUserAlerts();
      addToSelectUserAlerts(this.generatedAlertsQueue);
      this.resetGeneratedAlertsQueue();
    }

    return this;
  },



  /* Alert Handling
  ------------------------------------------------------- */
  /**
   * Takes the provided defaultProps and extends them with any extendProps
   * @param defaultProps AlertListAlertProps | AlertListSubAlertProps
   * @param extendProps extentionObject [optional]
   * @returns AlertListAlertProps | AlertListSubAlertProps
   */
  createAlertPropObject: function (
    defaultProps: AlertListAlertProps | AlertListSubAlertProps,
    extendProps?: extentionObject
  ) {
    return {
      alertId: _.uniqueId('SelectUsersByEmailList-'),
      ..._.extend({}, defaultProps, extendProps),
      interpolations: _.extend({}, defaultProps.interpolations, extendProps?.interpolations)
    };
  },

  /**
   * Calls createAlertPropObject with provided params and
   * puts the resulting object into the generatedAlertsQueue
   * @param defaultProps AlertListAlertProps
   * @param extendProps extentionObject [optional]
   * @returns AlertListAlertProps
   */
  createAlert: function (
    defaultProps: AlertListAlertProps,
    extendProps?: extentionObject
  ) {
    const alert = this.createAlertPropObject(defaultProps, extendProps) as AlertListAlertProps;
    this.generatedAlertsQueue.push(alert);

    return alert;
  },

  /**
   * Calls createAlertPropObject with provided params
   * @param defaultProps AlertListSubAlertProps
   * @param extendProps extentionObject [optional]
   * @returns AlertListSubAlertProps
   */
  createSubAlert: function (
    defaultProps: AlertListSubAlertProps,
    extendProps?: extentionObject
  ) {
    return this.createAlertPropObject(defaultProps, extendProps) as AlertListSubAlertProps;
  },

  /**
   * Creates an AlertListAlertProps object containing
   * subAlerts created from the items in the provided list
   * @param list Array<string>
   * @param alertProps AlertListAlertProps
   * @param subAlertProps AlertListSubAlertProps
   * @returns AlertListAlertProps
   */
  createNestedAlertList: function (
    list: Array<string>,
    alertProps: AlertListAlertProps,
    subAlertProps: AlertListSubAlertProps
  ) {
    const subAlerts: Array<AlertListSubAlertProps> = [];

    list.forEach((value) => {
      subAlerts.push(this.createSubAlert(subAlertProps, { interpolations: { value: value } }));
    });

    return this.createAlert(alertProps, { alerts: subAlerts });
  },

  /**
   * Creates an AlertListAlertProps object optionally containing
   * subAlerts created from the emails in the provided list
   * @param list Array<string>
   * @param singularAlertProps AlertListAlertProps
   * @param multipleAlertProps AlertListAlertProps
   * @returns AlertListAlertProps
   */
  createCountSpecificEmailAlertList: function (
    list: Array<string>,
    singularAlertProps: AlertListAlertProps,
    multipleAlertProps: AlertListAlertProps
  ) {
    return list.length === 1
      ? this.createAlert(singularAlertProps, { interpolations: { email: list[0] } })
      : this.createNestedAlertList(list, multipleAlertProps, this.identityValueSubAlertProps);
  },



  /* Submit Pre-Processing
  ------------------------------------------------------- */
  /**
   * Takes the list of email address and breaks it into an array of email strings
   * @param emailListQuery string
   * @returns Array<string>
   */
  convertEmailStringToArray: function (emailListQuery: string) {
    const { emailListQuerySeparatorRegex } = this.props;

    return _.compact(emailListQuery.replace(/[ ]/g, '').replace(emailListQuerySeparatorRegex, ',').split(','));
  },

  /**
   * Test each email in a list for valid formatting.
   * Return the valid list and use the invalid list to create an alert
   * @param emailList Array<string>
   * @returns Array<string>
   */
  validateEmailList: function (emailList: Array<string>) {
    const { validateEmailRegex, isValidEmailString } = this.props;
    const validEmailList: Array<string> = [];
    const invalidEmailList: Array<string> = [];

    emailList.forEach((email) => {
      if (isValidEmailString(email, validateEmailRegex)) {
        validEmailList.push(email);
      } else if (email) {
        invalidEmailList.push(email);
      }
    });

    if (invalidEmailList.length) {
      this.createCountSpecificEmailAlertList(
        invalidEmailList,
        this.invalidEmailAlertProps,
        this.invalidEmailsAlertProps
      );
    }

    return validEmailList;
  },

  /**
   * Take the email list string and process it before searching for matching users
   * @returns SelectUsersByEmailList
   */
  processEmailList: function () {
    const {
      selectUsersByEmailListSearch,
      selectUsersByEmailListClearQuery,
      maxEmails,
      emailListQuery,
      filters,
      // selectedUsers,
      // filterUserIdsFromResults
    } = this.props;
    if (emailListQuery) {
      let emailList = this.convertEmailStringToArray(emailListQuery);

      if (!_.isEmpty(emailList)) {
        if (emailList.length > maxEmails) {
          this.createAlert(this.tooManyEmailsAlertProps, { interpolations: { maxEmails: maxEmails } });
          emailList = emailList.slice(0, maxEmails - 1);
        }

        const validEmailList = this.validateEmailList(emailList);

        if (!_.isEmpty(validEmailList)) {
          // const filterUserIds = selectedUsers.map((user) => user.id).concat(filterUserIdsFromResults);
          const filterUserIds = [] as Array<string>;
          selectUsersByEmailListSearch(validEmailList, filterUserIds, filters);
        } else {
          this.dispatchGeneratedAlertsQueue();
          selectUsersByEmailListClearQuery();
        }
      } else {
        this.dispatchGeneratedAlertsQueue();
      }
    }

    return this;
  },



  /* Submit Post-Processing
  ------------------------------------------------------- */
  /**
   * Take the emailList & userSearchResults and divide them into lists of results:
   * - Emails which match a currently selected user (Alert Created)
   * - Search Results which matched an email
   * - Emails which didn't match a search result
   * @returns object - Contains list of matchingUserResults & noMatchingUserResults
   */
  sortUserSearchResults: function () {
    const { emailList, userSearchResults, selectedUsers, filterUserIdsFromResults } = this.props;
    const matchingUserResults: Array<CatalogUserOrTeam> = [];
    const noMatchingUserResults: Array<string> = emailList.concat([]);
    const matchesSelectedUser: Array<string> = [];
    const matchesFilteredUser: Array<string> = [];

    userSearchResults.forEach((result: CatalogUserOrTeam) => {
      if (result.email && emailList.indexOf(result.email) != -1) {
        if (selectedUsers.find((user: CatalogUserOrTeam) => user.email === result.email)) {
          matchesSelectedUser.push(result.email);
        } else if (filterUserIdsFromResults && filterUserIdsFromResults.indexOf(result.id) !== -1) {
          matchesFilteredUser.push(result.email);
        } else {
          matchingUserResults.push(result);
        }

        noMatchingUserResults.splice(noMatchingUserResults.indexOf(result.email), 1);
      }
    });

    if (matchesSelectedUser.length) {
      this.createCountSpecificEmailAlertList(
        matchesSelectedUser,
        this.alreadySelectedUserAlertProps,
        this.alreadySelectedUsersAlertProps
      );
    }

    if (matchesFilteredUser.length) {
      // Stubbing this in for the moment
      this.createCountSpecificEmailAlertList(
        matchesFilteredUser,
        this.cannotSelectUserAlertProps,
        this.cannotSelectUsersAlertProps
      );
    }

    return {
      matchingUserResults,
      noMatchingUserResults
    };
  },

  /**
   * Take the userSearchResults and process them
   * @returns SelectUsersByEmailList
   */
  processUserSearchResults: function () {
    const { addToSelectedUsers } = this.props;
    const { matchingUserResults, noMatchingUserResults } = this.sortUserSearchResults();

    if (!_.isEmpty(matchingUserResults)) {
      this.createAlert(this.successfullySelectedAlertProps, {
        interpolations: { count: matchingUserResults.length }
      });
      addToSelectedUsers(matchingUserResults);
    }

    if (!_.isEmpty(noMatchingUserResults)) {
      this.createCountSpecificEmailAlertList(
        noMatchingUserResults,
        this.noMatchingUserFoundAlertProps,
        this.noMatchingUsersFoundAlertProps
      );
    }

    this.dispatchGeneratedAlertsQueue();

    return this;
  },



  /* Renderers
  ------------------------------------------------------- */
  /**
   * Render an info dialog section
   * @returns JSX.Element | null
   */
  renderInfoDialogSection: function (
    unscopedTranslationKey: string,
    interpolations: unknown = {},
    content: JSX.Element | null = null
  ) {
    const translationKey = `${i18nScope}.info_dialog.sections.${unscopedTranslationKey}`;
    const message = I18n.t(translationKey);
    let response = null;

    if (message && !isMissingTranslation(message, translationKey)) {
      response = (
        <div className={`${classNameScope}--info-dialog-section`}>
          <div className={`${classNameScope}--info-dialog-section-label`}>
            {I18n.t(translationKey, interpolations)}
          </div>
          {content}
        </div>
      );
    }

    return response;
  },

  /**
   * Render a separator list item
   * @returns JSX.Element | null
   */
  renderInfoDialogSeparatorListItem: function (separatorKey: string) {
    const translationKey = `${i18nScope}.separators.${separatorKey}`;
    const message = I18n.t(translationKey);
    let response = null;

    if (message && !isMissingTranslation(message, translationKey)) {
      response = (
        <li key={_.uniqueId('selectUsersByEmailList-separatorListItem')} className={`${classNameScope}--info-dialog-section--separator-list-item`}>
          {message}
        </li>
      );
    }

    return response;
  },

  /**
   * Render the info dialog separator section
   * @returns JSX.Element | null
   */
  renderInfoDialogSeparatorSection: function () {
    const separatorListItems: JSX.Element[] = _.compact(this.props.emailListQuerySeparatorKeys?.map(this.renderInfoDialogSeparatorListItem));
    let response = null;

    if (separatorListItems?.length) {
      const unscopedTranslationKey = 'separators';
      const content = (
        <ul className={`${classNameScope}--info-dialog-section--separator-list`}>
          {separatorListItems}
        </ul>
      );
      response = this.renderInfoDialogSection(unscopedTranslationKey, {}, content);
    }

    return response;
  },

  /**
   * Render the info dialog email limit section
   * @returns JSX.Element | null
   */
  renderInfoDialogEmailLimitSection: function () {
    const { maxEmails } = this.props;
    const unscopedTranslationKey = 'email_limit';
    const response = this.renderInfoDialogSection(unscopedTranslationKey, { maxEmails }, null);

    return response;
  },

  /**
   * Render the info dialog empty characters section
   * @returns JSX.Element | null
   */
  renderInfoDialogEmptyCharactersSection: function () {
    const unscopedTranslationKey = 'empty_characters';
    const response = this.renderInfoDialogSection(unscopedTranslationKey, {}, null);

    return response;
  },

  /**
   * Render the info dialog content
   * @returns JSX.Element
   */
  renderInfoDialogContent: function () {
    const response = (
      <div className={`${classNameScope}--info-dialog-content`}>
        {this.renderInfoDialogEmailLimitSection()}
        {this.renderInfoDialogEmptyCharactersSection()}
        {this.renderInfoDialogSeparatorSection()}
      </div>
    );

    return response;
  },

  /**
   * Render the info dialog icon and label
   * @returns JSX.Element
   */
  renderInfoDialogIconAndLabel: function () {
    const response = (
      <div className={`${classNameScope}--info-dialog`}>
        <span className={`${classNameScope}--info-dialog-label`}>
          {I18n.t('info_dialog.label', { scope: i18nScope })}
        </span>
        <span className={`${classNameScope}--info-dialog-icon`}>
          <ForgeIcon name="info_outline" />
          <ForgeIcon name="info" />
        </span>
        <ForgeTooltip delay={300}>
          {this.renderInfoDialogContent()}
        </ForgeTooltip>
      </div>
    );

    return response;
  },

} as SelectUsersByEmailListComponent;





/* Class Definition
============================================================================= */
/**
 * Contains the input for entering one or more email addresses,
 * and a button to confirm them and add them to the list of selected users.
 */
export class SelectUsersByEmailList extends Component<SelectUsersByEmailListProps> {

  /* Attributes
  ----------------------------------------------------------------- */

  /* Text Translation Keys
  ------------------------------------------------------- */
  ALERT_ALREADY_SELECTED_USER: string;
  ALERT_ALREADY_SELECTED_USERS: string;
  ALERT_CANNOT_SELECT_USER: string;
  ALERT_CANNOT_SELECT_USERS: string;
  ALERT_IDENTITY_VALUE: string;
  ALERT_INVALID_EMAIL: string;
  ALERT_INVALID_EMAILS: string;
  ALERT_NO_MATCHING_USER_FOUND: string;
  ALERT_NO_MATCHING_USERS_FOUND: string;
  ALERT_NO_USER_RESULTS_FOUND: string;
  ALERT_SUCCESSFULLY_SELECTED: string;
  ALERT_TOO_MANY_EMAILS: string;
  INPUT_LABEL: string;



  /* Pre-define our alert properties to streamline logic later
  ------------------------------------------------------- */
  defaultAlertProps: AlertListAlertProps;
  defaultSubAlertProps: AlertListSubAlertProps;
  alreadySelectedUserAlertProps: AlertListAlertProps;
  alreadySelectedUsersAlertProps: AlertListAlertProps;
  cannotSelectUserAlertProps: AlertListAlertProps;
  cannotSelectUsersAlertProps: AlertListAlertProps;
  identityValueSubAlertProps: AlertListSubAlertProps;
  invalidEmailAlertProps: AlertListAlertProps;
  invalidEmailsAlertProps: AlertListAlertProps;
  noMatchingUserFoundAlertProps: AlertListAlertProps;
  noMatchingUsersFoundAlertProps: AlertListAlertProps;
  noUserResultsFoundAlertProps: AlertListAlertProps;
  successfullySelectedAlertProps: AlertListAlertProps;
  tooManyEmailsAlertProps: AlertListAlertProps;
  rawEmailList: Array<string>;
  generatedAlertsQueue: Array<AlertListAlertProps>;




  /* Static & Builtins
  ----------------------------------------------------------------- */
  static defaultProps = {
    emailListQuerySeparatorRegex: /[,\t;]+/g,
    emailListQuerySeparatorKeys: ['commas', 'tabs', 'semicolons'],
    maxEmails: 10,
    noResultsMessage: '',
    filters: { only: 'site_members' },
    filterUserIdsFromResults: [],
    placeHolderText: I18n.t('placeholder', { scope: i18nScope }),
    selectButtonText: I18n.t('select_button_text', { scope: i18nScope }),
    validateEmailRegex: constants.VALID_EMAIL_PATTERN,
    isValidEmailString: defaultEmailStringValidator,
    addToSelectUserAlerts: (alerts:AlertListAlertProps[] | AlertListAlertProps) =>  undefined,
    removeFromSelectUserAlerts: (alerts:AlertListAlertProps[] | AlertListAlertProps) => undefined,
    clearSelectUserAlerts: () => undefined
  };




  /* Methods
  ----------------------------------------------------------------- */
  constructor(props: SelectUsersByEmailListProps) {
    super(props);

    // Extend the component object onto our class instance
    mixIntoComponentInstance.call(this, this, [component]);

    // Pre-define our alert properties to streamline logic later
    this.preDefineAlertProperties(props);
  }

  componentDidUpdate = () => {
    this.whenComponentUpdates();
  };

  /* Helpers
  ------------------------------------------------------- */
  preDefineAlertProperties: (props: SelectUsersByEmailListProps) => SelectUsersByEmailList;

  whenComponentUpdates: () => SelectUsersByEmailList;



  /* State Handling
  ------------------------------------------------------- */
  resetGeneratedAlertsQueue: () => SelectUsersByEmailList;

  dispatchGeneratedAlertsQueue: () => SelectUsersByEmailList;



  /* Alert Handling
  ------------------------------------------------------- */
  createAlertPropObject: (
    defaultProps: AlertListAlertProps | AlertListSubAlertProps,
    extendProps?: extentionObject
  ) => AlertListAlertProps | AlertListSubAlertProps;

  createAlert: (
    defaultProps: AlertListAlertProps,
    extendProps?: extentionObject
  ) => AlertListAlertProps;

  createSubAlert: (
    defaultProps: AlertListSubAlertProps,
    extendProps?: extentionObject
  ) => AlertListSubAlertProps;

  createNestedAlertList: (
    list: Array<string>,
    alertProps: AlertListAlertProps,
    subAlertProps: AlertListSubAlertProps
  ) => AlertListAlertProps;

  createCountSpecificEmailAlertList: (
    list: Array<string>,
    singularAlertProps: AlertListAlertProps,
    multipleAlertProps: AlertListAlertProps
  ) => AlertListAlertProps;



  /* Submit Pre-Processing
  ------------------------------------------------------- */
  convertEmailStringToArray: (emailListQuery: string) => Array<string>;

  validateEmailList: (emailList: Array<string>) => Array<string>;

  processEmailList: () => SelectUsersByEmailList;



  /* Submit Post-Processing
  ------------------------------------------------------- */
  sortUserSearchResults: () => object;

  processUserSearchResults: () => SelectUsersByEmailList;



  /* Rendering
  ------------------------------------------------------- */
  renderInfoDialogSection: () => JSX.Element | null;

  renderInfoDialogSeparatorListItem: () => JSX.Element | null;

  renderInfoDialogSeparatorSection: () => JSX.Element | null;

  renderInfoDialogEmailLimitSection: () => JSX.Element | null;

  renderInfoDialogEmptyCharactersSection: () => JSX.Element | null;

  renderInfoDialogContent: () => JSX.Element;

  renderInfoDialogIconAndLabel: () => JSX.Element;

  render() {
    const { placeHolderText, selectButtonText, emailListQuery, selectUsersByEmailListQueryChanged, maxEmails } = this.props;
    const selectDisabled = !emailListQuery || emailListQuery.trim().length < 5;

    return (
      <div className={`${classNameScope}--container`}>
        <div className={`${classNameScope}--input-row`}>
          <textarea
            className={`${classNameScope}--input text-input`}
            placeholder={placeHolderText || ''}
            value={emailListQuery}
            onChange={selectUsersByEmailListQueryChanged}
          />
          <Button disabled={selectDisabled} onClick={this.processEmailList} className={`${classNameScope}--submit`}>
            {selectButtonText}
          </Button>
        </div>
        <div className={`${classNameScope}--info-text`}>
          {this.renderInfoDialogIconAndLabel()}
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state: SelectUsersByEmailListState, ownProps: SelectUsersByEmailListProps): SelectUsersByEmailListState => {
  const getSelectUsersByEmailListState = ownProps.getSelectUsersByEmailListState || defaultGetSelectUsersByEmailListState;

  return {
    emailListQuery: _.flow((s: any) => (getSelectUsersByEmailListState(s) || s), SelectUsersByEmailListSelectors.getEmailListQuery)(state),
    emailList: _.flow((s: any) => (getSelectUsersByEmailListState(s) || s), SelectUsersByEmailListSelectors.getEmailList)(state),
    userSearchResults: _.flow((s: any) => (getSelectUsersByEmailListState(s) || s), SelectUsersByEmailListSelectors.getUserSearchResults)(state),
    filters: _.flow((s: any) => (getSelectUsersByEmailListState(s) || s), SelectUsersByEmailListSelectors.getFilters)(state),
    loadData: _.flow((s: any) => (getSelectUsersByEmailListState(s) || s), SelectUsersByEmailListSelectors.getLoadData)(state),
  } as SelectUsersByEmailListState;
};

const mapDispatchToProps = {
  selectUsersByEmailListQueryChanged: (event: ChangeEvent<HTMLTextAreaElement>) =>
    SelectUsersByEmailListActions.selectUsersByEmailListQueryChanged(event.target.value),
  selectUsersByEmailListClearQuery: SelectUsersByEmailListActions.selectUsersByEmailListClearQuery,
  selectUsersByEmailListAddToEmailList: SelectUsersByEmailListActions.selectUsersByEmailListAddToEmailList,
  selectUsersByEmailListRemoveFromEmailList: SelectUsersByEmailListActions.selectUsersByEmailListRemoveFromEmailList,
  selectUsersByEmailListSearch: SelectUsersByEmailListActions.selectUsersByEmailListSearch
} as SelectUsersByEmailListDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(SelectUsersByEmailList);
