import React, {FunctionComponent} from 'react';
import moment from 'moment';

interface Props {
  timestamp: number, // unix timestamp
  todayLabel?: string,
  unknownLabel?: string
}

const DateFromNow: FunctionComponent<Props> = ({timestamp, todayLabel, unknownLabel}) => {
  const createdAtMoment = moment(timestamp, 'X');
  let label = createdAtMoment.fromNow();

  if (createdAtMoment.isValid()) {
    if (createdAtMoment.isSame(moment(), 'day') && todayLabel) {
      label = todayLabel;
    }
  } else {
    label = unknownLabel || '';
  }

  const title = createdAtMoment.isValid() ? createdAtMoment.format('LLLL') : unknownLabel;
  return <span title={title}>{label}</span>;
};

export default DateFromNow;
