import React, { ReactElement } from 'react';
import _ from 'lodash';

type PaneChildType = ReactElement;
interface IAccordionContainerProps {
  children: PaneChildType[];
}

interface IAccordionContainerState {
  paneState: { [key: string]: boolean };
  firstRun: boolean;
}

export default class AccordionContainer extends React.Component<
  IAccordionContainerProps,
  IAccordionContainerState
> {
  constructor(props: IAccordionContainerProps) {
    super(props);

    this.state = {
      paneState: this.getDefaultPaneState(),
      firstRun: true
    };
  }

  componentDidUpdate(prevProps: IAccordionContainerProps, prevState: IAccordionContainerState) {
    // When AccordionContainer is constructed, not all its AccordionPanes
    // are loaded.  So when the component updates we want to re-read in
    // the default open/collapsed state from any new panes, then apply the
    // current paneState over the top of the structure.
    const paneState = _.assign({}, this.getDefaultPaneState(), prevState.paneState);
    if (!_.isEqual(prevState.paneState, paneState)) {
      this.setState({ paneState });
    }
  }

  getDefaultPaneState() {
    const paneState = {};

    React.Children.forEach(this.props.children, (pane, index) => {
      if (pane) {
        const isOpen = (index === 0 && !pane.props.isFirstPaneClosedByDefault) || pane.props.isOpen;
        paneState[`pane-${index}`] = isOpen;
      }
    });

    return paneState;
  }

  handlePaneToggle = (paneId: string) => {
    const { paneState } = this.state;
    paneState[paneId] = !paneState[paneId];
    this.setState({ paneState });
  };

  renderPanes() {
    const { children } = this.props;
    const { paneState, firstRun } = this.state;

    return React.Children.map(children, (pane, index) => {
      const onToggle = this.handlePaneToggle;
      const paneId = firstRun ? `pane-${index}` : pane.props.paneId;
      const isOpen = _.get(paneState, paneId, false);

      if (!pane) {
        return pane;
      }

      return React.cloneElement(pane, {
        isOpen,
        onToggle,
        paneId
      });
    });
  }

  render() {
    return <div className="socrata-accordion-container">{this.renderPanes()}</div>;
  }
}
