import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { FeatureFlags } from 'common/feature_flags';
import './styles/index.scss';

import thunk from 'redux-thunk';

import reducer from './reducers';

import AssetBrowserWrapper from './components/asset_browser_wrapper';
import { RENDER_STYLE_CARD, RENDER_STYLE_LIST, VERSION_DRAFT, VERSION_PUBLISHED } from 'common/components/AssetBrowser/lib/constants';

export class AssetBrowser extends Component {
  constructor(props) {
    super(props);

    // use the redux devtool's composeEnhancers to keep them around
    const composeEnhancers =
      (
        _.get(window, 'serverConfig.environment') === 'development' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'AssetBrowser' })
      ) ||
      compose;

    const middlewares = [thunk, ...props.middlewares];

    this.store = createStore(reducer(props), composeEnhancers(applyMiddleware(...middlewares)));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkValidProps(nextProps);
  }

  checkValidProps(nextProps) {
    // Throw/warn for unsupported prop combinations
    if (nextProps.showFilters && nextProps.renderStyle === RENDER_STYLE_CARD) {
      console.warn('AssetBrowser does not yet support the filter sidebar in the "card" renderStyle.');
    }
  }

  render() {
    return (
      <Provider store={this.store}>
        <AssetBrowserWrapper {...this.props} />
      </Provider>
    );
  }
}

AssetBrowser.propTypes = {
  additionalTopbarComponents: PropTypes.array,
  additionalBottombarComponents: PropTypes.array,
  allowedVersions: PropTypes.array,
  approvalsSettingsLink: PropTypes.node,
  closeOnSelect: PropTypes.bool,
  columns: PropTypes.array,
  enableAssetInventoryActions: PropTypes.bool,
  ids: PropTypes.array,
  middlewares: PropTypes.array,
  initialTab: PropTypes.string,
  onAssetSelected: PropTypes.func,
  onClose: PropTypes.func,
  pageSize: PropTypes.number,
  parentIds: PropTypes.array,
  renderStyle: PropTypes.string,
  selectMode: PropTypes.bool,
  showAssetCounts: PropTypes.bool,
  showAuthorityFilter: PropTypes.bool,
  showAwaitingApprovalFilter: PropTypes.bool,
  showDerivedFromFilter: PropTypes.bool,
  showFilters: PropTypes.bool,
  showHeader: PropTypes.bool,
  showIdsFilter: PropTypes.bool,
  showManageAssets: PropTypes.bool,
  showOwnedByFilter: PropTypes.bool,
  showPager: PropTypes.bool,
  showSearchField: PropTypes.bool,
  tabs: PropTypes.object.isRequired,
  showSystemDatasets: PropTypes.bool
};

AssetBrowser.defaultProps = {
  additionalTopbarComponents: [],
  additionalBottombarComponents: [],
  closeOnSelect: true,
  enableAssetInventoryActions: true,
  ids: [],
  onAssetSelected: _.noop,
  // rather than plumbing custom behavior through the nest of redux
  // actions for every permutation of state that a caller would want
  // to know about, we let callers pass in custom middleware to spy on
  // the state of the asset browser.
  // the alternative would have been to make the asset browser accept
  // a data provider or something, but that's not how it's built for
  // better or for worse. it would have made things more complicated,
  // but would have let host components share state more easily.
  // but we can solve it simply enough by allowing host components
  // to pass in a middleware. so they could do something like:
  //
  // middlewares = (store: any) => (next: any) => (action: any) => {
  //  if (action.type === 'UPDATE_CATALOG_RESULTS') {
  //    onAssetsChanged(action.response)
  //  }
  //  return next(action);
  //}
  //
  // or whatever to spy on the action and internal state of the asset browser.
  // it's a little problematic in that we're leaking the state of the redux
  // store into host components, but what are you gonna do.
  middlewares: [],
  onClose: _.noop,
  pageSize: 10,
  parentIds: [],
  renderStyle: RENDER_STYLE_LIST,
  selectMode: false,
  showAssetCounts: true,
  showAuthorityFilter: true,
  showAwaitingApprovalFilter: false,
  showDerivedFromFilter: false,
  showFilters: true,
  showHeader: true,
  showIdsFilter: false,
  showManageAssets: false,
  showOwnedByFilter: true,
  showPager: true,
  showSearchField: true,
  allowedVersions: [null, VERSION_PUBLISHED, VERSION_DRAFT],
  mergeFilters: _.merge
};

export default AssetBrowser;
