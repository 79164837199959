import React from 'react';
import { ForgeCheckbox } from '@tylertech/forge-react';

import { ColumnFormat } from 'common/types/viewColumn';
import I18n from 'common/i18n';

interface IThousandsSeparatorProps {
  onChange: (f: ColumnFormat) => void;
  format: ColumnFormat;
}

const t = (k: string) => I18n.t(k, { scope: 'shared.dataset_management_ui.format_column' });

export const ThousandsSeparator = (props: IThousandsSeparatorProps) => {
  const { format, onChange } = props;
  const hideThousands = format?.hasOwnProperty('groupSeparator') && format.groupSeparator == null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    onChange({
      ...(format ?? {}),
      groupSeparator: checked ? null : ','
    });
  };

  return (
    <div className="authoring-field">
      <div className="hide-thousands-separator-container">
        <ForgeCheckbox dense>
          <input
            type="checkbox"
            id="hide-thousands-separator"
            data-testid="hide-thousands-separator"
            checked={hideThousands}
            onChange={handleChange}
          />
          <label htmlFor="hide-thousands-separator">
            <span className="forge-typography--body2">{t('hide_thousands')}</span>
          </label>
        </ForgeCheckbox>
      </div>
    </div>
  );
};
