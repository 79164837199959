import { ForgeCard, ForgeIcon, ForgeLabelValue } from '@tylertech/forge-react';
import { AudienceScope, View } from 'common/types/view';
import React, { Component, FunctionComponent, useEffect, useRef, useState } from 'react';
import { formatDateWithLocale } from 'common/dates';
import { getForgeIconNameForDisplayType, getAssetNameForDisplayType } from 'common/displayTypeMetadata';
import { get as _get, debounce as _debounce } from 'lodash';
import classNames from 'classnames';
import I18n from 'common/i18n';
import AssetBadgesSection from '../AssetBadgeSection/AssetBadgeSection';
import { isMobileOrTablet } from 'common/visualizations/helpers/MediaQueryHelper';
import './AssetCard.scss';
import { ForgeTooltip } from '@tylertech/forge-react';
import ExpandableTextSection from '../ExpandableTextSection';
import TagList from '../TagList';
import { currentUserIsSiteMember, currentUserIsSuperAdmin } from 'common/current_user';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'shared.components.asset_card', ...options });

export interface AssetCardProps {
  name: string;
  url: string;
  onClick?: () => void;
  description?: string;
  viewCount: number;
  updatedAt: string;
  parentRowsUpdatedAt?: string;
  displayType: string;
  provenance?: string;
  categories?: string[];
  scope: string;
  sourceDomain?: string;
  tags?: string[];
  sourceLink?: string;
  onTagSelect?: (tagValue: string) => void;
}

const audienceComponent = (scope: string) => {
  let audienceText;
  let audienceIcon;

  switch (scope) {
    case AudienceScope.Site: {
      audienceText = t('internal');
      audienceIcon = 'business';
      break;
    }
    case AudienceScope.Private: {
      audienceText = t('private');
      audienceIcon = 'lock';
      break;
    }
    case AudienceScope.Public: {
      audienceText = t('public');
      audienceIcon = 'public';
      break;
    }
  }
  return (
    <>
      <ForgeIcon name={audienceIcon} />
      <span>{audienceText}</span>
    </>
  );
};

const AssetCard: FunctionComponent<AssetCardProps> = ({
  name,
  url,
  onClick,
  description,
  viewCount,
  updatedAt: viewUpdatedAt,
  parentRowsUpdatedAt,
  displayType,
  provenance,
  categories,
  scope,
  sourceDomain,
  tags,
  sourceLink,
  onTagSelect
}) => {
  const iconName = getForgeIconNameForDisplayType(displayType);
  const assetName = getAssetNameForDisplayType(displayType);

  // Cache is not flushed in related views during update to default view. This will sometimes cause the updatedAt
  // value for related content to be stale. Getting the max of the default and the child updated at will get the accurate date.
  const actualUpdatedAt = (() => {
    if (parentRowsUpdatedAt && parentRowsUpdatedAt > viewUpdatedAt) {
      return parentRowsUpdatedAt;
    } else {
      return viewUpdatedAt;
    }
  })();

  const isMobile = isMobileOrTablet();
  const isFederated = sourceDomain !== window.location.hostname;
  const isDataFederated = isFederated && !!sourceLink; // sourceLink is only present for data federated assets

  const getTagsComponent = () => {
    if (tags && tags.length > 0) {
      return <div data-testid="asset-card-tag-section" className={'tag-section body-content forge-typography--body2'}>
        {t('tags')} <TagList tags={tags} maxLength={5} onTagSelect={onTagSelect} />
      </div>;
    }
    return null;
  };

  const getTitleLink = () => {
    let linkProps: any;
    if (isFederated && !isDataFederated) {
      linkProps = {
        target: '_blank',
        rel: 'external'
      };
    }

    return <a
      className="entry-name-link"
      data-testid="view-card-entry-link"
      href={url}
      onClick={onClick}
      {...linkProps}
    >
      {name}
    </a>;
  };

  const getFederatedInfo = () => {
    // only show the federation info when the asset is catalog federated or if the current user is roled and the asset is data federated
    if (isFederated && (!isDataFederated || currentUserIsSiteMember() || currentUserIsSuperAdmin())) {
      const domainLink = '<a href="https://' + sourceDomain + '" class="asset-card-federation-link" target="_blank" rel="noreferrer" data-testid="asset-card-federated-domain-link">' + sourceDomain + '</a>';
      const linkText = I18n.t('data_provided_by', { scope: 'shared.components.view_card', sourceDomain: domainLink });
      return <>
          <div
        className="forge-typography--body2"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: linkText }} />
      </>;
    } else {
      return null;
    }
  };

  return (
    <div className="asset-card">
      <ForgeCard>
        <div>
          <div className="header">
            <div className="header-row">
              <div className="header-left-section">
                <div className="asset-icon">
                  <ForgeIcon slot="start" name={iconName}>
                    <ForgeTooltip position="bottom">{assetName}</ForgeTooltip>
                  </ForgeIcon>
                </div>

                <div className="title-and-badges">
                  <h3 className="entry-name forge-typography--headline5" slot="start">
                    {getTitleLink()}
                  </h3>
                  <AssetBadgesSection categories={categories} provenance={provenance} />
                </div>
              </div>
              <div className="header-right-section">{audienceComponent(scope)}</div>
            </div>
            {getFederatedInfo()}
          </div>
          <div className={classNames({ 'body-section': true, mobile: isMobile })}>
            <div className={classNames({ 'left-section': true, mobile: isMobile })}>
              <div className='body-content'>
                <ExpandableTextSection bodyText={description} noTextMessage={t('no_description_provided')} countOfLinesToShow={2} />
              </div>
              {getTagsComponent()}
            </div>
            <div className={classNames({ 'right-section': true, mobile: isMobile })}>
              <ForgeLabelValue>
                <span slot="label">{t('last_updated')}</span>
                <span slot="value">{formatDateWithLocale(actualUpdatedAt)}</span>
              </ForgeLabelValue>
              <ForgeLabelValue>
                <span slot="label">{t('views')}</span>
                <span slot="value">{viewCount}</span>
              </ForgeLabelValue>
            </div>
          </div>
        </div>
      </ForgeCard>
    </div>
  );
};

export default AssetCard;
