import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { ResultsAndFilters } from 'common/components/AssetBrowser/components';

export class TabContent extends Component {
  render() {
    const { activeTab, tabs } = this.props;
    const currentTab = tabs[activeTab];

    if (!currentTab) {
      console.error(`Could not find activeTab with key '${activeTab}'.`);
      return (
        <div style={{color: 'red'}}>
          Could not find activeTab with key '{activeTab}'. Unable to render content.
        </div>
      );
    }

    // Render the component specified in the current tab. Pass the component all props given to the
    // AssetBrowser, along with any tab-component-specific props.
    // If a component isn't specified, render ResultsAndFilters (catalog) component.
    return React.createElement(currentTab.component || ResultsAndFilters,
      _.merge({}, this.props, currentTab.props));
  }
}

TabContent.propTypes = {
  activeTab: PropTypes.string.isRequired,
  tabs: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  activeTab: state.header.activeTab
});

export default connect(mapStateToProps)(TabContent);
