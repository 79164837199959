import type { FunctionComponent } from 'react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-expect-error
import TypedCell from './TypedCell';
import { ColumnFormat } from 'common/types/viewColumn';

interface Props {
  uid: string;
  isDropping: boolean;
  isError: boolean;
  value: { file_id: string, filename: string | null } | null
  format: ColumnFormat;
}

const DocumentCell: FunctionComponent<Props> = ({ uid, value, isDropping, format }) => {
  let link = null;
  if (value) {
    const { file_id, filename } = value;
    // target _blank is fine without no-referrer in this case because this is a relative link-- always going to us.
    // eslint-disable-next-line react/jsx-no-target-blank
    link = (<a target="_blank" href={`/views/${uid}/files/${file_id}`}>{filename || file_id}</a>);
  }
  return <TypedCell isDropping={isDropping} value={link} format={format} />;
};

export default DocumentCell;
