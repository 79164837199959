/* eslint react/no-did-mount-set-state: 0 */
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import CronBuilder, {
  generateStartTimeCandidates
} from 'common/components/ScheduleModal/components/CronBuilder';
import ErrorMessage, { ValidationErrors } from 'common/components/ScheduleModal/components/ErrorMessage';
import ScheduleAttributes from 'common/components/ScheduleModal/components/ScheduleAttributes';
import {
  CancelButton,
  ChangeScreen,
  DeleteButton,
  MainButton,
  PauseButton
} from 'common/components/ScheduleModal/components/ScheduleButtons';
import ScheduleStatus from 'common/components/ScheduleModal/components/ScheduleStatus';
import { FormStatus, FORM_STATUS, SCREENS, ScreenType } from 'common/components/ScheduleModal/constants';
import * as helpers from 'common/components/ScheduleModal/helpers';
import { ChangeSchedule, ShowError, ValidationError } from 'common/components/ScheduleModal/types';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';
import { getCurrentUser } from 'common/current_user';
import { PhxChannel, ResourceProvider } from 'common/types/dsmapi';
import { AutomationParams, Schedule } from 'common/types/schedule';
import * as _ from 'lodash';
import { default as momentTimezone, default as mtz } from 'moment-timezone';
import React, { Component } from 'react';
import { none, Option, some } from 'ts-option';
import '../index.scss';
import CompletionStatus from './CompletionStatus';
import CurrentlyRunning from './CurrentlyRunning';

const emptyCron = () => {
  const now = mtz();
  // for new schedules, let's default the start time to whatever selection is closest
  // to now. before we defaulted to 00:00, which meant we got a ton of schedules that all
  // ran at 10am PST
  const t = _.minBy(generateStartTimeCandidates(), (time) => Math.abs(time.diff(now)))!;
  return `${t.get('minute')} ${t.get('hour')} * * *`;
};

const emptySchedule = (fourfour: string, automationParams: AutomationParams): Schedule => ({
  cadence: {
    interval_minutes: null,
    timezone: momentTimezone.tz.guess(),
    start: null,
    cron: emptyCron()
  },
  // this isn't required for the API, and is ignored by it, but is present
  // to make the types simpler. Otherwise we'd have an explosion of Omit<Schedule, 'created_by'> all
  // over the place
  created_by: {
    display_name: getCurrentUser()!.displayName,
    email: getCurrentUser()!.email,
    user_id: getCurrentUser()!.id
  },
  paused_due_to_failure: false,
  id: null,
  paused: false,
  action: automationParams,
  runstate: { state: 'unknown' },
  dataset_name: '',
  fourfour: fourfour,
  consecutive_failures: 0,
  last_run: ''
});

function LoadScreen() {
  return (
    <div className="scheduling-modal-spinner-screen dsmp-schedule-overlay">
      <span className="spinner-default spinner-large" />
    </div>
  );
}

interface ScheduleSourceProps {
  fourfour: string;
  scheduleProvider: (ff: string) => ResourceProvider<Schedule>;
  automationParams: AutomationParams;
  onDismiss: () => void;
  onScheduleCompleted?: (s: Schedule) => void;
  onScheduleDeleted?: () => void;
  onScheduleChanged?: (s: Schedule) => void;
}
interface ScheduleSourceState {
  url: string;
  schedule: Option<Schedule>;
  validation: ValidationErrors;
  screen: ScreenType;
  status: FormStatus;
  loading: boolean;
  scheduleCompletionStatus: 'failure' | 'success' | null;
}

class ScheduleSource extends Component<ScheduleSourceProps, ScheduleSourceState> {
  private scheduleChannel: Option<PhxChannel> = none;
  private dismissTimeout: any | undefined;

  constructor(props: ScheduleSourceProps) {
    super(props);
    this.state = {
      url: '',
      screen: SCREENS.MAIN,
      loading: true,
      scheduleCompletionStatus: null,
      status: FORM_STATUS.CLEAN,
      validation: {},
      schedule: some(emptySchedule(this.props.fourfour, this.props.automationParams))
    };
    this.changeScreen = this.changeScreen.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeURL = this.handleChangeURL.bind(this);
    this.handleRunNow = this.handleRunNow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.showError = this.showError.bind(this);
  }

  componentDidMount() {
    const { fourfour, scheduleProvider } = this.props;
    this.onUpdate(scheduleProvider(fourfour));
  }

  componentWillUnmount() {
    this.scheduleChannel.forEach((c) => c.leave());
    clearTimeout(this.dismissTimeout);
  }

  getMainAction() {
    switch (this.state.screen) {
      case SCREENS.MAIN:
        return this.handleSubmit;
      case SCREENS.DELETE:
        return this.handleDelete;
      case SCREENS.REDIRECT:
        return this.handleChangeURL;
      case SCREENS.CONFIRM:
        return this.props.onDismiss;
      default:
        return _.noop;
    }
  }

  callAsync<T>(apiCall: (fourfour: string) => Promise<T>): Promise<T> {
    this.setState({
      status: FORM_STATUS.PENDING
    });

    return apiCall(this.props.fourfour)
      .then((resp) => {
        this.setState({
          status: FORM_STATUS.DONE
        });

        return resp;
      })
      .catch(() => {
        this.setState({
          status: FORM_STATUS.DONE
        });
        return Promise.reject();
      });
  }

  onUpdate(res: ResourceProvider<Schedule>) {
    this.setState({
      status: FORM_STATUS.PENDING
    });
    res
      .ok((updatedSchedule) => {
        this.setSchedule(updatedSchedule);
        if (this.props.onScheduleChanged) this.props.onScheduleChanged(updatedSchedule);
      })
      .error((e) => {
        //????
        this.setState({
          status: FORM_STATUS.CLEAN,
          loading: false
        });
      })
      .failure(() => {
        this.setState({
          status: FORM_STATUS.CLEAN,
          loading: false
        });
      })
      .run();
  }

  setSchedule(schedule: Schedule) {
    this.setState({
      schedule: some(schedule),
      status: FORM_STATUS.CLEAN,
      validation: {},
      loading: false
    });

    if (this.scheduleChannel.isEmpty) {
      const chan = helpers.scheduleChannel(schedule.fourfour);
      this.scheduleChannel = some(chan);
      chan.on('update', (s: Schedule) => {
        this.state.schedule.forEach((stateSchedule) => {
          this.maybeShowScheduleCompletion(stateSchedule, s);
        });
        this.setState({ schedule: some(s) });
      });
    }
  }

  maybeShowScheduleCompletion(prevSchedule: Schedule, newSchedule: Schedule) {
    if (helpers.isRunning(prevSchedule) && !helpers.isRunning(newSchedule)) {
      const scheduleCompletionStatus = newSchedule.consecutive_failures > 0 ? 'failure' : 'success';
      this.setState({ scheduleCompletionStatus });
      this.dismissTimeout = setTimeout(() => {
        this.setState({ scheduleCompletionStatus: null });
        if (this.props.onScheduleCompleted) this.props.onScheduleCompleted(newSchedule);
      }, 5000);
    }
  }

  async handlePause() {
    this.state.schedule.forEach((s) => {
      this.onUpdate(helpers.updateSchedule({ ...s, paused: !s.paused }));
    });
  }

  async handleRunNow() {
    this.state.schedule.forEach((s) => {
      this.onUpdate(helpers.runNow(s));
    });
  }

  async handleChangeURL() {
    const revisionSeq = await this.callAsync<number>(helpers.changeURL);
    const toastProps = {
      type: ToastType.INFO,
      content: helpers.t('toast_redirect')
    };
    showToastNow(toastProps);
    this.props.onDismiss();
    this.state.schedule.forEach((s) => {
      const kind = s.action.type === 'from_url' ? 'url' : 'agent';
      window.location.href = `/d/${this.props.fourfour}/revisions/${revisionSeq}/sources/${kind}`;
    });
  }

  onChange(schedule: Schedule) {
    this.setState({
      schedule: some(schedule),
      status: FORM_STATUS.DONE,
      validation: {}
    });
  }

  showError(e: ValidationError) {
    this.setState({
      validation: {
        [e.name]: e.reason
      }
    });
  }

  handleSubmit() {
    this.state.schedule.forEach((sched) => {
      let result;

      if (helpers.exists(sched)) {
        result = helpers.updateSchedule(sched);
      } else {
        result = helpers.createSchedule(this.props.fourfour, sched, this.props.automationParams);
      }

      result
        .ok(() => {
          showToastNow({
            type: ToastType.SUCCESS,
            content: helpers.t('toast_success')
          });
          this.props.onDismiss();
        })
        .error((e) => {
          e.validationErrors().forEach((ve) => {
            this.setState({
              validation: {
                [ve.path]: ve.reasons[0]
              }
            });
          });
        })
        .run();
    });
  }

  handleDelete() {
    helpers
      .deleteSchedule(this.props.fourfour)
      .ok(() => {
        showToastNow({
          type: ToastType.INFO,
          content: helpers.t('toast_delete_success')
        });
        this.props.onDismiss();
        if (this.props.onScheduleDeleted) this.props.onScheduleDeleted();
      })
      .error(() => {
        showToastNow({
          type: ToastType.ERROR,
          content: helpers.t('toast_delete_error')
        });
      })
      .run();
  }

  changeScreen(screen: ScreenType) {
    return () => this.setState({ screen });
  }

  render() {
    const headerProps = {
      title: helpers.getTitle(this.state.screen),
      onDismiss:
        this.state.status === FORM_STATUS.CLEAN ? this.props.onDismiss : this.changeScreen(SCREENS.CONFIRM)
    };

    if (this.state.schedule.isEmpty) {
      return null;
    }
    const { screen } = this.state;
    const schedule = this.state.schedule.get;

    if (this.state.scheduleCompletionStatus) {
      return (
        <CompletionStatus
          completionStatus={this.state.scheduleCompletionStatus}
          onDismiss={this.props.onDismiss}
        />
      );
    }

    if (helpers.isRunning(schedule)) {
      return <CurrentlyRunning onDismiss={this.props.onDismiss} schedule={schedule} />;
    }

    let content = null;
    if (this.state.screen === SCREENS.MAIN) {
      content = (
        <PrimaryContent
          validation={this.state.validation}
          showError={this.showError}
          schedule={schedule}
          automationParams={this.props.automationParams}
          loading={this.state.loading}
          status={this.state.status}
          changeScreen={this.changeScreen}
          onChange={this.onChange}
          runNow={this.handleRunNow}
        />
      );
    } else {
      content = <SecondaryContent state={this.state} />;
    }

    return (
      <Modal
        className="scheduler-modal"
        containerStyle={{ width: 800, maxWidth: 800, padding: 0 }}
        onDismiss={this.props.onDismiss}
      >
        <ModalHeader {...headerProps}>
          {schedule.paused && <span className="dsmp-schedule-pause-pill">{helpers.t('paused')}</span>}
        </ModalHeader>
        {content}
        <ModalFooter>
          <PauseButton schedule={schedule} screen={screen} handlePause={this.handlePause} />
          <DeleteButton schedule={schedule} screen={screen} changeScreen={this.changeScreen} />
          <MainButton
            status={this.state.status}
            schedule={schedule}
            screen={screen}
            validation={this.state.validation}
            action={this.getMainAction()}
          />
          <CancelButton
            schedule={schedule}
            screen={screen}
            onDismiss={this.props.onDismiss}
            changeScreen={this.changeScreen}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

interface SecondaryContentProps {
  state: ScheduleSourceState;
}

function SecondaryContent({ state }: SecondaryContentProps) {
  function getSecondaryContent(screen: ScreenType) {
    switch (screen) {
      case SCREENS.CONFIRM:
        return helpers.t('confirm_close_body');
      case SCREENS.REDIRECT:
        return helpers.t('confirm_source_change_body');
      case SCREENS.DELETE:
        return helpers.t('confirm_delete');
      default:
        return '';
    }
  }

  return (
    <ModalContent className="dsmp-schedule-modal-content">
      {state.status === FORM_STATUS.PENDING && <LoadScreen />}
      <div className="dsmp-schedule-secondary-content">{getSecondaryContent(state.screen)}</div>
    </ModalContent>
  );
}

interface PrimaryContentProps {
  loading: boolean;
  automationParams: AutomationParams;
  status: FormStatus;
  validation: ValidationErrors;
  schedule: Schedule;
  onChange: ChangeSchedule;
  changeScreen: ChangeScreen;
  showError: ShowError;
  runNow: () => void;
}
export function PrimaryContent({
  loading,
  automationParams,
  schedule,
  validation,
  onChange,
  showError,
  changeScreen,
  runNow
}: PrimaryContentProps) {
  return (
    <ModalContent className="dsmp-schedule-modal-content primary-content">
      {loading ? (
        <LoadScreen />
      ) : (
        <div>
          {schedule.paused && <div className="dsmp-schedule-overlay" />}
          {status === FORM_STATUS.PENDING && <LoadScreen />}
          <div id="dsmp-schedule-url-warning">
            <span className="dsmp-schedule-url-bold">{helpers.t('important')}:</span> All updates will
            <span className="dsmp-schedule-url-bold"> replace</span> the previously published data
          </div>
          <ScheduleStatus schedule={schedule} runNow={runNow} />
          <ScheduleAttributes automationParams={automationParams} changeScreen={changeScreen} />
          <CronBuilder
            schedule={schedule}
            onChange={onChange}
            showError={showError}
            validation={validation}
          />

          <ErrorMessage validation={validation} name="url" />
        </div>
      )}
    </ModalContent>
  );
}

export default ScheduleSource;
