import React from 'react';
import { sniffOS, titleCase, OS } from './lib';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import ConnectionIndicator from './ConnectionIndicator';
import withTooltip from '../hocs/withTooltip';
import { fetchTranslation } from 'common/locale';
import { StepProps } from 'common/components/hocs/withStepper/types';

const t = (k: string) => fetchTranslation(k, 'shared.components.setup_agent.install_agent');

const ServiceTooltip = withTooltip(
  <SocrataIcon name={IconName.Info} className="provision-agent-tooltip-icon" />,
  <span className="provision-agent-tooltip-text">
    <p>{t('service_tooltip_text_p1')}</p>
    <p>{t('service_tooltip_text_p2')}</p>
  </span>
);

type InstallSteps = Record<OS, any>;
const installSteps: InstallSteps = {
  'linux': [
    (<span key="generic_setup_1">
      {t('generic_setup_1')}
      <br />
      <span dangerouslySetInnerHTML={{ __html: t('generic_setup_1_tip') }} />
    </span>),
    t('linux_setup_2'),
    (<span key="linux_setup_3">
      {t('linux_setup_3')}
      <ServiceTooltip />
      <ol className="alpha">
        <li>{t('linux_setup_3_1')}</li>
        <li>{t('linux_setup_3_2')}</li>
        <li dangerouslySetInnerHTML={{ __html: t('linux_setup_3_3') }} />
      </ol>
    </span>)
  ],
  'macos': [
    (<span key="generic_setup_1">
      {t('generic_setup_1')}
      <br />
      <span dangerouslySetInnerHTML={{ __html: t('generic_setup_1_tip') }} />
    </span>),
    t('macos_setup_2'),
    (<span key="macos_setup_3">
      {t('macos_setup_3')}
      <ServiceTooltip />
      <ol className="alpha">
        <li dangerouslySetInnerHTML={{ __html: t('macos_setup_3_1') }} />
        <li>{t('macos_setup_3_2')}</li>
        <li dangerouslySetInnerHTML={{ __html: t('macos_setup_3_3') }} />
      </ol>
    </span>)
  ],
  'windows': [
    (<span key="generic_setup_1">
      {t('generic_setup_1')}
      <br />
      <span dangerouslySetInnerHTML={{ __html: t('generic_setup_1_tip') }} />
    </span>),
    t('windows_setup_2'),
    t('windows_setup_3'),
    (<span key="windows_setup_4">
      {t('windows_setup_4')}
      <ServiceTooltip />
      <ol className="alpha">
        <li>{t('windows_setup_4_1')}</li>
        <li>{t('windows_setup_4_2')}</li>
        <li>{t('windows_setup_4_3')}</li>
        <li>{t('windows_setup_4_4')}</li>
        <li>{t('windows_setup_4_5')}</li>
      </ol>
    </span>)
  ]
};

function osDisplayName(os: OS) {
  switch (os) {
    case 'macos':
      return 'macOS';
    default:
      return titleCase(os);
  }
}

interface InstallAgentState {
  os: OS;
}

export default class InstallAgent extends React.Component<StepProps, InstallAgentState> {
  constructor(props: StepProps) {
    super(props);
    this.state = {
      os: sniffOS()
    };
  }

  render() {
    return (
      <div className="provision-agent-step">
        <h5>{t('title')}</h5>
        <section>
          {(Object.keys(installSteps) as (keyof InstallSteps)[]).map(k => {
            const isSelected = k === this.state.os;
            const classNames = ['btn', 'btn-default'];
            if (isSelected) {
              classNames.push('btn-primary');
            } else {
              classNames.push('cancel-btn');
            }

            return (
              <button
                className={classNames.join(' ')}
                key={k}
                onClick={() => {
                  this.setState({
                    os: k
                  });
                }}>
                {osDisplayName(k)}
              </button>
            );
          })}
        </section>
        <ol>
          {installSteps[this.state.os].map((step: OS, idx: any) => {
            return (<li key={idx}>{step}</li>);
          })}
        </ol>
        <ConnectionIndicator {...this.props} />
      </div>
    );
  }
}
