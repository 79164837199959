import PropTypes from 'prop-types';
import values from 'lodash/fp/values';

import { APPROVALS } from 'common/core/approvals_enums';

// NOTE: Coming here looking for TypeScript types? Check out `common/types/approvals`!

/**
 * Describes an ApprovalState enum value from core's guidance API.
 */
export default PropTypes.oneOf(values(APPROVALS.STATUS));
