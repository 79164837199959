import { MetadataDiff } from 'common/types/revision';
import { pastOrPresentChangeLabel, Tense } from './util';
import React from 'react';
import ChangesWithDetails from './ChangesWithDetails';
import { IconName } from '../SocrataIcon';
import _ from 'lodash';
import { ClientContextVariableCreate, formatParameterDate, getSuggestedValuesTranslation, SuggestedValuesType } from 'common/types/clientContextVariable';
import formatString from 'common/js_utils/formatString';
const scope = 'shared.components.asset_changes.apply_parameters';
import { fetchTranslation } from 'common/locale';
import './AssetChanges.scss'; // TO DO STYLING
import { ForgeIcon, ForgeList, ForgeListItem, ForgeExpansionPanel, ForgeOpenIcon } from '@tylertech/forge-react';
import { getForgeIconNameForDataType } from 'common/views/dataTypeMetadata';
import { SoQLType } from 'common/types/soql';
import FeatureFlags from 'common/feature_flags';

const t = (k: string) => fetchTranslation(k, scope);
const withTense = pastOrPresentChangeLabel(`${scope}.parameter_changes`);

interface ClientContextVariableOldAndNew {
  old: ClientContextVariableCreate;
  new: ClientContextVariableCreate
}

interface CRUDContextVariables {
  addedVariables: ClientContextVariableCreate[];
  updatedVariables: ClientContextVariableOldAndNew[];
  deletedVariables: ClientContextVariableCreate[];
}

interface ClientContextVariableUpdate {
  field: string,
  old: any,
  new: any
}

// some dark magic to basically treat undefined and null as the same in the comparison
// so that the object {value: "hi", displayName null} is treated equal to {value: "hi"}
const compareWithoutNullProperties = (firstVal: any, secondVal: any) => {
  return _.isPlainObject(firstVal) && _.isPlainObject(secondVal)
    ? _.isEqualWith(
      _.omitBy(firstVal, (value) => _.isNil(value)),
      _.omitBy(secondVal, (value) => _.isNil(value)),
      (a: any, b: any, key?: number | string | symbol): boolean | undefined =>
        // base case, means we are at the end of the tree
        key != null ? compareWithoutNullProperties(a, b) : undefined,
    )
    : undefined;
};

const getChanges = (step: MetadataDiff, tense: Tense): CRUDContextVariables => {
  const contextVariablesCRUD: CRUDContextVariables = {
    addedVariables: [],
    updatedVariables: [],
    deletedVariables: []
  };

  const oldContextVars = step.diff?.old.clientContext?.clientContextVariables || [];
  const newContextVars = step.diff?.new.clientContext?.clientContextVariables || [];

  if (!oldContextVars && !newContextVars) return contextVariablesCRUD;

  // New and updated context variables
  newContextVars.forEach((newVars) => {
    const oldVars = oldContextVars.find((oldVar) => oldVar.name === newVars.name);
    if (!oldVars) {
      contextVariablesCRUD.addedVariables.push(newVars);
    } else if (!_.isEqualWith(oldVars, newVars, compareWithoutNullProperties)) {
      contextVariablesCRUD.updatedVariables.push({ old: oldVars, new: newVars });
    }
  });

  // Deleted context variables
  const newContextVarNames = newContextVars.map(newVar => newVar.name);
  contextVariablesCRUD.deletedVariables = oldContextVars.filter(oldVar => !newContextVarNames.includes(oldVar.name));

  return contextVariablesCRUD;
};

const getDetails = (contextVariablesCrud: CRUDContextVariables): string => {
  const details = [];
  if (contextVariablesCrud.addedVariables.length) {
    details.push(
      formatString(t(`parameter_additions.${contextVariablesCrud.addedVariables.length > 1 ? 'plural' : 'singular'}`), {
        count: contextVariablesCrud.addedVariables.length
      })
    );
  }
  if (contextVariablesCrud.deletedVariables.length) {
    details.push(
      formatString(t(`parameter_deletions.${contextVariablesCrud.deletedVariables.length > 1 ? 'plural' : 'singular'}`), {
        count: contextVariablesCrud.deletedVariables.length
      })
    );
  }
  if (contextVariablesCrud.updatedVariables.length) {
    details.push(
      formatString(t(`parameter_updates.${contextVariablesCrud.updatedVariables.length > 1 ? 'plural' : 'singular'}`), {
        count: contextVariablesCrud.updatedVariables.length
      })
    );
  }
  return details.join(', ');
};

const formatValue = (value: string, dataType: SoQLType) => {
  switch (dataType) {
    case 'calendar_date':
      return formatParameterDate(value);
    case 'checkbox':
      return t(value);
    default:
      return  _.escape(value);
  }
};

const createContextUpdates = (clientContextVar: ClientContextVariableOldAndNew): ClientContextVariableUpdate[] => {
  const updates: ClientContextVariableUpdate[] = [];

  for (const prop in clientContextVar.old) {
    const property = prop as keyof ClientContextVariableCreate;
    if (!_.isEqualWith(clientContextVar.new[property], clientContextVar.old[property], compareWithoutNullProperties)) {
      updates.push({ field: property, old: clientContextVar.old[property], new: clientContextVar.new[property] });
    }
  }
  return updates;
};

const getSpecificValueString = (contextVar: ClientContextVariableCreate) => {
  if (!contextVar.suggestedValues?.valueList) return null; // should never happen but incase there is a bug gracefully handle this being empty
  const specificValues = contextVar.suggestedValues.valueList;
  const specificValuesToPrint = specificValues.slice(0, 5);
  const valuesString = specificValuesToPrint.map((val) => `${formatValue(val.value, contextVar.dataType)}` + (val.displayName ? ` "${ _.escape(val.displayName)}"` : '')).join(', ');
  const andMore = specificValues.length > 5 ? ` ${formatString(t('and_more'), { count: specificValues.length - 5 })}` : '';
  return `${formatString(t('values'), { newValues: valuesString })}${andMore}`;
};

const getUpdateString = (update: ClientContextVariableUpdate, contextVar: ClientContextVariableOldAndNew) => {
  if (update.field === 'suggestedValues') {
    return getSpecificValueString(contextVar.new);
  } else if (update.field === 'defaultValue') {
    return formatString(t('parameter_update_default_value'),
      {
        oldDefaultValue: formatValue(update.old, contextVar.old.dataType),
        newDefaultValue: formatValue(update.new, contextVar.new.dataType)
      });
  } else if (update.field === 'displayName') {
    return formatString(t('parameter_update_display_name'),
      {
        oldName:  _.escape(update.old),
        newName:  _.escape(update.new)
      });
  }
};

const ClientContextInfo = ({ contextVar, deleting, index }: { contextVar: ClientContextVariableCreate, deleting: boolean, index: number }) => {
  const testIdPredicate = `parameter-${deleting ? 'deletion' : 'creation'}-${index}-`;
  return (
    <ForgeExpansionPanel className={deleting ? 'deletion-list-panel' : 'creation-list-panel'} open={true}>
      <ForgeListItem slot="header" key={0}>
        <ForgeIcon slot='leading' name={getForgeIconNameForDataType(contextVar.dataType)} />
        <div slot='title'>
          <span data-testid={`${testIdPredicate}name`} dangerouslySetInnerHTML={{
            __html: contextVar.displayName ?
              formatString(t('parameter_name'), { displayName:  _.escape(contextVar.displayName), fieldName:  _.escape(contextVar.name) })
              : contextVar.name
          }} />
        </div>
        <ForgeOpenIcon slot="trailing"></ForgeOpenIcon>
      </ForgeListItem>
      <ForgeList dense={true} indented={true} static={true} wrap={true}>
        {FeatureFlags.value('enable_parameters_enhancements') &&
          <>
            <ForgeListItem key={0}>
              <span data-testid={`${testIdPredicate}available-values`} dangerouslySetInnerHTML={{
                __html: formatString(t('available_value'), { availableValues: getSuggestedValuesTranslation(contextVar.suggestedValuesType) })
              }} />
            </ForgeListItem>
            {contextVar.suggestedValuesType === SuggestedValuesType.SPECIFIC_VALUES &&
              <ForgeListItem key={1}>
                <span data-testid={`${testIdPredicate}specific-values`} dangerouslySetInnerHTML={{
                  __html: getSpecificValueString(contextVar) || ''
                }} />
              </ForgeListItem>
            }
          </>
        }
        <ForgeListItem key={2}>
          <span data-testid={`${testIdPredicate}default-value`} dangerouslySetInnerHTML={{
            __html: formatString(t('default_value'), { defaultValue: formatValue(contextVar.defaultValue, contextVar.dataType) })
          }} />
        </ForgeListItem>
      </ForgeList>
    </ForgeExpansionPanel>
  );
};

const ClientContextUpdates = ({ contextVar, tense }: { contextVar: ClientContextVariableOldAndNew, tense: Tense }) => {
  const updates = createContextUpdates(contextVar);
  return (
    <ForgeExpansionPanel className="change-list-panel" open={true}>
      <ForgeListItem slot="header" key={-1}>
        <ForgeIcon slot='leading' name={getForgeIconNameForDataType(contextVar.old.dataType)} />
        <div slot='title'>
          <span data-testid='update-parameter-name-value' dangerouslySetInnerHTML={{
            __html: formatString(t('changes_to_parameter'), { parameterName:  _.escape(contextVar.old.name) })
          }} />
        </div>
        <ForgeOpenIcon slot="trailing"></ForgeOpenIcon>
      </ForgeListItem>

      <ForgeList dense={true} indented={true} static={true} wrap={true}>
        {updates.map((update, i) => (
          // when the feature flag is off we don't want to return a list item for suggestedValues
          (!FeatureFlags.value('enable_parameters_enhancements') && update.field === 'suggestedValues') ? null :
            <ForgeListItem key={i}>
              <span data-testid={`update-parameter-suggested-values-${i}`} dangerouslySetInnerHTML={{
                __html: getUpdateString(update, contextVar)
              }} />
            </ForgeListItem>
        ))}
      </ForgeList>
    </ForgeExpansionPanel>
  );
};

const getContents = (contextVariablesCrud: CRUDContextVariables, tense: Tense) => {
  return (
    <div className="column-operations">
      {contextVariablesCrud.addedVariables.length > 0 &&
        <div>
          <p>{withTense('additions', tense)}</p>
          <ForgeList className="changes creation-list" dense={true} static={true} wrap={true}>
            {contextVariablesCrud.addedVariables.map((contextVar, i) => {
              return <ClientContextInfo contextVar={contextVar} key={i} deleting={false} index={i}/>;
            })}
          </ForgeList>
        </div>}
      {contextVariablesCrud.deletedVariables.length > 0 &&
        <div>
          <p>{withTense('deletions', tense)}</p>
          <ForgeList className="changes deletion-list" dense={true} static={true} wrap={true}>
            {contextVariablesCrud.deletedVariables.map((contextVar, i) => {
              return <ClientContextInfo contextVar={contextVar} key={i} deleting={true} index={i} />;
            })}
          </ForgeList>
        </div>}
      {contextVariablesCrud.updatedVariables.length > 0 &&
        <div>
          <p>{withTense('updates', tense)}</p>
          <ForgeList className="changes change-list" static={true} wrap={true} >
            {contextVariablesCrud.updatedVariables.map((contextVar, i) => {
              return <ClientContextUpdates contextVar={contextVar} key={i} tense={tense} />;
            })}
          </ForgeList>
        </div>}
    </div>
  );
};

const hasChanges = (contextVariables: CRUDContextVariables): boolean => {
  return contextVariables.addedVariables.length > 0 || contextVariables.deletedVariables.length > 0 || contextVariables.updatedVariables.length > 0;
};

const ClientContextVariableChange = ({ step, tense }: { step: MetadataDiff; tense: Tense }) => {
  const changes = getChanges(step, tense);
  if (!hasChanges(changes)) return null;
  const contents = getContents(changes, tense);

  return (
    <div className="asset-change-step" data-testid="client-context-variable-change">
      <ChangesWithDetails
        icon={IconName.ColumnInfo}
        name={t('parameter_changes_title')}
        details={getDetails(changes)}
        contents={contents}
      />
    </div>
  );
};

export default ClientContextVariableChange;
