import * as React from 'react';
import formatString from 'common/js_utils/formatString';
import { FunctionDocStatus, FunSpec, Scope, SoQLType } from 'common/types/soql';
import './SoQLDocs.scss';
import { fetchTranslation } from 'common/locale';
import SearchInput from '../SearchInput';
import { humanizeName } from './FunctionDoc';
import './SoQLDocs.scss';
import _ from 'lodash';
const t = (k: string) => fetchTranslation(k, 'shared.soql_docs');

// Match is a little verbose because it a shared object between
// ACE's autocompletion dropdown thing and also our function/column
// doc shower-thing. So it has all the stuff in it.
type RenderMatch = (abbreviated: boolean) => JSX.Element;
export interface Match {
  name: string;
  value: string;
  impls: FunSpec[];
  doc: RenderMatch;
  meta: string;
  score: number;
}

interface Props {
  selection: Match | undefined;
  completer: (term: string, exact: boolean) => Match[];
}

interface State {
  term: string;
  overridable: boolean;
  exact: boolean;
}

function useNameOrIdentity(useHumanizedName: boolean, name: string, identity: string) {
  if (!useHumanizedName) {
    return name;
  }
  if (name.startsWith('#') && identity) {
    return identity;
  }
  return humanizeName(name);
}

export type Matcher = (prefix: string, exact: boolean) => Match[];
export interface ColumnLike {
  fieldName: string;
  displayName: string;
  soqlType: SoQLType;
}
export function defaultMatcher(
  specs: Scope,
  columnLike: ColumnLike[],
  renderFunImpls: (name: string, fs: FunSpec[]) => RenderMatch,
  renderColumn: (c: ColumnLike) => RenderMatch,
  useHumanizedName = false
): Matcher {
  // memoizaiton so we don't redo this group by on every key press
  let scope: { [name: string]: FunSpec[] } | undefined;
  const getScope = () => {
    if (specs.length && !scope) {
      scope = _.chain(specs)
        .reject((entry) => [FunctionDocStatus.Hidden, FunctionDocStatus.Deprecated].includes(entry.doc_v2?.status as FunctionDocStatus))
        .groupBy((entry) => entry.name)
        .value();
    }
    return scope;
  };

  const normalize = (thing: string) => (
    thing.toLowerCase()
  );

  const matches = (target: string, prefix: string, exact: boolean) => {
    if (prefix === '' || !prefix) return true;

    const normalTarget = normalize(target);
    const normalPrefix = normalize(prefix);

    return exact ?
      normalPrefix === normalTarget :
      _.includes(normalTarget, normalPrefix);
  };

  return (prefix: string, exact: boolean) => {
    const possibleColumns = columnLike
      /* eslint @typescript-eslint/no-shadow: "warn" */
      .filter((columnLike) => matches(columnLike.fieldName, prefix, exact))
      .map((columnLike) => ({
        name: columnLike.fieldName,
        value: columnLike.fieldName,
        score: 2,
        meta: `Column (type: ${columnLike.soqlType})`,
        doc: renderColumn(columnLike),
        column: columnLike
      }));

    const possibleFuncs = _.map(_.pickBy(
      getScope(),
      (_value, name) => matches(useNameOrIdentity(useHumanizedName, name, _.get(_value, '[0].identity')), prefix, exact)
    ), (impls, name) => {
      const funcName = useNameOrIdentity(useHumanizedName, name, _.get(impls, '[0].identity'));
      return {
        name: funcName,
        value: funcName,
        impls,
        meta: 'function',
        score: 1,
        doc: renderFunImpls(funcName, impls)
      };
    });

    return (possibleColumns as any).concat(possibleFuncs);
  };
}


class SoQLDocs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      term: '',
      overridable: true,
      exact: false
    };
    this.onUpdateSearch = this.onUpdateSearch.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.selection &&
      this.state.term !== nextProps.selection.name &&
      this.state.overridable
    ) {
      this.setState({
        term: nextProps.selection.name,
        overridable: true,
        exact: true
      });
    }
  }

  // This overridable stuff is here so the docs don't change out from under the user.
  // Example:
  // user pulls up docs on function X by typing in the doc search input.
  // then they change to the code editor and start typing stuff - by default the editor
  // will show docs for the selected autocomplete item or the hovered token, which would
  // hide the doc the user just pulled up as a reference, which would be super annoying. So
  // there would be no way to look at docs *and* type at the same time, which is dumb.
  //
  // So that's what this overridable boolean is for - it makes the doc pulled up by the user
  // in the input field take priority over the editor. If they delete the input value, then
  // the editor is allowed to show docs again from autocomplete+token hovering
  onUpdateSearch(term: string) {
    this.setState({
      term,
      overridable: term === '',
      exact: false
    });
  }

  render() {
    const { term, exact } = this.state;

    let results;
    let count = 0;
    if (!term) {
      results = (
        <div className="alert info">{t('pls_type')}</div>
      );
    } else {
      const matches = this.props.completer(term, exact);
      count = matches.length;
      if (matches.length === 0) {
        const superFuzzy = this.props.completer('', false).filter(match => {
          const impl = match.impls && match.impls[0];
          const docString = _.get(impl, 'doc');
          if (docString && docString.indexOf(term) > 0) {
            return true;
          }
          return false;
        }).map(match => match.name);

        let fuzzyMatches;
        if (superFuzzy.length) {
          const showDocsFor = (name: string) => {
            this.setState({
              term: name,
              exact: true,
              overridable: false
            });
          };

          fuzzyMatches = (
            <div>
              {formatString(t('fuzzy_matches'), { term })}

              <ul>
                {superFuzzy.map(name => (
                  <li key={name}>
                    <a onClick={() => showDocsFor(name)}>{humanizeName(name)}</a>
                  </li>
                ))}
              </ul>
            </div>
          );
        }

        results = (
          <div className="alert info">
            {formatString(t('no_functions_matching'), { term })}

            {fuzzyMatches}
          </div>
        );
      } else {
        results = matches.map((funOrColumn, i) => {
          if (funOrColumn.doc) {
            return (
              <div key={`${funOrColumn.name}_${i}`}>{funOrColumn.doc(false)}</div>
            );
          }
          return null;
        });
      }
    }

    let resultCount;
    if (count) {
      resultCount = (
        <p className="result-count">
          {formatString(t(`showing_docs_count.${count > 1 ? 'plural' : 'single'}`), { count })}
        </p>
      );
    }

    return (
      <div className="soql-docs">
        <div>
          <SearchInput
            title={t('search_docs')}
            value={term}
            onChange={this.onUpdateSearch}
            onSearch={_.noop}
            id={'search-soql-docs'}/>
          {resultCount}
        </div>
        {results}
      </div>
    );
  }
}

export default SoQLDocs;
