import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import * as filters from 'common/components/AssetBrowser/actions/filters';

export class DerivedFromFilter extends Component {
  render() {
    const {
      derivedFromFilterEnabled,
      toggleDerivedFromFilter,
      parentIds
    } = this.props;

    const inputId = 'filter-derived-from';

    const onChange = () => toggleDerivedFromFilter(parentIds);
    return (
      <form className="derived-from-filter filter-section">
        <div className="checkbox checkbox-filter">
          <input id={inputId} type="checkbox" onChange={onChange} checked={derivedFromFilterEnabled} />
          <label htmlFor={inputId}>
            <span className="fake-checkbox">
              <SocrataIcon name="checkmark3" />
            </span>
            {I18n.t('shared.asset_browser.filters.global_filter_compatible')}
          </label>
        </div>
      </form>
    );
  }
}

DerivedFromFilter.propTypes = {
  derivedFromFilterEnabled: PropTypes.bool,
  toggleDerivedFromFilter: PropTypes.func.isRequired,
  parentIds: PropTypes.array
};

DerivedFromFilter.defaultProps = {
  derivedFromFilterEnabled: true
};

const mapStateToProps = (state) => ({
  derivedFromFilterEnabled: state.filters.derivedFromFilterEnabled
});

const mapDispatchToProps = (dispatch) => ({
  toggleDerivedFromFilter: (parentIds) => dispatch(filters.toggleDerivedFromFilter(parentIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DerivedFromFilter);
