import React from 'react';
import PropTypes from 'prop-types';
import { Plugin } from 'common/types/gateway';

interface PluginIconProps {
  plugin?: Plugin;
}

function PluginIcon({ plugin }: PluginIconProps) {
  let content;
  if (plugin) {
    const { icon, display_name } = plugin;
    content = <img src={`data:image/jpeg;base64,${icon}`} alt={display_name} />;
  } else {
    content = <span className="spinner-default" />;
  }

  return (
    <div className="tile-icon">
      {content}
    </div>
  );
}

export default PluginIcon;
