import { createDsmapiRevision } from 'common/views/helpers';
import I18n from 'common/i18n';
import * as Dsmapi from 'common/types/dsmapi';
import * as Gateway from 'common/types/gateway';
import { AgentAction, AutomationParams, Schedule, ScheduleAction, URLAction } from 'common/types/schedule';
import _ from 'lodash';
import { none, Option, some } from 'ts-option';
import { SCREENS, ScreenType } from './constants';
import { PhxChannel } from 'common/types/dsmapi';
import { Agent } from 'common/types/gateway';

// js doesn't have integer division so Math.floor
// turns float into int
export function getHours(minutes: number | string) {
  const m = _.isString(minutes) ? parseInt(minutes) : minutes;
  return Math.floor(m / 60);
}

/* getMinutes(minutes) Returns the remainder of minutes modulo 60
*
* @param {integer} minutes - The total number of minutes (e.g. 150)
* @returns {integer} - The remaining minutes (e.g. 30)
*/
export function getMinutes(minutes: number | string) {
  const m = _.isString(minutes) ? parseInt(minutes) : minutes;
  return m % 60;
}

export function t(k: string, params: any = {}) {
  return I18n.t(k, { ...params, scope: 'shared.components.asset_action_bar.schedule_from_url' });
}

export function getTitle(screen: ScreenType) {
  switch (screen) {
    case SCREENS.CONFIRM:
      return t('confirm_close');
    case SCREENS.REDIRECT:
      return t('confirm_source_change');
    case SCREENS.DELETE:
      return t('delete_schedule');
    default:
      return t('title');
  }
}

export function daysToMinutes(days: number) {
  return days * 24 * 60;
}

export function minutesToDays(minutes: number | null) {
  if (_.isNull(minutes)) return NaN;
  return Math.floor((minutes / 24) / 60);
}

export function exists(schedule: Schedule): boolean {
  return !!schedule.id;
}

export function validateInterval(days: string | undefined): Option<string> {
  if (_.isUndefined(days)) return some(t('invalid_interval'));

  const num = parseFloat(days);

  if (num > 0 && num <= 31 && num % 1 === 0) {
    return none;
  }

  return some(t('invalid_interval'));
}

interface URLAutomationParams {
  type: 'from_url';
  parameters: { url: string };
}
interface AgentAutomationParams {
  type: 'connection_agent';
  parameters: {
    agent_uid: string;
    namespace: Gateway.Namespace;
    path: string[];
    parameters: any;
    agent: Gateway.Agent;
    human_path: string[] | null;
  };
}


export const isRunning = (s: Schedule) => {
  return s.runstate && s.runstate.state === 'running';
};


export function isURL(automationParams: ScheduleAction): automationParams is URLAutomationParams {
  return automationParams.type === 'from_url';
}

export function isAgent(automationParams: ScheduleAction): automationParams is AgentAutomationParams {
  return automationParams.type === 'connection_agent';
}

export function agentHasNamespace(agent: Gateway.Agent, namespace: Gateway.Namespace): boolean {
  const found = _.find(agent.namespaces, function(n: Gateway.Namespace) {
    return n.name === namespace.name;
  });
  return found !== undefined;
}

function buildScheduleAction(automationParams: AutomationParams): ScheduleAction | undefined {
  if (isURL(automationParams)) {
    const parameters = automationParams.parameters;
    return {
      type: 'from_url',
      parameters: { url: parameters.url }
    };
  }

  if (isAgent(automationParams)) {
    const parameters = automationParams.parameters;
    return {
      type: 'connection_agent',
      parameters: {
        agent_uid: parameters.agent_uid,
        namespace: parameters.namespace,
        path: parameters.path,
        human_path: parameters.human_path,
        parameters: parameters.parameters // such parameters
      }
    };
  }
}

// api call functions
export function createSchedule(fourfour: string, schedule: Schedule, automationParams: AutomationParams) {
  schedule.cron_in_timezone = true; // TODO: next step, after cron-conversion code is removed from dsmapi, stop sending this
  return Dsmapi.post<Schedule>(`/schedule/${fourfour}`, {...schedule, action: buildScheduleAction(automationParams) });
}

export function changeURL(fourfour: string) {
  return createDsmapiRevision(fourfour, true);
}

export function runNow(schedule: Schedule) {
  return Dsmapi.put<Schedule>(`/schedule/${schedule.fourfour}/run`);
}

export function updateSchedule(schedule: Schedule) {
  schedule.cron_in_timezone = schedule.cadence.cron_in_timezone; // TODO: next step, after cron-conversion code is removed from dsmapi, stop sending this
  return Dsmapi.put<Schedule>(`/schedule/${schedule.fourfour}`, schedule);
}

export function getSchedule(fourfour: string) {
  return Dsmapi.get<Schedule>(`/schedule/${fourfour}`);
}

export function deleteSchedule(fourfour: string) {
  return Dsmapi.del(`/schedule/${fourfour}`);
}

export function scheduleChannel(fourfour: string): PhxChannel {
  const chan = Dsmapi.socket(fourfour).channel(`schedule:${fourfour}`);
  chan.join();
  return chan;
}

function assertNever(x: ScheduleAction): any {
  throw new Error(`Encountered an unknown schedule action type: ${x.type}`);
}

export function getHumanSourceName(action: ScheduleAction) {
  switch (action.type) {
    case 'connection_agent':
      const humanPath = _.get(action,'parameters.human_path') || [];
      const availableName = humanPath.length > 0 ? humanPath : (_.get(action, 'parameters.path') || []);
      return availableName.join('/');
    case 'from_url':
      return _.get(action, 'parameters.url');
    default:
      return assertNever(action);
  }
}

export function scheduleToAutomationParams(schedule: Schedule, agents: Agent[]): AutomationParams {
  const action = schedule.action;
  if (action.type === 'from_url') {
    return { type: 'from_url', parameters: action.parameters };
  } else if (action.type === 'connection_agent') {
    const agent = _.find(agents, a => a.agent_uid.toString() === action.parameters.agent_uid) as Agent;
    return { type: 'connection_agent', parameters: { ...action.parameters, agent: agent }};
  }
  return assertNever(schedule.action);
}
