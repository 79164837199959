import _ from 'lodash';

import * as ceteraActions from 'common/components/AssetBrowser/actions/cetera';
import { FeatureFlags } from 'common/feature_flags';

const getInitialState = () => ({
  values: {
    charts: 0,
    datasets: 0,
    files: 0,
    filters: 0,
    hrefs: 0,
    maps: 0,
    measures: 0,
    stories: 0,
  },
  fetchingAssetCounts: false,
  fetchingAssetCountsError: false
});

export default (state, action) => {
  if (_.isUndefined(state)) {
    return getInitialState();
  }

  if (action.type === ceteraActions.UPDATE_ASSET_COUNTS) {
    const getCountForAssetType = (assetType) => {
      const asset = _.filter(action.assetCounts, (assetCount => assetCount.value === assetType))[0];
      return (asset && _.has(asset, 'count')) ? asset.count : 0;
    };

    let totalDatasetCount = getCountForAssetType('dataset') + getCountForAssetType('system_dataset');

    return {
      ...state,
      values: {
        charts: getCountForAssetType('chart'),
        'datasets,system_datasets': totalDatasetCount,
        files: getCountForAssetType('file'),
        filters: getCountForAssetType('filter'),
        // EN-36089: Treat hrefs and federated_hrefs as one asset type
        'hrefs,federated_hrefs': getCountForAssetType('href') + getCountForAssetType('federated_href'),
        maps: getCountForAssetType('map'),
        measures: getCountForAssetType('measure'),
        stories: getCountForAssetType('story')
      }
    };
  }

  if (action.type === ceteraActions.FETCH_ASSET_COUNTS) {
    return {
      ...state,
      fetchingAssetCounts: true,
      fetchingAssetCountsError: false
    };
  }

  if (action.type === ceteraActions.FETCH_ASSET_COUNTS_SUCCESS) {
    return {
      ...state,
      fetchingAssetCounts: false,
      fetchingAssetCountsError: false
    };
  }

  if (action.type === ceteraActions.FETCH_ASSET_COUNTS_ERROR) {
    return {
      ...state,
      fetchingAssetCounts: false,
      fetchingAssetCountsError: true
    };
  }

  return state;
};
