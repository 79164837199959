import mtz from 'moment-timezone';
import { filter } from 'lodash';


// Maybe a bit surprising to omit DIRTY status since we have
// CLEAN, but this was done to keep these mutually exclusive.
// The status is currently used to show a loading spinner
// and disable buttons, which we want to do when form is clean
// or when api call is pending. Since the form must be dirty
// to make an api call, we omit it here as redundant.

const CLEAN = 'CLEAN';
const PENDING = 'PENDING';
const DONE = 'DONE';
export type FormStatus =
  'CLEAN' | 'PENDING' | 'DONE';
export const FORM_STATUS: {[k: string]: FormStatus} = {
  CLEAN, PENDING, DONE
};

export const ZONES = filter(mtz.tz.names(), zone => zone !== 'US/Pacific-New'); // elixir Timex library doesn't find this valid, and tbh, they're right

const CONFIRM =  'CONFIRM';
const DELETE =  'DELETE';
const REDIRECT =  'REDIRECT';
const MAIN =  'MAIN';
export type ScreenType =
  'CONFIRM' | 'DELETE' | 'REDIRECT' | 'MAIN';

export const SCREENS: {[k: string]: ScreenType} = {
  CONFIRM, DELETE, REDIRECT, MAIN
};
