import React from 'react';
import { ForgeIconButton, ForgeTextField, ForgeIcon, ForgeStack, ForgeTooltip } from '@tylertech/forge-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import I18n from 'common/i18n';
import { generateBaseUrl } from '../helpers';
import { DatasetLandingPageEnhancedView } from 'common/types/view';

const t = (key: string, options: { [key: string]: any } = {}) =>
  I18n.t(key, { scope: 'shared.components.share_and_embed.share_tab', ...options });

export interface ShareTabProps {
  view: DatasetLandingPageEnhancedView;
  setShowCopiedLinkToast: (shouldShow: boolean) => void;
}

const ShareTab: React.FunctionComponent<ShareTabProps> = ({ view, setShowCopiedLinkToast }) => {
  const { facebookShareUrl, twitterShareUrl, emailShareUrl } = view;
  const shareUrl = `${generateBaseUrl()}/d/${view.id}`;

  const shareLinkSection = shareUrl ? (
    <div>
      <span>{t('share_link_top_label')}</span>
      <ForgeTextField>
        <label htmlFor="shareLink" slot="label">
          {t('share_link_label')}
        </label>
        <input id="shareLink" type="text" value={shareUrl} disabled />
        <CopyToClipboard text={shareUrl}>
          <ForgeIconButton className="link-copy-button" slot="trailing">
            <button
              onClick={() => setShowCopiedLinkToast(true)}
              aria-label={t('copy_button_label', { scope: 'shared.components.share_and_embed' })}
              data-testid="share-tab-copy-button"
            >
              <ForgeIcon name="content_copy" />
            </button>
            <ForgeTooltip position="bottom">
              {t('copy_button_label', { scope: 'shared.components.share_and_embed' })}
            </ForgeTooltip>
          </ForgeIconButton>
        </CopyToClipboard>
      </ForgeTextField>
    </div>
  ) : null;

  return (
    <div className="share-tab">
      <ForgeStack>
        {shareLinkSection}
        <ForgeStack inline alignment="start">
          <span>{t('social_media_link_label')}</span>
          <ForgeStack inline alignment="center">
            <a
              className="email"
              href={emailShareUrl}
              aria-label={t('social_media_links.email')}
              data-testid="share-tab-email-link"
            >
              <span className="icon-email" />
            </a>
            <a
              className="twitter"
              href={twitterShareUrl}
              target="_blank"
              rel="noreferrer"
              aria-label={t('social_media_links.twitter')}
              data-testid="share-tab-twitter-link"
            >
              <span className="icon-twitter" />
            </a>
            <a
              className="facebook"
              href={facebookShareUrl}
              target="_blank"
              rel="noreferrer"
              aria-label={t('social_media_links.facebook')}
              data-testid="share-tab-facebook-link"
            >
              <span className="icon-facebook" />
            </a>
          </ForgeStack>
        </ForgeStack>
      </ForgeStack>
    </div>
  );
};

export default ShareTab;
