import { AnyAction } from 'redux';
import { camelCase } from 'lodash';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import negate from 'lodash/fp/negate';
import partialRight from 'lodash/fp/partialRight';
import uniq from 'lodash/fp/uniq';
import upperFirst from 'lodash/fp/upperFirst';

import { UsersCatalogSearchResults } from 'common/types/users/catalogUsers';

import { SelectUsersByEmailListState } from './SelectUsersByEmailListTypes';
import * as SelectUsersByEmailListSelectors from './SelectUsersByEmailListSelectors';

/** Initial State
------------------------------------------------------------------------------- **/
export const initialState: SelectUsersByEmailListState = {
  userSearchResults: [],
  emailListQuery: '',
  emailList: [],
  filters: { only: 'site_members' },
  loadData: null
};

/** General Helpers
------------------------------------------------------------------------------- **/
export const getUserId = get('id');
export const getUserScreenName = get('screen_name'); //  this field is part of the 'user' object given back from core
export const getUserDisplayName = get('displayName'); // this field is part of the 'user' object in our state
const getSearchResultUserId = get('user.id');
const notIncludes = negate(includes);

const searchResultNotInListOfIds = (idList: Array<string>) => {
  return flow(getUserId, partialRight(notIncludes, [idList]));
};


const filterSelectedMembersFromResults = (
  userSearchResults: Array<UsersCatalogSearchResults>,
  filterUserIdsFromResults: Array<string> = []
) => {
  return filter(searchResultNotInListOfIds(filterUserIdsFromResults), userSearchResults);
};

export const handlers = {
  /** User Search
  --------------------------------------------------------- **/

  /** Selected Users
  ----------------------------------------------- **/


  /** By Email List
  ----------------------------------------------- **/
  handleSelectUsersByEmailListQueryChanged: <Type extends { emailListQuery: string }> (
    state: SelectUsersByEmailListState,
    { emailListQuery }: Type
  ) => {
    const response = {
      ...state,
      userSearchResults: [],
      emailList: [],
      emailListQuery,
      loadData: null
    };

    return response;
  },

  handleSelectUsersByEmailListClearQuery: <Type extends { emailListQuery: string }> (
    state: SelectUsersByEmailListState,
    { emailListQuery }: Type
  ) => {
    const response = {
      ...state,
      userSearchResults: [],
      emailList: [],
      emailListQuery: emailListQuery === '' ? emailListQuery : '',
      loadData: null
    };

    return response;
  },

  handleSelectUsersByEmailListAddToEmailList: <Type extends { email: string }> (
    state: SelectUsersByEmailListState,
    { email }: Type
  ) => {
    const response = {
      ...state,
      userSearchResults: [],
      emailList: uniq(SelectUsersByEmailListSelectors.getEmailList(state).concat([email])),
      loadData: null
    };

    return response;
  },

  handleSelectUsersByEmailListRemoveFromEmailList: <Type extends { email: string }> (
    state: SelectUsersByEmailListState,
    { email }: Type
  ) => {
    const response = {
      ...state,
      userSearchResults: [],
      emailList: filter((e) => e !== email, SelectUsersByEmailListSelectors.getEmailList(state)),
      loadData: null
    };

    return response;
  },

  handleSelectUsersByEmailListSearch: (
    state: SelectUsersByEmailListState,
    { emailList, filterUserIdsFromResults, filters }: { emailList: Array<string>, filterUserIdsFromResults: Array<string>, filters: object }
  ) => {
    const response = {
      ...state,
      emailList,
      filters,
      userSearchResults: [],
      loadData: 'loading'
    };

    return response;
  },

  /** Results
  ------------------------------------------------- **/
  handleSelectUsersByEmailListSearchResults: (
    state: SelectUsersByEmailListState,
    { userSearchResults, filterUserIdsFromResults } : { userSearchResults: Array<UsersCatalogSearchResults>, filterUserIdsFromResults: Array<string> }
  ) => {
    const response = {
      ...state,
      userSearchResults: filterSelectedMembersFromResults(userSearchResults, filterUserIdsFromResults),
      loadData: 'loaded'
    };

    return response;
  }

  /** Helpers
  --------------------------------------- **/

};

/** Reducer
------------------------------------------------------------------------------- **/

const reducer = (state = initialState, action: AnyAction): SelectUsersByEmailListState => {
  const handlerMethod = handlers['handle' + upperFirst(camelCase(action.type))];
  const response = typeof handlerMethod === 'function' ? handlerMethod(state, action.payload) : state;

  return response;
};

export default reducer;
