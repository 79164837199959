import { fetchJson, defaultHeaders } from './http';
import { omitNilValues } from './functional_helpers';

export const makeGetRequest = (path, params) => {
  const fetchOptions = {
    credentials: 'same-origin',
    headers: defaultHeaders
  };
  const queryStringParams = new URLSearchParams(omitNilValues(params));

  const apiPath = `${path}?${queryStringParams.toString()}`;

  return fetchJson(apiPath, fetchOptions);
};
