import React, { Component, FunctionComponent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { purify } from 'common/purify';
import { ForgeButton } from '@tylertech/forge-react';
import { ForgeOpenIcon } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import _ from 'lodash';
import './expandable-text-section.scss';

const t = (k: string, options: { [key: string]: any } = {}) => I18n.t(k, { scope: 'shared.components.expandable_text_section', ...options });

export interface ExpandableTextSectionProps {
  bodyText?: string,
  noTextMessage?: string, // optionally display a message when no bodyText is provided
  countOfLinesToShow?: number // how many lines of text to display before cutting it off, defaults to 2
  additionalClasses?: string // additional classes that will be on the outer layer of the component. They will be placed last to overwrite any default styles
}

const ExpandableTextSection: FunctionComponent<ExpandableTextSectionProps> = ({bodyText, noTextMessage, countOfLinesToShow = 2, additionalClasses = ''}) => {
  const [showReadMoreButton, setShowReadMoreButton] = useState(true);
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);
  const textRef = useRef<HTMLDivElement>(null);

  const displayedDescriptionText = bodyText || noTextMessage || '';
  if (textRef.current) {
    // this sets the css variable that sets how many lines to display
    textRef.current.style.setProperty('--display-line-count', `${countOfLinesToShow}`);
  }

  const checkIfCollapsedDescriptionIsAboveLineLimit = (descriptionElement: HTMLDivElement) => {
    const { clientHeight, scrollHeight } = descriptionElement;
    return scrollHeight > clientHeight;
  };

  const shouldDisplayReadMoreButton = () => {
    if (textRef.current) {
      const descriptionHasCollapsedCSSClass = textRef.current.classList.contains(
        'collapsed-text-section'
      );

      if (!descriptionHasCollapsedCSSClass) {
        textRef.current.classList.add('collapsed-text-section');
      }

      setShowReadMoreButton(checkIfCollapsedDescriptionIsAboveLineLimit(textRef.current));

      // If the description wasn't collapsed before this check, remove the CSS class to expand it again.
      if (!descriptionHasCollapsedCSSClass) {
        textRef.current.classList.remove('collapsed-text-section');
      }
    }
  };

  useEffect(() => {

    const debouncedCheck = _.debounce(shouldDisplayReadMoreButton, 50);

    debouncedCheck();

    window.addEventListener('resize', debouncedCheck);
    // There is an occasional situation where clientHeight & scrollHeight is 0 despite having content when the component is first rendered
    // (seems to happen sometimes when wrapped in forge components, also different browsers can have issues with this).
    // This is a back up check that forces all the expandable text sections to double check their heights once more once the page completely loads
    window.addEventListener('load', shouldDisplayReadMoreButton);
    // Okay, I know this sucks. When this component is rendered but not visible it will never show the button since the clientHeight and scroll height = 0.
    // This happens when using forge view finder (typically with a tabbed view) when this component is rendered in a view that is not currently visible.
    // This hack then detects the event when the tab bar is checked and re-checks if we need to render the button. There is no event specific to view finder changing.
    // If in the long run we keep running into situations where the show more button is not rendering we can use the nuclear option which would be using requestAnimationFrame
    // which will always work (probably). The downside of that approach is it will continuously run the check, 10's of thousands of times a minute (though might be able to be slowed down)
    // See Anthony for the radioactive code to achieve this if it comes down to it.
    window.addEventListener('forge-tab-bar-activate', shouldDisplayReadMoreButton);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
      window.removeEventListener('load', shouldDisplayReadMoreButton);
      window.removeEventListener('forge-tab-bar-activate', shouldDisplayReadMoreButton);
    };
  }, [textRef]);


  const readMoreButton = (
    <ForgeButton type="flat" className="collapse-button">
      <button className="collapse-toggle" onClick={() => setIsDescriptionCollapsed(!isDescriptionCollapsed)}>
        {isDescriptionCollapsed ? t('read_more') : t('read_less')}
        <ForgeOpenIcon open={!isDescriptionCollapsed} />
      </button>
    </ForgeButton>
  );

  return (
    <div className={classNames('expandable-text-section', 'forge-typography--body1', additionalClasses)}>
      <div
        className={classNames(isDescriptionCollapsed && 'collapsed-text-section')}
        style={{ color: !bodyText ? '#767676' : undefined, WebkitLineClamp: `${countOfLinesToShow}` }}
        ref={textRef}
      >
        <div dangerouslySetInnerHTML={{ __html: purify(displayedDescriptionText) }} />
      </div>
      {showReadMoreButton && readMoreButton}
    </div>
  );
};

export default ExpandableTextSection;
