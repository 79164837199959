import { DefaultApi, Configuration } from '@socrata/core-roles-api';
import { defaultHeaders } from 'common/http';

/**
 * Core Roles API Client
 *
 * Generated from OpenAPI Specification that lives at:
 * https://github.com/socrata/apis/blob/main/specs/roles/roles.yaml
 */
export default new DefaultApi(
  new Configuration({
    credentials: 'same-origin',
    basePath: window.location.origin,
    headers: defaultHeaders
  })
);
