import Archive from 'common/types/archive';
import * as Dsmapi from 'common/types/dsmapi';
import { Revision } from 'common/types/revision';
import { View } from 'common/types/view';
import _, { toNumber } from 'lodash';
import { Option, option } from 'ts-option';

export const getRevisions = (view: View) => (
  offset: number,
  limit: number
): Promise<{ revisions: Revision[]; totalCount: Option<number> }> => {
  return new Promise((resolve, reject) => {
    const url = `/revision/${view.id}?${new URLSearchParams({
      applied: 'true',
      offset: offset + '',
      limit: limit + '',
      restorable: 'true'
    }).toString()}`;
    Dsmapi.get<Revision[]>(url)
      .ok((response, headers) => {
        const totalCount = option(
          headers.find(({ key }) => key.toLowerCase() === 'x-total-count')
        ).map(({ value }) => toNumber(value));
        resolve({ revisions: response, totalCount });
      })
      .error(reject)
      .failure(reject)
      .run();
  });
};

export type Change = { type: 'revision'; value: Revision } | { type: 'archive'; value: Archive };

export const getChanges = (view: View) => (
  cursor: Option<string>,
  limit: number
): Promise<{ changes: Change[]; next: Option<string> }> => {
  return new Promise((resolve, reject) => {
    console.log('get changes with', cursor.getOrElseValue(''));
    const url = `/revision/${view.id}/changes?${new URLSearchParams({
      cursor: cursor.getOrElseValue(''),
      limit: limit + ''
    }).toString()}`;
    Dsmapi.get<never>(url)
      // horrific
      .ok((_resource, _headers, body: { resource: Change[]; meta: { next: string | null } }) => {
        resolve({ changes: body.resource, next: option(body.meta.next) });
      })
      .error(reject)
      .failure(reject)
      .run();
  });
};

export function assertUnreachable(x: never): never {
  throw new Error('This should not happen');
}
