import _ from 'lodash';
import { Filter } from '@airbrake/browser/dist/filter/filter';

// EN-15648 - ignore cross origin errors coming from within iframes on DSLP
const dslpCrossOriginErrorsFilter: Filter = (notice) => {
  const message = _.get(notice, 'errors[0].message', '');
  const crossOriginMessage = /Blocked a frame with origin/;

  if (crossOriginMessage.test(message)) {
    return null;
  }

  return notice;
};

export default dslpCrossOriginErrorsFilter;
