import I18n from 'common/i18n';

const t = (key: string, options?: any) =>
  I18n.t(key, { scope: 'shared.dataset_management_ui.metadata_manage.save_errors', ...options });

export default class SaveError extends Error {
  responseError: object;
  constructor(response?: object | string) {
    /*
      This is a little hacky, but it seems better than building a whole proper
      error handling setup for what right now is considered a very rare edge case.
      If this error becomes more common or we start needing to display more specific
      "on save" errors like this, we should consider adding some sort of error type
      field to check in DSMAPI instead.
    */
    if (typeof response === 'object') {
      if ((response as { message: string }).message.includes('This resource name is taken.')) {
        const suggestion = (response as { message: string }).message.split(' ').pop();
        super(t('validation_error_existing_resource_name', { suggestion }));
      } else {
        super(t('validation_error_general'));
      }
      this.responseError = response;
    } else {
      super(t('validation_error_general'));
      const responseError = response ? { message: response } : {};
      this.responseError = responseError;
    }
  }
}
