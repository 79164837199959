import PropTypes from 'prop-types';
import React from 'react';
import TypedCell from './TypedCell';
import EditCell from './EditCell';

const EMPTY_URL = {
  url: '',
  description: ''
};

// Copied from https://github.com/socrata/dsmapi/blob/82687022eea702d5194a45c4ded2caf6646593a9/lib/dsmapi/util/urls.ex#L4
const validURLProtocols = ['http','https','mailto','news','ftp','ftps'];
const validURLPattern = new RegExp(`^(${validURLProtocols.join('|')})://`);

function URLCell({ isEditing, value, isError, updateCell, isDropping, format }) {
  let a = null;
  if (isEditing) {
    // unable to edit description - need better UI to break out fields
    return (<EditCell
      value={(isError ? EMPTY_URL : value) || EMPTY_URL}
      updateCell={updateCell} />
    );
  }
  if (value) {
    const { url, description } = value;
    const label = description || url;
    if (validURLPattern.test(url)) {
      a = (<a target="_blank" className="url-cell" href={url} rel="noreferrer">{label}</a>);
    } else {
      a = (<span data-url={url}>{label}</span>);
    }
  }
  return <TypedCell isDropping={isDropping} value={a} format={format} />;
}

URLCell.propTypes = {
  isDropping: PropTypes.bool,
  isEditing: PropTypes.bool,
  isError: PropTypes.bool,
  updateCell: PropTypes.func,
  value: PropTypes.any,
  format: PropTypes.object
};

export default URLCell;
