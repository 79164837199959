import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { compact as _compact } from 'lodash';
import { RootState } from '../store';
import { Attachment } from 'common/types/revision';
import {
  FileUploadStatus,
  OnUploadAttachmentSignature,
  UploadStatuses,
  UploadingAttachment
} from '../../types';

export enum UploadFileActionType {
  ADD_NEW_FILE = 'ADD_NEW_FILE',
  UPDATE_FILE = 'UPDATE_FILE',
  REMOVE_FILE = 'REMOVE_FILE',
  REORDER_FILES = 'REORDER_FILES',
  INITIALIZE_FILES_LIST = 'INITIALIZE_FILES_LIST'
}

export interface AddNewFileAction {
  type: UploadFileActionType.ADD_NEW_FILE;
  uploadId: string;
  file: UploadingAttachment;
}

export interface UpdateFileAction {
  type: UploadFileActionType.UPDATE_FILE;
  changes: Partial<FileUploadStatus> & Pick<FileUploadStatus, 'uploadId'>;
}

export interface RemoveFileAction {
  type: UploadFileActionType.REMOVE_FILE;
  uploadId: string;
}

export interface ReorderFilesAction {
  type: UploadFileActionType.REORDER_FILES;
  files: FileUploadStatus[];
}

export interface InitializeFilesListAction {
  type: UploadFileActionType.INITIALIZE_FILES_LIST;
  files: FileUploadStatus[];
}

export type UploadFileActions = AddNewFileAction | UpdateFileAction | RemoveFileAction | ReorderFilesAction;

type Dispatch = ThunkDispatch<RootState, void, UploadFileActions>;

export const addNewFile = (uploadId: string, file: UploadingAttachment): AddNewFileAction => ({
  type: UploadFileActionType.ADD_NEW_FILE,
  uploadId,
  file
});

export const updateFile = (
  changes: Partial<FileUploadStatus> & Pick<FileUploadStatus, 'uploadId'>
): UpdateFileAction => ({
  type: UploadFileActionType.UPDATE_FILE,
  changes
});

export const removeFile = (uploadId: string): RemoveFileAction => ({
  type: UploadFileActionType.REMOVE_FILE,
  uploadId
});

export const reorderFiles = (files: FileUploadStatus[]): ReorderFilesAction => ({
  type: UploadFileActionType.REORDER_FILES,
  files
});

export const initializeFilesList = (existingAttachments: Attachment[]): InitializeFilesListAction => {
  const initializedFiles = existingAttachments.map((attachment): FileUploadStatus => {
    const uploadId = generateUploadId(attachment.name);

    return {
      uploadId,
      status: UploadStatuses.SUCCESS,
      percent: 100,
      uploadedFile: attachment
    };
  });

  return {
    type: UploadFileActionType.INITIALIZE_FILES_LIST,
    files: initializedFiles
  };
};

// The version used in the uploadId is a randomized string.
// This is so that any uploads of the same file name are tracked separately
// by the browser.
export const generateUploadId = (fileName: string): string =>
  `${fileName} (version ${Math.random().toString(36).substring(2, 15)})`;

const filterOutUploadsInProgress = (fileList: FileUploadStatus[]): Attachment[] => {
  return _compact(
    fileList.map(({ uploadedFile }) => {
      if ('asset_id' in uploadedFile) {
        return uploadedFile;
      }
    })
  );
};

export const uploadAttachment =
  (
    file: File,
    onUploadAttachment: OnUploadAttachmentSignature
  ): ThunkAction<Promise<Attachment[]>, RootState, void, UploadFileActions> =>
  async (dispatch: Dispatch, getState) => {
    const uploadId = generateUploadId(file.name);
    dispatch(addNewFile(uploadId, { name: file.name }));

    const onProgress = (event: ProgressEvent) => {
      dispatch(
        updateFile({
          uploadId,
          status: UploadStatuses.IN_PROGRESS,
          percent: event.loaded / event.total
        })
      );
    };

    try {
      const newAttachment = await onUploadAttachment(file, onProgress);

      dispatch(
        updateFile({ uploadId, uploadedFile: newAttachment, status: UploadStatuses.SUCCESS, percent: 100 })
      );
      return filterOutUploadsInProgress(getState().uploadFiles.files);
    } catch (error) {
      dispatch(updateFile({ uploadId, status: UploadStatuses.ERROR, error: error.body }));
      throw error;
    }
  };

export const reorderAttachments =
  (files: FileUploadStatus[]): ThunkAction<Attachment[], RootState, void, UploadFileActions> =>
  (dispatch: Dispatch, getState) => {
    dispatch(reorderFiles(files));

    return filterOutUploadsInProgress(getState().uploadFiles.files);
  };

export const removeAttachment =
  (uploadId: string): ThunkAction<Attachment[], RootState, void, UploadFileActions> =>
  (dispatch: Dispatch, getState) => {
    dispatch(removeFile(uploadId));
    return filterOutUploadsInProgress(getState().uploadFiles.files);
  };

export const editAttachment =
  (uploadId: string, newName: string): ThunkAction<Attachment[], RootState, void, UploadFileActions> =>
  (dispatch: Dispatch, getState) => {
    const files = getState().uploadFiles.files;

    const fileToUpdate = files.find((file) => file.uploadId === uploadId);

    if (fileToUpdate && fileToUpdate.uploadedFile) {
      dispatch(
        updateFile({
          uploadId,
          uploadedFile: {
            ...fileToUpdate.uploadedFile,
            name: newName
          }
        })
      );
    }
    return filterOutUploadsInProgress(getState().uploadFiles.files);
  };
