import React from 'react';
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import * as helpers from 'common/components/ScheduleModal/helpers';

function Failure() {
  return (
    <div className="schedule-progress">
      <span className="done-icon failed-icon socrata-icon-close-circle"></span>
      <p>{helpers.t('complete.failed')}</p>
    </div>
  );
}
function Success() {
  return (
    <div className="schedule-progress">
      <span className="done-icon success-icon socrata-icon-checkmark-alt"></span>
      <p>{helpers.t('complete.success')}</p>
    </div>
  );
}

interface CompletionStatusProps {
  completionStatus: 'failure' | 'success';
  onDismiss: () => void;
}

export default function CompletionStatus({ completionStatus, onDismiss }: CompletionStatusProps) {
  let content = null;
  if (completionStatus === 'failure') content = <Failure />;
  if (completionStatus === 'success') content = <Success />;

  return (
    <Modal className="scheduler-modal" containerStyle={{ width: 800, maxWidth: 800, padding: 0 }} onDismiss={onDismiss}>
      <ModalHeader title={''} onDismiss={onDismiss} />
      <ModalContent className="schedule-complete">
        {content}
      </ModalContent>
      <ModalFooter />
    </Modal>
  );
}
