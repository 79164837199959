import React from 'react';

import Alignment from './Alignment';
import DatetimeFormat from './DatetimeFormat';
import { ColumnFormat } from 'common/types/viewColumn';

interface Props {
  onUpdateFormat: (f: ColumnFormat) => void;
  onRemoveFormat: (k: string) => void;
  format: ColumnFormat;
  showAlignment : boolean;// not shown when hierarchy is choosen
  // Because of nested form tags, we are getting 'validateDOMNesting' warning,
  // but form tag need to be used in frontend to display data format.
  includeFormTag: boolean;
  defaultAlignment: string;
}

function DatetimeColumnFormat({
  onUpdateFormat,
  format,
  showAlignment,
  includeFormTag = true,
  defaultAlignment,
}: Props) {

  const renderContent = () => {
    return (
      <>
        {showAlignment && <Alignment onChange={onUpdateFormat} format={format} defaultAlignment={defaultAlignment}/>}
        <DatetimeFormat onChange={onUpdateFormat} format={format} />
      </>
    );
  };

  return (
    <div id="format-column" data-testid='date-time-column-format'>
      {includeFormTag ? <form>{renderContent()}</form> : renderContent()}
    </div>
  );
}


export default DatetimeColumnFormat;
