import get from 'lodash/fp/get';

import { SORT_DIRECTION as COMMON_SORT_DIRECTION } from 'common/Constants';
export const SORT_DIRECTION = COMMON_SORT_DIRECTION;

import { makeGetRequest } from './catalog-service-api';
import { TEAM_ROLES } from './teams/constants';

const mapMember = (isOwner) => ({ id, screen_name: screenName }) => ({
  id,
  screenName,
  teamRole: isOwner ? TEAM_ROLES.OWNER : TEAM_ROLES.MEMBER
});

const transformTeamsListFromApi = (results) =>
  results.map(({
    description,
    email,
    id,
    member_count: memberCount,
    members,
    owners,
    screen_name: screenName
  }) => ({
    description,
    email,
    id,
    memberCount,
    members: [...owners.map(mapMember(true)), ...members.map(mapMember(false))],
    screenName
  }));

export const SORT_KEYS = {
  EMAIL: 'email',
  SCREEN_NAME: 'screen_name'
};

export const getTeams = (
  domain,
  {
    query,
    filters,
    orderBy = SORT_KEYS.SCREEN_NAME,
    sortDirection = SORT_DIRECTION.ASC,
    limit,
    offset = 0
  } = {}
) => {
  return makeGetRequest('/api/catalog/v1/teams', {
    domain,
    limit,
    offset,
    order: `${orderBy} ${sortDirection}`,
    q: query,
    ...(filters || {})
  }).then((json) => ({
    ...json,
    resultCount: json.resultSetSize,
    teams: transformTeamsListFromApi(json.results)
  }));
};

export const getTeamsContainingUser = (
  domain,
  uid,
  {
    query,
    filters,
    orderBy = SORT_KEYS.SCREEN_NAME,
    sortDirection = SORT_DIRECTION.ASC,
    limit,
    offset = 0
  } = {}
) => {
  return makeGetRequest(`/api/users/${uid}/teams`)
    .then((json) => {
      if (json.length !== 0) {
        let path = '/api/catalog/v1/teams?';
        json.forEach((team) => {
          path += `ids=${team.id}&`;
        });
        return makeGetRequest(path, {
          domain,
          limit,
          offset,
          order: `${orderBy} ${sortDirection}`,
          q: query,
          ...(filters || {})
        });
      }
  }).then((json) => ({
    ...json,
    resultCount: json.resultSetSize,
    teams: transformTeamsListFromApi(json.results)
  }));
};

const transformAutocompleteResult = (result) => ({
  ...result,
  title: get('team.screen_name', result)
});

export const autocomplete = (domain, query) =>
  makeGetRequest('/api/catalog/v1/teams/autocomplete', {
    domain,
    q: query
  }).then((searchResults) => ({
    ...searchResults,
    results: searchResults.results.map(transformAutocompleteResult)
  }));
