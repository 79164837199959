import PropTypes from 'prop-types';
import PermissionsPropType from './PermissionsPropType';

/**
 * A core view (aka asset aka blist aka a million other things)
 */
export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  permissions: PermissionsPropType // Omitted if user has no special privileges on this view.
});
