import React from 'react';
import { fetchTranslation } from 'common/locale';
import { FinishOptions, StepProps } from 'common/components/hocs/withStepper/types';

const t = (k: string) => fetchTranslation(k, 'shared.components.setup_agent.summary_next');

export default class SummaryNext extends React.Component<StepProps> {
  render() {
    return (
      <div className="provision-agent-step">
        <h5>{t('summary_next_steps')}</h5>
        <div className="stepper-content summary-next-steps">
          <p>{t('summary')}</p>
          <h5>{t('next_steps')}</h5>
          {this.props.finishOptions.map((option: FinishOptions) => {
            return (
              <div className="finish-option-container" key={option.key}>
                <div className="left-col finish-option-left-col">
                  <input
                    id={`finish-option-${option.key}`}
                    type="radio"
                    name="finish-option-type-radio"
                    checked={this.props.selectedFinish.action === option.action}
                    onChange={() => this.props.onSelectFinish(option)} />
                </div>
                <div className="right-col finish-option-right-col">
                  <label htmlFor={`finish-option-${option.key}`}>
                    <span className="fake-radiobutton" />
                    <h6>{option.title}</h6>
                  </label>
                  <div>{option.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
