import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';

import * as filters from 'common/components/AssetBrowser/actions/filters';

export class SourceFilter extends Component {
  render() {
    const { source, changeSource, sources } = this.props;
    const scope = 'shared.asset_browser.filters.source';

    const mappedSources = _.map(sources, (_source) => ({ title: _source, value: _source }));
    mappedSources.unshift({ title: I18n.t('options.all', { scope }), value: null, defaultOption: true });

    return (
      <div className="filter-section source">
        <label className="filter-label">
          {I18n.t('label', { scope })}
        </label>
        <Dropdown
          inputId="source-filter"
          options={mappedSources}
          onSelection={changeSource}
          placeholder={I18n.t('placeholder', { scope })}
          value={source} />
      </div>
    );
  }
}

SourceFilter.propTypes = {
  source: PropTypes.string,
  changeSource: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  source: state.filters.source,
  sources: state.filters.sources
});

const mapDispatchToProps = (dispatch) => ({
  changeSource: (value) => dispatch(filters.changeSource(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceFilter);
