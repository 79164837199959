import SoQLTypeIcon from 'common/components/SoQLTypeIcon';
import formatString from 'common/js_utils/formatString';
import { fetchTranslation } from 'common/locale';
import {
  FunSpec,
  Type,
  Documentation,
  DocumentationExample,
  QueryDocExample,
  ExpressionDocExample
} from 'common/types/soql';
import _ from 'lodash';
import * as React from 'react';
const t = (k: string) => fetchTranslation(k, 'shared.soql_docs');

/* need a better strategy for this
 * existing soql editor just renders it straight, which is confusing
 * this helps, but still underscores for things like NOT_IN are confusing to users
 *
 * Transforms for cosmetic use:
 *  - cast${column_type} -> cast::{column_type}
 *  - op${op} -> {op}
 *  - count/* -> count(*) */
export function humanizeName(name: string) {
  return name.replace('op$', '').replace('#', '').replace('$', '::').replace('count/*', 'count(*)');
}

function intersperse<T>(things: T[], thing: (i: number) => T) {
  let first = true;
  /* eslint @typescript-eslint/no-shadow: "warn" */
  return _.flatMap(things, (t, i) => {
    if (first) {
      first = false;
      return [t];
    }
    return [thing(i), t];
  });
}

function renderArgs(argSpec: Type[]) {
  return intersperse(
    argSpec.map((ts, i) => <span key={`args${ts.kind}${ts.type}${i}`}>{ts.type}</span>),
    (i: number) => <span key={`comma-${i}`}>, </span>
  );
}
/* eslint @typescript-eslint/no-shadow: "warn" */
const renderResult = (t: Type) => {
  switch (t.kind) {
    case 'fixed':
      return (
        <span>
          {t.type} <SoQLTypeIcon type={t.type} isDisabled={false} />
        </span>
      );
    case 'variable':
      return <span>{t.type}</span>;
    default:
      console.error(`Invalid arg bound ${t}`);
  }
};

function genMaxWidth() {
  const compositionPane = document.querySelector('.composition-pane');
  if (compositionPane) {
    return _.toNumber(compositionPane.getBoundingClientRect().width);
  }
}

const Signatures: React.SFC<{ impls: FunSpec[] }> = ({ impls }) => {
  const sigs = impls.map((impl, i) => {
    return (
      <li key={i}>
        {humanizeName(impl.name)}({renderArgs(impl.sig)}) -&gt; {renderResult(impl.result)}
      </li>
    );
  });

  return (
    <div className="function-signatures">
      <p className="h6">{t('function_sigs')}</p>
      <ul>{sigs}</ul>
    </div>
  );
};

const ExpressionExample = ({ example }: { example: ExpressionDocExample }) => (
  <div className="expression-example">
    <pre>
      {example.expression}
      {formatString(t('result'), { result: example.result })}
    </pre>
    <div className="expression-example-text">
      <p>{example.explanation}</p>
    </div>
  </div>
);

const QueryExample = ({ example }: { example: QueryDocExample }) => (
  <div className="query-example">
    <pre>{example.query}</pre>
    <div className="query-example-text">
      <p>{example.explanation}</p>
      {TryIt(example)}
    </div>
  </div>
);

const TryIt = (example: QueryDocExample) => {
  if (!example.tryit) return null;
  return (
    <a
      className="btn btn-default"
      target="_blank"
      href={`${example.tryit}?$query=${encodeURI(example.query)}`}
    >
      {t('try_it')}
    </a>
  );
};

const DocExample = ({ example }: { example: DocumentationExample }) => {
  if (example.type === 'query_example') return <QueryExample example={example} />;
  if (example.type === 'expression_example') return <ExpressionExample example={example} />;
  return null;
};

const DocumentationWithExamples = ({ doc }: { doc: Documentation }) => {
  return (
    <div>
      <pre>{doc.docstring}</pre>
      {doc.examples.map((example, i) => (
        <div key={`example-${i}`} className="example">
          <span className="text-quiet">{formatString(t('example'), { i: i + 1 })}</span>
          <DocExample key={i} example={example} />
        </div>
      ))}
    </div>
  );
};

const SimpleStringDoc = ({ doc }: { doc: string }) => <pre>{doc}</pre>;

interface Props {
  impls: FunSpec[];
  name: string;
  abbreviated: boolean;
}
const FunctionDoc: React.SFC<Props> = ({ name, impls, abbreviated }) => {
  if (impls.length === 0) return null;

  const maxWidth = genMaxWidth();

  const { doc, doc_v2: docV2 } = impls[0];
  let content = null;
  if (docV2) {
    content = <DocumentationWithExamples doc={docV2} />;
  } else if (doc) {
    // just string based documentation.
    // this can be removed once both halves (dsmapi and frontend) are out in the wild and not in
    // any danger of being rolled back
    content = <SimpleStringDoc doc={doc} />;
  }

  const className = abbreviated ? 'dsmui-soql-doc doc-abbreviated' : 'dsmui-soql-doc doc-full';
  const docs = abbreviated ? (
    <p>{t('see_docs')}</p>
  ) : (
    <div className="documentation-body">
      <p className="h6">{t('function_documentation')}</p>
      <div>{content}</div>
    </div>
  );

  return (
    <div className={className} style={{ maxWidth: maxWidth ? `${maxWidth}px` : 'unset' }}>
      <p className="h4 function-name">{formatString(t('function_name'), { name: humanizeName(name) })}</p>
      <Signatures impls={impls} />
      {docs}
    </div>
  );
};

export default FunctionDoc;
