import { sortBy } from 'lodash';
import { takeLatest, call, put, select, all, take, race, fork, delay } from 'redux-saga/effects';

import { fetchJsonWithDefaultHeaders } from 'common/http';
import { mapSoqlRowsResponseToTable } from 'common/visualizations/dataProviders/SoqlHelpers';

import * as queryEditorActions from './QueryEditorActions';
import { getAssetUid, getQuery, getColumnBaseUid } from './QueryEditorSelectors';
import { addLimitToQuery } from '../Util';


/**
 * Fetch the current view that we're writing a query against from core
 */
export function* fetchView() {
  const assetUid = yield select(getAssetUid);

  try {
    const view = yield call(
      fetchJsonWithDefaultHeaders,
      `/api/views/${assetUid}.json`,
      { credentials: 'same-origin' }
    );

    yield put(queryEditorActions.fetchViewSuccess(view));
  } catch (error) {
    const errorJson = yield error.response.json();
    yield put(queryEditorActions.fetchViewFail(errorJson.message));
  }
}

/**
 * Tries to fetch the rows for the asset with the query
 * Will return an object with an `error` key if the request fails
 * Or an object with a `rows` key if it succeeds
 */
function* maybeFetchRows(assetUid, encodedQuery) {
  try {
    const rows = yield call(
      fetchJsonWithDefaultHeaders,
      `/api/id/${assetUid}.json?$query=${encodedQuery}`,
      { credentials: 'same-origin' }
    );

    return { rows };
  } catch (error) {
    const errorJson = yield error.response.json();
    return { error: errorJson };
  }
}

/**
 * Tries to fetch the columns for the asset with the query
 * Will return an object with an `error` key if the request fails
 * Or an object with a `columns` key if it succeeds
 */
function* maybeFetchColumns(assetUid, columnBaseUid, encodedQuery) {
  try {
    const columns = yield call(
      fetchJsonWithDefaultHeaders,
      `/api/views/${assetUid}.json?method=getColumnsForViewWithQuery&columnBaseUid=${columnBaseUid}&query=${encodedQuery}`,
      { credentials: 'same-origin' }
    );

    return { columns };
  } catch (error) {
    const errorJson = yield error.response.json();
    return { error: errorJson };
  }
}

function* possibleCollocationStatus(assetUid, query) {
  const joinMarker = /union|join|unionall|intersect|minus/i;
  if (query.search(joinMarker) > -1) {
    const existsOrNeeded = yield call(fetchCollocationNeeded, assetUid, query);
    // 'missing', 'in-progress', 'completed', or 'not-needed',
    return existsOrNeeded.status;
  } else {
    return 'not-needed';
  }
}

function* fetchCollocationNeeded(assetUid, query) {
  const body = {
    basedOnUid: assetUid,
    query: query
  };

  try {
    const existsOrNeeded = yield call(
      fetchJsonWithDefaultHeaders,
      '/api/collocate?method=checkExistsOrNeeded',
      { method: 'PUT', body: JSON.stringify(body) }
    );

    return existsOrNeeded;
  } catch (error) {
    const errorJson = yield error.response.json();
    throw errorJson;
  }
}

function* requestCollocation(assetUid, query) {
  const body = {
    basedOnUid: assetUid,
    query: query
  };

  try {
    const collocate = yield call(
      fetchJsonWithDefaultHeaders,
      '/api/collocate?method=createFromQuery',
      { method: 'POST', body: JSON.stringify(body) }
    );
    return collocate;

  } catch (error) {
    const errorJson = yield error.response.json();
    throw errorJson;
  }
}

/**
 * Fetches the rows and columns for the current query from core
 * Note that this actually results in two fetches; one for rows and one for columns
 */
export function* pollRowsAndColumnsForCurrentQuery() {
  const COLLOCATION_POLL_DELAY = 30000;
  const USELESS_COLUMN_PARSE_ERROR = 'Error parsing SoQL query. null';

  /* eslint-disable no-constant-condition */
  while (true) {
    const assetUid = yield select(getAssetUid);
    const query = yield select(getQuery);
    const columnBaseUid = yield select(getColumnBaseUid);

    // add limit and URI encode the query before sending it off...
    const queryWithLimit = addLimitToQuery(query);
    const encodedQuery = encodeURIComponent(queryWithLimit);

    try {
      const collocationStatus = yield call(possibleCollocationStatus, assetUid, query);
      var collocationInProgress = collocationStatus === 'in-progress';

      if (collocationStatus === 'missing') {
        const collocationResponse = yield call(requestCollocation, assetUid, query);
        collocationInProgress = collocationInProgress || collocationResponse.status === 'in-progress';
      }

      // if already in progress, skip to the fetchRows(true)
      if (collocationInProgress) {
        yield put(queryEditorActions.fetchRows(true));
        yield delay(COLLOCATION_POLL_DELAY);
      } else {
        // we want to call these in parallel but don't want the whole thing to blow up if either fails
        // so we catch the errors in the above functions and return them in a special way
        const [maybeRows, maybeColumns] = yield all([
          call(
            maybeFetchRows,
            assetUid,
            encodedQuery
          ),
          call(
            maybeFetchColumns,
            assetUid,
            columnBaseUid,
            encodedQuery
          )
        ]);

        // the error we get back from fetching the columns is generally better than the one we get back from fetching rows,
        // so we'll prioritize returning that as the actual error
        // unless it is this useless error.
        if (maybeColumns.error && maybeRows.error) {
          const bestError = maybeColumns.error.message === USELESS_COLUMN_PARSE_ERROR ? maybeRows.error : maybeColumns.error;
          throw bestError;
        } else if (maybeColumns.error) {
          throw maybeColumns.error;
        } else if (maybeRows.error) {
          throw maybeRows.error;
        }

        const { columns } = maybeColumns;
        const { rows } = maybeRows;

        // This function will go through and add null values for every row that doesn't have a value for a column
        const columnNames = sortBy(columns, ['position']).map(column => column.fieldName);
        const mappedResponse = mapSoqlRowsResponseToTable(columnNames, rows);

        yield put(queryEditorActions.fetchRowsSuccess(columns, mappedResponse.rows));
      }
    } catch (error) {
      yield put(queryEditorActions.fetchRowsFail(error));
      break;
    }
  }
}

// Continue to poll until the fetch succeeds OR the user closes the Query Editor modal
// whichever comes first
function* watchPoll() {
  /* eslint-disable no-constant-condition */
  while (true) {
    yield take(queryEditorActions.FETCH_ROWS);
    yield race([
      call(pollRowsAndColumnsForCurrentQuery),
      take(queryEditorActions.FETCH_ROWS_SUCCESS),
      take(queryEditorActions.CLOSE_QUERY_EDITOR)
    ]);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(queryEditorActions.FETCH_VIEW, fetchView),
    fork(watchPoll)
  ]);
}
