import React, { FunctionComponent } from 'react';
import {
  ForgeIconButton,
  ForgeIcon,
  ForgeButton,
  ForgeDivider,
  ForgeDrawer,
  ForgeModalDrawer
} from '@tylertech/forge-react';
import useScreenSize from 'common/screen_size/useScreenSize';
import I18n from 'common/i18n';
import './FacetSidebar.scss';

interface FacetSidebarProps {
  /** A translated string */
  title: string;
  hasMobileView?: boolean;
  isMobileDrawerOpen?: boolean;
  onMobileDrawerClose?: () => void;
  onMobileClearAllFilters?: () => void;
}

/** A wrapper containing a collection of facets. A title is displayed with
 * the facets underneath. The facets are rendered by passing in a list of
 * FacetSection components as children (children are default props).
 *
 * FacetSidebar should be the first element in the container where it should
 * appear, ideally a ForgeScaffold component. MobileFacetSidebarTrigger will
 * be rendered inline, so it should be placed wherever it is supposed to appear.
 *
 * The mobile version of this component has an X to close the sidebar, as well as
 * two buttons on the bottom: clear all the filter selections, or view the results
 * (which just closes the sidebar).
 */
const FacetSidebar: FunctionComponent<FacetSidebarProps> = ({
  title,
  hasMobileView,
  isMobileDrawerOpen,
  onMobileDrawerClose,
  onMobileClearAllFilters,
  children
}) => {
  const scope = 'shared.components.facet_sidebar';
  const isMobileView = useScreenSize();
  const mobileViewEnabled = hasMobileView ?? false;

  if (isMobileView && mobileViewEnabled) {
    return (
      <ForgeModalDrawer
        slot="left"
        open={isMobileDrawerOpen}
        on-forge-modal-drawer-close={onMobileDrawerClose}
      >
        <div className="facet-sidebar">
          <div className="facet-title">
            <span slot="start" className="forge-typography--title">
              {title}
            </span>
            <ForgeIconButton>
              <button
                type="button"
                aria-label={I18n.t('close_drawer', { scope })}
                onClick={onMobileDrawerClose}
              >
                <ForgeIcon name="close"></ForgeIcon>
              </button>
            </ForgeIconButton>
          </div>
          <div className="facet-sections">{children}</div>
          <ForgeDivider />
          <div className="facet-sidebar-mobile-buttons">
            <ForgeButton>
              <button type="button" onClick={onMobileClearAllFilters}>
                <span>{I18n.t('clear_all', { scope })}</span>
              </button>
            </ForgeButton>
            <ForgeButton type="raised">
              <button type="button" onClick={onMobileDrawerClose}>
                <span>{I18n.t('view_results', { scope })}</span>
              </button>
            </ForgeButton>
          </div>
        </div>
      </ForgeModalDrawer>
    );
  } else {
    return (
      <ForgeDrawer slot="left" direction="left">
        <div className="facet-sidebar">
          <div className="facet-title">
            <span slot="start" className="forge-typography--title">
              {title}
            </span>
            <ForgeDivider />
          </div>
          <div className="facet-sections">{children}</div>
        </div>
      </ForgeDrawer>
    );
  }
};

export default FacetSidebar;
