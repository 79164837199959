import React, { Component } from 'react';

import { refreshAnalysis } from 'common/program_analytics/helpers';
import I18n from 'common/i18n';

interface Props {
  viewId: string;
}

export default class RefreshButton extends Component<Props> {
  render() {
    const scope = 'shared.asset_browser.result_list_table';

    return (
      <button onClick={() => refreshAnalysis(this.props.viewId)} className="user-cell refresh-link">
        {I18n.t('program_analytics_datasets.refresh', { scope })}
      </button>
    );
  }
}
