import * as _ from 'lodash';
import {
  SimpleCompilationResult,
  isCompilationFailed,
  isCompilationSucceeded,
  isCompilationStarted
} from 'common/types/compiler';
import { Option } from 'ts-option';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import I18n from 'common/i18n';
import './compiler-result.scss';
import { ForgeButton } from '@tylertech/forge-react';

const t = (k: string) => I18n.t(`shared.compilation_result.${k}`);

interface CompilationResultProps<CS extends SimpleCompilationResult> {
  busy?: boolean;
  result: Option<CS>;
  onRun?: (r: CS) => void;
}
/* eslint @typescript-eslint/no-shadow: "warn" */
function CompilerResult<CS extends SimpleCompilationResult>(props: CompilationResultProps<CS>) {
  const errorDivRef = useRef<HTMLDivElement>(null);
  const [largeError, setLargeError] = useState(false);
  const [errorExpanded, setErrorExpanded] = useState(false);

  const { inner, disabled, onRun } = props.result.match({
    none: () => {
      const inner = <div className="compiler-result compiler-ready">{t('start')}</div>;
      const disabled = true;
      const onRun = _.noop;
      return { inner, disabled, onRun };
    },
    some: (r) => {
      if (isCompilationStarted(r)) {
        const inner = <div className="compiler-result compiler-in-progress">{t('compiling')}</div>;
        const disabled = true;
        const onRun = _.noop;
        return { inner, disabled, onRun };
      } else if (isCompilationFailed(r)) {
        let classNames = 'compiler-result compiler-error';
        let seeFullErrorLabel = t('error_label_expanded');
        let socrataIconClassName = 'socrata-icon-arrow-up vee-dropdown-icon';
        if (!errorExpanded) {
          classNames += ' overflow-hidden';
          seeFullErrorLabel = t('error_label');
          socrataIconClassName = 'socrata-icon-arrow-down vee-dropdown-icon';
        }
        const inner = (
          <div ref={errorDivRef} className={classNames}>
            {largeError ? (
              <span className="see-full-error-span" onClick={() => onClick()}>
                <span>{seeFullErrorLabel}</span>
                <span className={socrataIconClassName}></span>
              </span>
            ) : (
              ''
            )}
            {
              r.soql_exception.english.includes('\n') ?
                r.soql_exception.english.split('\n').slice(0, -1).join('\n') :
                r.soql_exception.english
            }
          </div>
        );
        const disabled = true;
        const onRun = _.noop;
        return { inner, disabled, onRun };
      } else if (isCompilationSucceeded(r)) {
        const inner = <div className="compiler-result compiler-success">{t('compilation_succeeded')}</div>;
        const disabled = false;
        const userOnRun = props.onRun;
        const onRun = userOnRun ? () => userOnRun(r) : _.noop;
        return { inner, disabled, onRun };
      }
      return { inner: <div></div>, disabled: true, onRun: _.noop };
    }
  });

  const isEllipsisActive = (el: RefObject<HTMLDivElement>) => {
    if (el.current) {
      const currentOverflow = el.current.style.overflow;
      const currentWhiteSpace = el.current.style.whiteSpace;
      const currentTextOverflow = el.current.style.textOverflow;
      if (!currentOverflow || currentOverflow === 'visible') {
        el.current.style.overflow = 'hidden';
        el.current.style.whiteSpace = 'nowrap';
        el.current.style.textOverflow = 'ellipsis';
      }

      const willOverflow =
        el.current.offsetHeight < el.current.scrollHeight || el.current.offsetWidth < el.current.scrollWidth;

      el.current.style.overflow = currentOverflow;
      el.current.style.whiteSpace = currentWhiteSpace;
      el.current.style.textOverflow = currentTextOverflow;
      return willOverflow;
    }
  };

  const onClick = () => {
    setErrorExpanded(!errorExpanded);
  };

  useEffect(() => setLargeError(isEllipsisActive(errorDivRef) || false));

  return (
    <div className="compiler-result-wrap">
      {inner}
      {props.onRun ? (
        <div className="compiler-result-run">
          <ForgeButton type="raised">
            <button
              id={'apply-button'}
              onClick={onRun}
              disabled={disabled}>
              <span className="run-icon socrata-icon-play" />
              {t('run')}
            </button>
          </ForgeButton>
        </div>
      ) : null}
    </div>
  );
}

export default CompilerResult;
