import React, { FunctionComponent } from 'react';
import { isNumber, get, isUndefined } from 'lodash';
import formatNumber from 'common/js_utils/formatNumber';

interface Props {
  /** (Optional) Displayed as the number of rows a dataset has */
  rowCount?: number;
  /** Displayed as the column count for a dataset */
  columnCount: number;
  /** What to label this metadata info */
  rowLabel: string;
}

/** Renders a short <section> that lists the number of rows, number of columns,
 *  and row label of a dataset.
 *
 *  @note Row count may be fetched asynchronously, since we don't have this value
 *  immediately available (i.e. column stats service is not in use) and it may be
 *  expensive to get it on the fly for multi-million-row datasets (see EN-14299).
 */
const RowDetails: FunctionComponent<Props> = ({rowCount, columnCount, rowLabel}) => {
  // Renders row count info. Omitted if row count is not a number.
  const renderRowCount = () => {
    // Legacy guard because there many files using this which are JS
    if (!isNumber(rowCount)) {
      return null;
    }

    return (
      <div className="metadata-pair">
        <dt className="metadata-pair-key">{get(window, 'I18n.common.dataset_contents.rows')}</dt>

        <dd className="metadata-pair-value">{formatNumber(rowCount)}</dd>
      </div>
    );
  };

  // Renders column count info. Omitted if column count is not a positive number.
  const renderColumnCount = () => {
    // Legacy guard because there many files using this which are JS
    if (!isNumber(columnCount) || columnCount <= 0) {
      return null;
    }

    return (
      <div className="metadata-pair">
        <dt className="metadata-pair-key">{get(window, 'I18n.common.dataset_contents.columns')}</dt>

        <dd className="metadata-pair-value">{formatNumber(columnCount)}</dd>
      </div>
    );
  };

  // Renders row label info. Omitted if the row label is empty or default.
  const renderRowLabel = () => {
    // Legacy guard because there many files using this which are JS
    if (!rowLabel || rowLabel.toLowerCase() === get(window, 'I18n.common.default_row_label')) {
      return null;
    }

    return (
      <div className="metadata-pair">
        <dt className="metadata-pair-key">{get(window, 'I18n.common.dataset_contents.row_display_unit')}</dt>

        <dd itemProp="variableMeasured" className="metadata-pair-value">
          {rowLabel}
        </dd>
      </div>
    );
  };

  // Conditionally render row/column stats within the interior content box.
  // Shows a spinner while fetching the row count asychronously.
  const renderRowStats = () => {
    if (isUndefined(rowCount)) {
      return (
        <div className="metadata-section busy">
          <span className="spinner-default" />
        </div>
      );
    }

    return (
      <div className="metadata-section">
        <dl className="metadata-row">
          {renderRowCount()}
          {renderColumnCount()}
          {renderRowLabel()}
        </dl>
      </div>
    );
  };

  return (
    <section className="landing-page-section dataset-contents">
      <h2 className="landing-page-section-header">{get(window, 'I18n.common.dataset_contents.title')}</h2>

      <div className="section-content">{renderRowStats()}</div>
    </section>
  );
};

export default RowDetails;
