import { connect, ConnectedProps } from 'react-redux';
import FlashMessageWrapper from '../components/utils/flashMessage/FlashMessageWrapper';
import { hideFlashMessage } from '../reduxStuff/actions/flashMessage';
import { RootState, AppDispatch } from '../reduxStuff/store';

const mapStateToProps = (state: RootState) => ({
  flashMessages: state.flashMessage.flashMessages
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onCloseClick: (id: string) => dispatch(hideFlashMessage(id))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

// Everything the wrapper component will see, NOT the container's props
export type FlashMessageWrapperDirectProps = ConnectedProps<typeof connector>;

export default connector(FlashMessageWrapper);
