import React from 'react';
import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

const t = (key: string) => I18n.t(key, { scope: 'shared.scan_result' });

export enum ScanStatus {
  Unscanned = 'unscanned',
  Empty = 'empty', // seems to be DSMAPI only
  Ok = 'ok',
  Bad = 'bad',
  TooLarge = 'too-large'
}

export interface ScanResult {
  state: ScanStatus;
  scannedAt: string | null; // representing a date
}

interface Props {
  result: ScanResult;
}

const ScanResultComponent: React.FunctionComponent<Props> = (props: Props) => {
  const { result } = props;
  if (result === undefined) {
    return null;
  }
  const classNames = `scan-result scan-result-${result.state}`;
  let iconName, translationKey: string | undefined;

  switch (result.state) {
    case ScanStatus.Ok:
      iconName = IconName.Security;
      translationKey = 'secure';
      break;
    case ScanStatus.Bad:
      iconName = IconName.Warning;
      break;
    case ScanStatus.TooLarge:
      iconName = IconName.Warning;
      translationKey = 'not_scanned';
      break;
    case ScanStatus.Empty:
    case ScanStatus.Unscanned:
    default:
      return null;
  }

  return (<div className={classNames} title={translationKey && t(translationKey)}>
    <SocrataIcon name={iconName} />
  </div>);
};

export default ScanResultComponent;
