const generateBaseUrl = (): string => {
  let domain: string;
  const serverConfig = window.serverConfig;
  if (serverConfig?.domain) {
    domain = serverConfig.localePrefix
      ? `${serverConfig.domain}${serverConfig.localePrefix}`
      : serverConfig.domain;
  } else {
    console.warn('Unable to get domain from serverConfig');
    domain = window.location.hostname;
  }

  return `https://${domain}`;
};

export { generateBaseUrl };
