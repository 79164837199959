/** Flags that can show up on a user */
enum UserFlags {
  /**
   * The user has a role on the domain
   * @deprecated Please use the "userSegment" field instead
   */
  SiteMember = 'siteMember',

  /**
   * The user is logged in but does NOT have a role on the domain
   * @deprecated Please use the "userSegment" field instead
   */
  CommunityMember = 'communityMember',

  /** The user is a super-admin (an employee or a service account) */
  SuperAdmin = 'admin',

  /**
   * If a user has this, then they can be a co-owner of a story
   * (on some domains, only users with stories rights can be co-owners)
   */
  MayBeStoriesCoOwner = 'mayBeStoriesCoOwner',

  /**
   * If this is not present, the user has accepted the EULA.
   * Users are required to accept this before they can access the site.
   */
  AcceptedEula = 'acceptedEula',

  /**
   * If this is present, then the user is currently deactivated.
   * This can be either on the specific site that they're on, or on the organization as a whole.
   *
   * This means that they cannot login and cannot be shared assets, among other things.
   */
  Disabled = 'disabled',

  /**
   * If this is present, the user has been deactivated on the whole organization.
   * If this is present, then the `disabled` flag will ALWAYS be present.
   *
   * Unless checking in the context of an organization, _always_ check for the `disabled` flag instead of this one.
   */
  DisabledOnOrganization = 'disabledOnOrganization',

  /**
   * If present, this means that the user has been soft-deleted from the entire system and should/does not exist on any domain
   */
  Deleted = 'deleted',

  /**
   * This means that the user does not (and cannot) have a password set, and that they only access their account via SSO or API keys
   */
  NoPassword = 'nopassword'
}

export default UserFlags;
