import React from 'react';

// hack to get around Window being modified globally
type TrueWindow = ReturnType<typeof window.open>;
export type MediaSize = 'small' | 'medium' | 'large' | 'xlarge';
export interface DimensionProps {
  windowHeight: number;
  windowWidth: number;
  mediaSize: MediaSize;
  trackWindow: (window: TrueWindow | undefined) => void;
}

/* eslint @typescript-eslint/ban-types: "warn" */
export default function withWindowSize<P extends object>(WrappedComponent: React.ComponentType<P & DimensionProps>) {
  return class extends React.Component<P> {
    // Makes the component easy to find in tests and React DevTools.
    // Otherwise shows up as some autogenerated name like _class2.
    static displayName = 'WithWindowSize';

    updateWindowDimensions: () => void;

    state = {
      sizes: { [window.name]: { width: 0, height: 0 }},
      current: window.name
    };

    trackWindow(newWindow: TrueWindow | undefined) {
      if (newWindow) {
        this.setState({ current: newWindow.name });
        if (!this.state[newWindow.name]) {
          this.forceUpdateWindowDimensions(newWindow);
          newWindow.addEventListener('resize', () => this.forceUpdateWindowDimensions(newWindow));
        }
      }
    }

    componentDidMount() {
      this.forceUpdateWindowDimensions();
      this.updateWindowDimensions = () => this.forceUpdateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    forceUpdateWindowDimensions = (hookedWindow: TrueWindow = window) => {
      if (!hookedWindow) { return; }
      this.setState({ sizes: { [hookedWindow.name]: { width: hookedWindow.innerWidth, height: hookedWindow.innerHeight }}});
    };

    currentDimensions() {
      const currentStateWindow = this.state.sizes[this.state.current];
      if (currentStateWindow) {
        return currentStateWindow;
      } else {
        // TODO: This case should never happen, but it definitely does.
        // Something to do with the dev tools or undocking or both. Didn't get to the bottom of it.
        return { width: window.innerWidth, height: window.innerHeight };
      }
    }

    render() {
      const currentDimensions = this.currentDimensions();

      // these numbers track common/styleguide/variables/_breakpoints.scss
      let mediaSize = 'xlarge';
      if (currentDimensions.width < 1200) {
        mediaSize = 'large';
      }
      if (currentDimensions.width < 992) {
        mediaSize = 'medium';
      }
      if (currentDimensions.width < 768) {
        mediaSize = 'small';
      }

      return (
        <WrappedComponent
          {...(this.props as P)}
          windowWidth={currentDimensions.width}
          windowHeight={currentDimensions.height}
          mediaSize={mediaSize as MediaSize}
          trackWindow={(win: Window | null | undefined) => this.trackWindow(win)}
        />
      );
    }
  };
}
