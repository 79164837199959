import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

const DEFAULT_TABLET_BREAKPOINT = 1024;
const DEFAULT_MOBILE_BREAKPOINT = 640;

function useViewportSize(
  tabletBreakpoint: number = DEFAULT_TABLET_BREAKPOINT,
  mobileBreakpoint: number = DEFAULT_MOBILE_BREAKPOINT
) {
  const [isTabletView, setIsTabletView] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsTabletView(window.innerWidth < tabletBreakpoint);
      setIsMobileView(window.innerWidth < mobileBreakpoint);
    }

    const throttledHandleResize = throttle(handleResize, 300);

    window.addEventListener('resize', throttledHandleResize);
    handleResize();

    return () => window.removeEventListener('resize', throttledHandleResize);
  }, [tabletBreakpoint, mobileBreakpoint]);

  return { isTabletView, isMobileView };
}

export default useViewportSize;
