import React, { InputHTMLAttributes, PropsWithChildren } from 'react';
import omit from 'lodash/omit';

import { CustomInputProps } from './types';
import RequiredStar from './RequiredStar';

interface CustomRadioProps {
  /**
   * Whether or not to hide the label for the radio button,
   * Useful for rows of radio buttons that may have labels elsewhere
   * NOTE: For accessibility reasons, ALL radio buttons MUST have labels,
   * even if the labels are hidden
   */
  hideLabel?: boolean;

  /** Whether or not the radio button is actually checked */
  checked: boolean;
}

type RadioProps = InputHTMLAttributes<HTMLInputElement> & PropsWithChildren<CustomInputProps & CustomRadioProps>;

/**
 * Generic controlled radio button component
 *
 * Renders with a label (and required * if it's required) and handles valid/disabled/etc.
 *
 * All props will be passed to the "real" <input type="radio" /> that is rendered.
 *
 * Children will be rendered next to the label. This is useful for i.e. buttons that open modals to show more
 * info about the radio button.
 */
const Radio = React.forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { children, id, name, className, label, required, hideLabel, valid, errorMessage } = props;

  // since we need an id to link the label, we use the name if we're not passed an id
  const inputId = id || `form-radio-${name}`;

  // used in aria-describedby for an error message, if there is one
  const errorId = `${inputId}-error`;

  // omit any "non-form" props that have been passed in
  // otherwise, pass the props to the input wholesale
  const filteredProps = omit(props, [
    'key', // if rendering an array of inputs
    'children', // we put this in the label
    'defaultChecked', // potentially from the "uncontrolled" version
    'containerClassName',
    'valid',
    'errorMessage',
    'iconName',
    'label'
  ]);

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...filteredProps,
    id: inputId,
    'aria-required': required
  };

  // we only want to add the `aria-describedby` if we have an error message
  if (!valid) {
    inputProps['aria-describedby'] = errorId;
    inputProps['aria-invalid'] = !valid;
  }

  return (
    <div className={className}>
      <div className="form-component-checkbox button-label-container">
        <label className="form-component-checkbox button-label" htmlFor={inputId}>
          <span className="form-component-checkbox button-label-text-container" hidden={hideLabel}>
            {label} <RequiredStar required={required || false} />
          </span>
          {children}
        </label>
        <input type="radio" ref={ref} {...inputProps} />
        {!valid && (
          <div className="form-component-checkbox button-error-message" hidden={hideLabel} id={errorId}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
});

Radio.defaultProps = {
  hideLabel: false,
  required: false,
  valid: true,
  disabled: false
};

Radio.displayName = 'Radio'; // forwardRef alters the name

export default Radio;
