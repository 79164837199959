import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import { getIconNameForDataType } from 'common/views/dataTypeMetadata';
import SocrataIcon from 'common/components/SocrataIcon';

import Docs from './Docs';
import ColumnDocsRelatedFunctions from './ColumnDocsRelatedFunctions';

class ColumnDocs extends Component {
  static propTypes = {
    column: PropTypes.object.isRequired
  };

  renderFooter = () => {
    const { column } = this.props;
    const { dataTypeName } = column;

    return (
      <div className="query-editor-info-flannel-footer">
        <span>{I18n.t('shared.query_editor.view_full_data_type_docs')}</span>
        <a
          className="query-editor-info-flannel-external-link"
          href={`https://dev.socrata.com/docs/datatypes/${dataTypeName}.html`}
          target="_blank" rel="noreferrer">
            {I18n.t('shared.query_editor.link_text')}
          <SocrataIcon
            className="query-editor-info-flannel-external-icon"
            name="external" />
        </a>
      </div>
    );
  };

  render() {
    const { column } = this.props;
    const { name, fieldName, dataTypeName, description } = column;

    return (
      <Docs title={name} name={fieldName} renderFooter={this.renderFooter}>
        <div className="query-editor-info-flannel-row">
          <div className="query-editor-info-flannel-label">{I18n.t('shared.query_editor.field_name')}</div>
          <div className="query-editor-info-flannel-details">{fieldName}</div>
        </div>
        <div className="query-editor-info-flannel-row">
          <div className="query-editor-info-flannel-label">{I18n.t('shared.query_editor.data_type')}</div>
          <div className="query-editor-info-flannel-details">
            <SocrataIcon
              className="query-editor-info-pane-column-data-type-icon"
              name={getIconNameForDataType(dataTypeName)} />
            {dataTypeName}
          </div>
        </div>
        <div className="query-editor-info-flannel-row">
          <div className="query-editor-info-flannel-label">{I18n.t('shared.query_editor.description')}</div>
          <div className="query-editor-info-flannel-details">{description ? description : I18n.t('shared.query_editor.no_description')}</div>
        </div>
        <div className="query-editor-info-flannel-section-label">
          {I18n.t('shared.query_editor.related_functions')}
        </div>
        <ColumnDocsRelatedFunctions dataTypeName={dataTypeName} />
      </Docs>
    );
  }
}

export default ColumnDocs;
