/**
 * User invitation to an organization or domain API's.
 */
import { checkStatus, fetchJson, defaultHeaders } from './http';
import _ from 'lodash';

// NOTE: Do not change this to an arrow function since it's actually used in an instanceof
// which apparently doesn't work with arrow functions
function FutureAccountsCreationError(errors, fileName, lineNumber) {
  const instance = new Error(fileName, lineNumber);
  instance.errors = errors.map((error) => {
    switch (error) {
      case 'Cannot create a future account for yourself':
        return { translationKey: 'users.errors.create_user_for_self' };
      default:
        return error;
    }
  });

  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  return instance;
}

FutureAccountsCreationError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true
  }
});

/**
 * Get all invited users on the current domain
 * @returns {Promise<any | never>} Array of future users
 */
const getFutureUsers = () => {
  const fetchOptions = {
    credentials: 'same-origin',
    headers: defaultHeaders
  };
  const apiPath = '/api/future_accounts';
  return fetchJson(apiPath, fetchOptions);
};

/**
 *  This invites users to the domain by assigning the given role (roleId) to each of them
 * @param emails - comma separated list of emails
 * @param roleId - a number of the role (administrator, etc..) Id (Available roles for the environment
 *                  can be listed by calling getAllRoles from common/core/roles API)
 * @returns {Promise<any | never>}
 */
export const postFutureUsers = (emails, roleId) => {
  const apiPath = '/api/future_accounts?method=createMultiple';
  const body = new URLSearchParams();
  body.set('addresses', emails);
  body.set('roleId', roleId);
  const fetchOptions = {
    body: body.toString(),
    credentials: 'same-origin',
    headers: _.merge({}, defaultHeaders, { 'Content-Type': 'application/x-www-form-urlencoded' }),
    method: 'POST'
  };
  return fetchJson(apiPath, fetchOptions).then((response) => {
    if (_.get(response, 'errors')) {
      throw new FutureAccountsCreationError(response.errors);
    } else {
      return _.get(response, 'created', []);
    }
  });
};

/**
 * Removes an invitation for a user. This is done by removing the future user (only, if they are registered they are no longer in this)
 * @param id - this is not the 4x4 but a given id
 * @returns {Promise<Response | never>}
 */
export const removeFutureUser = (id) => {
  const apiPath = `/api/future_accounts?method=delete&id=${id}`;
  const fetchOptions = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'DELETE'
  };

  return fetch(apiPath, fetchOptions).then(checkStatus);
};

/**
 * Sends the email with the invite link again
 * @param email
 * @returns {Promise<any | never>}
 */
export const resendFutureUserEmail = (email) => {
  const apiPath = `/api/future_accounts?method=resendEmail&email=${encodeURIComponent(email)}`;
  const fetchOptions = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'POST'
  };

  return fetchJson(apiPath, fetchOptions);
};

export default {
  getFutureUsers,
  postFutureUsers,
  removeFutureUser,
  resendFutureUserEmail,
  FutureAccountsCreationError
};
