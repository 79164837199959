import React, { FunctionComponent } from 'react';
import { ForgeButton, ForgeIcon, ForgeAppBar, ForgePageState } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import 'common/components/ErrorMessageTemplate/error-message.scss';

interface ErrorMessageProps {
  includeHeader?: boolean;
  dataStatus: string | null | undefined;
}

const ErrorMessageTemplate: FunctionComponent<ErrorMessageProps> = ({ includeHeader, dataStatus }) => {
  const numStatus = typeof dataStatus === 'string' ? parseInt(dataStatus) : 403;
  const value = errorValues[numStatus];
  const splitMessage = I18n.t(`shared.errors.${value.error_name}.message`).split('<br/>');
  const pageState = (
    <ForgePageState className="forge-page-state-contents">
      <img src={value.image} slot="graphic" alt="" className={`error-image img-${dataStatus}`} />
      <div slot="title" className="error-title">
        {I18n.t(`shared.errors.${value.error_name}.title`)}
      </div>
      <div slot="message" className="error-message">
        {splitMessage[0]}
        <br></br>
        {splitMessage[1]}
      </div>
      <div className="buttons-container" slot="message">
        <ForgeButton type={value.button.type} slot="action" className={value.button.className}>
          <button onClick={value.button.onClick}>{value.button.text}</button>
        </ForgeButton>
      </div>
    </ForgePageState>
  );
  if (includeHeader) {
    return (
      <div>
        <ForgeAppBar slot="header" title-text="Tyler Data & Insights">
          <ForgeIcon slot="logo" name="tyler_talking_t_logo" external external-type="custom" />
        </ForgeAppBar>
        {pageState}
      </div>
    );
  }
  return <div>{pageState}</div>;
};

const errorValues = {
  403: {
    error_name: 'forbidden_403',
    button: {
      type: 'outlined',
      onClick: () => history.back(),
      text: I18n.t('shared.buttons.go_back'),
      className: 'first-button'
    },
    image: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/403-error-spot-hero.svg'
  },
  404: {
    error_name: 'not_found_404',
    button: {
      type: 'raised',
      onClick: () => (location.href = '/'),
      text: I18n.t('shared.buttons.back_to_homepage'),
      className: 'first-button'
    },
    image: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/404-error-spot-hero.svg'
  }
};

export default ErrorMessageTemplate;
