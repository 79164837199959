import { fetchResults } from 'common/components/AssetBrowser/lib/helpers/cetera';
import { updateQueryString } from 'common/components/AssetBrowser/lib/query_string';
import { CHANGE_PAGE } from 'common/components/AssetBrowser/actions/pager';

export const CHANGE_TAB = 'CHANGE_TAB';
export const SET_INITIAL_TAB = 'SET_INITIAL_TAB';

export const setInitialTab = (initialTab) => (dispatch) => {
  dispatch({ type: SET_INITIAL_TAB, initialTab });
};

export const changeTab = (newTab) => (dispatch, getState) => {
  dispatch({ type: CHANGE_TAB, newTab });
  dispatch({ type: CHANGE_PAGE, pageNumber: 1 });

  const onSuccess = () => {
    updateQueryString({ getState });
  };

  return fetchResults(
    dispatch,
    getState,
    {
      pageNumber: 1,
      action: CHANGE_TAB,
      activeTab: newTab
    },
    onSuccess
  );
};
