import { SCREENS, ScreenType } from 'common/components/ScheduleModal/constants';
import * as _ from 'lodash';
import * as helpers from 'common/components/ScheduleModal/helpers';
import { Agent } from 'common/types/gateway';
import { AgentAction, URLAction } from 'common/types/schedule';
import React from 'react';
import { AutomationParams } from 'common/types/schedule';

interface ScheduleFromURLProps {
  action: URLAction;
  changeScreen: (s: ScreenType) => () => void;
}
function ScheduleFromURL({ action, changeScreen }: ScheduleFromURLProps) {
  return (
    <div className="scheduling-form-control scheduling-source">
      <label htmlFor="url">{helpers.t('url')}</label>
      <div className="scheduling-form-change">
        <div className="scheduling-form-textinput scheduling-form-url">{action.url}</div>
        {action.url &&
          <button className="scheduling-form-change-params" onClick={changeScreen(SCREENS.REDIRECT)}>
            {helpers.t('change_url_btn')}
          </button>}
      </div>
    </div>
  );
}

interface ScheduleFromAgentProps {
  action: AgentAction & { agent: Agent | undefined };
  changeScreen: (s: ScreenType) => () => void;
}
function ScheduleFromAgent({ action, changeScreen }: ScheduleFromAgentProps) {
  let agentName = _.get(action.agent, 'name', '');
  let agentClasses = '';
  let pluginName = action.namespace.name;
  let pluginClasses = '';
  const deletedTranslation = helpers.t('deleted');

  if (action.agent == undefined) {
    agentName = `[${deletedTranslation}]`;
    agentClasses = 'deleted';
  }

  if (action.agent == undefined || !helpers.agentHasNamespace(action.agent, action.namespace)) {
    pluginName = `${pluginName} [${deletedTranslation}]`;
    pluginClasses = 'deleted';
  }

  return (
    <div className="scheduling-source">
      <div className="scheduling-form-attributes">
        <div>
          <label>{helpers.t('agent')}</label>
          <span className={agentClasses}>{agentName}</span>
        </div>
        <div>
          <label>{helpers.t('plugin_name')}</label>
          <span className={pluginClasses}>{pluginName}</span>
        </div>
        <div>
          <label>{helpers.t('datasource')}</label>
          <span>{helpers.getHumanSourceName({type: 'connection_agent', parameters: action})}</span>
        </div>
        <div>
          <label>{helpers.t('plugin_type')}</label>
          <span>{action.namespace.type}</span>
        </div>
      </div>

      <div className="scheduling-form-change">
        <button className="scheduling-form-change-params" onClick={changeScreen(SCREENS.REDIRECT)}>
          {helpers.t('change_agent_btn')}
        </button>
      </div>
    </div>
  );
}

interface ScheduleAttributesProps {
  automationParams: AutomationParams;
  changeScreen: (s: ScreenType) => () => void;
}
export default function ScheduleAttributes({ automationParams, changeScreen }: ScheduleAttributesProps) {
  if (helpers.isURL(automationParams)) {
    return <ScheduleFromURL action={automationParams.parameters as URLAction} changeScreen={changeScreen} />;
  }
  if (helpers.isAgent(automationParams)) {
    return <ScheduleFromAgent action={automationParams.parameters} changeScreen={changeScreen} />;
  }
  return null;
}
