import React from 'react';
import I18n from 'common/i18n';
import cx from 'classnames';
import { includes as _includes } from 'lodash';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.dataset_tab') =>
  I18n.t(key, { scope });

export interface LabelAndErrorsProps {
  errors: string[];
  isPrivate?: boolean;
  isRequired?: boolean;
  name: string;
  label?: string;
  className?: string;
  useLabels: boolean;
}

const LabelAndErrors: React.FunctionComponent<LabelAndErrorsProps> = ({
  errors,
  isPrivate,
  isRequired,
  children,
  name,
  label,
  className,
  useLabels
}) => {
  const halfWidthThings = ['tags', 'category', 'row_label', 'contact_email'];
  const halfWidth = _includes(halfWidthThings, name);

  const labelClassNames = ['block-label'];
  const labels = [label].filter((labelToTest) => !!labelToTest);

  if (isRequired) {
    labelClassNames.push('label-required');
    labels.push(t('subtitles.required_field'));
  }

  return (
    <div className={cx(className, { 'half-width': halfWidth })}>
      {label && useLabels && (
        <label htmlFor={name} className={labelClassNames.join(' ')} aria-label={labels.join(' ')}>
          {label}
        </label>
      )}
      {isPrivate && useLabels && (
        <div>
          <SocrataIcon name={IconName.Private} />
          <span className={'private-message'}>{t('subtitles.private_field')}</span>
        </div>
      )}
      {children}
      <ul className="error-list">
        {errors.map((error, index) => (
          <li className="error-message" key={index}>
            {error}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LabelAndErrors;
