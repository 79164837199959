import { fetchJsonWithParsedError } from 'common/http';

const subscriptionApi = {
  checkSubscription: (viewId, activity) => {
    const fetchUrl = '/api/notifications_and_alerts/subscriptions?dataset_domain=' +
    `${window.location.host}&dataset=${viewId}&activity=${activity}`;

    return fetchJsonWithParsedError(fetchUrl, {
      method: 'GET'
    }).then((result) => result.data);
  },
  subscribe: (datasetId, activity) => {
    return fetchJsonWithParsedError('/api/notifications_and_alerts/subscriptions', {
      method: 'POST',
      body: JSON.stringify({
        subscription: {
          domain: window.location.host,
          dataset: datasetId,
          activity
        }
      })
    }).
    then((subscribedResult) => subscribedResult.data);
  },
  unsubscribe: (subscriptionId) => {
    return fetchJsonWithParsedError(`/api/notifications_and_alerts/subscriptions/${subscriptionId}`, {
      method: 'DELETE'
    }).
    then((unsubscribedResult) => unsubscribedResult.data);
  }
};

export default subscriptionApi;
