import React from 'react';
import { FunctionComponent } from 'react';
import './AssetCardSkeleton.scss';
import { ForgeCard } from '@tylertech/forge-react';
import { ForgeSkeleton } from '@tylertech/forge-react';
import classNames from 'classnames';
import { isMobileOrTablet } from 'common/visualizations/helpers/MediaQueryHelper';


const AssetCardSkeleton: FunctionComponent = () => {
  const isMobile = isMobileOrTablet();
  return (
    <div className="asset-card-skeleton" aria-hidden="true">
      <ForgeCard>
        <div>
          <div className="header">
            <ForgeSkeleton avatar></ForgeSkeleton>
            <ForgeSkeleton form-field></ForgeSkeleton>
          </div>
          <div className={classNames({ 'body-section': true, 'mobile': isMobile })}>
            <div className={classNames({ 'left-section': true, 'mobile': isMobile })}>
              <div className='body-content'>
                <ForgeSkeleton text></ForgeSkeleton>
                <ForgeSkeleton text></ForgeSkeleton>
              </div>
              <div data-testid="asset-card-tag-section" className={'tag-section body-content'}>
                <ForgeSkeleton chip></ForgeSkeleton>
                <ForgeSkeleton chip></ForgeSkeleton>
                <ForgeSkeleton chip></ForgeSkeleton>
                <ForgeSkeleton chip></ForgeSkeleton>
              </div>
            </div>
            <div className={classNames({ 'right-section': true, 'mobile': isMobile })}>
              <ForgeSkeleton form-field></ForgeSkeleton>
              <ForgeSkeleton form-field></ForgeSkeleton>
            </div>
          </div>
        </div>
      </ForgeCard>
    </div>
  );
};

export default AssetCardSkeleton;
