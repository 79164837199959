/* Imports
============================================================================= */
import React, { Component } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import mixIntoComponentInstance from 'common/components/mixIntoComponentInstance';
import { AlertListAlert } from 'common/components/AlertList/AlertListAlert';
import { AlertDisplayType, AlertListComponent, AlertListProps, AlertListAlertProps } from 'common/components/AlertList/AlertListTypes';

import './alert-list.scss';





/* Predefined Values
============================================================================= */
export const alertTypeDisplayOrder = [
  AlertDisplayType.Success,
  AlertDisplayType.Info,
  AlertDisplayType.Notice,
  AlertDisplayType.Warning,
  AlertDisplayType.Error
];





/* Component Definition
============================================================================= */
export const component = {
  props: {} as AlertListProps,



  /* Attributes
  ----------------------------------------------------------------- */




  /* Methods
  ----------------------------------------------------------------- */

  /* Helpers
  ------------------------------------------------------- */
  /**
   * Sort the alerts into display order
   * @returns AlertListAlertProps[]
   */
  sortAlerts: function () {
    const { alerts } = this.props;
    const sortedAlerts = alerts.concat([]).sort((a: AlertListAlertProps, b: AlertListAlertProps) => {
      const aTypeIndex = alertTypeDisplayOrder.indexOf(a.type);
      const bTypeIndex = alertTypeDisplayOrder.indexOf(b.type);
      let response = 0;

      if (aTypeIndex > bTypeIndex) {
        response = 1;
      } else if (aTypeIndex < bTypeIndex) {
        response = -1;
      }

      return response;
    });

    return sortedAlerts;
  },



  /* Renderers
  ------------------------------------------------------- */
  /**
   * Render an AlertListAlert element from the given properties
   * @param alert The alert properties to render into an AlertListAlert element
   * @returns JSX.Element
   */
  renderAlert: function (alert: AlertListAlertProps) {
    return <AlertListAlert key={alert.alertId} {...alert} />;
  },

  /**
   * Render an array of AlertListAlert properties into AlertListAlert elements
   * @param alertList Array of alert property objects to render with renderAlert()
   * @returns Array<JSX.Element> | null
   */
  renderAlertList: function (alertList: AlertListAlertProps[]) {
    let response = null;

    if (!isEmpty(alertList)) {
      response = alertList.map(this.renderAlert) as Array<JSX.Element>;
    }

    return response;
  }
} as AlertListComponent;





/* Class Definition
============================================================================= */
export class AlertList extends Component<AlertListProps> {
  /* Attributes
  ----------------------------------------------------------------- */



  /* Static & Builtins
  ----------------------------------------------------------------- */
  static defaultProps = {
    alerts: []
  };



  /* Methods
  ----------------------------------------------------------------- */
  constructor(props: AlertListProps) {
    super(props);

    // Extend the component object onto our class instance
    mixIntoComponentInstance.call(this, this, [component]);
  }


  /* Helpers
  ------------------------------------------------------- */
  sortAlerts: () => AlertListAlertProps[];


  /* Renderers
  ------------------------------------------------------- */
  renderAlert: (alert: AlertListAlertProps) => JSX.Element;

  renderAlertList: (alertList: AlertListAlertProps[]) => Array<JSX.Element> | null;

  render() {
    const { alerts } = this.props;
    let response = null;

    if (!isEmpty(alerts)) {
      response = <div className="alert-list">{this.renderAlertList(this.sortAlerts())}</div>;
    }

    return response;
  }
}

export default AlertList;
