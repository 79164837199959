import { FinishOptions } from 'common/components/hocs/withStepper/types';
// NOT intenteded to be reliable
// Only cares about three OSs. Sorry BSD and SunOS people.
// For reference see:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator
// https://stackoverflow.com/a/19883965
export type OS = 'windows' | 'linux' | 'macos';

export function sniffOS(): OS {
  const nav: Navigator = window.navigator;

  if (/win/i.test(nav.platform)) {
    return 'windows';
  } else if (/mac/i.test(nav.platform)) {
    return 'macos';
  } else {
    return 'linux';
  }
}

export function titleCase(str: string): string {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
}

export interface Props {
  handleStepStateChange: (newState: any) => void;
  stepState: any;
  stepIdx: number;
}
