import * as QueryEditorActions from './QueryEditorActions';
import { generateSelectAllColumnsQuery } from '../Util';

/** Hold onto an instance of the Ace Editor for cursor positioning */
const editorLoaded = (state, { editorInstance }) => {
  return {
    ...state,
    editorInstance
  };
};

/** User is typing into the editor */
const queryChanged = (state, { query }) => ({
  ...state,
  query,
  isValid: true
});

/** The "Add" button on an action item is clicked */
const actionListItemClicked = (state, { action }) => {
  const { query, editorInstance } = state;
  const cursorPosition = editorInstance.getSession().selection.getCursor();

  const newQuery = query.split(/\n/).map((row, i) => {
    if (i === cursorPosition.row) {
      return `${row.substring(0, cursorPosition.column)}${action}${row.substring(cursorPosition.column)}`;
    } else {
      return row;
    }
  }).join('\n');

  return {
    ...state,
    query: newQuery
  };
};

/** View was successfully fetched from core */
const fetchViewSuccess = (state, { view }) => ({
  ...state,
  view,

  // if we don't have a query, generate one from the view's columns
  query: state.query ? state.query : generateSelectAllColumnsQuery(view)
});

/** Failed to fetch view from core */
const fetchViewFail = (state, { error }) => {
  console.error('Error fetching view', error);

  return {
    ...state,
    viewFetchError: error
  };
};

/** Fetch rows from core for the current query; actual fetching done in a saga */
const fetchRows = (state, { collocating }) => ({
  ...state,
  collocating,
  fetchingRows: !collocating,
  rowFetchError: null
});

/** Successfully fetched rows/columns from core */
const fetchRowsSuccess = (state, { columns, rows }) => ({
  ...state,
  columns,
  rows,
  fetchingRows: false,
  collocating: false
});

/** Failed to fetch rows/columns from core */
const fetchRowsFail = (state, { error }) => {
  console.error('Error fetching rows', error);

  return {
    ...state,
    rowFetchError: error,
    fetchingRows: false
  };
};

/** Success callback was called at least once */
const successCallbackCalled = (state) => ({
  ...state,
  successCallbackCalled: true
});

/** Shows the documentation for something */
const showDocumentation = (state, { docType, name }) => ({
  ...state,
  documentation: {
    docType,
    name
  }
});

/** Hides the currently open documentation */
const hideDocumentation = (state) => ({
  ...state,
  documentation: null
});

export default (state = {}, action) => {
  switch (action.type) {
    case QueryEditorActions.EDITOR_LOADED:
      return editorLoaded(state, action);
    case QueryEditorActions.QUERY_CHANGED:
      return queryChanged(state, action);
    case QueryEditorActions.ACTION_LIST_ITEM_CLICKED:
      return actionListItemClicked(state, action);
    case QueryEditorActions.FETCH_VIEW_SUCCESS:
      return fetchViewSuccess(state, action);
    case QueryEditorActions.FETCH_VIEW_FAIL:
      return fetchViewFail(state, action);
    case QueryEditorActions.FETCH_ROWS:
      return fetchRows(state, action);
    case QueryEditorActions.FETCH_ROWS_SUCCESS:
      return fetchRowsSuccess(state, action);
    case QueryEditorActions.FETCH_ROWS_FAIL:
      return fetchRowsFail(state, action);
    case QueryEditorActions.SUCCESS_CALLBACK_CALLED:
      return successCallbackCalled(state, action);
    case QueryEditorActions.SHOW_DOCUMENTATION:
      return showDocumentation(state, action);
    case QueryEditorActions.HIDE_DOCUMENTATION:
      return hideDocumentation(state, action);
    default:
      return state;
  }
};
