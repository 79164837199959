import React from 'react';
import _ from 'lodash';

import './index.scss';

interface WithToolTipState {
  offset: any;
  visible: boolean;
}

function withTooltip(Component: JSX.Element, tip: JSX.Element) {
  return class WithTooltip extends React.Component<any, WithToolTipState> {
    constructor(props = {}) {
      super(props);
      this.state = {
        visible: false,
        offset: { left: 'calc(20% + 0px)' }
      };
      this.getOffset = this.getOffset.bind(this);
    }

    getOffset(node: HTMLSpanElement) {
      if (!node || !node.children || !node.children.length) {
        return;
      }

      // kind of a gross hack but the user shouldn't have to mess with the
      // internals of this component very often. The problem this solves is:
      // we don't have access to the passed component to make it relative, so
      // the tooltip is positioned relative to the "gateway-tooltip-container".
      // If the user adds padding or margin to the passed component, it moves
      // but the tooltip does not. So this grabs the styling of the passed component
      // and programatically moves the tooltip along with it.
      const computedStyles = window.getComputedStyle(node.children[0]);
      const marginLeft = parseInt(computedStyles['margin-left'], 10);
      const paddingLeft = parseInt(computedStyles['padding-left'], 10);
      const newOffset = { left: `calc(20% + ${Math.max(marginLeft, paddingLeft)}px` };

      if (!_.isEqual(newOffset, this.state.offset)) {
        this.setState({
          offset: newOffset
        });
      }
    }

    render() {
      return (
        <div
          className="with-tooltip-container"
          onMouseOver={() =>
            this.setState({
              visible: true
            })
          }
          onMouseOut={() =>
            this.setState({
              visible: false
            })
          }
        >
          <span ref={(n: HTMLSpanElement) => this.getOffset(n)}>{Component}</span>
          {this.state.visible && (
            <div className="with-tooltip" style={this.state.offset}>
              {tip}
            </div>
          )}
        </div>
      );
    }
  };
}

export default withTooltip;
