import { ViewColumn } from 'common/types/viewColumn';
import { ExtraOptions, TableColumn } from '../types';
import { map, each, isNil, keyBy, get, isObject, Dictionary } from 'lodash';
import { Row } from './type';
import {
  agGridDataFormatter,
  isEmailType
} from 'common/visualizations/views/agGridReact/helpers/TableColumnFormatting';
import { Column, GridApi, ICellRendererParams } from '@ag-grid-community/core';
import { isUrlColumnType } from 'common/visualizations/helpers/CalendarHelper';

interface FormatRowDataArgs {
  rowData: Row[];
  tableColumns: TableColumn[];
  viewColumns: ViewColumn[];
  gridApi: GridApi;
  extraOptions: ExtraOptions;
}

// Tried doing it in the print preview worker. But the DataTypeFormatter requires
// jQuery and i18n. So for now, formatting the table cell content in the main thread.
// Later: Probably, we can create a generic DataTypeFormatter that takes
// sanitizeHTML/currencySymbol as inputs.
// - original DataTypeFormatter will use jquery/I18n for sanitizeHTML/currencySymbol
// - web worker DataTypeFormatter where we will pass a new sanitizeHTML and pass
//  currencySymbol to webWorker from mainThread
export const formatRows = ({
  rowData,
  tableColumns,
  viewColumns,
  extraOptions,
  gridApi
}: FormatRowDataArgs) => {
  const { dataset, nonStandardAggregations, columnFormats } = extraOptions;
  const allDisplayedColumns: Column[] = gridApi.getAllDisplayedColumns();
  const rowGroupCols: Column[] = gridApi.getRowGroupColumns();
  const columnsById = keyBy(allDisplayedColumns.concat(rowGroupCols), (col) => col.getColId().toString());
  const fieldToViewColumn = keyBy(viewColumns, (viewColumn) => viewColumn.fieldName);

  return map(rowData, (rowDatum: Row) => {
    const newRowDatum: Row = { ...rowDatum };

    each(tableColumns, (tableColumn) => {
      const viewColumn = fieldToViewColumn[tableColumn.field];
      const value = rowDatum[tableColumn.field];
      const columnDef = columnsById[tableColumn.field]?.getColDef();
      const newColumnParams = {
        ...columnDef,
        colDef: columnDef,
        value,
        valueFormatted: value
      } as ICellRendererParams;
      const isTextColumn = viewColumn.renderTypeName === 'text';
      const isUrlColumn = isUrlColumnType(tableColumn.field, viewColumns);
      const isEmailColumn = isEmailType(tableColumn.field, viewColumns);
      const isDateColumn = viewColumn.renderTypeName === 'calendar_date';
      const isCountAgg = columnDef.aggFunc === 'count';
      const isAggValue = ['subTotal', 'total', 'header'].includes(rowDatum._rowType);

      if (isCountAgg && isDateColumn && isAggValue) {
        // The PDF incorrectly renders the aggregation values (COUNT) of the date column
        // in date format, instead of as a number.
        return value;
      }

      if (!isNil(value)) {
        const formattedValue =
          isUrlColumn || isEmailColumn
            ? formatUrlValue(value)
            : agGridDataFormatter(
                dataset.domain,
                dataset.datasetUid,
                viewColumns,
                nonStandardAggregations,
                newColumnParams,
                columnFormats,
                false,
                true
              );
        newRowDatum[tableColumn.field!] = isTextColumn ? decodeHtmlEntities(formattedValue) : formattedValue;
      }
    });

    return newRowDatum;
  });
};

interface URLValue {
  url: string;
}
const formatUrlValue = (value: URLValue | string) => {
  return isObject(value) ? value.url : value;
};

const decodeHtmlEntities = (text: string) => {
  const div = document.createElement('div');
  div.innerHTML = text;
  return div.textContent || div.innerText;
};
